import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import ImagebuilderFilterSection from '../Image/ImagebuilderFilterSection';
import AuditSection from './AuditSection';
import { getSubPath } from '../../../utils/utility';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region', 'image_name', 'duration']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath.replace(getSubPath, '');
                    if(row.submenulist.filter(e => e.link === trimmedPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter && filter.selectedProvider,
            selectedAccount: filter && filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter && filter.selectedRegion ? filter.selectedRegion : [],
            selectedImageType: filter && filter.selectedImageType ? filter.selectedImageType : '',
            selectedImage: filter && filter.selectedImage ? filter.selectedImage : '',
            datePickerStartDate: filter && filter.datePickerStartDate ? filter.datePickerStartDate : '',
            datePickerEndDate: filter && filter.datePickerEndDate ? filter.datePickerEndDate : '',
            callSearch: true            
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            let prevValue = state.selectedTab ? state.selectedTab : 'Image'
            setState(prevState => ({ ...prevState, callSearch: false, selectedTab: "" }))
            setTimeout(() => {
                setState(prevState => ({ ...prevState, selectedTab: prevValue })) // Set the same value
            }, 1000); // Change to the desired delay (in milliseconds)
        }
    }, [state.callSearch, state.selectedTab])

    const handleClickOutside = (event) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
    };

    return (
        <div onClick={(event) => handleClickOutside(event, 'parent')}>
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Audit Logs</p>
                        {/* <p className="mx-4 text-white">consolidate image audit</p> */}
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <ImagebuilderFilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex grow'>
                <div className='w-full p-2'>
                    <div className='bg-black rounded-md'>
                        {state.selectedTab === 'Image' && state.selectedProvider ?
                            <AuditSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                                datePickerEndDate={state.datePickerEndDate}
                                datePickerStartDate={state.datePickerStartDate}
                            />
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage