/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsTab.js
 * @author Prakash // on 05/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
// import PropTypes from 'prop-types'
import { listAlerts, getAlertsDailyTrend } from '../../../actions/Collider/ObservabilityAction'
// import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentConvertionLocalToUtcTime, subDays, getDayFromSelectedDuration, subHours } from '../../../utils/utility'

import Search from '../../common/SearchWithHiddenInput'
import ApexTrendBarChart from '../../common/charts/ApexTrendBarChart'
import MultiSelectSection from '../../common/MultiSelectSection'
import { DateRange } from 'react-date-range'
import {Icon} from "@iconify/react";

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const AlertsTab = (props) => {
    const dateRef = useRef();

    const [state, setState] = useState({
        alertListLoader: true,
        
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        pageType: "Alerts",
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        minimizeGraphSection: false,
        selectedGraphDuration: '+3d'
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const dispatch = useDispatch()

    

    useEffect(() => {
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedGraphDuration !== 'custom') {
            let period = state.selectedGraphDuration.substring(state.selectedGraphDuration.length, state.selectedGraphDuration.length - 1);
            let durationBtw = getDayFromSelectedDuration(state.selectedGraphDuration);
            
            if (period === 'd') {
                datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');

            setState(prevState => ({ 
                ...prevState, 
                datePickerStartDate, 
                datePickerEndDate,
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

            setDateState([dateSelection])
        }

	}, [state.selectedGraphDuration])

    useEffect(() => {
        if(state.callAlertsDatilyTrend) {
            setState(prevState => ({ ...prevState, callAlertsDatilyTrend: false }))
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            let assetId = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            // params.asset_id = assetId
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = assetId
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            dispatch(getAlertsDailyTrend(params))
                .then((response) => {
                    if(response && !response.error) {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: response }))   
                    } else {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: {} }))
                    }
                })
                
            setState(prevState => ({ ...prevState, callAlertsTrend: true }))

        }
    }, [state.callAlertsDatilyTrend, dispatch, state.selectedAsset, state.datePickerStartDate, state.datePickerEndDate, props])

    useEffect(() => {
        // let graphData = {
        //     start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
        //     end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
        // }
        let graphData = {}
        let trendSeverityCount = state.alertDailyTrendData && state.alertDailyTrendData.total_count ? state.alertDailyTrendData.total_count : {}
        let graphDetails = state.alertDailyTrendData && state.alertDailyTrendData.results ? state.alertDailyTrendData.results : {}           
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            graphData = {
                data: graphDetails.data.count,
                labels: graphDetails.labels,
                // start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                // end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        setState(prevState => ({ ...prevState, graphData, trendSeverityCount }))

    }, [state.alertDailyTrendData,])

    const resetGraph = () => {
        let graphData = state.graphData
        setState(prevState => ({ ...prevState, graphData: {}, zoomedStartDate: "", zoomedEndDate: "", calltoGetCategoryALerts: true }))
        setTimeout(() => {
            setState(prevState => ({ ...prevState, graphData }))            
        }, 2000); 
    }
    
    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    // const gotoPage = (pageNumber) => {
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState,  currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState,  pageNumber: state.currentPage })  )
    //     } else {
    //         setState(prevState => ({ ...prevState,  pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callAlertGroups) {
            setState(prevState => ({ ...prevState, callAlertGroups: false }))
            let params = {}
            // params.alerts_case_id = '2408-1028-7602'
            
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            // let assetId = state.selectedAsset && state.selectedAsset.asset_id ? state.selectedAsset.asset_id : ""
            // params.asset_id = assetId

            params.aggregate_by = state.groupedBy
            
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }   
            
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false }))
                    } else {
                        setState(prevState => ({ ...prevState, alertResponse: [], alertListLoader: false }))
                    }
                })
        }

    }, [state.callAlertGroups, state.alertGroupData, dispatch, props, state.alertType, state.groupedBy, state.selectedAsset, state.datePickerEndDate, state.datePickerStartDate])

    useEffect(() => {
        let listAllAlerts = []
        let totalCount = 0
        let alertsCategory = []
        if(state.alertResponse && state.alertResponse.length) {
            totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), calltoGetCategoryALerts: true  }))
    }, [state.alertResponse])

    useEffect(() => {
        if(state.calltoGetCategoryALerts) {
            let filterdCategoryAlerts = state.listAllAlerts
            if(state.selectedCategory !== 'Important / All') {
                filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
                if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                    filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
                }
            }
    
            let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])
    
            let totalPages = 1
            if(orderedArray.length > state.perPage) {
                totalPages = Math.ceil(orderedArray.length / state.perPage)
            }
            setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlert: "", selectedAlertId: "", totalPages, startRecord: 0, currentPage: 1, calltoGetCategoryALerts: false, }))
        }
    }, [state.calltoGetCategoryALerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory])

    const filterZoomData = (zoomedStartDate, zoomedEndDate) => {
        let data = state.totalFilteredArray ? state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > state.perPage) {
			totalPages = Math.ceil(data.length / state.perPage)
		}

        setState(prevState => ({ ...prevState, totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate }))

    }

    const getAlertDetails = (alertItemDetails) => {
        setState(prevState => ({ 
            ...prevState, 
            selectedAlertId: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails.event_id, 
            selectedAlert: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails,
            expandAlertDetails: true,
            expandAssetDetails: false
        }))
    }
    
    const handleChange = (event) => {
        let groupedBy = state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        setState(prevState => ({ ...prevState, alertType: event.target.value, groupedBy, selectedAlert: "", selectedAlertId: "", alertListLoader: true, callAlertGroups: true }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",                
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className="" onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap justify-between mb-2'>
                <p className='mb-1 lg:w-1/2 w-full self-center'>Showing alerts for the period of <span className='text-info'>{momentConvertionLocalToUtcTime(state.datePickerStartDate, 'MMM DD YYYY HH:mm')} - {momentConvertionLocalToUtcTime(state.datePickerEndDate, 'MMM DD YYYY HH:mm')}</span> (utc time)</p>
                <div className='py-1 lg:w-1/2 w-full pl-3 flex justify-end'>
                    <p className="b-block mb-0 text-lightGray self-center mr-3">Duration</p>
                    <div onClick={(event) => {
                        if(!state.showDuration) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "showDuration")
                        }
                    }}>
                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between cursor-pointer ${state.selectedGraphDuration ? 'text-black' : 'text-lightGray'}`}>
                            {state.selectedGraphDuration ? (durationOptions.filter(e => e.value === state.selectedGraphDuration).length ? durationOptions.filter(e => e.value === state.selectedGraphDuration)[0].option : state.selectedGraphDuration) : 'Select'}
                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedGraphDuration ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                        </p>
                        {state.showDuration ?
                            <div className='position-relative'>
                                <MultiSelectSection 
                                    fields={["value", "option"]}
                                    removeTop={true}
                                    options={durationOptions}
                                    selectedValues={state.selectedGraphDuration ? state.selectedGraphDuration : []}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            if(value !== 'custom') {
                                                setState(prevState => ({ ...prevState, selectedGraphDuration: value }))
                                            } else {
                                                setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                            }
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    className={'options-right-container'}
                                    singleSelection={true}
                                    hideSearch={false}
                                    removeTopOptions={true}
                                />
                            </div>
                        : null}
                    </div>
                </div>
                {state.showDateRangePicker ?                                                
                    <div className='headerDateRange mt-2' style={{'zIndex': '999999999','position': 'absolute','right': '20px','margin-top': '50px'}} ref={dateRef}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={dateState}
                        />
                    </div>
                : null}
            </div>
            <div className="bg-GhostWhite mt-2 p-2 rounded-md">
                <div className="flex justify-between">
                    <div className="flex">
                        <p className="mb-0">Total ({state.trendSeverityCount && state.trendSeverityCount.total ? state.trendSeverityCount.total : 0})</p>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-darkRed px-2 py-1 self-center`}>C</div>
                            <p className="m-0 text-sm text-black"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Critical ? state.trendSeverityCount.severity.Critical : 0}</p>
                        </div>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-HalloweenOrange px-2 py-1 self-center`}>H</div>
                            <p className="m-0 text-sm text-black"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.High ? state.trendSeverityCount.severity.High : 0}</p>
                        </div>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-SlateBlue px-2 py-1 self-center`}>M</div>
                            <p className="m-0 text-sm text-black"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Medium ? state.trendSeverityCount.severity.Medium : 0}</p>
                        </div>
                        <div className="flex ml-2 self-center">
                            <div className={`text-xs text-white bg-darkGreen px-2 py-1 self-center`}>L</div>
                            <p className="m-0 text-sm text-black"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Low ? state.trendSeverityCount.severity.Low : 0}</p>
                        </div>
                    </div>
                    <div className="flex">
                        {!state.minimizeGraphSection ?
                            <i className='far fa-redo cursor-pointer' onClick={()=> resetGraph()}></i>
                        : null}
                        <i className={`fa fa-caret-${state.minimizeGraphSection ? "down" : "down"} cursor-pointer ml-3 text-gray2`} onClick={() => setState(prevState => ({ ...prevState, minimizeGraphSection: !state.minimizeGraphSection }))}></i>
                    </div>
                </div>
                {state.graphData && Object.keys(state.graphData).length && !state.minimizeGraphSection ?
                    <div className="mt-n2"> 
                        <ApexTrendBarChart
                            graphData={state.graphData}
                            graphRiskCountSection={false}
                            sparkline={false}
                            yaxis={false}
                            yaxisLabel={true}
                            yaxisTickAmount={2}
                            xaxis={true}
                            xaxisFormat={'string'}
                            xaxisLabel={true}
                            axisLabelColor={'#50586A'}
                            legend={false}
                            stacked={false}
                            height={100}
                            horizontal={false}
                            barHeight={'40%'}
                            barEndShape={'flat'}
                            columnWidth={'0%'}
                            backgroundBarShape={'rounded'}
                            backgroundBarColors={['#333947']}
                            showBackgroundBarColors={true}
                            className={"transparentTooltip"}
                            colors={['#3DAFE8']}
                            zoomEnabled={true}
                            getZoomPeriod={true}
                            zoomPeriod={(zoomedStartDate, zoomedEndDate) => {filterZoomData(zoomedStartDate, zoomedEndDate)}}
                        />
                    </div>
                : null}
            </div>
            <div className="flex mt-2">
                <div className="rounded-md bg-GhostWhite p-2 w-full">
                    <div className={`flex mt-2 ml-1 mb-2 ${state.alertListLoader ? 'disabled' : ""}`}>
                        <div className="flex w-2/3">
                            <p className="m-0 self-end small mr-2">Show </p>
                            <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit px-2 pb-1 focus-visible:border-b border-mediumDarkGray mr-2" onChange={handleChange} value={state.alertType}>
                                <option className="text-black" key="All" value="All">ALL</option>
                                <option className="text-black" key="Application" value="Application">Application</option>
                                <option className="text-black" key="Infra" value="Infra">Infra</option>
                            </select>
                            <p className="m-0 self-end small mx-2 text-nowrap"> alerts grouped by</p>
                            <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit px-2 pb-1 focus-visible:border-b border-mediumDarkGray mr-2 w-fit" onChange={(e) =>
                                setState(prevState => ({ ...prevState, groupedBy: e.target.value, selectedAlert: "", selectedAlertId: "", selectedCategory: "Important / All", alertListLoader: true, callAlertGroups: true }))
                            } value={state.alertType}>
                                <option value='severity'>Severity</option>
                                <option value='event_name'>Name</option>
                                {state.alertType !== "Application" ?
                                    <React.Fragment>
                                    {/* <option value='asset_name'>Assets</option> */}
                                    {/* <option value='resource_type'>Service</option> */}
                                    <option value='category'>Category</option>
                                    </React.Fragment>
                                : null}
                            </select>
                        </div>
                        <div className="flex flex-wrap ml-2 w-1/3">
                            <p className="m-0 self-end small mx-2">Category</p>
                            {state.alertsCategory ? 
                                <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit px-2 pb-1 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => setState(prevState => ({ ...prevState, selectedCategory: e.target.value, calltoGetCategoryALerts: true }))} value={state.selectedCategory}>
                                    {state.alertsCategory.map(item => {
                                        return(
                                            <option value={item.category}>{item.category+" "+item.count}</option>
                                        )
                                    })}
                                </select>
                            : null}
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-2 justify-between w-full">
                        <div className="flex w-2/3 self-center">
                            <p className="text-sm m-0 self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length} alert(s)</p>
                            
                            {state.zoomedStartDate && state.zoomedStartDate !== "" ? 
                                <p className="text-sm m-0 self-center pt-1 text-info ml-1">{momentDateGivenFormat(state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                            :
                                <p className="text-sm m-0 self-center pt-1 text-info ml-1">{state.brushStartTime ? momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</p>
                            }
                        </div>
                        <div className="flex justify-end w-1/3 self-center">
                            {state.totalFilteredArray && state.totalFilteredArray.length ?
                                <Search
                                    data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                    topClassName={'text-black bg-transparent border border-lightGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={25}
                                    filteredData={(filteredArray) => setState(prevState => ({ ...prevState, filteredArray }))}
                                />
                            : null}
                            {state.filteredArray && state.filteredArray.length > state.perPage ?
                                <div className='flex justify-end text-black w-full mt-2'>
                                    <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                    <div className="pagination text-sm">
                                        <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                        <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                        <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                        <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                    
                    <div className="flex flex-wrap">
                        <div className={`bg-GhostWhtie py-3 pl-2 w-full`}>
                            {state.alertListLoader ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : state.filteredArray && state.filteredArray.length ?
                                state.filteredArray.map((item, i) => {
                                    return(
                                        <div key={'item_'+i} className={`px-2 mb-2 pb-1 border border-extraLightGray ${state.selectedAlertId && state.selectedAlertId === item.event_id ? "bg-BlockWhite" : ""}`}> 
                                            <div className="flex cursor-pointer" onClick={() => getAlertDetails(item)}>
                                                <span className={`text-xs mr-2 self-start mt-1  bg-${item.severity ? item.severity.toLowerCase() : 'critical'} px-y py-1 rounded-full`}>
                                                    {item.severity ? 
                                                        (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                    : 'U'}
                                                </span>
                                                <p className="mb-0">
                                                    <span className="mb-0 mr-2 text-black text-sm font-weight-bold text-lightGray">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                    <span className={`mb-0 mr-2 text-sm text-purple-2`}>
                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                        {item.event_name ? ' : ' + item.event_name : ""}
                                                        {item.event_type ? ' : '+ item.event_type : ""}</span>
                                                    {item.asset_name ?
                                                        <span className="mb-0 mr-2 text-sm text-black font-weight-bold">{item.asset_name}</span>
                                                    : null}
                                                    <span className="mb-0 small mr-1 text-lightGray">Resource</span>
                                                    {item.account_id ?
                                                        <span className="mb-0 text-sm text-lightGray font-weight-bold">{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span className="mb-0 text-sm text-lightGray font-weight-bold">{' : '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span className="mb-0 text-sm text-lightGray font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                    : null}
                                                    {item.service_name ?
                                                        <span className="mb-0 text-sm text-lightGray font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                    : null}
                                                    {item.description ?
                                                        <span className="mb-0 ml-2 text-sm text-black font-weight-bold">{item.description}</span>
                                                    : null}
                                                </p>
                                            </div>
                                            {state.selectedAlertId && state.selectedAlertId === item.event_id ?
                                                <div className="rounded-md bg-dark3 p-3">
                                                    <div className={`bg-dark p-3 rounded-md mb-2`}>
                                                        <div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandAlertDetails: !state.expandAlertDetails }))}>
                                                            <div className="p-3 mb-0 flex justify-between">
                                                                <span className='ml-2'>Alert Details</span>
                                                                {state.expandAlertDetails ?
                                                                    <Icon icon="mdi-light:minus" className={`text-black slef-center cursor-pointer`} width="20" height="20" />
                                                                : 
                                                                    <Icon icon="iconoir:plus" width="20" height="20"  className='text-black slef-center cursor-pointer' />
                                                                }
                                                            </div>
                                                        </div>
                                                        {state.expandAlertDetails ?
                                                            <div className="bg-black p-3 rounded-md">
                                                                <div className="flex flex-wrap justify-between mb-2 pb-2">
                                                                    <div>
                                                                        <p className="mb-0 text-lg text-black">{state.selectedAlert.event_name ? state.selectedAlert.event_name : ""}</p>
                                                                        <p className="mb-0 text-sm">{state.selectedAlert.description ? state.selectedAlert.description : ""}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="b-block mb-0">Severity</p>
                                                                        <span className={`mr-2 bg-${state.selectedAlert.severity ? state.selectedAlert.severity.toLowerCase() : 'transparent border border-lightGray' }`}>  
                                                                            {state.selectedAlert.severity ? capitalizeFirstLetter(state.selectedAlert.severity) : 'Unknown'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                                                    <div className="py-1 lg:w-1/2 w-full">
                                                                        <p className="b-block mb-0">Alert Time</p>
                                                                        <p className="mb-0 text-black">
                                                                            {state.selectedAlert.type === 'alert' ?
                                                                                state.selectedAlert.event_time ? momentConvertionUtcToLocalTime(state.selectedAlert.event_time, 'DD MMM YYYY HH:mm') : ""
                                                                            :
                                                                                state.selectedAlert.created_at ? momentConvertionUtcToLocalTime(state.selectedAlert.created_at, 'DD MMM YYYY HH:mm') : ""
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div className="py-1 lg:w-1/2 w-full lg:pl-3 pl-0">
                                                                        <p className="b-block mb-0">Alert Metrics</p>
                                                                        {state.selectedAlert.type === 'alert' ?
                                                                            <p className="mb-0 text-black">
                                                                                {state.selectedAlert.event_source ? state.selectedAlert.event_source : ""}
                                                                                {state.selectedAlert.event_type ? (' : ')+state.selectedAlert.event_type : ""}
                                                                            </p>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            
                                                                <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                                                    <div className="py-1 lg:w-1/2 w-full">
                                                                        <p className="b-block mb-0">Threshold / Statistic / Period</p>
                                                                        <p className="mb-0 text-warning">
                                                                            {state.selectedAlert.threshold && state.selectedAlert.threshold}{state.selectedAlert.unit ? (state.selectedAlert.unit ? (state.selectedAlert.unit.toLowerCase() === 'percent' ? ' %' : state.selectedAlert.unit) : "") : ""}
                                                                            {state.selectedAlert.statistic ? (' / ')+state.selectedAlert.statistic : ""}
                                                                            {state.selectedAlert.period ? (' / ')+state.selectedAlert.period : ""}
                                                                        </p>
                                                                    </div>
                                                                    <div className="py-1 lg:w-1/2 w-full lg:pl-3 pl-0">
                                                                        <p className="b-block mb-0">Provider : Account : Region </p>
                                                                        <p className="mb-0 text-black">
                                                                            {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                            {state.selectedAlert.account_id ? (' : ')+state.selectedAlert.account_id : ""}
                                                                            {state.selectedAlert.region ? (' : ')+state.selectedAlert.region : ""}
                                                                        </p>
                                                                    </div>
                                                                </div>                                                                       
                                                            </div>
                                                        : null}
                                                    </div>

                                                    <div className={`p-2 bg-black rounded-md mb-2`}>
                                                        <div id={'heading_config_assets'} onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                                            <div className="p-3 mb-0 flex justify-between">
                                                                <span className='ml-2'>Asset Details</span>
                                                                {state.expandAssetDetails ?
                                                                    <Icon icon="mdi-light:minus" className={`text-black slef-center cursor-pointer`} width="20" height="20" />
                                                                : 
                                                                    <Icon icon="iconoir:plus" width="20" height="20"  className='text-black slef-center cursor-pointer' />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div id={'collapse_config_assets'} className={`collapse ${state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                <div className="flex mb-2">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Name</p>
                                                                        <p className="mb-0 text-black">{state.selectedAlert.asset_name ? state.selectedAlert.asset_name : ""}</p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Id</p>
                                                                        <p className="mb-0 text-black">{state.selectedAlert && state.selectedAlert
                                                                        .asset_id ? state.selectedAlert.asset_id : ""}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex mb-2 border-top">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Resource</p>
                                                                        <p className="mb-0 text-black">
                                                                            {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                            {state.selectedAlert.account_id ? (" : ")+state.selectedAlert.account_id : ""}
                                                                            {state.selectedAlert.region ? (" : ")+state.selectedAlert.region : ""}
                                                                        </p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Services</p>
                                                                        <p className="mb-0 text-black">
                                                                            {state.selectedAlert.resource_type ? state.selectedAlert.resource_type : ""}
                                                                            {state.selectedAlert.service_name ? (" : ")+state.selectedAlert.service_name : ""}
                                                                        </p>
                                                                    </div>
                                                                </div>                                                        
                                                                <div className="flex mb-2 border-top">
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">ARN</p>
                                                                        <p className="mb-0 text-black">{state.selectedAlert.asset_arn ? state.selectedAlert.asset_arn : ""}</p>
                                                                    </div>
                                                                </div>
                                                                {state.selectedAlert && state.selectedAlert.category ? 
                                                                    <div className="flex mb-2 border-top">
                                                                        <div className="py-1 w-100">
                                                                            <p className="b-block mb-0">Category</p>
                                                                            <div className={`flex flex-wrap`}>
                                                                                {Array.isArray(state.selectedAlert.category) ?
                                                                                    state.selectedAlert.category.map(item => {
                                                                                        return(
                                                                                            <small key={'alert_'+item} className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                        )
                                                                                    })
                                                                                : 
                                                                                    <small className={`mr-1 mb-1 badge badge-light`}>{state.selectedAlert.category}</small>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    )
                                })
                            :
                                <div className='flex justify-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertsTab