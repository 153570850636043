import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeTheFirstLetterOfEachWord, convertToLowerCase, isValidDateTime, momentConvertionUtcToLocalTime, removeUnderScore } from '../../utils/utility';
import { getJobMetrics } from '../../actions/Collider/JobStatusAction';

import MetricsTab from '../common/Mertics/MetricsTab';
import LogsTab from './LogsTab';
import {Icon} from "@iconify/react";

const JobDetailsSidePanel = ({ selectedJobDetails, closeSidePanel, detailsFormat }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef()
    const [state, setState] = useState({
        showLoading: false,
        selectedTab: "Details",
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (state.selectedTab === "Observability") {
            let params = {}
            params.start_time = selectedJobDetails && selectedJobDetails.start_time
            params.end_time = selectedJobDetails && selectedJobDetails.end_time
            params.asset_id = selectedJobDetails && selectedJobDetails.asset_id
            if(selectedJobDetails && selectedJobDetails.created_at) {
                params.created_at = selectedJobDetails.created_at
            }
            // Fetch job metrics data here using params and update the state
            dispatch(getJobMetrics(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false,
                        }));
                    }
                })
        }
    }, [state.selectedTab, dispatch, selectedJobDetails])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className='advanced-search overflow-auto' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search-content w-3/5 bg-GhostWhite' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='font-bold text-lg mb-0'>Create Details</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className="flex flex-wrap text-sm font-bold text-center">
                        <li className="me-2">
                            <p className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</p>
                        </li>
                        <li className="me-2">
                            <p className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Script' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Script' }))}>Script</p>
                        </li>
                        <li className="me-2">
                            <p className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Licenses' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Licenses' }))}>Licenses</p>
                        </li>
                        <li className="me-2">
                            <p className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Observability' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Observability' }))}>Observability</p>
                        </li>
                        <li className="me-2">
                            <p className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Logs' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Logs' }))}>Logs</p>
                        </li>
                        {/* <li className="nav-item">
                            <p className={`nav-link cursor-pointer ${state.selectedTab === 'Cost' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Cost' }))}>Cost</p>
                        </li>
                        <li className="nav-item">
                            <p className={`nav-link cursor-pointer ${state.selectedTab === 'Output Summary' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Output Summary' }))}>Output Summary</p>
                        </li> */}
                    </ul>
                </div>
                <div className='m-2 overflow-auto'>
                    {state.selectedTab === "Details" ? 
                        <React.Fragment>
                            <div className="rounded bg-white p-3">
                                <React.Fragment>
                                {detailsFormat && detailsFormat.length ? 
                                    detailsFormat.map((row, index) => {
                                        return(
                                            <div className={`${!index ? '' : 'mt-2'}`} key={'key_'+index}>
                                                <p className={`mb-1 text-SlateBlue text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                                <small className='text-lightGray'>{row.description}</small>
                                                {row.fields && row.fields.map(fld => {
                                                    return(
                                                        fld === 'licenses' && selectedJobDetails && Array.isArray(selectedJobDetails[fld]) ?
                                                            selectedJobDetails[fld].map(item => {
                                                                return(
                                                                    Object.entries(item).map(([key, value], cIndex) => {
                                                                        return(
                                                                            <div key={'vol_'+cIndex} className="flex mt-3">
                                                                                <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                                <span className="text-black">{value}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        :
                                                            <div key={fld} className="flex mt-3">
                                                                <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(fld)}</span>
                                                                {selectedJobDetails && selectedJobDetails[fld] ?
                                                                    <React.Fragment>
                                                                    {typeof selectedJobDetails[fld] === 'boolean' ?
                                                                        <span className={`text-black`}>{selectedJobDetails[fld] ? 'True' : 'False'}</span>
                                                                    : isValidDateTime(selectedJobDetails[fld]) ?
                                                                        <span className="text-black">{momentConvertionUtcToLocalTime(selectedJobDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                    : convertToLowerCase(fld) === "status" ?
                                                                        <span className={`self-center border border-${convertToLowerCase(selectedJobDetails[fld])}`}>{selectedJobDetails[fld]}</span>
                                                                    : convertToLowerCase(fld) === "tags" ?
                                                                        <div className="flex flex-wrap gap-1">
                                                                            {selectedJobDetails[fld].map(item => {
                                                                                return(
                                                                                    <span key={item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    : 
                                                                        <span className="text-black">{fld === 'provider' ? selectedJobDetails[fld].toUpperCase() : selectedJobDetails[fld]}</span>
                                                                        // <span className="text-black">{selectedJobDetails[fld]}</span>
                                                                    }
                                                                    </React.Fragment>
                                                                : null}
                                                            </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                : null}
                                </React.Fragment>
                            </div>
                            <div className="rounded bg-white p-3 mt-3">
                                {selectedJobDetails && selectedJobDetails.job_script ?
                                    <React.Fragment>
                                    <p className={`mt-2 mb-1 text-SlateBlue text-lg pb-1`}>Job Script</p>
                                    <div className='text-black bg-white rounded-md p-3'>
                                        <pre className='text-black' style={{lineHeight: 2.5}}>{selectedJobDetails.job_script}</pre>
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>

                        </React.Fragment>
                    : state.selectedTab === "Script" ?
                        <div className="rounded bg-GhostWhite p-3">
                            {selectedJobDetails && selectedJobDetails.job_script ?
                                <React.Fragment>
                                <p className={`mt-2 mb-1 text-SlateBlue text-xl font-bold pb-1`}>Job Script</p>
                                <div className='text-black bg-white rounded-md p-3'>
                                    <pre className='text-black' style={{lineHeight: 2.5}}>{selectedJobDetails.job_script}</pre>
                                </div>
                                </React.Fragment>
                            : 
                                <div className='flex justify-center m-4'>
                                    Script not available for the selecetd job
                                </div>
                            }
                        </div>
                    : state.selectedTab === "Observability" ?
                        <div className=''>
                            {state.showMetricLoading ?
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : state.metricDetails ?
                                <MetricsTab
                                    metricDetails={state.metricDetails}
                                    selectedDetails={selectedJobDetails}
                                    height={175}
                                    showStatistics={["min", "avg", "max"]}
                                />
                            : null}
                        </div>
                    : state.selectedTab === 'Licenses' ?
                        <div className="rounded">
                            <p className={`mb-2`}>Showing the list of licenses used in job <span className="text-info">{selectedJobDetails && selectedJobDetails.job_name ? selectedJobDetails.job_name : ""}</span> </p>
                            <div className="relative overflow-x-auto mt-2">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-md text-black bg-white">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">Application</th>
                                            <th scope="col" className="px-6 py-3">License Count</th>
                                            <th scope="col" className="px-6 py-3">License Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {selectedJobDetails.licenses && selectedJobDetails.licenses.length ?
                                        selectedJobDetails.licenses.map((row, index)=> {
                                            return(
                                                <tr key={'lic_'+index} className="bg-GhostWhite border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td className="px-6 py-4 text-sm">{row.application_name ? row.application_name : ''}</td>
                                                    <td className="px-6 py-4 text-sm">{row.license_name ? row.license_name : ''}</td>
                                                    <td className="px-6 py-4 text-sm">{row.licenses_count ? row.licenses_count : ''}</td>
                                                </tr>
                                            )
                                        })
                                    : 
                                        <tr>
                                            <td colspan="3" className='px-6 py-3 text-center text-lightGray'>
                                                There are no licenses for this job.
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    : state.selectedTab === "Logs" ?
                        <LogsTab
                            selectedJobDetails={selectedJobDetails}
                        />
                    : null}
                </div>
            </div>
        </div>
    );
};

export default JobDetailsSidePanel;