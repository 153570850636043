/*************************************************
 * Collider
 * @exports
 * @file QueueDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { listStackEvents } from '../../../actions/CatalogAction';
import Search from '../../common/SearchComponent'
import Select from 'react-select';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, subDays, subHours, momentDateGivenFormat } from '../../../utils/utility';
import MetricsTab from '../../common/Mertics/MetricsTab';
import { getQueueMetrics } from '../../../actions/Collider/ObservabilityAction';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import {Icon} from "@iconify/react";

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const QueueDetailsSidePanel = ({ selectedQueueDetails, closeSidePanel, detailsFormat }) => {
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const clickOut = useRef();
    const dateRef = useRef();
    const dispatch = useDispatch(); // Create a dispatch function    
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (state.callEventsList && selectedQueueDetails) {
            setState(prevState => ({ ...prevState, callEventsList: false, showEventsLoading: true }));
            let params = {}
            params.provider = selectedQueueDetails.provider
            params.account_id = selectedQueueDetails.account_id
            params.region = selectedQueueDetails.region
            params.cluster_name = selectedQueueDetails.cluster_name
            params.queue_name = selectedQueueDetails.queue_name
            
				
			if(state.nextToken) {
				params.next_token = state.nextToken
			}

            // Fetch job metrics data here using params and update the state
            dispatch(listStackEvents(params))
                .then((response) => {
                    if(response) {
                        let nextToken = ""
                        if(response.next_token) {
                            nextToken = response.next_token	
                        }

                        let results = [] 
                        if(state.nextToken && state.nextToken.length) {
                            results = state.listEvents && state.listEvents.length ? state.listEvents : []
                        }
                        
                        if(response.results && response.results.length) {
                            results = results.concat(response.results)
                        }
                        
                        let totalPages = 1
                        if(results && results.length) {
                            totalPages = Math.ceil(results.length / state.perPage)
                        }
                        setState(prevState => ({ ...prevState, listEvents: results, totalEvents: response.total ? response.total : 0, totalPages, nextToken, filteredListEvents: results, showEventsLoading: false }));
                    }
                })
        }
    }, [state.callEventsList, dispatch, selectedQueueDetails, state.nextToken, state.listEvents, state.perPage])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {            
            setState(prevState => ({ ...prevState, showEventsLoading: true, callEventsList: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callMetricAPi) {

            let duration = state.selectedGraphDuration
            let startTime = '';
            let endTime = ''
            if(duration === 'custom') {
                startTime = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss');
                endTime = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss');
            } else {
                let period = duration.substring(duration.length, duration.length - 1);
                let durationBtw = getDayFromSelectedDuration(duration);
                
                if (period === 'd') {
                    startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                } else {
                    startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                }
                endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
            }
    
            let params = {};
            params.start_time = startTime;
            params.end_time = endTime;
            params.asset_id = selectedQueueDetails && selectedQueueDetails.asset_id;
            if(selectedQueueDetails && selectedQueueDetails.queue_name) {
                params.queue_name = selectedQueueDetails.queue_name
            }
            
            if(selectedQueueDetails && selectedQueueDetails.account_id) {
                params.account_id = selectedQueueDetails.account_id
            }
            if(selectedQueueDetails && selectedQueueDetails.region) {
                params.region = selectedQueueDetails.region
            }
        
            if (selectedQueueDetails && selectedQueueDetails.asset_id) {            
                dispatch(getQueueMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                callMetricAPi: false
                            }));
                        }
                    });
            }
        }
    }, [state.callMetricAPi, dispatch, state.selectedGraphDuration, selectedQueueDetails, state.datePickerEndDate, state.datePickerStartDate])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
    }

    const handleSelect = (date) => {
        // let datePickerStartDate = momentDateGivenFormat(
        //     date.selection.startDate,
        //     'YYYY-MM-DD 00:00:00'
        // );
        // let datePickerEndDate = momentDateGivenFormat(
        //     date.selection.endDate,
        //     'YYYY-MM-DD 23:59:59'
        // );
        // setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }))     
        
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",
                metricDetails: [], 
                showMetricLoading: true,
                callMetricAPi: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-GhostWhite overflow-auto' ref={clickOut}>
                <div className='header-search bg-white flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='text-xl'>Queue Details</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>              
                <div className='m-2'>
                    <div className={`px-2`}>
                        <ul className="flex flex-wrap text-sm font-bold text-center">
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Events' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', callEventsList: true }))}>Events</span>
                            </li>
                        </ul>
                    </div>
                    <div className="rounded-md bg-white p-3 min-h-screen overflow-y-auto">
                        {state.selectedTab === 'Details' ?
                            detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div className={`${!index ? '' : 'mt-2'}`}>
                                        <p className={`mb-1 text-SlateBlue text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-lightGray'>{row.description}</small>
                                        {row.fields && row.fields.map(fld => {
                                            return(
                                                fld === "queue_configuration" ?
                                                    <div className={`rounded-md bg-GhostWhite p-2 mb-2 mt-2`}>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Subnet IDs</span>
                                                            <div className="flex flex-wrap">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].subnet_ids ?
                                                                    selectedQueueDetails[fld].subnet_ids.map(ids => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 mt-1 mr-1 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Security Group IDs</span>
                                                            <div className="flex flex-wrap">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].security_group_ids ?
                                                                    selectedQueueDetails[fld].security_group_ids.map(ids => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 mt-1 mr-1 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Policies</span>
                                                            <div className="flex flex-wrap">
                                                                {selectedQueueDetails[fld] && selectedQueueDetails[fld].policies ?
                                                                    selectedQueueDetails[fld].policies.map(pol => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 mt-1 mr-1 rounded-full text-black text-xs break-all'>{pol}</span>
                                                                        )
                                                                    })
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Script Location</span>
                                                            <span className={`self-center text-black break-all`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].script_location ? selectedQueueDetails[fld].script_location : ""}</span>
                                                        </div>

                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Capacity Reservation Id</span>
                                                            <span className={`self-center text-black break-all`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].reservation_id ? selectedQueueDetails[fld].reservation_id : ""}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Volume Details</p>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Type</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_type ? selectedQueueDetails[fld].volume_type : ""}</span>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Size</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_size && selectedQueueDetails[fld].volume_size}</span>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Encryption</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_encryption ? "True" : "False"}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>S3 Access</p>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Bucket</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].bucket_name ? selectedQueueDetails[fld].bucket_name : ""}</span>
                                                        </div>
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">Write Access</span>
                                                            <span className={`self-center text-black`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].enable_s3_write_access ? "True" : "False"}</span>
                                                        </div>

                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Compute Resources</p>
                                                        {selectedQueueDetails[fld] && selectedQueueDetails[fld].compute_resources ?
                                                            selectedQueueDetails[fld].compute_resources.map((comp, cIndex) => {
                                                                return (
                                                                    <div className={`bg-BlockWhite p-2 rounded mb-2 ${!cIndex ? "mt-2" : ""}`}>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Name</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Instance Type</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Min Instance Count</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Max Instance Count</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Efa Enabled</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Placement Group Enabled</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                    </div>
                                                                    <div className="flex mt-3">
                                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Disable Simultaneous Multithreading</span>
                                                                        <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                    </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : null}
                                                    </div>
                                                :
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">{removeUnderScore(fld)}</span>
                                                        {selectedQueueDetails && selectedQueueDetails[fld] && fld !== "queue_configuration" ?
                                                            <React.Fragment>
                                                            {typeof selectedQueueDetails[fld] === 'boolean' ?
                                                                <span className={`text-black break-all`}>{selectedQueueDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedQueueDetails[fld]) ?
                                                                <span className="text-black break-all">{momentConvertionUtcToLocalTime(selectedQueueDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{selectedQueueDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div className="flex flex-wrap">
                                                                    {selectedQueueDetails[fld].map(item => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 mt-1 mr-1 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-black break-all">{fld === 'provider' ? selectedQueueDetails[fld].toUpperCase() : selectedQueueDetails[fld]}</span>
                                                            }
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        : state.selectedTab === "Events" ?
                            <div className='m-2 flex-grow'>
                                {state.showEventsLoading ?
                                    <div className='flex justify-center m-4'>
                                        <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                    </div>
                                : state.listEvents && state.listEvents.length ?
                                    <React.Fragment>
                                        <div className="flex flex-wrap mb-2 justify-between">
                                            <div className="flex mr-2 w-4/6">
                                                <p className="text-sm m-0 self-center pt-1">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                                            </div>
                                            <div className="flex justify-end w-2/6 mb-n2">
                                                {state.listEvents && state.listEvents.length ?
                                                    <Search
                                                        data={state.listEvents ? state.listEvents : []}
                                                        topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                                        searchClassName={'px-2'}
                                                        searchIconColor={'text-black '}
                                                        searchPlaceHolder={'Search....'}
                                                        className={'bg-transparent text-black'}
                                                        widthHeight={25}
                                                        filteredData={(filteredListEvents) => {
                                                            let totalPages = 1
                                                            if(filteredListEvents.length > state.perPage) {
                                                                totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                            }
                                                            setState(prevState => ({ ...prevState, filteredListEvents, totalPages }))
                                                        }}
                                                    />
                                                : null}
                                                {state.listEvents && state.listEvents.length > state.perPage ?
                                                    <div className='flex justify-end text-black w-full'>
                                                        <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.listEvents / state.perPage)}</strong> </span>
                                                        <div className="pagination text-sm">
                                                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                                            {/* <span>
                                                                Go to
                                                                <input
                                                                    type='number'
                                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                                    onChange={e => {
                                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                                        gotoPage(page)
                                                                    }}
                                                                    className="ml-1 inputClass"
                                                                />
                                                            </span> */}
                                                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        {state.filteredListEvents && state.filteredListEvents.length ?
                                            state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <div key={"fil_"+i} className={`px-2 mb-2 pb-1 ${!i ? "" : "border-t border-mediumDarkGray"}`}> 
                                                        <div className="flex cursor-pointer break-all">
                                                            <p className="mb-0">
                                                                <span className="mb-0 mr-2 text-black text-sm font-semibold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                                {item.resource_type ?
                                                                    <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_type}</span>
                                                                : null}
                                                                {item.resource_status ?
                                                                    <span className={`mb-0 mr-2 text-sm text-info ml-2`}>{item.resource_status}</span>
                                                                : null}
                                                                {item.user_id ?
                                                                    <span className={`mb-0 mr-2 text-sm ml-2`}>{item.user_id}</span>
                                                                : null}
                                                                {item.resource_status_reason ?
                                                                    <span className="mb-0 ml-2 text-sm text-black font-semibold">{item.resource_status_reason}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className='flex justify-center m-4 text-black'>
                                                There are no events on this criteria. Please try adjusting your filter.
                                            </div>
                                        }
                                    </React.Fragment>
                                :
                                    <div className='flex justify-center m-4 text-black'>
                                        Therer are no events on this criteria.
                                    </div>
                                }
                            </div>
                        : state.selectedTab === 'Observability' ?  
                            <div className='rounded bg-black m-2 p-2 overflow-y-auto min-h-screen'>
                                {selectedQueueDetails && selectedQueueDetails.asset_id ?
                                    <div className='flex justify-between mb-1'>
                                        <p className='mb-1'>Showing data for the period of <span className='ml-1 text-info'>{state.datePickerStartDate ? momentDateGivenFormat(state.datePickerStartDate, "DD MMM YYYY HH:mm") : ''} - {state.datePickerStartDate ? momentDateGivenFormat(state.datePickerEndDate, "DD MMM YYYY HH:mm") : ''}</span> <span className='ml-2'>(local time)</span>
                                        </p>
                                        <div className="flex">
                                            <label className='mb-0 mr-2 self-center'>Duration</label>
                                            <div style={{ minWidth: "130px" }}>
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    className='f13 p-0 filterSelectDrop'
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    value={{
                                                        value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                                        label: state.selectedGraphDuration ? durationOptions.filter(e => e.value === state.selectedGraphDuration)[0].option : <span className='placeholder'>Select</span>
                                                    }}
                                                    options={durationOptions && durationOptions.map(row => ({
                                                        value: row.value,
                                                        label: row.option,
                                                    }))}
                                                    onChange={event => {
                                                        if(event.value !== 'custom') {
                                                            setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: event.value, showDateRangePicker: false }))
                                                        } else {
                                                            if(state.selectedGraphDuration === 'custom') {
                                                                setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                                            } else {
                                                                setState(prevState => ({ ...prevState, selectedGraphDuration: 'custom', showDateRangePicker: true }))
                                                            }
                                                        }                                                    
                                                    }}
                                                />
                                                {state.showDateRangePicker ?
                                                    <div className='headerDateRange mt-2' style={{zIndex: '999999999','position': 'absolute','right': '10px'}} ref={dateRef}>
                                                        <DateRange
                                                            editableDateInputs={true}
                                                            onChange={handleSelect}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={dateState}
                                                        />
                                                        {/* // <div className='obsDateTimePicker text-black5 pt-2 ml-n1' style={{zIndex: '999999999','position': 'absolute','right':0}}>
                                                        //     <RangePicker
                                                        //         show={true} // default is false
                                                        //         disabled={false}
                                                        //         placeholder={['Start Time', 'End Time']}
                                                        //         defaultTimes={['00:00', '23:59']} // ['hh:mm', 'hh:mm']
                                                        //         onConfirm={handleSelectNew}
                                                        //         // onClose={() => console.log('onClose')}
                                                        //         // onClear={() => console.log('onClear')}
                                                        //         style={{ width: '610px', margin: '0 auto' }}
                                                        //     />
                                                        // </div> */}
                                                        
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <p className='f12 m-3 self-center'>Please select an asset to get the Metric details</p>
                                }
                                <div className='flex justify-between mb-1'>
                                    {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                        <span className={`ml-1 text-info mb-0`}>
                                            <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                            {selectedQueueDetails.asset_name}
                                        </span>
                                    : null}
                                    {/* {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                        <div className={`rounded mr-2 bg-black text-info px-2 py-1 f12 truncate self-center`} id={`metricsTitle`}>
                                            {selectedQueueDetails.asset_name}
                                        </div>
                                    : null} */}
                                </div>
                                {state.showMetricLoading ?
                                    <div className='flex justify-center m-4'>
                                        <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                    </div>
                                : Object.entries(selectedQueueDetails).length && !state.showMetricLoading ?
                                    <MetricsTab
                                        metricDetails={state.metricDetails}
                                        selectedDetails={selectedQueueDetails}
                                        height={115}
                                    />
                                : null}
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QueueDetailsSidePanel;