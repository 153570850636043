/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageAccounts.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';

import { getOnboardedAccountsDetails, deleteOnboardedAccount } from '../../../actions/AccountAction'

import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, getSubPath, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import OnboardAccountSidePanel from './OnboardAccountSidePanel';
import OnboardClusterSidePanel from './OnboardClusterSidePanel';
import AccountDetailSidePanel from './AccountDetailSidePanel'

const ManageAccounts = () => {	
	const[state, setState] = useState({
		showLoading: true,
		selectedAccountDetails: {},
		selectedClusterDetails: {},
		actionsAllowed: []
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath.replace(getSubPath, '');
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
				// else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true }));
		setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
	}, [])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(getOnboardedAccountsDetails(params))
				.then((response) => {
					if(response.results) {
						setState(prevState => ({ ...prevState, listAccounts: response.results, filterListAccounts: response.results, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listAccounts: [], filterListAccounts: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListAccounts && state.filterListAccounts.length ? state.filterListAccounts : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListAccounts])

    const deleteAccountFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.deleteAccountDetails 
        let params = {}
		params.account_id = item.account_id
		dispatch(deleteOnboardedAccount(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response && response.message ? response.message : "Error in deleting"

					if(response.status) {
						messageType = 'success'
						// toggle()
						setTimeout(() => { setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callSearch: true, showDeleteLoading: false }))}, 1000)
					} else {
						setState(prevState => ({ ...prevState, showDeleteLoading: false }))
					}


					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})

    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>

            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
					<div className="xl:col-span-6 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Manage Accounts</p>
						<p className="mx-4 text-black">Manage list of accounts</p>
					</div>
					<div className="xl:col-span-6 col-span-12 self-center xl:mt-4">
						{state.allocationDetails && state.allocationDetails.length ?
							state.allocationDetails.map(row => {
								return(
									<div className='bg-GhostWhite border border-mediumDarkGray rounded-md px-5 py-3 mr-3'>
										<div className='flex justify-center'>
											<div className='w-fit-content'>
												<p className='text-black text-center mb-1 f14'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray f14'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
												<p className='text-center f18 mb-0 text-warning'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray f14'>GBP</span></p>
											</div>
										</div>
									</div>
								)
							})
						: null}
					</div>
				</div>
			</div>
			{state.showCreateAccountPanel ?
				<OnboardAccountSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showCreateAccountPanel: false }))}
					pageMode={state.pageMode}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					selectedAccountDetails={state.selectedAccountDetails}
				/>
			: null}
			{state.showAccountDetailSidePanel ?
				<AccountDetailSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showAccountDetailSidePanel: false }))}
					pageMode={state.pageMode}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					AccountDetails={state.AccountDetails}
				/>
			: null}
			{state.showOnboardClusterPanel ?
				<OnboardClusterSidePanel 
					closeSidePanel={() => setState(prevState => ({...prevState, showOnboardClusterPanel: false }))}
					pageMode={state.pageMode}
					selectedAccountId = {state.selectedAccountId}
					refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
					selectedClusterDetails={state.selectedClusterDetails}
					AccountDetails={state.AccountDetails}
				/>
			: null}

			<div className='m-2'>
				<div className='flex flex-wrap justify-between'>
					<p className='mb-0'>
						<small>Showing {state.filterListAccounts && state.filterListAccounts.length ? state.filterListAccounts.length : 0 } out of total {state.listAccounts && state.listAccounts.length ? state.listAccounts.length : 0} account(s)</small>
						{state.selectedProvider ?
							<React.Fragment>
							<small className='ml-1'>obtained from the</small>
							<small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
							<small className='ml-1'>provider</small>
							</React.Fragment>
						: null}
						{state.selectedAccount && state.selectedAccount.length ?
							<React.Fragment>
							<small className='ml-1'>with the account</small>
							<small className='badge badge-outline-info mx-1'>{state.selectedAccount[0]}</small>
							{state.account_id.length > 1 ?
								<small className='ml-1 text-info'>more</small>
							: null}
							</React.Fragment>
						: null}
						{state.selectedRegion && state.selectedRegion.length ?
							<React.Fragment>
							<small className='ml-1'>in the</small>
							<small className='badge badge-outline-info mx-1'>{state.selectedRegion[0]}</small>
							{state.selectedRegion.length > 1 ?
								<small className='ml-1 text-info'>more</small>
							: null}
							<small className='ml-1'>region</small>
							</React.Fragment>
						: null}
					</p>
					<div className='flex flex-wrap self-center'>
						<div className='flex lg:ml-3 md:ml-3 ml-0  lg:mb-2 md:mb-2 mb-2'>
							{/* {state.listAccounts && state.listAccounts.length ?
								<CSVLink
									data={state.filterListAccounts ? state.filterListAccounts : []} 
									headers={state.tableHeaders ? state.tableHeaders : []}
									filename={'job-lists.csv'}
									className={'self-center'}
									target='_blank'
								>
									<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
								</CSVLink>
							: null} */}
							{state.listAccounts && state.listAccounts.length ?
								<Search
									data={state.listAccounts ? state.listAccounts : []}
									topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
									searchClassName={'px-2'}
									searchIconColor={'text-black '}
									searchPlaceHolder={'Search....'}
									className={'bg-transparent text-black'}
									widthHeight={25}
									filteredData={(filterListAccounts) => setState(prevState => ({ ...prevState, filterListAccounts }))}
								/>
							: null}

							{state.actionsAllowed && state.actionsAllowed.includes('create') ?
								<button className={`btn bg-HalloweenOrange px-2 py-1 rounded-md ml-3  lg:mt-0 md:mt-0 mt-2 text-white self-center`} onClick={() => {
									setState(prevState => ({ ...prevState, showCreateAccountPanel: true, pageMode: 'Create' }))
								}}>Onboard&nbsp;Account</button>
							: null}
						</div>
					</div>
				</div>
				{state.filterListAccounts && state.filterListAccounts.length ?
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: 'Provider',
								accessor: 'provider',
								width: 300,
							},
							{
								Header: 'Account Id',
								accessor: 'account_id',
								Cell: cellInfo => (
									<div className="cursor-pointer">
										<div className='flex w-full text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'Create', 
												AccountDetails: cellInfo.row.original,
												selectedAccountId: cellInfo.row.original.account_id,
												showAccountDetailSidePanel: true
											}))}
										>{cellInfo.row.original.account_id}</div>
									</div>
								),
								width: 300,
							},
							{
								Header: 'Account Name',
								accessor: 'account_name',
								width: 300,
							},
							{
								Header: ' ',
								Cell: cellInfo => (
									<div className="flex justify-between cursor-pointer self-center">
										<div className='text-info mr-2'
											onClick={() => setState(prevState => ({
												...prevState,
												pageMode: 'Create', 
												selectedClusterDetails: cellInfo.row.original,
												AccountDetails: cellInfo.row.original,
												showOnboardClusterPanel: true
											}))}
										>Onboard Cluster </div>
										{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
											<div className='text-danger mr-2'
												onClick={() => setState(prevState => ({
													...prevState,
													deleteModal: true,
													deleteAccountDetails: cellInfo.row.original
												}))}
											>Remove </div>
										: null}
									</div>
								),
								width: 300
							}
						]}
						data={state.filterListAccounts}
						perPage={20}
                        tableHead={'bg-lightGray text-black'}
                        tableBody={'text-black'}
                        tableBodyColor={'bg-white'}
                        tableType={'table-light-hover'}
                        perPageClass={`bg-lightGray text-black rounded-md ml-2`}
                        textColor={'text-black'}
                        selectedColor={'bg-transparent'}
                        dashboard={true}
						showPaginationTop={true}
                        paginationColor={'text-black'}
						sortByColumn={''}
						riskTooltip={[0]}
						onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListAccounts: tableRow }))}
						// tableSize={'table-sm'}
					/>
				: 
					<div className='flex justify-center m-4'>
						<p>There are no data on this criteria. Please try adjusting your filter.</p>
					</div>
				}
			</div>
			<div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
				<div className="relative p-4 w-full max-w-md max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteAccountDetails: {} }))}>
							<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
							</svg>
							<span className="sr-only">Close modal</span>
						</button>
						<div className="p-4 md:p-5 text-center">
							<svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
							</svg>
							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the account "{state.deleteAccountDetails && state.deleteAccountDetails.accountName ? state.deleteAccountDetails.accountName : ''}"?</h3>

							<div className='flex mt-4 justify-center'>
							<button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
								onClick={() => {
									setState(prevState => ({ ...prevState, showDeleteLoading: true }))
									if(!state.showDeleteLoading) {
										deleteAccountFunction()
									}
								}}
							>
								Yes, I'm sure
							</button>
							<button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
								if(!state.showDeleteLoading) {
									setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
								}
							}}>
							No, cancel
							</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageAccounts