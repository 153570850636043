/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LogsTab.js
 * @author Prakash // on 06/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { convertToLowerCase, getAccountNameFromId, momentConvertionUtcToLocalTime } from '../../utils/utility'
import { getJobLogs, sartSystemJobLogs, getSystemJobLogs, downloadJobLogs, listDownloadResults, deleteDownloadResults } from '../../actions/Collider/JobStatusAction'

import Search from '../common/SearchWithHiddenInput'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable';
 import { Store as CommonNotification } from 'react-notifications-component';
 import {Icon} from "@iconify/react";


const LogsTab = ({selectedJobDetails}) => {
    
    const dispatch = useDispatch(); // Create a dispatch function

    const [state, setState] = useState({
        minMaxHeight: '700px',
        showLogLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logTypes: [
            {label: 'Job Output', value: 'job'},
            {label: 'Job Error', value: 'error'},
            {label: 'Compute Node', value: 'compute node'},
            {label: 'Head Node', value: 'head node'}
        ],
        selectedJobLog: 'job',
    })

	useEffect(() => {
        let browserBorder = 100
		let headerHeight = 120
        let otherHeight = 80
				
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - otherHeight ) + 'px'
        setState(prevState => ({ ...prevState, minMaxHeight: outerHeight, callJobLogs: true }))
    }, [])

    useEffect(() => {
        if(state.callJobLogs) {
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if (selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }
            
            if(state.nextLogToken) {
                params.next_token = state.nextLogToken
            }
            
            params.job_id = selectedJobDetails.job_id;
            params.log_type = state.selectedJobLog

            // params.size = 5
    
            dispatch(getJobLogs(params))
                .then((response) => {
                    if(response) {
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextLogToken) {
                            totalResults = state.logsList &&  state.logsList.length ? state.logsList : []
                            // startRecord = startRecord + state.perPage;
                            // currentPage = currentPage+ 1
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
    
                        setState(prevState => ({ ...prevState, logsList: totalResults, filteredLogsList: totalResults, totalPages, startRecord, currentPage, showLogLoading: false, callJobLogs: false, nextLogToken: response.next_token }));
                    }
                })
        }
    }, [state.callJobLogs, dispatch, selectedJobDetails, state.perPage, state.logsList, state.nextLogToken, state.selectedJobLog, state.startRecord, state.currentPage, state.totalPages])

    useEffect(() => {
        if(state.callNodeLogs) {
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if(selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }

            if(selectedJobDetails.start_time) {
                params.start_time = selectedJobDetails.start_time;
            }
            if(selectedJobDetails.end_time) {
                params.end_time = selectedJobDetails.end_time;
            }
            if(selectedJobDetails.created_at) {
                params.created_at = selectedJobDetails.created_at;
            }
            params.job_id = selectedJobDetails.job_id;    
            params.log_type = state.selectedJobLog
            dispatch(sartSystemJobLogs(params))
                .then((response) => {
                    if(response) {    
                        setState(prevState => ({
                            ...prevState,
                            getSystemJobLogsInput: response,
                            callNodeLogs: false,
                            callGetNodeLogs: true,
                            // showLogLoading: true
                        }));
                    }
                })
        }
    }, [state.callNodeLogs, dispatch, selectedJobDetails, state.perPage, state.selectedJobLog])    

    useEffect(() => {
        if(state.callGetNodeLogs) {
            let params = state.getSystemJobLogsInput
            dispatch(getSystemJobLogs(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        if(response.status && response.status.toLowerCase() === "running" && !results.length) {
                            setState(prevState => ({
                                ...prevState,
                                logResponse: response,
                                showLogLoading: false,
                                callGetNodeLogs: false
                            }))
                            setTimeout(() => setState(prevState => ({ ...prevState, callGetNodeLogs: true })), 5000) //showLogLoading: true
                        } else {
                            let totalPages = 1
                            if(results.length > state.perPage) {
                                totalPages = Math.ceil(results.length / state.perPage)
                            }
                            if(response.status && response.status.toLowerCase() === "running") {
                                setTimeout(() => setState(prevState => ({ ...prevState, callGetNodeLogs: true })), 5000) //showLogLoading: true
                            }
        
                            setState(prevState => ({ ...prevState, logResponse: response, logsList: results, filteredLogsList: results, totalPages, startRecord: 0, currentPage: 1, showLogLoading: false, callGetNodeLogs: false }));
                        }
                    }
                })
        }
    }, [state.callGetNodeLogs, dispatch, selectedJobDetails, state.perPage, state.getSystemJobLogsInput])

    // Function to navigate between pages
    const navigateLogPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextLogToken) {            
            setState(prevState => ({ ...prevState, showLogLoading: true, callJobLogs: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }
    
    const downloadJobLogFunction = (item) => {
        let params = {
            provider: selectedJobDetails.provider,
            account_id: selectedJobDetails.account_id,
            region: selectedJobDetails.region,
        };

        if (selectedJobDetails.cluster_name) {
            params.cluster_name = selectedJobDetails.cluster_name;
        }

        if(selectedJobDetails.start_time) {
            params.start_time = selectedJobDetails.start_time;
        }
        if(selectedJobDetails.end_time) {
            params.end_time = selectedJobDetails.end_time;
        }
        if(selectedJobDetails.created_at) {
            params.created_at = selectedJobDetails.created_at;
        }
        params.job_id = selectedJobDetails.job_id;    
        if(item) {
            params.log_type = item.log_type
        } else {
            params.log_type = state.downloadLogType
        }
        
        // Download job logs here using params
        dispatch(downloadJobLogs(params))
            .then(response => {
                let fileUrl = response && response.url ? response.url : ''
                if(fileUrl) {
                    window.open(fileUrl, '_blank')
                } else {
                    let messageType = 'success'
                    let message = 'Download Initiated check download list'

                    setTimeout(() => { setState(prevState => ({ ...prevState, callDownloadList: true }))}, 1000)

                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                }
            })
    };
    
    
    const handleChange = (event) => {
        let selectedJobLog = event.target.value
        if(selectedJobLog === "job" || selectedJobLog === "error") {
            setState(prevState => ({ ...prevState, selectedJobLog, filteredLogsList: [], logsList: [], nextLogToken: '', logResponse: {}, showLogLoading: true, callJobLogs: true }))
        } else {
            setState(prevState => ({ ...prevState, selectedJobLog, filteredLogsList: [], logsList: [], nextLogToken: '', logResponse: {}, showLogLoading: true, callNodeLogs: true }))
        }
    }

    // const onClickDownloadList = () => {
    //     setState(prevState => ({ ...prevState, showDownloadList: !state.showDownloadList, callDownloadList: !state.showDownloadList, showLogLoading: !state.showDownloadList }))
    // }
    
    useEffect(() => {
        if(state.callDownloadList) {
            let params = {
                provider: selectedJobDetails.provider,
                account_id: selectedJobDetails.account_id,
                region: selectedJobDetails.region,
            };
    
            if(selectedJobDetails.cluster_name) {
                params.cluster_name = selectedJobDetails.cluster_name;
            }
            params.job_id = selectedJobDetails.job_id;
            // params.status = "Completed"
            params.log_type = 
            dispatch(listDownloadResults(params))
                .then((response) => {
                    if(response) {    
                        setState(prevState => ({
                            ...prevState,
                            downloadList: response,
                            filteredDownloadList: response,
                            callDownloadList: false,
                            showLogLoading: false
                        }));
                    }
                })
        }
    }, [state.callDownloadList, dispatch, selectedJobDetails])

    const handleDeleteDownload = (row) => {
        let params = {}
        params.download_id = row.download_id;
        dispatch(deleteDownloadResults(params))
            .then((response) => {
                // if(response) {    
                //     setState(prevState => ({
                //         ...prevState,
                //         downloadList: response,
                //         filteredDownloadList: response,
                //         callDownloadList: false,
                //         showLogLoading: false
                //     }));
                // }

                let messageType = 'success'
                let message = response && response.message ? response.message : 'Error in deleting the download'

                if(!response.status) {   
                    messageType = 'danger'  
                } else {
                    setTimeout(() => { setState(prevState => ({ ...prevState, callDownloadList: true }))}, 1000)
                }

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }
    
    return (
        <div className=''>
            <div className={`px-2`}>
                <ul className="flex flex-wrap text-sm text-white font-bold text-center dark:border-gray-700 dark:text-gray-400 mb-1 mt-3">
                    <li className="me-2">
                        <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${!state.showDownloadList ? 'active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, showDownloadList: false }))}>Logs</span>
                    </li>
                    <li className="me-2">
                        <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.showDownloadList ? 'active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, showDownloadList: true }))}>Downloads</span>
                    </li>
                </ul>
            </div>
            <div className='w-full rounded-md bg-white p-3'>
                {!state.showDownloadList ?
                    <div className='flex justify-between'>
                        <p className='flex mr-2 w-2/3'>
                            <span className='text-lightGray text-xs m-0 self-center pt-1'>Showing {state.filteredLogsList && state.filteredLogsList.length} of total {state.logsList && state.logsList.length}</span>
                            <div className={`self-center mx-2`}>
                                {/* <label for="category" className="block mb-2 text-sm font-medium text-white">Category</label> */}
                                <select className="bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit pb-2 focus-visible:border-b" onChange={handleChange}>
                                    {state.logTypes && state.logTypes.map(item => {
                                        return(
                                            <option key={'opt_'+item.value} className="text-black" value={item.value} defaultValue={state.selectedJobLog === item.value ? true :  false}>{item.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <span className='text-lightGray text-xs self-center pt-1'> log&nbsp;event(s)</span>
                            {state.logResponse && state.logResponse.status && state.logResponse.status.toLowerCase() === 'running' ?
                                <span className='self-center ml-3'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </span>
                            : null}
                            {state.logResponse && state.logResponse.status ?
                                <span className={`ml-3 self-center border border-${convertToLowerCase(state.logResponse.status) === "running" ? "success" : convertToLowerCase(state.logResponse.status) === "failed" ? 'danger' : convertToLowerCase(state.logResponse.status) === "completed" ? 'success' : 'mulbery'} px-2 rounded-md text-black ${convertToLowerCase(state.logResponse.status) === "running" ? 'bg-success' : convertToLowerCase(state.logResponse.status) !== "completed" ? "bg-danger" : convertToLowerCase(state.logResponse.status) !== "failed" ? 'bg-success' : 'bg-mulbery'}`}>{state.logResponse.status}</span>
                            : null}

                            {state.logsList && state.logsList.length && state.logResponse && convertToLowerCase(state.logResponse.status) !== "completed" && convertToLowerCase(state.logResponse.status) !== "failed" ?
                                state.selectedJobLog !== "log" ?
                                    null
                                    // <span className="ml-3 flex far fa-redo text-xs cursor-pointer text-info self-center" onClick={() => setState(prevState => ({ ...prevState, currentPage: 1, startRecord: 0, nextLogToken: '', showLogLoading: true, callGetNodeLogs: true }))}> <span className="ml-2">refresh </span></span>
                                    // <span className={`ml-3 self-center text-info`} onClick={() => setState(prevState => ({ ...prevState, callGetNodeLogs: true }))}>get latest logs</span>
                                :                                     
                                    <span className="ml-3 flex text-xs cursor-pointer text-SlateBlue self-center" onClick={() => setState(prevState => ({ ...prevState, currentPage: 1, startRecord: 0, nextLogToken: '', callJobLogs: true }))}> 
                                        <Icon icon="ooui:reload" color="white" width="18" height="18" />
                                        <span className="ml-2">refresh </span>
                                    </span>
                                    // <span className={`ml-3 self-center text-info`} onClick={() => setState(prevState => ({ ...prevState, callJobLogs: true }))}>get latest logs</span>
                            : null}
                        </p>
                        <div className='w-1/3 mb-n2'>
                            <div className='flex justify-end'>
                                {state.logsList && state.logsList.length ?
                                    <Search
                                        data={state.logsList ? state.logsList : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-transparent text-black border border-mediumDarkGray'}
                                        searchClassName={'px-2'}
                                        searchIconColor={'text-lightGray'}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent text-black'}
                                        widthHeight={22}
                                        filteredData={(filteredLogsList) => {
                                            let totalPages = 1
                                            if(filteredLogsList.length > state.perPage) {
                                                totalPages = Math.ceil(filteredLogsList.length / state.perPage)
                                            }
                                            setState(prevState => ({ ...prevState, filteredLogsList, totalPages, currentPage: 1, startRecord: 0 }))
                                        }}
                                    />
                                : null}
                            </div>
                            {!state.showDownloadList && state.filteredLogsList && state.filteredLogsList.length > state.perPage ?
                                <div className='flex justify-end text-black text-xs mt-2'>
                                    <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-SlateBlue'}`} onClick={() => navigateLogPage('start', state.currentPage)}></Icon></button> 
                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-SlateBlue'}`} onClick={() => navigateLogPage('previous', state.currentPage)}></Icon></button> 
                                    {/* <span>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ''
                                                gotoPage(page)
                                            }}
                                            className='ml-1 inputClass'
                                        />
                                    </span> */}
                                    {/* <button><i className={`fal fa-angle-right cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('next', state.currentPage)}></i></button>  */}
                                    {/* <button><i className={`fal fa-angle-right cursor-pointer text-info`} onClick={() => navigateLogPage('next', state.currentPage)}></i></button> */}
                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextLogToken ? 'text-SlateBlue' : 'text-ligthGray') : 'text-SlateBlue'}`} onClick={() => navigateLogPage('next', state.currentPage)}></Icon></button>
                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-ligthGray' : 'text-SlateBlue'}`} onClick={() => navigateLogPage('end', state.currentPage)}></Icon></button>
                                </div>
                            : null}
                        </div>
                    </div>
                :
                    <div className='flex justify-between'>
                        {state.downloadList && state.downloadList.length && state.downloadList.filter(e => convertToLowerCase(e.status) !== "completed").length && state.downloadList.filter(e => convertToLowerCase(e.status) !== "failed").length ?
                            <span className={`mr-3 self-center text-SlateBlue cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, callDownloadList: true }))}>Refresh Downloads</span>
                        : null}
                        <div className='flex justify-end w-full'>
                            <div className='w-full mb-n2'>
                                {/* <p className='text-SlateBlue f18 m-0 w-20 self-center cursor-pointer' onClick={onClickDownloadList}>
                                    {!state.showDownloadList ? "Download List" : "back to Logs"}
                                </p> */}
                                <div className='flex justify-end'>
                                    <p className='text-xs cursor-pointer m-0 pt-1 mr-2 text-SlateBlue self-center' onClick={() => downloadJobLogFunction()}> Click Here</p>
                                    <p className='text-xs m-0 self-center pt-1 mr-2'>to Initiate download for </p>
                                    <select className="bg-transparent border-b border-mediumDarkGray text-white text-xs w-fit pb-2 focus-visible:border-b" onChange={(e) => setState(prevState => ({ ...prevState, downloadLogType: e.target.value}))}>
                                        {state.logTypes && state.logTypes.map(item => {
                                            return(
                                                <option key={'opt_'+item.value} className="text-black" value={item.value} defaultValue={state.downloadLogType === item.value ? true :  false}>{item.label}</option>
                                            )
                                        })}
                                    </select>
                                    {/* {state.downloadList && state.downloadList.length && state.downloadList.filter(e => convertToLowerCase(e.status) !== "completed" && convertToLowerCase(e.status) !== "failed").length ?
                                        <span className={`ml-3 self-center text-info`} onClick={() => downloadJobLogFunction()}>refresh downloads</span>
                                    : null} */}
                                    {/* {state.filteredLogsList && state.filteredLogsList.length ?
                                        <p className='cursor-pointer m-0 text-info' onClick={() => downloadJobLogFunction()}> Download</p>
                                    : null} */}
                                    {state.downloadList && state.downloadList.length ?
                                        <Search
                                            data={state.downloadList}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            searchPlaceHolder={'Search downloads....'}
                                            topClassName={'ml-2 w-20 bg-transparent'}
                                            className={'bg-transparent text-black border-lightGray form-control-sm'}
                                            searchClassName={'border-lightGray'}
                                            searchIconColor={'text-black'}
                                            filteredData={(filteredDownloadList) => {
                                                setState(prevState => ({ ...prevState, filteredDownloadList }))
                                            }}
                                        />
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <div className='flex overflow-auto h-screen'>
                    <div className={`w-full`}>
                        <div className={`py-3`} >
                            {state.showLogLoading ? 
                                <div className='flex justify-center m-4'>
                                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="black" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </div>
                            : !state.showDownloadList ?
                                state.filteredLogsList && state.filteredLogsList.length ?
                                    state.filteredLogsList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`flex mr-2 px-2 mb-2 pb-1 ${(state.startRecord + state.perPage !== state.startRecord) && state.filteredLogsList.length !== i+1 ? 'border-b border-mediumDarkGray' : ''} ${state.selectedAlertId !== item.group_id ? 'cursor-pointer' : ' text-black pt-1'}`} key={'key_'+i}>
                                                <div className='flex'>
                                                    <p className='mb-0'>
                                                        <span className='mb-0 mr-2 text-black text-xs font-bold'>{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                        {/* <span className={`mb-0 mr-2 text-xs text-lightGray mr-2`}>
                                                            Ingestion Time
                                                        </span>
                                                        <span className='mb-0 mr-2 text-black text-xs font-weight-bold'>{item.ingestionTime ? momentConvertionUtcToLocalTime(item.ingestionTime, 'DD MMM YYYY HH:mm') : ''} </span> */}
                                                        <span className='mb-0 mr-2 text-xs text-black font-bold'>{item.message}</span>
                                                        {/* {item.message && JSON.parse(item.message) && Object.entries(JSON.parse(item.message)).length ?
                                                            Object.entries(JSON.parse(item.message)).map(([key, value]) => {
                                                                return(
                                                                    <React.Fragment>
                                                                        <span className='mb-0 mr-2 text-xs text-lightGray font-weight-bold'>{key}</span>
                                                                        <span className='mb-0 mr-2 text-xs text-white font-weight-bold'>{item.message}</span>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        : null} */}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-white'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                            : 
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: "Date",
                                            accessor: "date",
                                            Cell: cellInfo => (<span>{cellInfo.row.original.timestamp ? momentConvertionUtcToLocalTime(cellInfo.row.original.timestamp, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                            width: 150
                                        },
                                        {
                                            Header: "Job Details",
                                            accessor: "job_name",
                                            Cell: () => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {selectedJobDetails && selectedJobDetails.job_name ? selectedJobDetails.job_name : ""}
                                                </span>
                                                <span className='ml-2'>
                                                    ({selectedJobDetails && selectedJobDetails.job_id ? selectedJobDetails.job_id : ""})
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 200
                                        },
                                        {
                                            Header: "Cluster",
                                            accessor: "cluster_name",
                                            width: 200
                                        },
                                        {
                                            Header: "Log Type",
                                            accessor: "log_type",
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {cellInfo.row.original.log_type && state.logTypes.filter(e => e.value === cellInfo.row.original.log_type).length ? state.logTypes.filter(e => e.value === cellInfo.row.original.log_type)[0].label : cellInfo.row.original.log_type}
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 130
                                        },
                                        {
                                            Header: "Resource",
                                            accessor: d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region,
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                <span className=''>
                                                    {cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
                                                </span>
                                                </React.Fragment>
                                            ),
                                            width: 220
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            Cell: cellInfo => (
                                                cellInfo.row.original.status ?
                                                    <span className="bordr border-info rounded-md mr-2 mt-2 self-center text-sm px-2 text-black">{cellInfo.row.original.status}</span>
                                                : null
                                            )
                                        },
                                        {
                                            Header: " ",
                                            Cell: cellInfo => (
                                                cellInfo.row.original.status ?
                                                    <div className='flex'>
                                                        <span className="text-info text-sm cursor-pointer" onClick={() => downloadJobLogFunction(cellInfo.row.original)}>Download</span>
                                                        <span className="text-black mx-2 text-sm">/</span>
                                                        <span className="text-red mr-2 text-sm cursor-pointer" onClick={() => handleDeleteDownload(cellInfo.row.original)}>Delete</span>
                                                    </div>
                                                : null
                                            ),
                                            width: 200
                                        },
                                    ]}
                                    data={state.filteredDownloadList ? state.filteredDownloadList : []}
                                    perPage={10}
                                    tableHead={'bg-white text-black'}
                                    tableBody={'bg-GostWhite text-black'}
                                    tableType={'table-light-hover'}
                                    perPageClass={`bg-transparent text-lightGray`}
                                    textColor={'text-white'}
                                    selectedColor={'bg-white'}
                                    dashboard={true}
                                    sortByColumn={"date"}
                                    riskTooltip={[]}
                                    onClickRow={tableRow => {}}
                                    emptyMessage={"There is no data on this criteria"}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogsTab