import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap'
import { capitalizeAllLetter, getAccountNameFromId, getRegionName, momentSlashConvertionLocalToUtcTime, noSpace, onlyNumeric } from '../../utils/utility';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../actions/commonActionNew'
import { getJobFilters, submitJob, getJobBudget } from '../../actions/Collider/JobStatusAction';
 import { Store as CommonNotification } from 'react-notifications-component';
import MultiSelectSection from '../common/MultiSelectSection';
import Files from '../hpc/files/file-list/index'
import {Icon} from "@iconify/react";

const CreateJobPanel = ({ closeSidePanel }) => {
    
    const [state, setState] = useState({
        showLoading: false,
        job_type: 'manually',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
        clusters: [],
        scheduleRadio: 'run_immediately',
        budgetDetails: false
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
    }, [dispatch])

    useEffect(() => {
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedCluster) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedCluster,
              }
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedCluster])
    
    useEffect(() => {
        let params = {}
        if(providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else {
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
            })
        }
    }, [dispatch, providers])

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

    useEffect(() => {
        if(state.selectedCluster && state.job_type === 'manually') {        
            let jobParams = {}
            jobParams.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                jobParams.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                jobParams.region = state.selectedRegion
            }
            jobParams.cluster_name = state.selectedCluster
            
            dispatch(getJobFilters(jobParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            application: response.licenses?.application ? response.licenses.application  : [],
                            queues: response.queues ? response.queues : [],
                            licenses: response.licenses?.license_name ? response.licenses.license_name : [],
                            qos: response.qos ? response.qos : [],
                            qosFilter: response.queue_qos_filters ? response.queue_qos_filters : {},
                            qosParameters: response.qos_parameters ? response.qos_parameters : {},
                            jobFilters: response
                        }));
                    }
            })
        }
    }, [state.selectedCluster, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.job_type])

    useEffect(() => {
        if(state.selectedQueue) {
            let qosFilter = state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].qos ? state.qosFilter[state.selectedQueue].qos :[]
            let qosMessage =  state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].message ? state.qosFilter[state.selectedQueue].message : ''
            setState(prevState => ({
                ...prevState,
                qos: qosFilter,
                qosMessage,
            }));
        }
    }, [state.selectedQueue ,state.qosFilter])

    useEffect(() => {
        if(state.qos_name) {
            let qosParValue = state.qosParameters[state.qos_name] ? state.qosParameters[state.qos_name] : {}
            setState(prevState => ({
                ...prevState,
                qosParValue
            }));
        }
    }, [state.qos_name, state.qosParameters])

    useEffect(() => {
        if (state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            fetchingCluster: false
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))
	}    

	const handleCommand = e => {		
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}

    useEffect(() => {
        if(state.getJobBudgetDetail) {
            setState(prevState => ({ ...prevState, getJobBudgetDetail: false }))

            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                    params.licenses_count = parseInt(state.licenses_count)
                }
            }

            
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true }))

            if(!inputValidationError) {
                dispatch(getJobBudget(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, budgetDetails: response, saveLoading: false }))
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, budgetDetails: [] }))
                        }
                    })
            }

        }
    }, [state, dispatch])

    useEffect(() => {
        if(state.callSaveFunction) {
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.job_budget = state.budgetDetails
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                    if(state.licenses_count) {
                        params.licenses_count = parseInt(state.licenses_count)
                    } else {
                        inputValidationError = true
                    }
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                }
            }
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true, callSaveFunction: false }))
            
            if(!inputValidationError) {
                dispatch(submitJob(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in creating job'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Job created successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });

                        setState(prevState => ({ ...prevState, budgetDetails: false }))
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                        }
                        // setState(prevState => ({ ...prevState, createRespons: response }))
                    })
    
            }
        }
    },  [state.callSaveFunction, closeSidePanel, dispatch, state])

    const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateDateTime(str, state)
		} else {
			validateDateTime(time, state)
		}
	}

    const validateDateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let date = str.substring(0, 2)

        let scheduleAt = ''
        if(strLength > 1 && parseInt(date) > 31) {
			scheduleAt += '01/'
		} else if(strLength > 1 && parseInt(date) < 10) {
			if(date.length === 2) {
				scheduleAt = date+'/'
			} else {
				scheduleAt += '0'+date+'/'
			}
		} else if(strLength > 1 && parseInt(date) <= 31) {
			scheduleAt += date+'/'
		}

		if(scheduleAt === '') {
			scheduleAt = dateTime
		}
		
		let month = str.substring(2, 4)
		
		if(month.length) {
			if(strLength > 3 && parseInt(month) > 12) {
				scheduleAt += '01/'
			} else if(strLength > 3 && parseInt(month) > 10) {
				scheduleAt += month+'/'
			} else if(strLength > 3 && month.length === 2) {
				scheduleAt += month+'/'
			} else {
                scheduleAt += month
            }
		}
		
        let year = str.substring(4, 8)
		if(strLength > 4 && year.length < 4) {
            scheduleAt += year
		} else if(strLength > 5 && year.length === 4) {
            scheduleAt += year+' '
        }

		let firstHour = str.substring(8, 10)

		if(strLength > 8 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 9 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 8 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } 
		
		let firstMinute = str.substring(10, 12)
		
		if(firstMinute.length) {
			if(strLength > 10 && parseInt(firstMinute) > 59) {
				scheduleAt += '00'
			} else if(strLength > 10 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

    const validateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
        let scheduleAt = ''
		let firstHour = str.substring(0, 2)
		if(strLength > 1 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 2 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } else if(strLength === 1) {
			scheduleAt += firstHour            
        }
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 2 && parseInt(firstMinute) > 59) {
				scheduleAt += '00:'
			} else if(strLength > 2 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		} 
		
		let firstSeconds = str.substring(4, 6)
		
		if(firstSeconds.length) {
			if(strLength > 4 && parseInt(firstSeconds) > 59) {
				scheduleAt += ':00'
			} else if(strLength > 4 && parseInt(firstSeconds) > 10) {
				scheduleAt += ":"+firstSeconds
			} else {
				scheduleAt += ":"+firstSeconds
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    const handleFocus = (event) => {
        // let name =  event.target.getAttribute('name');        
        // setState(prevState => ({ ...prevState, help_field: name, showMessage: true }))
    }
    const handleBlur = () => {
        setState(prevState => ({ ...prevState, showMessage: false, help_message: '', help_field: '' }))
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const content = event.target.result;
          setState(prevState => ({ ...prevState, job_script: content, showLocalFileOptions: false }))
        };
    
        reader.readAsText(file);
    };

	const handleChildClick = (event, type, dropdownType, section) => {

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search-content w-4/5 bg-GhostWhite overflow-auto'>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='text-xl'>Create Job</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2 overflow-auto'>
                    <div className='rounded bg-white p-3'>
                        <div className='flex mt-2'>
                            <div className='py-1 w-1/4'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">Provider <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                    {state.inputValidationError && !state.selectedProvider ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showProvider")
                                }}>
                                    <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-1/2 ${state.selectedProvider ? 'text-black' : 'text-lightGray'}`}>
                                        <span className='truncate'>{state.selectedProvider ? state.selectedProvider : 'Select'}</span>
                                        <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                    </p>
                                    {state.showProvider ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["provider_name", "provider_name"]}
                                                widthClass={"minWidth220"}
                                                removeTop={true}
                                                options={state.providers}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, 
                                                        selectedProvider: value,
                                                        selectedAccount: "",
                                                        selectedRegion: "",
                                                        selectedCluster: "",
                                                        selectedQueue: "",
                                                        fetchingCluster: true,
                                                        selectedLincense: "",
                                                        selectedApplication: "",
                                                        licenses_count: "",
                                                        budgetDetails: false
                                                    }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={true}
                                                topClass={'135px'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-1/4 pl-3'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">Account <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                    {state.inputValidationError && !state.selectedAccount ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showAccount")
                                }}>
                                    <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.selectedAccount ? 'text-black' : 'text-lightGray'}`}>
                                        <span className='truncate'>{state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}</span>
                                        <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                    </p>
                                    {state.showAccount ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["account_id", "account_name"]}
                                                removeTop={true}
                                                options={state.accounts}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedAccount: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                                widthClass={"minWidth220"}
                                                topClass={'135px'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-1/4 pl-3'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">Region <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                    {state.inputValidationError && !state.selectedRegion ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showRegion")
                                }}>
                                    <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.selectedRegion ? 'text-black' : 'text-lightGray'}`}>
                                        <span className='truncate'>{state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}</span>
                                        <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                    </p>
                                    {state.showRegion ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["region", "name"]}
                                                widthClass={"minWidth220"}
                                                removeTop={true}
                                                options={state.regions}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                                topClass={'135px'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-1/4 pl-3'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">Cluster Name<Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                    {state.inputValidationError && !state.selectedCluster ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showCluster")
                                }}>
                                    <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.selectedCluster ? 'text-black' : 'text-lightGray'}`}>
                                        <span className='truncate'>{state.selectedCluster ? state.selectedCluster : 'Select'}</span>
                                        <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                    </p>
                                    {state.showCluster && !state.fetchingCluster ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                // fields={["region", "name"]}
                                                widthClass={"minWidth220"}
                                                removeTop={true}
                                                options={state.clusters}
                                                selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedCluster: value, selectedQueue: "", qos_name: "", budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                                topClass={'135px'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        {state.selectedProvider !== 'GCP' ?
                            <div className="bg-transparent text-black flex mt-4">
                                <p className="mr-2 text-black self-center">Create </p>
                                <div className="flex items-center space-x-2 mr-3">
                                    <input 
                                        type="radio" 
                                        id="radio1" 
                                        name="options" 
                                        className="form-radio h-4 w-4 text-blue-600" 
                                        checked={state.job_type === "manually" ? true : false}
                                        onChange={() => setState(prevState => ({ ...prevState, job_type: "manually", scheduled_at: "" }))}
                                    />
                                    <label className="text-gray-700">Manual Job</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input 
                                        type="radio" 
                                        id="radio1" 
                                        name="options" 
                                        className="form-radio h-4 w-4 text-blue-600"
                                        checked={state.job_type === "script" ? true : false}
                                        onChange={() => setState(prevState => ({ ...prevState, job_type: "script" }))}
                                    />
                                    <label className="text-gray-700">Script Job</label>
                                </div>
                            </div>
                        : null}
                        {state.job_type === "manually"  ?
                            <React.Fragment>
                                <div className='flex mt-2'>
                                    <div className={`py-1 ${state.selectedProvider !== 'GCP' ? 'w-1/3' : 'w-1/4'}`}>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray flex">Queue Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                            {state.inputValidationError && !state.selectedQueue ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showQueueName")
                                        }}>
                                            <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.selectedQueue ? 'text-black' : 'text-lightGray'}`}>
                                                <span className='truncate'>{state.selectedQueue ? state.selectedQueue : 'Select'}</span>
                                                <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                            </p>
                                            {state.showQueueName ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        // fields={["provider_name", "provider_name"]}
                                                        widthClass={"minWidth220"}
                                                        removeTop={true}
                                                        options={state.queues}
                                                        selectedValues={state.selectedQueue ? state.selectedQueue : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, selectedQueue: value, budgetDetails: false }))
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={true}
                                                        topClass={'135px'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className={`py-1 w-1/3 pl-3`}>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray flex">Job Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                            {state.inputValidationError && !state.selectedQueue ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <input 
                                            className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                            placeholder="Enter job name" 
                                            value={state.job_name ? state.job_name : ''}
                                            onChange={e => {
                                                handleInputChange('job_name', noSpace(e.target.value))
                                                setState(prevState => ({ ...prevState, budgetDetails: false }))
                                            }}
                                            type="text" 
                                            name="job_name"
                                        />
                                    </div>
                                </div>
                                {state.selectedProvider !== 'GCP' ?
                                    <div className='flex mt-2 border-t border-darkGray'>
                                        <div className={`py-1 w-1/3`}>
                                            <div className='flex justify-between'>
                                                <p className="b-block mb-0 text-lightGray flex">Qos Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                                {state.inputValidationError && !state.qos_name ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </div>
                                            <div onClick={(event) => {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "showQosName")
                                            }}>
                                                <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.qos_name ? 'text-black' : 'text-lightGray'}`}>
                                                    <span className='truncate'>{state.qos_name ? state.qos_name : 'Select'}</span>
                                                    <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                                </p>
                                                {state.showQosName ?
                                                    <div className='relative'>
                                                        <MultiSelectSection 
                                                            // fields={["provider_name", "provider_name"]}
                                                            widthClass={"minWidth220"}
                                                            removeTopOptions={true}
                                                            options={state.qos}
                                                            selectedValues={state.qos_name ? state.qos_name : []}
                                                            callbackMultiSelect={(value) => {
                                                                setState(prevState => ({ ...prevState, qos_name: value, budgetDetails: false }))
                                                            }}
                                                            singleSelection={true}
                                                            hideSearch={true}
                                                            topClass={'135px'}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        <div className={`py-1 w-1/3 pl-3`}>
                                            <div className='flex justify-between'>
                                                <p className="b-block mb-0 text-lightGray">Working Directory</p>
                                            </div>
                                            <input 
                                                className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                placeholder="Enter working directory" 
                                                value={state.working_directory ? state.working_directory : ''}
                                                type="text" 
                                                name={'working_directory'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('working_directory', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                : null}
                                {state.showMessage && (state.help_field === 'qos_name' || state.help_field === 'working_directory') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}
                                {state.qosParValue && Object.entries(state.qosParValue).length ?
                                    Object.entries(state.qosParValue).map(([key, value], qIndex) => {
                                        return(
                                            <span key={"row_"+qIndex} className="border border-info rouned-md mr-2 mt-2 self-center px-2">{key+' : '+value}
                                            </span>
                                        )
                                    })
                                : state.selectedQueue && state.qos_name && state.qosParValue ?
                                    <p className="text-purplishRed my-1 self-center">QOS parameters not found</p>
                                : null}
                                
                                {state.selectedProvider !== 'GCP' ?
                                    <React.Fragment>
                                        <p className='my-2 text-md text-black'>Parameters</p>
                                        <div className='flex mt-2 border border-mediumDarkGray p-3 rounded-md'>
                                            <div className={`py-1 w-1/4`}>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray flex">Total Tasks
                                                        <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                    </p>
                                                    {state.inputValidationError && !state.total_tasks ?
                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                    : null}
                                                </div>
                                                <input 
                                                    type="number" 
                                                    className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                    placeholder='Enter total tasks'
                                                    value={state.total_tasks ? state.total_tasks : ''}
                                                    name={'total_tasks'}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleInputChange('total_tasks', e.target.value)
                                                        setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                    }}
                                                />
                                            </div>
                                            <div className={`py-1 w-1/4 pl-3`}>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray flex">CPUS Per Task
                                                        <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                    </p>
                                                    {state.inputValidationError && !state.cpus_per_task ?
                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                    : null}
                                                </div>
                                                <input 
                                                    type="number" 
                                                    className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                    placeholder='Enter cpus per tasks'
                                                    value={state.cpus_per_task ? state.cpus_per_task : ''}
                                                    name={'cpus_per_task'}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleInputChange('cpus_per_task', e.target.value)
                                                        setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                    }}
                                                />
                                            </div>
                                            <div className={`py-1 w-1/4 pl-3`}>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray flex">Total Nodes
                                                        <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                    </p>
                                                    {state.inputValidationError && !state.total_nodes ?
                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                    : null}
                                                </div>
                                                <input 
                                                    type="number" 
                                                    className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                    placeholder='Enter total nodes'
                                                    value={state.total_nodes ? state.total_nodes : ''}
                                                    name={'total_nodes'}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleInputChange('total_nodes', e.target.value)
                                                        setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                    }}
                                                />
                                            </div>
                                            <div className={`py-1 w-1/4 pl-3`}>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray flex">Tasks Per Node
                                                        <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                    </p>
                                                    {state.inputValidationError && !state.tasks_per_node ?
                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                    : null}
                                                </div>
                                                <input 
                                                    type="number"  
                                                    className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                    placeholder='Enter takss per node'
                                                    value={state.tasks_per_node ? state.tasks_per_node : ''}
                                                    name={'tasks_per_node'}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        handleInputChange('tasks_per_node', e.target.value)
                                                        setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                    }}
                                                />
                                            </div>
                                            
                                        </div>
                                    </React.Fragment>
                                : null}
                                {state.showMessage && (state.help_field === 'total_tasks' || state.help_field === 'cpus_per_task' || state.help_field === 'total_nodes' || state.help_field === 'tasks_per_node') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}
                                <div className='flex mt-2 border-t border-darkGray'>
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className={`py-1 w-1/4`}>
                                            <div className='flex justify-between'>
                                                <p className="b-block mb-0 text-lightGray">Memory (MB)</p>
                                            </div>
                                            <input 
                                                type="number" 
                                                className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                placeholder="Enter memory" 
                                                value={state.memory ? state.memory : ''}
                                                name={'memory'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('memory', e.target.value)}
                                            />
                                        </div>
                                    : 
                                        <div className={`py-1 w-1/4`}>
                                            <div className='flex justify-between'>
                                                <p className="b-block mb-0 text-lightGray">Total CPUs</p>
                                                {state.inputValidationError && !state.cpus ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </div>
                                            <input
                                                type="number" 
                                                className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                placeholder="Enter total CPUs" 
                                                value={state.cpus ? state.cpus : ''}
                                                name={'cpus'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('cpus', onlyNumeric(e.target.value))}
                                            />
                                        </div>
                                    }
                                    <div className={`py-1 w-1/6 pl-3`}>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Time</p>
                                        </div>
                                        <input 
                                            type="text" 
                                            className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                            placeholder='HH:mm:ss'
                                            value={state.walltime ? state.walltime : ''}
                                            onKeyDown={e => onKeyDownTime(e, e.target.value, 'walltime')}
                                            onChange={e => validateTime(e.target.value, 'walltime')}
                                            name={'walltime'}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                                {state.selectedProvider === 'AWS' ?
                                    <React.Fragment>
                                        <p className='my-2 text-md text-black'>Licenses</p>
                                        <div className='flex mt-2 border border-mediumDarkGray p-3 rounded-md'>
                                            <div className={`py-1 w-1/4`}>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray">Application</p>
                                                </div>
                                                <div onClick={(event) => {
                                                    event.preventDefault();
                                                    handleChildClick(event, 'child', 'singleDropDown', "showLicense")
                                                }}>
                                                    <p className={`flex justify-between mb-0 bg-transparent border border-lightGray p-2 rounded w-3/4 ${state.selectedLincense ? 'text-black' : 'text-lightGray'}`}>
                                                        <span className='truncate'>{state.selectedApplication ? state.selectedApplication : 'Select'}</span>
                                                        <Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
                                                    </p>
                                                    {state.showLicense ?
                                                        <div className='relative'>
                                                            <MultiSelectSection 
                                                                // fields={["provider_name", "provider_name"]}
                                                                widthClass={"minWidth220"}
                                                                removeTop={true}
                                                                options={state.licenses}
                                                                selectedValues={state.selectedLincense ? state.selectedLincense : []}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, selectedLincense: value, selectedApplication: state.licenses.filter(e => e.license_name === value)[0].application, budgetDetails: false }))
                                                                }}
                                                                singleSelection={true}
                                                                hideSearch={true}
                                                                topClass={'135px'}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                                {state.selectedApplication ?
                                                    <div className={`py-1 w-1/3`}>
                                                        <div className='flex justify-between'>
                                                            <p className="b-block mb-0 text-lightGray flex">Licenses Request Count
                                                                <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                            </p>
                                                            {state.inputValidationError && !state.licenses_count ?
                                                                <span className='mb-0 text-red ml-2'>required</span>
                                                            : null}
                                                        </div>
                                                        <input 
                                                            type="number" 
                                                            className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                            placeholder='Enter licenses count'
                                                            name={'licenses_count'}
                                                            value={state.licenses_count ? state.licenses_count : ''}
                                                            onChange={e => {
                                                                setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                                handleInputChange('licenses_count', onlyNumeric(e.target.value))
                                                            }}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            
                                        </div>
                                    </React.Fragment>
                                : null}
                                {state.showMessage && (state.help_field === 'memory' || state.help_field === 'walltime') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}                                
                            </React.Fragment>
                        : null}
                        <div className={`flex text-black ${state.scheduleRadio === "schedule_at" ? "mt-4" : "mt-4"}`}>
                            <div className="flex items-center space-x-2 mr-3">
                                <input 
                                    type="radio" 
                                    id="radio1" 
                                    name="schedule_at" 
                                    className="form-radio h-4 w-4 text-blue-600" 
                                    checked={state.scheduleRadio === "run_immediately" ? true : false}
                                    onChange={() => setState(prevState => ({ ...prevState, scheduleRadio: "run_immediately", scheduled_at: "" }))}
                                />
                                <label className="text-gray-700">Run Immediately</label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <input 
                                    type="radio" 
                                    id="radio1" 
                                    name="schedule_at" 
                                    className="form-radio h-4 w-4 text-blue-600"
                                    checked={state.scheduleRadio === "schedule_at" ? true : false}
                                    onChange={() => setState(prevState => ({ ...prevState, scheduleRadio: "schedule_at" }))}
                                />
                                <label className="text-gray-700">Schedule At</label>
                            </div>
                            {state.scheduleRadio === "schedule_at" ?
                                <div className='py-1 w-1/5 pl-3'>
                                     <div className='flex justify-between'>
                                        <p className="b-block mb-0 text-lightGray">Schedule At</p>
                                    </div>
                                    <input 
                                        type="text" 
                                        className="placeholder:italic placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent text-black" 
                                        placeholder='DD/MM/YYY HH:mm'
                                        value={state.scheduled_at ? state.scheduled_at : ''}
                                        onKeyDown={e => onKeyDownTimeRange(e, e.target.value, 'scheduled_at')}
                                        onChange={e => validateDateTime(e.target.value, 'scheduled_at')}
                                        name={'scheduled_at'}
                                    />
                                </div>
                            : null}
                        </div>
                        {state.job_type === "manually" ?
                            <div className='flex mt-4 border-t border-darkGray'>
                                <div className={`py-1 w-full`}>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0 text-lightGray flex">Command
                                            <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                        </p>
                                        {state.inputValidationError && !state.command ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <textarea id="command" name="command" 
                                        rows="4" 
                                        className="w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 text-black" 
                                        onChange={handleCommand}
                                        value={state.command}
                                    />
                                </div>
                            </div>
                        : 
                            <div className='mt-4 border-t border-darkGray'>
                                <div className='flex justify-end py-1 w-full mb-2'>
                                    <span className='mb-22 border border-info rounded-lg px-2 text-black' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: !state.showLocalFileOptions }))}>{!state.showLocalFileOptions ? 'Open' : 'Close'} a Local File</span>
                                    <span className='mb-22 border border-info rounded-lg px-2 text-black ml-3' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: false, showFileBrowser: !state.showFileBrowser }))}>Browse shared folder</span>
                                </div>

                                {state.showLocalFileOptions ?
                                    <div className='bg-white p-2'>
                                        <p className='text-black'>Plese choose a file from your system</p>
                                        <input className='m-4' type="file" onChange={handleFileChange} />
                                    </div>
                                : state.showFileBrowser ?
                                    <div className='file-browser bg-white py-2'>
                                        <Files 
                                            fromScreen="jobs"
                                            selectedFile={(fileContent) => {
                                                let job_script = fileContent && fileContent.data ? fileContent.data : ''
                                                setState(prevState => ({ ...prevState, job_script, showFileBrowser: false }))
                                            }}
                                        />
                                    </div>
                                :
                                    <div className='py-1 w-full mb-5'>
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray flex">Job Script
                                                <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            </p>
                                            {state.inputValidationError && !state.command ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <textarea id="job_script" name="job_script"
                                            rows="25" 
                                            className="w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 text-black" 
                                            onChange={handleCommand}
                                            value={state.job_script}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 p-4 shadow-lg w-4/5 bg-GhostWhite">
                <div className={`flex justify-${state.budgetDetails ? 'between' : 'end'}`}>
                    {state.budgetDetails ?
                        <div>
                            <div className='flex pl-4'>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Budget&nbsp;Type</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.budget_type ? state.budgetDetails.budget_type : 0 }
                                    </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Total&nbsp;Budget</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.total_budget ? state.budgetDetails.total_budget : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                    </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Used&nbsp;Budget</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.budget_used ? state.budgetDetails.budget_used : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                    </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Available&nbsp;Budget</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.available_budget ? state.budgetDetails.available_budget : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                    </span>
                                </div>
                                <div className='flex self-center mr-5 text-black'>
                                    <span className='mr-2'>Job&nbsp;Cost</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.job_cost ? state.budgetDetails.job_cost : 0 }
                                        <span className='ml-1 f14 text-lightGray'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                    </span>
                                </div>
                            </div>
                            <div className='flex mt-3 pl-4'>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Total&nbsp;Licenses</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.total_licenses ? state.budgetDetails.total_licenses : 0 }
                                    </span>
                                </div>
                                <div className='flex self-center mr-3 text-black'>
                                    <span className='mr-2'>Used&nbsp;Licenses</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.licenses_used ? state.budgetDetails.licenses_used : 0 }
                                    </span>
                                </div>
                                <div className='flex self-center mr-5 text-black'>
                                    <span className='mr-2'>Available&nbsp;Licenses</span>
                                    <span className='mr-2'>:</span>
                                    <span className='text-warning'>
                                        {state.budgetDetails && state.budgetDetails.available_licenses ? state.budgetDetails.available_licenses : 0 }
                                    </span>
                                </div>
                            </div>
                            {state.budgetDetails && state.budgetDetails.message ?
                                <p className='flex pl-4 text-danger'>{state.budgetDetails.message}</p>
                            : null}
                        </div>
                    : null}
                    <div className={`self-center flex justify-end`}>
                        {state.saveLoading ?
                            <button type="button" className="bg-BlockWhite text-black ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center"><Icon icon="mingcute:down-small-line mt-1 mr-2"></Icon>  Submitting</button>
                        :
                            <div className='flex'>
                                {state.budgetDetails && state.budgetDetails ?
                                    <button type="button" className="bg-BlockWhite text-black ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center"
                                    onClick={ () => {
                                        if(state.budgetDetails.job_submission) {
                                            setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                        }
                                    }}>
                                        Continue
                                    </button>
                                : !state.budgetDetails ?
                                    <button type="button" className="bg-HalloweenOrange text-white ml-2 px-2 py-1 rounded inline-flex items-center self-center" onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, getJobBudgetDetail: true }))}>Submit</button>
                                : null}
                                <button type="button" className="bg-lightGray text-white ml-2 px-2 py-1 rounded inline-flex items-center self-center" onClick={ () => closeSidePanel()}>Cancel</button>
                            </div>
                        } 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateJobPanel;