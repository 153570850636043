import React, { useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

const FileManagerTable = ({
  columns,
  data,
  loading,
  onRowSelect,
  selectedColor = false,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(false)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
      data
    },
    useRowSelect
  );

  const onRowClick = (row) => {
    // if (row.original.type === 'directory') {
      onRowSelect(row.original, `${row.original.current_directory}/${row.original.url}`);
    // }
  };

  const renderTableSkeleton = () => {
    return(
      <div className="flex justify-center data-loading text-center">
        <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
            <path d="M4 12a8 8 0 0112-6.9" />
        </svg>
      </div>
    )
	}

  const renderNoData = () => {
		return (
			<>
        <table {...getTableProps()} className="table-auto text-black w-full">
          <thead className='bg-extraLightGray p-2'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className='py-1' {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              <td className="text-center text-black" colSpan={5}>This folder is empty.</td>
            </tr>
          </tbody>
        </table>
      </>
		)
	}

  return (
    <>
      {loading ? (
				renderTableSkeleton()
			) : (
        <>
        {(!data || !data.length)  ? (
          renderNoData()
          ) : (
            <>
              <div className="table-responsive mb-4">
                <table {...getTableProps()} className="table-auto w-full text-black">
                  <thead className='bg-extraLightGray p-2'>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th className='text-left px-2 py-1' {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className='p-2 bg-white' {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr className={`${selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-extraLightGray') : ''} w-100`}
                          {...row.getRowProps()} onClick={() => {
                            setSelectedRowIndex(i)
                            onRowClick(row)
                          }}
                        >
                          {row.cells.map(cell => (
                            <td className='pt-2 px-2 cursor-pointer' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FileManagerTable