import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../../actions/commonActionNew'
import NewRequest from './NewRequest';
import RequestHistory from './RequestHistory';
import FilterSection from '../../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region'],
        selectedTab: "New Request"
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), selectedTab: "New Request" }));
    //     }
    // }, [providers]);
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            callSearch: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            let prevValue = state.selectedTab
            setState(prevState => ({ ...prevState, callSearch: false, selectedTab: "" }))
            setTimeout(() => {
                setState(prevState => ({ ...prevState, selectedTab: prevValue })) // Set the same value
            }, 1000); // Change to the desired delay (in milliseconds)
        }
    }, [state.callSearch, state.selectedTab])

    const handleClickOutside = (event) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
    };

    return (
        <div onClick={(event) => handleClickOutside(event, 'parent')}>
            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Request</p>
                        <p className="mx-4 text-black">Consolidated view of workstation request</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex grow min-h-screen'>
                <div className='w-full min-h-screen m-2'>
                    <div className='bg-white min-h-screen rounded-md'>
                        <div className={`px-2`}>
                            <ul className="flex flex-wrap text-sm font-bold text-center">
                                <li className="me-2">
                                    <span className={`inline-block text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'New Request' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'New Request' }))}>New Request</span>
                                </li>
                                <li className="me-2">
                                    <span className={`inline-block text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Request History' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Request History', showMetricLoading: true }))}>My Request History</span>
                                </li>
                            </ul>
                        </div>
                        {state.selectedTab === 'New Request' && state.selectedProvider ?
                            <NewRequest
                                onSubscribe={(subscribedRow) => setState(prevState => ({...prevState, subscribedRow, selectedTab: 'Workstations' }))}
                                selectedProvider={state.selectedProvider} 
                                selectedAccount={state.selectedAccount} 
                                selectedRegion={state.selectedRegion} 
                            />
                        : state.selectedTab === 'Request History' && state.selectedProvider ?
                            <RequestHistory
                                onSubscribe={(subscribedRow) => setState(prevState => ({...prevState, subscribedRow, selectedTab: 'Workstations' }))}
                                selectedProvider={state.selectedProvider} 
                                selectedAccount={state.selectedAccount} 
                                selectedRegion={state.selectedRegion} 
                            />
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage