import React, { useState } from 'react';
import TreeView from './TreeView';
import {Icon} from "@iconify/react";

const TreeNode = ({ node, onNodeToggle, onNodeToggleFolder, onNodeClick }) => {
  const [loaderMountPathExpand, setLoaderMountPathExpand] = useState(false)
  const handleNodeToggle = async (node) => {
    if (onNodeToggle) {
      setLoaderMountPathExpand(true)
      await onNodeToggle(node);
      await onNodeToggleFolder(node);
      setLoaderMountPathExpand(false)
    }
  };
  
  return (
    <li>
      <div className='flex text-black'>
        <span className='flex self-center text-xs' onClick={() => handleNodeToggle(node)}>
          {(node && node.expanded && node.children) ?
            <Icon icon="uil:angle-down" className="text-lightGray" width="20" height="20" />
          : (node.type === 'directory') ? 
            <Icon icon="uil:angle-right" className="text-lightGray" width="20" height="20" />
          : null}
        </span> 
        <span className='flex text-xs' onClick={() => {
          onNodeClick(node)
          handleNodeToggle(node)
        }}>
          {loaderMountPathExpand && 
            <div className="inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-white mr-1" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
          }
          <Icon icon={`${node.type === 'directory' ? "flat-color-icons:folder" : "mingcute:file-fill"}`} className="mr-2 self-center" /> 
          {node.name}
        </span>
      </div>
      {node && node.children && node.expanded && (
        <TreeView
          data={node.children}
          onNodeToggle={onNodeToggle}
          onNodeClick={onNodeClick}
          onNodeToggleFolder={onNodeToggleFolder}
          parent={false}
        />
      )}
    </li>
  );
};

export default TreeNode;