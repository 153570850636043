/*************************************************
 * Collider
 * @exports
 * @file UserFieldDetails.js
 * @author Prakash // on 1/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';

export const UserFieldDetails = ({ detailsFormat, selectedDetails, selectedType }) => {
    return (
        <div className='m-2 grow overflow-auto'>
            <div className="rounded bg-white p-3">
                {detailsFormat && detailsFormat.length ? 
                    detailsFormat.map((row, index) => {
                        return(
                            <div key={'data_'+index} className={`${!index ? '' : 'mt-2'}`}>
                                <p className={`mb-1 text-SlateBlue text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                <small className='text-lightGray'>{row.description}</small>
                                {row.fields && row.fields.map((fld, fldIndex) => {
                                    return(
                                        <React.Fragment key={'fld_'+fldIndex}>
                                            {selectedDetails && selectedDetails[fld] ?
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 text-lightGray self-center">{removeUnderScore(fld)}</span>
                                                    {typeof selectedDetails[fld] === 'boolean' ?
                                                        <span className={`text-white`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                    : isValidDateTime(selectedDetails[fld]) ?
                                                        <span className="text-white">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                    : convertToLowerCase(fld) === "status" ?
                                                        <span className={`border border-badgeBorder bg-badgeBg rounded-full mb-2 px-2 text-black mr-2 text-black break-all text-xs`}>{selectedDetails[fld]}</span>
                                                    : convertToLowerCase(fld) === "tags" ?
                                                        <div className="flex flex-wrap">
                                                            {selectedDetails[fld].map((item, tagIndex) => {
                                                                return(
                                                                    <span key={'tag_'+tagIndex} className='border border-badgeBorder bg-badgeBg rounded-full mb-2 px-2 text-black mr-2 text-black break-all text-xs'>{item.key +' : '+item.value}</span>
                                                                )
                                                            })}
                                                        </div>
                                                    : Array.isArray(selectedDetails[fld]) ?
                                                            <div className="flex flex-wrap">
                                                                {selectedDetails[fld].map((item, cIndex) => {
                                                                    return(
                                                                        <span key={'item_'+cIndex} className='border border-badgeBorder bg-badgeBg rounded-full mb-2 px-2 text-black mr-2 text-black break-all text-xs'>{item}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                    :
                                                        <span className="text-white">{fld === 'provider' ? selectedDetails[fld][0].toUpperCase() : selectedDetails[fld]}</span>
                                                    }
                                                </div>
                                            : null}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center m-4'>
                        There are no data for user.
                    </div>
                }
            </div>
        </div>
    )
}