/*************************************************
 * Kiosk
 * @exports
 * @file DetailsSidePanel.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ClusterFieldDetails } from './ClusterFieldDetails';
import { QueuesFieldDetails } from './QueuesFieldDetails';
import { UserFieldDetails } from './UserFieldDetails';
import { ProjectFieldDetails } from './ProjectFieldDetails';
import {Icon} from "@iconify/react";


const DetailsSidePanel = (props) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
    }

    return (
        <div className='advanced-search overflow-auto' style={{ zIndex: 9999999 }} onClick={handleClickOutside}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search-content w-4/5 bg-GhostWhite' ref={clickOut}>
                <div className='header-search flex justify-between p-2'>
                    <div className='text-white'>
                        <span className='font-bold text-lg text-black'>{props.selectedType} Details</span>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => props.closeSidePanel()} />
                    </div>
                </div>                
                <React.Fragment>
                    <div className="mt-2">
                        <ul className="flex flex-wrap text-sm font-bold text-center">
                            <li className="me-2">
                                <span className={`inline-block px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                        </ul>
                    </div>
                    {state.selectedTab === "Details" ?
                        props.selectedType === "Cluster" ?
                            <ClusterFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "Queue" ?
                            <QueuesFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "User" ?
                            <UserFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "Project" ?
                            <ProjectFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : null
                    : null}
                </React.Fragment>   
            </div>
        </div>
    )
}
export default DetailsSidePanel;