import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { getAuditLogs } from '../../../actions/ImagebuilderAction'
import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";
// import Search from '../../common/SearchWithHiddenInput';
import { capitalizeTheFirstLetterOfEachWord, getSubPath, momentDateGivenFormat } from '../../../utils/utility';

let searchInput = null
const AuditSection = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 5  ,
        callListApi: true,
        listResults: []
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath.replace(getSubPath, '');
                    if(row.submenulist.filter(e => e.link === trimmedPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.callListApi) {
            setState(prevState => ({ ...prevState, callListApi: false }))
            let params = {}
            if(props.selectedProvider) {
                params.provider = props.selectedProvider.toLowerCase()
            }
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedImage) {
                params.image_name = props.selectedImage
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
    
            params.size = state.perPage
            
            if(state.token === 'next' && state.listResponse && state.listResponse.next_token) {
                params.next_token = state.listResponse && state.listResponse.next_token
            } else if(state.token === 'previous' && state.listResponse && state.listResponse.previous_token) {
                params.previous_token = state.listResponse && state.listResponse.previous_token
            }

            params.start_time = props.datePickerStartDate
            params.end_time = props.datePickerEndDate
    
            dispatch(getAuditLogs(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
    
                        setState(prevState => ({ ...prevState, listResponse: response, listResults: results, filterListResults: results, totalRecords: response.total ? response.total : 0, detailsFormat: response.image_details ? response.image_details : [], showLoading: false }));
                    }
                })
        }
    }, [dispatch, state.listResponse, props.selectedProvider, props.selectedAccount, props.selectedImage, props.selectedImageType, props.selectedRegion, state.searchText, state.perPage, state.callListApi, props.datePickerEndDate, props.datePickerStartDate, state.token])

    useEffect(() => {
        let dataResult = state.listResults && state.listResults.length ? state.listResults : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
        }
    }, [state.listResults])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.listResponse && state.listResponse.next_token.length && state.listResponse.next_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'next', callListApi: true }))
        } else if(action === 'previous' && currentPage === 1 && state.listResponse && state.listResponse.previous_token.length && state.listResponse.previous_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'previous', callListApi: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    return (
        <React.Fragment>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='p-2 overflow-auto'>
                <div className='flex flex-wrap justify-between my-3'>
                    <p className='mb-0 self-center md:w-1/2 w-full'>
                        <small>Showing {state.filterListResults && state.filterListResults.length} of total {state.totalRecords} image(s)</small>
                    </p>
                    <div className='flex flex-wrap self-center'>
                        <div className='flex justify-end w-full'>
                            {state.filterListResults && state.filterListResults.length ?
                                <CSVLink
                                    data={state.filterListResults ? state.filterListResults : []} 
                                    headers={state.tableHeaders ? state.tableHeaders : []}
                                    filename={'audit-logs'+new Date().getTime()+'.csv'}
                                    className={'self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            <div className='flex border border-lightGray rounded-md'>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`text-white w-5 self-center`} width={25} height={25} />
                                <div className={`w-11/12`}>
                                    <input
                                        value={state.searchText}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                            clearTimeout(searchInput)
                                            if(e.target.value.length > 2) {
                                                searchInput = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, startRecord: 0, currentPage: 1, totalPages: 1, listApi: [], filterListResults: [], showLoading: true, callListApi: true }))}, 1000); 
                                            } else if(!e.target.value.length) {
                                                setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, listApi: [], filterListResults: [], showLoading: true, callListApi: true }))
                                            }
                                        }}
                                        type='text'
                                        className={`bg-transparent text-white text-md pl-1 py-1`}
                                        placeholder='Search'
                                    />
                                </div>
                            </div>
                        </div>
                        {state.totalRecords > (state.filterListResults && state.filterListResults.length) ?
                            <div className='flex justify-end text-white mt-2 w-full'>
                                {/* <span className='mx-3 sel-center'>Paginate </span> */}
                                {/* <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></Icon></button>  */}
                                <button className={`flex ${state.listResponse && state.listResponse.previous_token && state.listResponse.previous_token.length ? 'text-info' : 'pointer-events-none text-ligthGray'}`} 
                                    onClick={() => {
                                        if(state.listResponse && state.listResponse.previous_token && state.listResponse.previous_token.length) {
                                            navigatePage('previous', state.currentPage)
                                        }
                                    }}>
                                        <span className='self-center'>previous</span> 
                                        <Icon icon="prime:angle-left" width="28" height="28" className={`cursor-pointer ${state.listResponse && state.listResponse.previous_token && state.listResponse.previous_token.length ? 'text-info' : 'pointer-events-none text-ligthGray'}`}></Icon>
                                    </button>
                                <button className={`flex ${state.listResponse && state.listResponse.next_token && state.listResponse.next_token.length ? 'text-info' : 'pointer-events-none text-ligthGray'}`}
                                    onClick={() => {
                                        if(state.listResponse && state.listResponse.next_token && state.listResponse.next_token.length) {
                                            navigatePage('next', state.currentPage)
                                        }
                                    }}
                                >
                                    <Icon icon="prime:angle-right" width="28" height="28" className={`ml-1 cursor-pointer ${state.listResponse && state.listResponse.next_token && state.listResponse.next_token.length ? 'text-info' : 'pointer-events-none text-ligthGray'}`}></Icon> <span className='self-center'>next</span>
                                </button>
                                {/* <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></Icon></button> */}
                            </div>
                        : null}
                    </div>
                </div>
                {state.filterListResults && state.filterListResults.length ?
                    state.filterListResults.map((item, i) => {
                        return(
                            <div className={`flex mr-2 px-2 mb-2 pb-1 ${(state.startRecord + state.perPage !== state.startRecord) && state.filterListResults.length !== i+1 ? 'border-b border-mediumDarkGray' : ''}`} key={'key_'+i}>
                                <div className='flex'>
                                    <p className='mb-0'>
                                        <span className='mb-0 mr-2 text-white font-bold'>{item.timestamp ? momentDateGivenFormat(item.timestamp, 'DD MMM YYYY HH:mm') : ''} (UTC time)</span>
                                        {item.provider ?
                                            <span className='mb-0 text-white font-bold'>{item.provider.toUpperCase()}</span>
                                        : null}
                                        {item.account_id ?
                                            <span className='mb-0 text-white font-bold'><span className='mx-1'>:</span>{item.account_id}</span>
                                        : null}
                                        {item.region ?
                                            <span className='mb-0 text-white font-bold'><span className='mx-1'>:</span>{item.region}</span>
                                        : null}
                                        {item.image_name ?
                                            <span className='mx-2 text-white font-bold'><span className='text-lightGray mr-1'>image</span> {item.image_name} 
                                            {item.ami_name ?
                                                <span className='text-lightGray'>({item.ami_name}) </span>
                                            : null}
                                            </span>
                                        : null}
                                        {item.user_name ?
                                            <span className='mb-0 mr-2 text-info font-bold'><span className='text-lightGray'>user</span> {item.user_name}</span>
                                        : null}
                                        <span className='mb-0 mr-2 text-white font-bold'>{item.message}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    })
                :
                    <div className='flex justify-center m-4'>
                        <p>No audit data were found..</p>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};
export default AuditSection