/*************************************************
 * Kiosk
 * @exports
 * @file Header.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import {Icon} from "@iconify/react";
import { getUserProfile } from '../../actions/commonActionNew'
import { capitalizeFirstLetter } from '../../utils/utility'

import { useMsal } from "@azure/msal-react";
import { msalConfig } from '../../authConfig';

const Header = () => {
	const { instance } = useMsal()
	const dropdownRef = useRef(null);

	const [state, setState] = useState({
		isDropdownOpen: false,
		profile: {}
	})

	const profileDetails = useSelector(state => state.filters.profileDetails);
	const dispatch = useDispatch(); // Create a dispatch function

	/**
	 * toggles profile settings dropdown
	 */
	const handleDropdownToggle = () => {
		setState(prevState => ({ ...prevState, isDropdownOpen: !prevState.isDropdownOpen }))
	}

	const handleLogout = () => {		
		// localStorage.removeItem('ACCESS_TOKEN');
		// localStorage.removeItem('ACCESS_TOKEN_EXPIERS_ON');
		localStorage.clear();
		sessionStorage.clear()
		
		const logoutRequest = {
			postLogoutRedirectUri: msalConfig.auth.redirectUri,
		};
	
		instance.logoutRedirect(logoutRequest);;
		// instance.logoutRedirect()
	}

	useEffect(() => {
		if(!Object.entries(profileDetails).length) {
			dispatch(getUserProfile({}))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, profile: response }))
					}
				})
		} else {
			setState(prevState => ({ ...prevState, profile: profileDetails }))
		}
	}, [dispatch, profileDetails])

	useEffect(() => {
		/**
		 * If clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isDropdownOpen: false }));
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	/**
	 * Renders Page header
	 */
	return (
		<div className="bg-GhostWhite w-full h-fit py-3 md:overflow-hidden overflow-y-auto md:sticky top-0 relative z-40">
			<div className="flex justify-between md:flex-row md:items-center">
				<p className="text-black text-sm mx-4 font-medium">Tachyon</p>
				<div className="relative" ref={dropdownRef}>
					<p className="text-black flex flex-row gap-1 font-medium items-center md:text-sm text-xs md:pl-0 pr-4 pl-5 cursor-pointer z-10" onClick={handleDropdownToggle}>
						{state.profile && Object.entries(state.profile).length &&
						(state.profile.firstName
							? capitalizeFirstLetter(state.profile.firstName) + ' ' + capitalizeFirstLetter(state.profile.lastName)
							: (state.profile.userName ? capitalizeFirstLetter(state.profile.userName) : "")
						)}
						<Icon icon="icon-park-solid:down-one" className="text-black" width="16" height="16" />
					</p>
					{state.isDropdownOpen && (
						<div className="fixed right-0 mt-2 bg-white border border-extraLightGray rounded-md mr-5 shadow-lg">
							<p className="block px-10 py-2 text-base text-black cursor-pointer" onClick={handleLogout}>
								Logout
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Header