/*************************************************
 * Tvastar
 * @exports
 * @file QosDetails.js
 * @author Prakash // on 21/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import { onlyNumeric } from '../../../utils/utility';
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchWithHiddenInput';
import {Icon} from "@iconify/react";

const QosDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		showQosDetails: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)
	// const hasError = useSelector(state => state?.hpc?.hpcPropsDetails?.hasError || false)

	const formLabelBuilder = useCallback((item) => {
		let querybuilder = []
		let query = item.label
		let splitString = query && query !== "" ?query.split(" ") : []
		splitString.forEach(str => {
			let result = str.split("$");
			if(result.length === 1) {
				let dataRow = {}
				dataRow.input = false
				dataRow.string = result[0]				
				querybuilder.push(dataRow)
			} else {
				result.forEach((resRow, index) => {
					if(resRow !== "") {
						if(index === 1) {
							let dataRow = {}
							dataRow.input = true
							dataRow.field = item.parameter
							dataRow.component = item.component
							dataRow.string = result[0]
							querybuilder.push(dataRow)
						} else {
							let dataRow = {}
							dataRow.input = false
							dataRow.string = resRow
							querybuilder.push(dataRow)
						}
					}
				})
			}
		})

		setState(prevState => ({ ...prevState, [item.parameter+"_label"]: querybuilder }))

	}, [])

	useEffect(() => {
		let masterData = state.masterData
		
		masterData.forEach(item => {
			if(item.component === "select" || item.component === "multiselect") {
				if(item.options) {
					setState(prevState => ({ ...prevState, [item.parameter+"_options"]: item.options, [item.parameter+"_0"]: item.default ? item.default : "" }))
				} else {
					setState(prevState => ({ ...prevState, apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" }))
				}
			} else {
				setState(prevState => ({ ...prevState, [item.parameter]: item.value, [item.parameter+'_existing']: item.value }))
			}

			formLabelBuilder(item)
		})

	}, [qosCreateEditInputs, formLabelBuilder, state.masterData, props.viewMode])

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
			let parameters = []
			props.masterData.forEach(row => {
				Object.entries(row).forEach(([rKey, rValue]) => {
					let errorMessage = ''
					if(rKey === 'value') {
						if(rValue && props.pageType === 'edit' && !state[row.parameter]) {
							// row.value = state[row.parameter] ? state[row.parameter] : ''
							// row.value = rValue
							errorMessage = 'Existing value should not be left empty'
							// row.value = rValue
							setState(prevState => ({ ...prevState, [row.parameter]: state[row.parameter+'_existing'] }))
							
						} else {
							row.value = state[row.parameter] ? state[row.parameter] : ''
						}
						
						setState(prevState => ({ ...prevState, [row.parameter+'_error']: errorMessage}))
					} else if(rKey === 'value_list') {
						row.value_list = state[row.parameter] ? state[row.parameter] : []
					}
				})
				if(row.value) {
					parameters.push(row)
				}
			})

			obj.parameters = parameters
			
			dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
		}
	}, [dispatch, qosCreateEditInputs, props, state])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		props.onChildPageClick(event)

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<React.Fragment>
			<div className='flex justify-between'>
				<p className='text-info text-lg'>QOS Details</p>
				{state.showQosDetails ?
					state.masterData && state.masterData.length ?
						<div style={{minWidth: "300px"}}>
						<Search
							data={state.masterData ? state.masterData : []}
							applyTags={false}
							applyLiteDarkTags={true}
							topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
							searchClassName={'px-2'}
							searchIconColor={'text=black '}
							searchPlaceHolder={'Search....'}
							className={'bg-transparent text=black'}
							widthHeight={25}
							filteredData={(filteredMasterData) => {
								// let totalPages = 1
								// if(filteredMasterData && filteredMasterData.length > state.perPage) {
								// 	totalPages = Math.ceil(filteredMasterData.length / state.perPage)
								// }
								setState(prevState => ({ ...prevState, filteredMasterData }))
							}}
						/>
						</div>
					: null
				: null}
			</div>

			{state.showQosDetails ?
				state.filteredMasterData && state.filteredMasterData.map((item, iIndex) => {
					return(
						<div key={'master_'+iIndex} className={`w-full py-3 mb-2 ${iIndex ? 'border-t border-mediumDarkGray' : ''}`} onClick={(event) => handleChildClick(event, 'parent')}>					
							<div className='mb-2 flex flex-wrap text=black'>
								<span className='mr-3 self-end text-lg text-info break-all'>{item.parameter}</span>
								{item.component === "multiselect" ?
									<div onClick={(event) => {
											event.preventDefault();
											handleChildClick(event, 'child', 'muliselectDropDown', "doprdown_"+iIndex)
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-2 truncate lg:w-1/6 md:w-1/6 w-full ${state[item.parameter] && state[item.parameter].length ? 'text=black' : 'text-lightGray'}`}>
											{state[item.parameter] && state[item.parameter].length ? state[item.parameter].length+' Selected' : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state[item.parameter] && state[item.parameter].length ? "text=black" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>
										{state['doprdown_'+iIndex] ?
											<MultiSelectSection
												fields={["value", "label"]}
												options={state[item.options]}
												selectedValues={state[item.parameter] ? state[item.parameter] : ''}
												callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [item.parameter]: value }))}
												singleSelection={false}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
								: item.component === "select" ?
									<div onClick={(event) => {
											if(state["showDropdown_"+iIndex]) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "showDropdown_"+iIndex)
											}
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between mb-2 truncate lg:w-1/6 md:w-1/6 w-full ${state[item.parameter] ? 'text=black' : 'text-lightGray'}`}>
											{state[item.parameter] ? state[item.parameter] : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state[item.parameter] ? "text=black" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>
										{state['showDropdown_'+iIndex] ?
											<MultiSelectSection
												fields={["value", "label"]}
												options={state[item.options] ? state[item.options] : []}
												selectedValues={state[item.parameter] ? state[item.parameter] : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, [item.parameter]: value, onChangeInput: true }))
														} else {
														value.preventDefault();
														handleChildClick(value, 'child', 'search', "")
														}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
								: item.component === "text" ?
									<React.Fragment>
									<input 
										type="text" 
										className={`border-b border-lightGray bg-transparent px-2 py-1 lg:w-1/6 md:w-1/6 w-full`}
										// style={{minHeight: "38px"}}
										placeholder={"enter"}
										value={state[item.parameter]}
										onChange={e => {
											let value = (item.data_type === 'integer' && e.target.value ? onlyNumeric(e.target.value) : e.target.value) 
											setState(prevState => ({ ...prevState, [item.parameter]: value, onChangeInput: true }))
											// onChangeInput(item.parameter, value)
											
										}}
										// onKeyDown={e => {
										// 	setState(prevState => ({ ...prevState, onChangeInput: true }))
										// }}
									/>
									{state[item.parameter+'_error'] ?
										<small className='text-danger lg:ml-2 md:ml-2 sm:mt-2 self-center'>{state[item.parameter+'_error']}</small>
									: null}
									</React.Fragment>
								: null}
							</div>
							<p className='text=black text-xs'>{item.label}</p>
						</div>
					)
				})
			: null}
		</React.Fragment>
	)
}

export default QosDetails
