/*************************************************
 * Collider
 * @exports
 * @file ApexSingleDonutChart.js
 * @author Prakash // on 08/03/2024
 * @copyright © 2019-24 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'


const ApexSingleDonutChart = (props) => {

    const [state, setState] = useState({})

	const drawGraph = useCallback((series, labels, colors, overAll, overAllLabel, graphHeading) => {	
		let options = {
			stroke: {
				width: 0
			},	
			chart: {
				type: 'donut',
				sparkline: {
					enabled: true
				},
				zoom: {
					enabled: false,
				},
				// offsetX: -10
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			labels: labels,
			// colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
			colors: colors,
			tooltip: {
				enabled: true,
				custom: function({ series, seriesIndex, w }) {
					let color = w.globals.colors[seriesIndex]
					// let val = ''
					// if(labels[0] !== 'No Data') {
					// 	val = series[seriesIndex]
					// }
					return '<div class="arrow_box flex">' +
                      '<span class="mr-2">' +  w.globals.labels[seriesIndex].label + '</span>' +
                      '<span style="color:'+ color +'">' + w.globals.labels[seriesIndex].value  + '</span>' +
                      '</div>'
				},
			},
			plotOptions: {
				offsetX: 10,
    			offsetY: 0,
				pie: {
					donut: {
						size: props.size,
						labels: {
							show: false,
						}
					},      
				}
			}
		}

        setState(prevState => ({ ...prevState, series, options, graphHeading }))
    }, [props])
    
    useEffect(() => {
        let overAll = 0
        let overAllLabel = 'Total'
        let graphData = props.graphData;
        let series = []
        let labels = []
		let graphHeading = ''
		let colors = props.colors
		if(graphData === 'mockData') {
			// overAll = 84
            series = [100]
            labels = [{label: 'Critical', value: 2500}]
        } else {
			series.push(100)
			labels.push(graphData)
		}

		overAll = overAll ? parseFloat(overAll).toFixed(2) : 0
		
		drawGraph(series, labels, colors, overAll, overAllLabel, graphHeading)
    }, [props, drawGraph])

	return (
		state.series ?
			<div className={`${props.className} apexCommonDonutChart`}>
				<p className={`font-weight-bold mb-1 text-center`}>{state.graphHeading ? state.graphHeading : ''}</p>
				<Chart options={state.options} series={state.series} type="donut" height={props.height} width={props.width} />
			</div>
		: null
	)
}

export default ApexSingleDonutChart;