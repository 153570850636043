/*************************************************
 * Collider
 * @exports
 * @file ClusterDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {Icon} from "@iconify/react";

import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import Events from './Events';

const ClusterDetailsSidePanel = ({ selectedClusterDetails, closeSidePanel, detailsFormat }) => {
    const clickOut = useRef()
    // const accounts = useSelector(state => state.filters.accounts);
    // const regions = useSelector(state => state.filters.regions);

    const [state, setState] = useState({
        showLoading: false,
        selectedTab: 'Details',
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-4/5 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-white flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='font-bold text-lg mb-0'>Cluster Details</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className={`px-2`}>
                        <ul className="flex flex-wrap text-sm font-bold text-center">
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Events' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true }))}>Events</span>
                            </li>
                        </ul>
                    </div>
                    <div className="rounded-md bg-white p-3 min-h-screen overflow-auto">
                        {state.selectedTab === 'Details' ?
                            detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div key={'det_'+index} className={`${!index ? '' : 'mt-2'}`}>
                                        <p className={`mb-1 text-SlateBlue text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-lightGray'>{row.description}</small>
                                        {row.fields && row.fields.map((fld, fIndex) => {
                                            return(
                                                <React.Fragment key={'fld_'+index+'_'+fIndex}>
                                                    {fld === "queue_configuration" ?
                                                        selectedClusterDetails[fld].length && selectedClusterDetails[fld].map((item, iIndex) => {
                                                            return(
                                                            <div key={'fItem_'+index+'_'+fIndex+'_'+iIndex} className={`rounded-md bg-GhostWhite p-2 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Subnet IDs</span>
                                                                    <div className="flex flex-wrap">
                                                                        {item && item.subnet_ids ?
                                                                            item.subnet_ids.map((ids, idsIndex) => {
                                                                                return(
                                                                                    <span key={'ids_'+index+'_'+fIndex+'_'+iIndex+'_'+idsIndex} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Security Group IDs</span>
                                                                    <div className="flex flex-wrap">
                                                                        {item && item.security_group_ids ?
                                                                            item.security_group_ids.map((ids, idsIndex) => {
                                                                                return(
                                                                                    <span key={'ids1_'+index+'_'+fIndex+'_'+iIndex+'_'+idsIndex} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Policies</span>
                                                                    <div className="flex flex-wrap">
                                                                        {item && item.policies ?
                                                                            item.policies.map(pol => {
                                                                                return(
                                                                                    <span key={'pol_'+index+'_'+fIndex+'_'+iIndex+'_'+pol} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{pol}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Script Location</span>
                                                                    <span className={`self-center text-black`}>{item && item.script_location ? item.script_location : ""}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Type</span>
                                                                    <span className={`self-center text-black`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Size</span>
                                                                    <span className={`self-center text-black`}>{item && item.volume_size && item.volume_size}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Encryption</span>
                                                                    <span className={`self-center text-black`}>{item && item.volume_encryption ? "True" : "False"}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                                    <span className={`self-center text-black`}>{item && item.bucket_name ? item.bucket_name : ""}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                                    <span className={`self-center text-black`}>{item && item.enable_s3_write_access ? "True" : "False"}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Compute Resources</p>
                                                                {item && item.compute_resources ?
                                                                    item.compute_resources.length && item.compute_resources.map((comp, cIndex) => {
                                                                        return (
                                                                            <div key={'comp_'+index+'_'+fIndex+'_'+iIndex+'_'+cIndex} className='bg-BlockWhite p-2 rounded-md mb-2'>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Name</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Instance Type</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.instance_type
                                                                                    ? Array.isArray(comp.instance_type) ? comp.instance_type[0] : comp.instance_type
                                                                                    : ""}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Min Instance Count</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Max Instance Count</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Efa Enabled</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Placement Group Enabled</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                            </div>
                                                                            <div className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Disable Simultaneous Multithreading</span>
                                                                                <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                            </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                {item && item.s3_access ?
                                                                    <div className="p-3 rounded-md bg-BlockWhite">
                                                                    {item.s3_access.map((buk, bukIndex) => {
                                                                        return(
                                                                            <div key={'buk_'+index+'_'+fIndex+'_'+iIndex+'_'+bukIndex} className={`flex ${(bukIndex && bukIndex+1 !== item.s3_access.length) ? 'mb-2' : ''}`}>
                                                                            <div className="">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                                                <span className={`self-center text-black`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                                                <span className={`self-center text-black`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                                                            </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            )
                                                        })
                                                    : fld === "login_node" ?
                                                        selectedClusterDetails[fld] &&selectedClusterDetails[fld].length && selectedClusterDetails[fld].map((login_node, strIndex) => {
                                                            return (
                                                                <React.Fragment key={'login_node_'+strIndex}>
                                                                {Object.entries(login_node).length ?
                                                                    Object.entries(login_node).map(([key, value], soIndex) => {
                                                                        return(
                                                                            <div key={'str_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex} className="flex mt-3">
                                                                                <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : fld === "storage_configuration" ?
                                                        selectedClusterDetails[fld].map((storage, strIndex) => {
                                                            return (
                                                                <React.Fragment key={'storage_'+strIndex}>
                                                                {Object.entries(storage).length ?
                                                                    Object.entries(storage).map(([key, value], soIndex) => {
                                                                        return(
                                                                            <div key={'str_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex} className="flex mt-3">
                                                                                <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'tagV_'+index+'_'+fIndex+'_'+strIndex+'_'+soIndex+'_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    : fld === "head_node" || fld === "cluster_configuration" ?
                                                        selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                            Object.entries(selectedClusterDetails[fld]).map(([key, value], clIndex) => {
                                                                return(
                                                                    key === 's3_access' ?
                                                                        <React.Fragment key={'cls_'+clIndex}>
                                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                        <div className="p-3 rounded-md bg-GhostWhite">
                                                                            {value.map((buk, bukIndex) => {
                                                                                return(
                                                                                    <div key={'cls_'+index+'_'+fIndex+'_'+clIndex+'_'+bukIndex} className={`flex ${(bukIndex && bukIndex+1 !== value.length) ? 'mb-2' : ''}`}>
                                                                                        <div className="">
                                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                                                            <span className={`self-center text-black`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                                                            <span className={`self-center text-black`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        </React.Fragment>
                                                                    :
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-1">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cls_tag_'+index+'_'+fIndex+'_'+clIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap gap-1">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cls_str_'+index+'_'+fIndex+'_'+clIndex+'_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                )
                                                            })
                                                        : null
                                                    : fld === "database_configuration" ?
                                                        Array.isArray(selectedClusterDetails[fld]) ? 
                                                        selectedClusterDetails[fld].map((dat, datIndex) => {
                                                            return(
                                                                <React.Fragment key={'datt_'+index+'_'+fIndex+'_'+datIndex}>
                                                                {Object.entries(dat).length ?
                                                                    Object.entries(dat).map(([key, value], dcIndex) => {
                                                                        return(
                                                                            <div key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex} className="flex mt-3">
                                                                                <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div className="flex flex-wrap gap-1">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span key={'dc_'+index+'_'+fIndex+'_'+datIndex+'_'+dcIndex+'_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                </React.Fragment>
                                                            )
                                                            })
                                                        : 
                                                            Object.entries(selectedClusterDetails[fld]).length ?
                                                                Object.entries(selectedClusterDetails[fld]).map(([key, value], cdIndex) => {
                                                                    return(
                                                                        <div key={'cd_'+index+'_'+fIndex+'_'+cdIndex} className="flex mt-3">
                                                                            <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-1">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cd_'+index+'_'+fIndex+'_'+cdIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap gap-1">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={'cd_'+index+'_'+fIndex+'_'+cdIndex+'_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : null
                                                    : selectedClusterDetails && selectedClusterDetails[fld] && fld !== 'head_node' && fld !== "cluster_configuration" && fld !== "database_configuration" && fld !== "queue_configuration" && fld !== "storage_configuration" ?
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(fld)}</span>
                                                            {typeof selectedClusterDetails[fld] === 'boolean' ?
                                                                <span className={`text-black break-all`}>{selectedClusterDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedClusterDetails[fld]) ?
                                                                <span className="text-black break-all">{momentConvertionUtcToLocalTime(selectedClusterDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{selectedClusterDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div className="flex flex-wrap gap-1">
                                                                    {selectedClusterDetails[fld].map(item => {
                                                                        return(
                                                                            <span key={'tagg_'+index+'_'+fIndex+'_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-black break-all">{fld === 'provider' ? selectedClusterDetails[fld].toUpperCase() : selectedClusterDetails[fld]}</span>
                                                            }
                                                        </div>
                                                    : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        : state.selectedTab === 'Events' ?
                            <Events 
                                selectedClusterDetails={selectedClusterDetails}
                            />
                        : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClusterDetailsSidePanel