import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import MultiSelectSection from '../../../common/MultiSelectSection';
import { onlyNumeric } from '../../../../utils/utility';
import {Icon} from "@iconify/react";

const HeadNodeSection = (props) => {
    const [state, setState] = useState({});
    const createUpdateCluster = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
    );
    const propsClusterFilters = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
    );
  
    const dispatch = useDispatch(); // Create a dispatch function

    const callPageVist = useCallback(() => {
        let clusterConfiguration = createUpdateCluster && createUpdateCluster.cluster_configuration
            ? createUpdateCluster.cluster_configuration
            : {};
    
        Object.entries(clusterConfiguration).forEach(([key, value]) => {
          if (key !== "s3_access") {
            if (key === "on_node_configured_script" && value) {
              setState((prevState) => ({ ...prevState, showNodeScript: true }));
            } else if (key === "on_node_updated_script" && value) {
              setState((prevState) => ({ ...prevState, showUpdatedScript: true }));
            }
            setState((prevState) => ({ ...prevState, [key]: value }));
          }
        });
      }, [createUpdateCluster]);

    useEffect(() => callPageVist(), [callPageVist]);
  
    const addPolicies = () => {
      let policies = state.policies ? state.policies : [];
      policies.push(state.policy);
  
      handleConfigurationInputChange("policies", policies);
      setState((prevState) => ({ ...prevState, policies, policy: "" }));
    };
  
    const removePolicies = (value) => {
      let policies = state.policies.filter((e) => e !== value);
  
      handleConfigurationInputChange("policies", policies);
      setState((prevState) => ({ ...prevState, policies }));
    };
  
    const removefromList = (value, listField) => {
      let filteredListItem = state[listField].filter((e) => e !== value);
  
      let array = [];
      filteredListItem.forEach((row) => {
        let rowValue = {
          value: row,
          label: row,
        };
        array.push(rowValue);
      });
  
      setState((prevState) => ({ ...prevState, [listField]: filteredListItem }));
    };
  
    const handleConfigurationInputChange = (label, value, valueField, type) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let clusterConfiguration = obj.cluster_configuration
        ? obj.cluster_configuration
        : {};
    clusterConfiguration[label] =
    type === "integer" ? parseInt(value) : value;
  
      obj["cluster_configuration"] = clusterConfiguration;
      dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  
      setState((prevState) => ({ ...prevState, [label]: value }));
    };
  
    const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };

    return (
        <div className='rounded bg-white p-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`flex`}>
                <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                    <div className='flex justify-between'>
                        <label className='self-center m-0 text-black'>Instance Type</label>
                        {state.hasError && state.instance_type ? 
                            <small className='text-issues'>required</small>
                        : null}
                    </div>
                    <div className='border-lightGray bg-transparent rounded-5'
                        onClick={(event) => {
                            if(!state.showInstanceType) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "showInstanceType")
                            }
                        }}
                    >
                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.instance_type && props.pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                            {state.instance_type ? state.instance_type : 'Select'}
                            {props.pageMode === 'Create' ? 
                                <Icon icon="icon-park-solid:down-one" className={`${state.instance_type ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                            : null}
                        </p>
                        {state.showInstanceType && props.pageMode === 'Create' ?
                            <MultiSelectSection
                                fields={["instance_type", "instance_type"]}
                                removeTop={true}
                                options={propsClusterFilters && propsClusterFilters.instance_type ? propsClusterFilters.instance_type : []}
                                selectedValues={state.instance_type ? state.instance_type : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState,  instance_type: value }))
                                        handleConfigurationInputChange('instance_type', value)
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                hideSearch={false}
                                topClass={'auto'}
                                widthClass={'minWidth220'}
                            />
                        : null}
                    </div>
                </div>
            </div>
            <div className={`flex mt-3`}>
                <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                    <div className='flex justify-between'>
                        <label className='self-center m-0 text-black'>Subnet Id </label>
                        {state.hasError && !state.subnet_id ?
                            <small className='text-issues'>required</small>
                        : null}
                    </div>
                    <div className='border-lightGray bg-transparent rounded-5'
                        onClick={(event) => {
                            if(!state.showSubnetId) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "showSubnetId")
                            }
                        }}
                    >
                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.subnet_id && props.pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                            {state.subnet_id ? state.subnet_id : 'Select'}
                            {props.pageMode === 'Create' ? 
                                <Icon icon="icon-park-solid:down-one" className={`${state.subnet_id ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                            : null}
                        </p>
                        {state.showSubnetId && props.pageMode === 'Create' ?
                            <MultiSelectSection
                                options={propsClusterFilters && propsClusterFilters.subnet_ids ? propsClusterFilters.subnet_ids : []}
                                selectedValues={state.subnet_id ? state.subnet_id : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, subnet_id: value }))
                                        handleConfigurationInputChange('subnet_id', value)
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                hideSearch={false}
                                topClass={'auto'}
                                widthClass={'minWidth220'}
                            />
                        : null}
                    </div>
                </div>
            </div>
            <div className='flex mt-3'>
                <div className='w-full'>
                    <div className='py-1 lg:w-1/3 md:w-1/2 w-full' onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "showSecurityGroupDropdown")
                    }}>
                        <p className='b-block mb-0 flex'>
                            Security Group IDs <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                            {state.inputValidationError && (!state.security_group_ids || !state.security_group_ids.length) ?
                                <span className='mb-0 text-red ml-2'>required</span>
                            : null}
                        </p>
                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.security_group_ids && state.security_group_ids.length ? 'text-black' : 'text-lightGray'}`}>
                            {state.security_group_ids && state.security_group_ids.length ? state.security_group_ids.length+' Selected' : 'Select'}
                            <Icon icon="icon-park-solid:down-one" className={`${state.security_group_ids && state.security_group_ids.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                        </p>
                        {state.showSecurityGroupDropdown ?
                            <div className='position-relative'>
                                <MultiSelectSection 
                                    // fields={["account_id", "account_name"]}
                                    removeTop={true}
                                    options={propsClusterFilters && propsClusterFilters.security_group_ids ? propsClusterFilters.security_group_ids : []}
                                    selectedValues={state.security_group_ids ? state.security_group_ids : []}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, 'security_group_ids': value }))
                                        handleConfigurationInputChange('security_group_ids', value)
                                    }}
                                    widthClass={'minWidth220'}
                                    removeTopOptions={true}
                                />
                            </div>
                        : null}
                    </div>
                    <div className='flex flex-wrap'>
                        {state.security_group_ids && state.security_group_ids.length ? 
                            state.security_group_ids.map(row => {
                                return(
                                    <span className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace'>{row}
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'security_group_ids') }/>
                                    </span>
                                )
                            })
                        : null}
                    </div>
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <div className='flex justify-between'>
                            <label className='self-center m-0 text-black'>Key pair</label>
                            {state.hasError && !state.key_name ? (
                                <small className='text-issues'>required</small>
                            ) : null}
                        </div>
                        <div
                            className="border-lightGray bg-transparent rounded-5"
                            onClick={(event) => {
                                if (!state["isKeyPair"]) {
                                    event.preventDefault();
                                    handleChildClick(event,"child","singleDropDown","isKeyPair");
                                }
                            }}
                            >
                            <p
                                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                state["key_name"] && props.pageMode === "Create" ? "text-black" : "placeholder"}`}
                            >
                                {state["key_name"]
                                ? propsClusterFilters.key_pair.filter((e) => e.value === state["key_name"]).length
                                    ? propsClusterFilters.key_pair.filter((e) => e.value === state["key_name"])[0].key
                                    : state["key_name"]
                                : "Select"}
                                <Icon icon="icon-park-solid:down-one" className={`${state.key_name ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                            </p>
                            {state["isKeyPair"] && props.pageMode === "Create" ? (
                                <MultiSelectSection
                                fields={["value", "key"]}
                                options={
                                    propsClusterFilters && propsClusterFilters.key_pair
                                    ? propsClusterFilters.key_pair.map((d) => ({ key: d, value: d}))
                                    : []
                                }
                                selectedValues={state["key_name"] ? state["key_name"] : ""}
                                callbackMultiSelect={(value) => {
                                    if (!value || typeof value === "string") {
                                    handleConfigurationInputChange("key_name", value);
                                    setState((prevState) => ({ ...prevState, "key_name": value}));
                                    } else {
                                    value.preventDefault();
                                    handleChildClick(value, "search", "singleDropDown", "");
                                    }
                                }}
                                singleSelection={true}
                                widthClass={"min-w-[220px]"}
                                removeTopOptions={true}
                                />
                            ) : null}
                            </div>
                    </div>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.cluster_configuration && propsClusterFilters.description.cluster_configuration.key_name ?
                        <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.cluster_configuration.key_name}</p>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='lg:w-1/2 md:w-1/2 w-full'>
                    <div className='flex justify-between'>
                        <label className='self-center m-0 text-black'>IAM Policies</label>
                        {state.hasError && !state.policy ? (
                            <small className='text-issues'>required</small>
                        ) : null}
                    </div>
                    <div className='flex'>
                        <input
                            type='text'
                            placeholder='arn:aws:iam::<account>:policy/<policy-name-with-path>'
                            className='border border-lightGray focus-visible:border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full'
                            // maxlength='128'
                            value={state.policy ? state.policy : ''}
                            onChange={e => {
                                setState(prevState => ({ ...prevState, policy: e.target.value}))
                            }}
                        />
                        <Icon icon="iconoir:plus" width="25" height="25"  className='cursor-pointer text-black ml-3 lg:mt-2 md:mt-2 mt-0' onClick={() => addPolicies()}/>
                    </div>
                    <div className='flex flex-wrap'>
                    {state.policies && Array.isArray(state.policies) && state.policies.length ? 
                        state.policies.map(row => {
                        return(
                            <span className={'flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace'}>{row}
                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removePolicies(row) }/>
                            </span>
                        )
                        })
                    : null}
                    </div>
                </div>
            </div>
            <p className='text-black f16 mt-3'>Scripts</p>
            <div className='bg-GhostWhite p-3 rounded mt-3'>
                <div className={'flex'}>
                    <div className='py-1 w-full self-center'>
                        <div className='flex'>
                            <label className="mb-0">
                                <input type="checkbox" 
                                    className="mt-1" 
                                    checked={state.showNodeScript ? true : false}
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, showNodeScript: !state.showNodeScript}))
                                    }}
                                />
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Run script on node configured</span>
                        </div>
                    </div>
                </div>
                {state.showNodeScript ? 
                    <div className={'flex my-3'}>
                        <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                            <div className='flex justify-between'>
                                {/* <label className='self-center m-0 text-black'>Node Configured Script</label> */}
                                {state.hasError && !state.on_node_configured_script ? (
                                    <small className='text-issues'>required</small>
                                ) : null}
                            </div>
                            <input
                                type='text'
                                placeholder='Enter script'
                                className='border border-lightGray focus-visible:border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full'
                                // maxlength='128'
                                value={state.on_node_configured_script ? state.on_node_configured_script : ''}
                                onChange={e => {
                                    setState(prevState => ({ ...prevState, on_node_configured_script: e.target.value}))
                                    handleConfigurationInputChange('on_node_configured_script', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                : null}
                <div className={'flex'}>
                    <div className='py-1 w-full self-center'>
                        <div className='flex'>
                            <label className="mb-0">
                                <input type="checkbox" 
                                    className="inputdemo mt-1" 
                                    checked={state.showUpdatedScript ? true : false}
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, showUpdatedScript: !state.showUpdatedScript}))
                                    }}
                                />
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Run script on node updated</span>
                        </div>
                    </div>
                </div>
                {state.showUpdatedScript ? 
                    <div className={'flex mt-3'}>
                        <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                            <div className='flex justify-between'>
                                {/* <label className='self-center m-0 text-black'>Node Updated Script</label> */}
                                {state.hasError && !state.on_node_updated_script ? (
                                    <small className='text-issues'>required</small>
                                ) : null}
                            </div>
                            <input
                                type='text'
                                placeholder='Enter updated script'
                                className='border border-lightGray focus-visible:border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full'
                                // maxlength='128'
                                value={state.on_node_updated_script ? state.on_node_updated_script : ''}
                                onChange={e => {
                                    setState(prevState => ({ ...prevState, on_node_updated_script: e.target.value}))
                                    handleConfigurationInputChange('on_node_updated_script', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                : null}
            </div>

            <p className='b-block text-black mb-2 mt-3'>Local Storage</p>
            <div className='rounded bg-GhostWhite p-3 mb-3'>
                <div className='flex'>
                    <div className='py-1 lg:w-1/5 md:1/3 w-full'>
                        <p className='b-block mb-0'>Root Volume Size (GB)</p>
                        <input
                            type='number'
                            placeholder='Enter volume size'
                            className={`border border-lightGray focus-visible:border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full ${props.pageMode !== 'Create' ? 'disabled text-lightGray' : 'text-black'}`}
                            // maxlength='128'
                            value={state.volume_size ? state.volume_size : ''}
                            onChange={e => {
                                if(props.pageMode === 'Create') {
                                    handleConfigurationInputChange('volume_size', onlyNumeric(e.target.value), 'integer')
                                }
                            }}
                        />
                    </div>
                    <div className='py-1 lg:w-1/5 md:1/3 w-full lg:pl-3 md:p1-3 p-0'>
                        {/* <p className='b-block mb-0 flex'>
                            Volume Type
                        </p> */}
                        <div className='flex justify-between'>
                            <label className='self-center m-0 text-black'>Volume Type</label>
                            {state.hasError && state.volume_type ? 
                                <small className='text-issues'>required</small>
                            : null}
                        </div>
                        <div className='border-lightGray bg-transparent rounded-5'
                            onClick={(event) => {
                                if(!state.showVollumeType) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showVollumeType")
                                }
                            }}
                        >
                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.volume_type && props.pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                                {state.volume_type ? state.volume_type : 'Select'}
                                {props.pageMode === 'Create' ? 
                                    <Icon icon="icon-park-solid:down-one" className={`${state.volume_type ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                : null}
                            </p>
                            {state.showVollumeType && props.pageMode === 'Create' ?
                                <MultiSelectSection
                                    options={propsClusterFilters && propsClusterFilters.volume_type ? propsClusterFilters.volume_type : []}
                                    selectedValues={state.volume_type ? state.volume_type : ''}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            setState(prevState => ({ ...prevState,  volume_type: value }))
                                            handleConfigurationInputChange('volume_type', value)
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    singleSelection={true}
                                    removeTopOptions={true}
                                    hideSearch={false}
                                    topClass={'auto'}
                                    widthClass={'minWidth220'}
                                />
                            : null}
                        </div>
                    </div>
                    <div className='py-1 lg:w-1/5 md:1/3 w-full lg:pl-3 md:p1-3 p-0 mt-3 self-center'>
                        <div className='flex self-center mr-3'>
                            <div className="flex mr-2 self-center" >
                                <label className="mb-0">
                                    <input type="checkbox" 
                                        className="inputdemo mt-1" 
                                        checked={state.volume_encrypted ? true : false}
                                        disabled={props.pageMode !== 'Create' ? true : false}
                                        onChange={(e) => 
                                            {handleConfigurationInputChange('volume_encrypted', !state.volume_encrypted)}
                                        }
                                    />
                                    <span className="slider round small pl-1"></span>
                                </label>
                            </div>
                            <span className="mb-0 text-lightGray text-nowrap">Encrypt Root Volume</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HeadNodeSection