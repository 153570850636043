/*************************************************
 * Tvastar
 * @exports
 * @file Lifecycle.js
 * @author Prakash // on 30/08/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
// import _ from 'lodash'
// import PropTypes from 'prop-types'
import { deleteLifecyclePolicies, listLifecyclePolicies, putLifecyclePolicy, disableLifecyclePolicy } from '../../../actions/CatalogAction'

import { momentTimeGivenFormat, momentDateGivenFormat, onlyNumeric, getSubPath } from '../../../utils/utility'
 import { Store as CommonNotification } from 'react-notifications-component';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import MultiSelectSection from '../../common/MultiSelectSection';
import {Icon} from "@iconify/react";

const Lifecycle = (props) => {
	const [state, setState] = useState({
		selectedTab: "Policy",
		showLoading: true,
		shutdownOptions: [
			{label: "Daily/Weekly", value: "daily"},
			{label: "Monthly", value: "monthly"},
		],
		dailyArray: [0],
		notificationArray: ["slack", "email"],
		dayOptions: [
			{label: "Monday", value: "Monday"},
			{label: "Tuesday", value: "Tuesday"},
			{label: "Wednesday", value: "Wednesday"},
			{label: "Thursday", value: "Thursday"},
			{label: "Friday", value: "Friday"},
			{label: "Saturday", value: "Saturday"},
			{label: "Sunday", value: "Sunday"},
		],
		
		retain_dependencies: true,
		retain_configuration: true,
		showDailySection: true,
		showMonthlySection: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation()
    const currentPath = location.pathname;

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

	useEffect(() => {
		if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = [];
			userMenu.menu && userMenu.menu.forEach(row => {
				if(row.submenulist && row.submenulist.length) {
					let trimmedPath = currentPath.replace(getSubPath, '');
					if(row.submenulist.filter(e => e.link === trimmedPath && e.lifecycle_action).length) {
						actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].lifecycle_action;
					}
				} else if(row.lifecycle_action) {
					actionsAllowed = row.lifecycle_action
				}
			})
			setState(prevState => ({ ...prevState, actionsAllowed }))
		}
	}, [userMenu, currentPath])

	useEffect(() => {
		let monthOptions = [];
		for(let i=1;i<=31;i++) {
			let dataRow = { label: i, value: i };
			monthOptions.push(dataRow)
		}

		setState(prevState => ({ ...prevState, monthOptions, callListLifecyclePolicies: true }))
	}, [])

	useEffect(() => {
		if(state.callListLifecyclePolicies) {
			let params = {}
			params.provider = props.selectedDetails && props.selectedDetails.provider ? props.selectedDetails.provider : "aws"
			if(props.selectedDetails && props.selectedDetails.account_id) {
				params.account_id = props.selectedDetails.account_id
			}
			if(props.selectedDetails && props.selectedDetails.region) {
				params.region = props.selectedDetails.region
			}
			if(props.selectedDetails && props.selectedDetails.workstation_id) {
				params.workstation_id = props.selectedDetails.workstation_id
			}
			if(props.selectedDetails && props.selectedDetails.service_name) {
				params.service_name = props.selectedDetails.service_name
			}

			dispatch(listLifecyclePolicies(params))
				.then((response) => {
					if(response) {
						let results = response && response.results ? response.results : {}
		
						let rules = response && response.rules && response.rules.length ? response.rules[0] : {}
			
						setState(prevState => ({
							...prevState,
							listPolicies: results.length ? results[0] : {},
							disabled_policy: results.length && results[0].disable ? true : false,
							editPolicyAction: results.length && Object.entries(results[0]).length ? true : false,
							listPolicyRules: rules,
							showLoading: true,
							callListLifecyclePolicies: false,
							showDailySection: true,
							callStructurePolicy: true
						}))
					}
				})
		}
	}, [state.callListLifecyclePolicies, dispatch, props.selectedDetails])

	const validateTimeRange = useCallback((time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"
			} else {
				data += firstMinute
			}
		}

		setState(prevState => ({ ...prevState, [state]: data }))
	}, [])

	useEffect(() => {
		if(state.callStructurePolicy) {
			let data = state.listPolicies
	
			let dailyArray = []

			if(data && Object.entries(data).length) {
				let dailyPolicies = data.policies && data.policies.filter(e => e.schedule === "daily").length ? data.policies.filter(e => e.schedule === "daily") : []
				if(dailyPolicies && dailyPolicies.length) {
					dailyPolicies.forEach((item, index) => {
						dailyArray.push(index)
						
						let selectedStopOptions = ""
						let selectedStartOptions = ""
						if(item.action && Object.entries(item.action).length) {
							Object.entries(item.action).forEach(([key, value]) => {
								selectedStartOptions = key
								selectedStopOptions = value
							})
						}
						
						setState(prevState => ({
							...prevState, 
							["start_time_"+index]: momentTimeGivenFormat(item.start_time, "HH:mm"),
							["end_time_"+index]: momentTimeGivenFormat(item.end_time, "HH:mm"),
							["selectedDays_"+index]: item.days_of_the_week ? item.days_of_the_week : [],
							["selectedStopOptions_"+index]: selectedStopOptions,
							["selectedStartOptions_"+index]: selectedStartOptions,
							["selectedStartStopOption_"+index]: selectedStartOptions+"/"+selectedStopOptions,
							["first_notification_"+index]: momentTimeGivenFormat(item.first_notification, "HH:mm"),
							["second_notification_"+index]: momentTimeGivenFormat(item.second_notification, "HH:mm"),
							showPolicies: true
						}))
						if(item.start_time) {
							validateTimeRange(momentTimeGivenFormat(item.start_time, "HH:mm"), "start_time_"+item)
						}
						if(item.end_time) {
							validateTimeRange(momentTimeGivenFormat(item.end_time, "HH:mm"), "end_time_"+item)
						}
						if(item.first_notification) {
							validateTimeRange(momentTimeGivenFormat(item.first_notification, "HH:mm"), "first_notification_"+item)
						}
						if(item.second_notification) {
							validateTimeRange(momentTimeGivenFormat(item.second_notification, "HH:mm"), "second_notification_"+item)
						}
					})
				} else {
					dailyArray = [0]
				}
	
				let monthlyPolicy = data.policies && data.policies.filter(e => e.schedule === "monthly").length ? data.policies.filter(e => e.schedule === "monthly") : []
	
				if(monthlyPolicy && monthlyPolicy.length) {
					monthlyPolicy.forEach(item => {
						let monhlySelectedStopOptions = ""
						let monthlySelectedStartOptions = ""
						if(item.actions && Object.entries(item.actions).length) {
							Object.entries(item.actions).forEach(([key, value]) => {
								monthlySelectedStartOptions = key
								monhlySelectedStopOptions = value
							})
						}
	
						setState(prevState => ({
							...prevState, 
							start_time: momentTimeGivenFormat(item.start_time, "HH:mm"),
							end_time: momentTimeGivenFormat(item.end_time, "HH:mm"),
							selectedStartDay: item.start_day,
							selectedEndDay: item.end_day,
							selectedStartOptions: monthlySelectedStartOptions,
							selectedStopOptions: monhlySelectedStopOptions,
							showPolicies: true
						}))
					})
				}
	 
				// if(data.notifications) {
				// 	state.notificationArray.forEach(row => {
				// 		this.setState({ ["shared_details_"+row]:  data.notifications[row] ? data.notifications[row] : [] })
				// 	})
				// }
	
				setState(prevState => ({
					...prevState, 
					notification_enabled: data.notification_enabled,
					retain_configuration: data.retain_configuration,
					retain_dependencies: data.retain_dependencies,
					expiration_time: data.expiration_time ? momentDateGivenFormat(data.expiration_time, "YYYY-MM-DD HH:mm") : "",
					CPU: data.metrics && data.metrics.threshold && data.metrics.threshold.CPU ? data.metrics.threshold.CPU : '',
					Memory: data.metrics && data.metrics.threshold && data.metrics.threshold.Memory ? data.metrics.threshold.Memory : '',
					// Disk: data.metrics && data.metrics.threshold && data.metrics.threshold.Disk ? data.metrics.threshold.Disk : '',
					GPU: data.metrics && data.metrics.threshold && data.metrics.threshold.GPU ? data.metrics.threshold.GPU : '',
					// duration_in_hrs: data.metrics && data.metrics.duration_in_hrs ? data.metrics.duration_in_hrs : '',
					extended_duration_in_hrs: data.extended_duration_in_hrs ? data.extended_duration_in_hrs : '',

				}))
			}
	
			let ruleOptions = []
			let dailyRuleOptions = []
			let rules = state.listPolicyRules
			// if(rules && rules.actions && Object.entries(rules.actions).length) {
			// let actions = [{
			// 	"launch": ["shutdown", "snapshot-shutdown"],
			// 	"snapshot-restore": ["shutdown 1", "snapshot-shutdown 1"]
			// }]
			if(rules && rules.actions && rules.actions.length) {
				rules.actions.forEach(row => {
					Object.entries(row).forEach(([key, value]) => {
						let dataRow = {}
						dataRow.label = key
						dataRow.value = key
						dataRow.endValue = value
						dataRow.startValue = key
						ruleOptions.push(dataRow)
					})
				})
	
				rules.actions.forEach(row => {
					Object.entries(row).forEach(([key, value]) => {
						if(Array.isArray(value)) {
							value.forEach(valRow => {
								let dataRow = {}
								dataRow.label = key+"/"+valRow
								dataRow.value = key+"/"+valRow
								dataRow.endValue = valRow
								dataRow.startValue = key
								dailyRuleOptions.push(dataRow)
							})
						} else {
							let dataRow = {}
							dataRow.label = key+"/"+value
							dataRow.value = key+"/"+value
							dataRow.endValue = value
							dataRow.startValue = key
							dailyRuleOptions.push(dataRow)						
						}
					})
				})
	
				if(ruleOptions && ruleOptions.length === 1) {				
					setState(prevState => ({ ...prevState, selectedStartStopOption: ruleOptions[0].startValue, selectedStopOptions: ruleOptions[0].endValue })) //selectedStartOptions: ruleOptions[0].startValue
				}
			}
	
			if(!dailyArray.length) {
				dailyArray = [0]
			}
	
			setState(prevState => ({
				...prevState,
				dailyArray, 
				ruleOptions, 
				dailyRuleOptions, 
				showLoading: false, 
				callStructurePolicy: false 
			}))
		}
	}, [state.callStructurePolicy, state.listPolicies, state.listPolicyRules, validateTimeRange])

	
	const handleExpiationChange = (date) => {
		let expiration_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		setState(prevState => ({ ...prevState,expiration_time}))
	}

	const addSection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }	

    const removeSection = (item) => {
        setState(prevState => ({ ...prevState, ["start_time_"+item]: "", ["end_time_"+item]: "", ["selectedDays_"+item]: "", ["selectedStartOptions_"+item]: "", ["selectedStopOptions_"+item]: "" }))

		let rowList = state.dailyArray;
		rowList.splice(item, 1);
		setState(prevState => ({ ...prevState, dailyArray: rowList }))
    }

	const removeDays = (item, day) => {
		let filteredResult = state["selectedDays_"+item].filter(e => e !== day)
        setState(prevState => ({ ...prevState,["selectedDays_"+item]: filteredResult }))
    }

	const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTimeRange(str, state)
		} else {
			validateTimeRange(time, state)
		}
	}

	const createUpdatePolicy = () => {
		let notifications = {}
		state.notificationArray.forEach(row => {
			notifications[row] = state["shared_details_"+row] && state["shared_details_"+row].length ? state["shared_details_"+row] : []
		})

		let hasError = false

		let policies = []
		state.dailyArray && state.dailyArray.forEach(item => {
			if(state["start_time_"+item] && state["end_time_"+item] && state["selectedDays_"+item] && state["selectedStartOptions_"+item] && state["selectedStopOptions_"+item]) {
				if(state["start_time_"+item] > state["end_time_"+item]) {
					hasError = true
				}

				let notifciationErrorMessage = []
				if(state["start_time_"+item] && state["end_time_"+item]) {
					if(state["first_notification_"+item] && (state["first_notification_"+item] < state["start_time_"+item] || state["first_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('first notification time should be between the start and end time of the policy')
						hasError = true
					}

					if(state["second_notification_"+item] && (state["second_notification_"+item] < state["start_time_"+item] || state["second_notification_"+item] > state["end_time_"+item])) {
						notifciationErrorMessage.push('second notification time should be between the start and end time of the policy')
						hasError = true
					}
				}

				if(state["first_notification_"+item] && state["second_notification_"+item]) {
					if(state["first_notification_"+item] > state["second_notification_"+item]) {
						notifciationErrorMessage.push('first notification time should not be greater than second notification time')
						hasError = true
					}
				}

				setState(prevState => ({ ...prevState, ['notifciationErrorMessage_'+item]: notifciationErrorMessage }))

				let dataRow = {}
				dataRow.schedule = "daily"
				dataRow.start_time = state["start_time_"+item] ? momentTimeGivenFormat(state["start_time_"+item], "HH:mm:00") : ""
				dataRow.end_time = state["end_time_"+item] ? momentTimeGivenFormat(state["end_time_"+item], "HH:mm:00") : ""
				dataRow.days_of_the_week = state["selectedDays_"+item]
				dataRow.first_notification = state["first_notification_"+item] ? momentTimeGivenFormat(state["first_notification_"+item], "HH:mm:00") : ""
				dataRow.second_notification = state["second_notification_"+item] ? momentTimeGivenFormat(state["second_notification_"+item], "HH:mm:00") : ""
				let action = {}
				action[state["selectedStartOptions_"+item]] = state["selectedStopOptions_"+item]
				dataRow.action = action

				policies.push(dataRow)
			} else {
				hasError = true
			}
		})
		
		if(state.start_time && state.end_time && state.selectedStartDay && state.selectedEndDay && state.selectedStartOptions && state.selectedStopOptions) {
			let dataRow = {}
			dataRow.schedule = "monthly"
			dataRow.start_time = state.start_time ? momentTimeGivenFormat(state.start_time, "HH:mm:00") : ""
			dataRow.end_time = state.end_time ? momentTimeGivenFormat(state.end_time, "HH:mm:00") : ""
			dataRow.start_day = state.selectedStartDay
			dataRow.end_day = state.selectedEndDay
			let action = {}
			action[state.selectedStartOptions] = state.selectedStopOptions
			dataRow.action = action

			policies.push(dataRow)
		}
		
		let monthlyErroMessage = ''
		if(state.start_time > state.end_time) {
			hasError = true
			monthlyErroMessage = 'start time should be less that end time'
		}

		let params = {}
		params.policies = policies
		params.retain_configuration = state.retain_configuration
		params.retain_dependencies = state.retain_dependencies
		params.notification_enabled = state.notification_enabled
		
		if(state.extended_duration_in_hrs) {
			params.extended_duration_in_hrs = parseInt(state.extended_duration_in_hrs)
		}
		let metrics = {}
		// if(state.duration_in_hrs) {
		// 	metrics.duration_in_hrs = parseInt(state.duration_in_hrs)
		// }
		let threshold = {}
		if(state.CPU) {
			threshold.CPU = parseInt(state.CPU)
			if(state.CPU > 100) {
				hasError = true
			}
		}
		if(state.Memory) {
			threshold.Memory = parseInt(state.Memory)
			if(state.Memory > 100) {
				hasError = true
			}
		}
		// if(state.Disk) {
		// 	threshold.Disk = parseInt(state.Disk)
		// 	if(state.Disk > 100) {
		// 		hasError = true
		// 	}
		// }
		if(props.selectedDetails && props.selectedDetails.gpu && state.GPU) {
			threshold.GPU = parseInt(state.GPU)
			if(state.GPU > 100) {
				hasError = true
			}
		}
		metrics.threshold = threshold

		params.metrics = metrics
		// params.notifications = notifications
		if(state.expiration_time) {
			params.expiration_time = momentDateGivenFormat(state.expiration_time, "YYYY-MM-DD HH:mm:00")
		}
		params.asset_id = props.selectedDetails && props.selectedDetails.asset_id ? props.selectedDetails.asset_id : ""
		if(state.listPolicies.policy_id) {
			params.policy_id = state.listPolicies.policy_id
		}		
		setState(prevState => ({ ...prevState, hasError, monthlyErroMessage, submitLoading: hasError ? false : true }))


		if(!hasError && policies && policies.length) {
			dispatch(putLifecyclePolicy(params))
				.then((response) => {
					let messageType = ""
					if(response.status) {
						messageType = 'success'
						setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
					} else {
						messageType = 'danger'
					}
					setState(prevState => ({ ...prevState, submitLoading: false }))
		
					let message = response && response.message ? response.message : 'Error in inserting the schedule policy '
		
					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				})
		}
	}
	

	const callDisableFuction = (disabled_policy) => {

		let params = {
			disable: disabled_policy ? true : false,
			policy_id: state.listPolicies.policy_id,
			workstation_id: props.selectedDetails && props.selectedDetails.workstation_id
		}
		
		dispatch(disableLifecyclePolicy(params))
			.then((response) => {
				let messageType = ""
				if(response.status) {
					messageType = 'success'
					// setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
				} else {
					messageType = 'danger'
				}
				setState(prevState => ({ ...prevState, submitLoading: false }))
	
				let message = response && response.message ? response.message : 'Error in disabling policy '
	
				CommonNotification.addNotification({
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					}
				});
			})
	}


	const deletePolicy = () => {
        let params = {}
        params.policy_id = state.listPolicies.policy_id
        dispatch(deleteLifecyclePolicies(params))
            .then((response) => { 
                let messageType = "danger"
                if(response.status) {
                    messageType = 'success'
					state.dailyArray.forEach(item => {
						setState(prevState => ({
							...prevState,
							["start_time_"+item]: "",
							["end_time_"+item]: "",
							["selectedDays_"+item]: [],
							["selectedStopOptions_"+item]: "",
							["selectedStartOptions_"+item]: "",
							["selectedStartStopOption_"+item]: ""
						}))
					})
					
					setState(prevState => ({
						...prevState, 
						dailyArray: [0],
						callListLifecyclePolicies: true
					}))
					setTimeout(() => {
						setState(prevState => ({ ...prevState, deleteLoading: false }))					
					}, 2000); 
                }	else {
					setState(prevState => ({ ...prevState, deleteLoading: false }))
				}			

                let message = response && response.message ? response.message : 'Error in deleting lifecycle policy '
                
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })

	}

    useEffect(() => {
        if(state.clearTime) {
            setState(prevState => ({ ...prevState, clearTime: false, hideDateTime: false }))
        }
    }, [state.clearTime])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
		<div className={`${!state.showLoading ? '' : ''} p-2`} onClick={(event) => handleChildClick(event, 'parent')}>
			{/* {state.showMetricsDetails ?
				<ViewMetricDetails 
					selectedDetails= {props.selectedDetails}
					closeSidePanel= {() => setState(prevState => ({ ...prevState, showMetricsDetails: false }))}
				/>
			: null} */}
			{state.showLoading ?
				<div className="w-full flex justify-center mt-4">
					<svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
						<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
						<path d="M4 12a8 8 0 0112-6.9" />
					</svg>
				</div>
			: !state.showPolicies ?
				!state.ruleOptions || !state.ruleOptions.length ?
					<div className='flex justify-center m-4'>
						<p className='text-black'>There are no Policies for this asset</p>
					</div>
				:
					<div className='flex justify-end'>
						{state.actionsAllowed && state.actionsAllowed.includes('create') ?
							<button className="border border-HalloweenOrange rounded-md bg-HalloweenOrange px-2 py-1 text-white" onClick={() => setState(prevState => ({ ...prevState, showPolicies: !state.showPolicies }))}>+ Add Policy</button>
						: null}
					</div>
			:
				<div className="">		
					<div className="flex justify-between">				
						<p className="flex flex-wrap px-2 self-center text-black mb-2">
							<span className='self-center mr-2 text-sm'>Showing the schedule details for </span>
							{state.listPolicies && state.listPolicies.policy_type && state.listPolicies.policy_type === 'Asset' ?
								<span className="text-info ml-1">{props.selectedDetails && props.selectedDetails.asset_name ? props.selectedDetails.asset_name : ""}</span>
							: state.listPolicies && state.listPolicies.policy_type && state.listPolicies.policy_type === 'Tags' ?
								<span className='flex flex-wrap self-center gap-2'>
									{state.listPolicies && state.listPolicies.tags && state.listPolicies.tags.length ? 
										state.listPolicies.tags.map((tag, index) => {
											return(
												<span key={"tag_"+index} className="border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all">{tag.key +" : "+tag.value}
												</span>
											)
										})
									: null}
								</span>
							:
								<span className="text-info ml-1">{props.selectedDetails && props.selectedDetails.resource_type ? props.selectedDetails.resource_type : ""}</span>
							}
							
							</p>

						{/* <p className="text-info mb-0 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showMetricsDetails: true }))}>Metric Details</p> */}
					</div>

					<div className="rounded p-3 bg-GhostWhite">
						<div className="flex justify-between text-black cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showDailySection: !state.showDailySection }))}>
							<div className="flex">
								<p className="mb-0 text-lg font-semibold text-warning">Daily/Weekly</p>
							</div>
							<span className={`ml-4 fa fa-angle-${state.showDailySection ? "up" : "down"} f22 text-lightGray`}></span>
						</div>
						{state.showDailySection ?
							<React.Fragment>
							{state.dailyArray.map((item, iIndex) => {
								return(
									<React.Fragment key={"dail_"+item}>
									{state.hasError ?
										!state["start_time_"+item] ?
											<p className='mb-1 text-danger'>Please enter start time</p>
										: !state["end_time_"+item] ?
											<p className='mb-1 text-danger'>Please enter end time</p>
										: state["start_time_"+item] && state["start_time_"+item] > state["end_time_"+item] ?
											<p className='mb-1 text-danger'>Start time should not exceed end_time</p>
										: (!state["selectedDays_"+item] || !state["selectedDays_"+item].length) ?
											<p className='mb-1 text-danger'>Please select hrs on</p>
										: !state["selectedStartStopOption_"+item] ?
											<p className='mb-1 text-danger'>Please select lifecycle method</p>
										: null
									: null}
									<div className="flex justify-between">
										<div className="flex flex-wrap mb-4">
											<p className={`mb-0 mr-1 mt-2 self-center text-black`}>Resource should be available between business hours</p>
											{/* ${state.hasError && !state["start_time_"+item] ? 'dottedDangerTextboxLine' : ''} */}
											<input 
												type="text" 
												className="text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent" 
												style={{minHeight: "38px"}}
												placeholder={"HH:MM"}
												value={state["start_time_"+item]}
												maxLength="5"
												onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "start_time_"+item)}
												onChange={e => validateTimeRange(e.target.value, "start_time_"+item)}
											/>
											
											<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>to</p>
											
											<input 
												type="text" 
												className="text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent"
												style={{minHeight: "38px"}}
												placeholder={"HH:MM"}
												value={state["end_time_"+item]}
												maxLength="5"
												onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "end_time_"+item)}
												onChange={e => validateTimeRange(e.target.value, "end_time_"+item)}
											/>
											<p className={`mb-0 ml-2 mr-1 mt-3 self-center text-lightGray f12`}> (utc time 24-hour format) </p>

											<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>hrs on</p>
											<div className='mt-2 lg:w-[10%] w-32' onClick={(event) => {
												event.preventDefault();
												handleChildClick(event, 'child', 'muliselectDropDown', "show_Days_"+item)
											}}>
												<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state["selectedDays_"+item] && state["selectedDays_"+item].length ? 'text-black' : 'text-lightGray'}`}>
													{state["selectedDays_"+item] && state["selectedDays_"+item].length ? state["selectedDays_"+item].length +' Selected' : 'Select'}
													<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
												</p>
												{state["show_Days_"+item] ?
													<div className='position-relative'>
														<MultiSelectSection 
															fields={["value", "label"]}
															removeTop={true}
															removeTopOptions={true}
															widthClass={'minWidth220'}
															options={state.dayOptions}
															selectedValues={state["selectedDays_"+item] ? state["selectedDays_"+item] : []}
															callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["selectedDays_"+item]: value }))}
														/>
													</div>
												: null}
											</div>
											{state["selectedDays_"+item] && state["selectedDays_"+item].length ? 
												state["selectedDays_"+item].map((day, index) => {
													return(
														<span key={"day_"+index} className="flex border border-lightGray px-2 py-1 rounded-full ml-2 mt-2 self-center text-info">{day}
															<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeDays(item, day) } />
														</span>
													)
												})
											: null}
											<p className={`mb-0 ml-1 mr-1 mt-2 self-center text-black`}>.  Apply</p>
											<div className='self-end mt-2 lg:w-[10%] w-32' onClick={(event) => {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "show_lifecycle_"+item)
											}}>
												<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state["selectedStartStopOption_"+item] ? 'text-black' : 'text-lightGray'}`}>
													{state["selectedStartStopOption_"+item] ? state["selectedStartStopOption_"+item] : 'Select'}
													<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
												</p>
												{state["show_lifecycle_"+item] ?
													<div className='position-relative'>
														<MultiSelectSection 
															fields={["value", "label"]}
															// className={'position-unset'}
															removeTop={true}
															removeTopOptions={true}
															widthClass={'minWidth220'}
															options={state.dailyRuleOptions}
															selectedValues={state["selectedStartStopOption_"+item] ? state["selectedStartStopOption_"+item] : ''}
															callbackMultiSelect={(value) => {
																setState(prevState => ({ 
																	...prevState, 
																	["selectedStartStopOption_"+item]: value, 
																	["selectedStopOptions_"+item]: state.dailyRuleOptions.filter(e => e.value === value)[0].endValue, 
																	["selectedStartOptions_"+item]: state.dailyRuleOptions.filter(e => e.value === value)[0].startValue
																}))
															}}
															singleSelection={true}
															hideSearch={true}
															// onChildClick={() => }
														/>
													</div>
												: null}
											</div>
											<p className={`mb-0 ml-1 mr-1 mt-2 self-center text-black`}>lifecycle method</p>
										</div>	
										{/* {state.dailyArray.length > 1 ?
											<span className={`far fa-trash cursor-pointer f18 self-center ml-4 w-5`} onClick={() => removeSection(item)}></span>
										: null} */}
									</div>
									
									<div className="flex mt-2">
										<div className="flex flex-wrap mb-2">
											<p className={`mb-0 mr-1 mt-2 self-center text-black`}>Send first notification at</p>
											{/* ${state.hasError && !state["start_time_"+item] ? 'dottedDangerTextboxLine' : ''} */}
											{!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
												<input 
													type="text" 
													// disabled={!state['end_time_'+item] ? true : false}
													className={`w-1/4 border-b border-lightGray bg-transparent px-2 py-1 text-black`}
													style={{minHeight: "38px"}}
													placeholder={"HH:MM"}
													value={state["first_notification_"+item]}
													maxLength="5"
													onKeyDown={e => {
														if(state['end_time_'+item]) {
															onKeyDownTimeRange(e, e.target.value, "first_notification_"+item)
															setState(prevState => ({ ...prevState, ['firstNotificationError_'+item]: '' }))
														} else {
															setState(prevState => ({ ...prevState, ['firstNotificationError_'+item]: 'please enter end time to set the notification', ["first_notification_"+item]: '' }))
														}
													}}
													onChange={e => {
														if(state['end_time_'+item]) {
															validateTimeRange(e.target.value, "first_notification_"+item)
														}
													}}
												/>
											:
												state["first_notification_"+item] ?
													<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state["first_notification_"+item]}</p>
												:
													<p className={`mb-0 ml-2 mr-1 mt-2 self-center border-lightGray-bottom w-5 mt-4`}></p>
											}
										</div>
									</div>
									<div className="flex mt-2">
										<div className="flex flex-wrap mb-2">
											<p className={`mb-0 mr-1 mt-2 self-center text-black`}>Send second notification at</p>
											{!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
												<input 
													type="text" 
													// disabled={!state['end_time_'+item] ? true : false}
													className={`w-1/4 border-b border-lightGray bg-transparent px-2 py-1 text-black`}
													style={{minHeight: "38px"}}
													placeholder={"HH:MM"}
													value={state["second_notification_"+item]}
													maxLength="5"
													onKeyDown={e => {
														if(state['end_time_'+item]) {
															onKeyDownTimeRange(e, e.target.value, "second_notification_"+item)
															setState(prevState => ({ ...prevState, ['secondNotificationError_'+item]: '' }))
														} else {
															setState(prevState => ({ ...prevState, ['secondNotificationError_'+item]: 'please enter end time to set the notification', ["second_notification_"+item]: '' }))
														}
													}}
													onChange={e => {
														if(state['end_time_'+item]) {
															validateTimeRange(e.target.value, "second_notification_"+item)
														}
													}}
												/>
											:
												state["second_notification_"+item] ?
													<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state["second_notification_"+item]}</p>
												:
													<p className={`mb-0 ml-2 mr-1 mt-2 self-center border-lightGray-bottom w-5 mt-4`}></p>
											}
										</div>
									</div>
									{state.hasError && state["notifciationErrorMessage_"+item] && state["notifciationErrorMessage_"+item].length ? 
										<div className='mt-2'>
											{state["notifciationErrorMessage_"+item].map(not => {
												return(
													<p className='mb-2 text-danger'>{not}</p>
												)
											})}
										</div>
									: null}

									{state['secondNotificationError_'+item] || state['firstNotificationError_'+item] ?
										<p className='flex text-danger mb-2'>{state['secondNotificationError_'+item] ? state['secondNotificationError_'+item] : (state['firstNotificationError_'+item] ? state['firstNotificationError_'+item] : '')}</p>

									: null}
									{((state.editPolicyAction && state.actionsAllowed.includes('update')) || (!state.editPolicyAction && state.actionsAllowed.includes('create'))) ?
										<div className='flex justify-end mb-2'>
											{state.dailyArray.length === iIndex+1 ?
												<span className="text-info mt-4 cursor-pointer" onClick={() => addSection("dailyArray")}>Add New Policy</span>
											: null}
											{state.dailyArray.length > 1 ?
												<span className="text-danger mt-4 cursor-pointer ml-3" onClick={() => removeSection(item)}> Remove Policy</span>
											: null}
										</div>
									: null}
									</React.Fragment>
								)
							})}
							{/* <span className="text-info mt-2 mb-5 cursor-pointer" onClick={() => addSection("dailyArray")}>+ Add New Policy</span> */}
							</React.Fragment>
						: null}
					</div>
					
					<div className="rounded p-3 bg-dark3 mt-3 hidden">
						<div className="flex justify-between text-black">
							<div className="flex">
								<p className="mb-0 f20 cursor-pointer text-warning text-lg font-semibold" onClick={() => setState(prevState => ({ ...prevState, showMonthlySection: !state.showMonthlySection }))}>Monthly</p>
							</div>
							<span className={`ml-4 fa fa-angle-${state.showMonthlySection ? "up" : "down"} f22 text-lightGray cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, showMonthlySection: !state.showMonthlySection }))}></span>
						</div>
						{state.showMonthlySection ?
							<div className="flex flex-wrap mb-2">
								<p className={`mb-0 mr-1 mt-2 self-center text-black`}>Resource should be</p>
								<div className='self-end mt-2 lg:w-[10%] w-32' onClick={(event) => {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "show_action")
								}}>
									<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state.selectedStartOptions ? 'text-black' : 'text-lightGray'}`}>
										{state.selectedStartOptions ? state.selectedStartOptions : 'Select'}
										<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
									</p>
									{state.show_action ?
										<div className='position-relative'>
											<MultiSelectSection 
												fields={["value", "label"]}
												// className={'position-unset'}
												removeTop={true}
												removeTopOptions={true}
												widthClass={'minWidth220'}
												options={state.ruleOptions}
												selectedValues={state.selectedStartOptions ? state.selectedStartOptions : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ 
														...prevState, 
														selectedStartOptions: value, 
														selectedStopOptions: Array.isArray(state.ruleOptions.filter(e => e.value === value)[0].endValue) ? "" : state.ruleOptions.filter(e => e.value === value)[0].endValue, 
														stopRuleOptions: Array.isArray(state.ruleOptions.filter(e => e.value === value)[0].endValue) ? state.ruleOptions.filter(e => e.value === value)[0].endValue : []
													}))
												}}
												singleSelection={true}
											/>
										</div>
									: null}
								</div>
								{/* <Select
									placeholder={'Select'}
									isSearchable={false}
									components={{
										IndicatorSeparator: () => null
									}}
									className={"selectOption"}
									value={({
										value: state.selectedStartOptions,
										label: state.selectedStartOptions && state.selectedStartOptions !== "" && state.ruleOptions.filter(e => e.startValue === state.selectedStartOptions).length ? state.ruleOptions.filter(e => e.startValue === state.selectedStartOptions)[0].startValue : <span className="placeholder">Select</span>
									})}		
									options={state.ruleOptions && state.ruleOptions.map(row => ({
										value: row.startValue,
										label: row.startValue,
										startValue: row.startValue,
										endValue: row.endValue
									}))}															
									onChange={event => setState(prevState => ({ 
										...prevState, 
										selectedStartOptions: event.startValue, 
										selectedStopOptions: Array.isArray(event.endValue) ? "" : event.endValue, 
										stopRuleOptions: Array.isArray(event.endValue) ? event.endValue : []
									}))}
								/> */}
								<p className={`mb-0 mx-2 mt-2 self-center text-black`}>at</p>

								<input 
									type="text" 
									className="text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent"
									style={{minHeight: "38px"}}
									placeholder={"HH:MM"}
									value={state.start_time}
									maxLength="5"
									onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "start_time")}
									onChange={e => validateTimeRange(e.target.value, "start_time")}
								/>											
								<p className={`mb-0 ml-2 mr-1 mt-3 self-center text-lightGray f12`}> (utc time 24-hour format) </p>
								<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>hrs on day</p>
								<div className='self-end mt-2 lg:w-[10%] w-32' onClick={(event) => {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "show_month_options")
								}}>
									<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state.selectedStartDay ? 'text-black' : 'text-lightGray'}`}>
										{state.selectedStartDay ? state.selectedStartDay : 'Select'}
										<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
									</p>
									{state.show_month_options ?
										<div className='position-relative'>
											<MultiSelectSection 
												fields={["value", "label"]}
												// className={'position-unset'}
												removeTop={true}
												removeTopOptions={true}
												widthClass={'minWidth220'}
												options={state.monthOptions}
												selectedValues={state.selectedStartDay ? state.selectedStartDay : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, selectedStartDay: value }))
												}}
												singleSelection={true}
											/>
										</div>
									: null}
								</div>
								{/* <Select
									placeholder={'Select'}
									isSearchable={true}
									components={{
										IndicatorSeparator: () => null
									}}
									className={"selectOption"}
									value={({
										value: state.selectedStartDay,
										label: state.selectedStartDay && state.selectedStartDay !== "" && state.shutdownOptions ? state.monthOptions.filter(e => e.value === state.selectedStartDay)[0].label : <span className="placeholder">Select</span>
									})}		
									options={state.monthOptions && state.monthOptions.map(row => ({
										value: row.value,
										label: row.label
									}))}																
									onChange={event => setState(prevState => ({ ...prevState, selectedStartDay: event.value }))}
								/> */}
								
								<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>and </p>

								{state.stopRuleOptions && state.stopRuleOptions.length ?
									<div className='self-end mt-2 lg:w-[10%] w-32' onClick={(event) => {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "show_stop_rule_options")
									}}>
										<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state.selectedStopOptions ? 'text-black' : 'text-lightGray'}`}>
											{state.selectedStopOptions ? state.selectedStopOptions : 'Select'}
											<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
										</p>
										{state.show_stop_rule_options ?
											<div className='position-relative'>
												<MultiSelectSection 
													// fields={["value", "label"]}
													// className={'position-unset'}
													removeTop={true}
													removeTopOptions={true}
													widthClass={'minWidth220'}
													options={state.stopRuleOptions}
													selectedValues={state.selectedStopOptions ? state.selectedStopOptions : ''}
													callbackMultiSelect={(value) => {
														setState(prevState => ({ ...prevState, selectedStopOptions: value }))
													}}
													singleSelection={true}
												/>
											</div>
										: null}
									</div>
									// <Select
									// 	placeholder={'Select'}
									// 	isSearchable={false}
									// 	components={{
									// 		IndicatorSeparator: () => null
									// 	}}
									// 	className={"selectOption"}
									// 	value={({
									// 		value: state.selectedStopOptions,
									// 		label: state.selectedStopOptions && state.selectedStopOptions !== "" ? state.selectedStopOptions : <span className="placeholder">Select</span>
									// 	})}		
									// 	options={state.stopRuleOptions && state.stopRuleOptions.map(row => ({
									// 		value: row,
									// 		label: row
									// 	}))}															
									// 	onChange={event => setState(prevState => ({ 
									// 		...prevState, 
									// 		selectedStopOptions: event.value
									// 	}))}
									// />
								:
									<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state.selectedStopOptions}</p>
								}
								<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>at</p>

								<input 
									type="text" 
									className="text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent"
									style={{minHeight: "38px"}}
									placeholder={"HH:MM"}
									value={state.end_time}
									maxLength="5"
									onKeyDown={e => onKeyDownTimeRange(e, e.target.value, "end_time")}
									onChange={e => validateTimeRange(e.target.value, "end_time")}
								/>
								<p className={`mb-0 ml-2 mr-1 mt-3 self-center text-lightGray f12`}> (utc time 24-hour format) </p>
								<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-black`}>hrs on day</p>

								<div className='self-end mt-2 lg:w-[10%] w-32' onClick={(event) => {
									event.preventDefault();
									handleChildClick(event, 'child', 'singleDropDown', "show_end_month_options")
								}}>
									<p className={`flex justify-between p-2 mb-0 border-b border-lightGray ${state.selectedEndDay ? 'text-black' : 'text-lightGray'}`}>
										{state.selectedEndDay ? state.selectedEndDay : 'Select'}
										<Icon icon="fa-solid:sort-down" width="16" height="16" className="mt-1" />
									</p>
									{state.show_end_month_options ?
										<div className='position-relative'>
											<MultiSelectSection 
												fields={["value", "label"]}
												// className={'position-unset'}
												removeTop={true}
												removeTopOptions={true}
												widthClass={'minWidth220'}
												options={state.monthOptions}
												selectedValues={state.selectedEndDay ? state.selectedEndDay : ''}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, selectedEndDay: value }))
												}}
												singleSelection={true}
											/>
										</div>
									: null}
								</div>
								{/* <Select
									placeholder={'Select'}
									isSearchable={true}
									components={{
										IndicatorSeparator: () => null
									}}
									className={"selectOption"}
									value={({
										value: state.selectedEndDay,
										label: state.selectedEndDay && state.selectedEndDay !== "" && state.shutdownOptions ? state.monthOptions.filter(e => e.value === state.selectedEndDay)[0].label : <span className="placeholder">Select</span>
									})}		
									options={state.monthOptions && state.monthOptions.map(row => ({
										value: row.value,
										label: row.label
									}))}																
									onChange={event => setState(prevState => ({ ...prevState, selectedEndDay: event.value }))}
								/> */}
							</div>
						: null}
					</div>

					
					<div className="flex flex-wrap px-2 py-3">
						<p className={`mb-0 mr-2 mt-2 self-center text-black`}>Expire the Resources on</p>
						{!state.hideDateTime ?
							<Datetime 
								value={state.expiration_time && state.expiration_time !== "" ? momentDateGivenFormat(state.expiration_time, 'DD MMM YYYY HH:mm') : ""}
								onChange={handleExpiationChange}
								dateFormat={'DD MMM YYYY'}
								timeFormat={'HH:mm'}
								inputProps={{ readOnly: true, class: "inputFieldNew" }}
								className="mt-3 text-black"
								// isValidDate={disableFutureDt}
							/>
						: null}
						<p className="mb-0 mt-2 ml-2 text-info cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, expiration_time: "", hideDateTime: true, clearTime: true }))}>Clear</p>
					</div>

					<div className="flex flex-wrap px-2 py-3">
						<p className={`mb-0 mr-1 mt-1 self-end text-black`}>Notification Reminder in hrs</p>
						<div className="w-1/5">
							<input 
								type="text" 
								className={`border-b border-lightGray bg-transparent px-2 py-1 text-black w-full focus-visible:border-lightGray`}
								placeholder={"hrs"}
								value={state.extended_duration_in_hrs}
								onChange={e => setState(prevState => ({ ...prevState, extended_duration_in_hrs: onlyNumeric(e.target.value) }))}
							/>
						</div>
					</div>

					<p className='text-black text-lg mt-3 mb-0'>Metrics <span className="text-info ml-4 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, CPU: '', GPU: '', Memory: '' }))}>Clear</span></p>
						<div className='rouded-md p-3 bg-GhostWhite'>
							<div className="flex mt-2">
								<div className='flex w-33'>
									<p className={`mb-0 mr-1 mt-1 self-center text-black`}>CPU</p>
											
									{!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
										<input 
											type="text" 
											className={`text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent`}
											style={{minHeight: "28px"}}
											placeholder={"cpu"}
											value={state.CPU}
											onChange={e => setState(prevState => ({ ...prevState, CPU: onlyNumeric(e.target.value) }))}
										/>	
									:
										
										state.CPU ?
											<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state.CPU}</p>
										:
											<p className={`mb-0 ml-2 mr-1 mt-2 self-center border-lightGray-bottom w-10 mt-4`}></p>
									}
									<span className='text-lightGray self-center mt-1'>%</span>
								</div>
								{props.selectedDetails && props.selectedDetails.gpu ?
									<div className='flex w-33 pl-3'>
										<p className={`mb-0 mr-1 mt-1 self-center text-black`}>GPU</p>
												
										{!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
											<input 
												type="text" 
												className={`text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent`}
												style={{minHeight: "28px"}}
												placeholder={"gpu"}
												value={state.GPU}
												onChange={e => setState(prevState => ({ ...prevState, GPU: onlyNumeric(e.target.value) }))}
											/>	
										:
											
											state.GPU ?
												<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state.GPU}</p>
											:
												<p className={`mb-0 ml-2 mr-1 mt-2 self-center border-lightGray-bottom w-10 mt-4`}></p>
										}
										<span className='text-lightGray self-center mt-1'>%</span>
									</div>
								: null}
								<div className='flex w-50 pl-3'>
									<p className={`mb-0 mr-1 mt-1 self-center text-black`}>Memory</p>
											
									{!state.editPolicyAction || (state.editPolicyAction && state.actionsAllowed.includes('update')) ?
										<input 
											type="text" 
											className={`text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent`}
											style={{minHeight: "28px"}}
											placeholder={"memory"}
											value={state.Memory}
											onChange={e => setState(prevState => ({ ...prevState, Memory: onlyNumeric(e.target.value) }))}
										/>	
									:
										
										state.Memory ?
											<p className={`mb-0 ml-2 mr-1 mt-2 self-center text-info`}>{state.Memory}</p>
										:
											<p className={`mb-0 ml-2 mr-1 mt-2 self-center border-lightGray-bottom w-10 mt-4`}></p>
									}
									<span className='text-lightGray self-center mt-1'>%</span>
								</div>
							</div>
							{state.hasError && state.CPU > 100 ?
								<p className='ml-2 my-2 text-danger self-center'>CPU should be between 0 and 100</p>
							: null}
							{props.selectedDetails && props.selectedDetails.gpu && state.hasError && state.GPU > 100 ?
								<p className='ml-2 text-danger self-center'>GPU should be between 0 and 100</p>
							: null}
							{state.hasError && state.Memory > 100 ?
								<p className='ml-2 text-danger self-center'>Memory should be between 0 and 100</p>
							: null}
							<div className="flex flex-wrap mt-2 hidden">
								<div className='flex w-50'>
									<p className={`mb-0 mr-1 mt-2 self-center text-black`}>Disk</p>
									<input 
										type="text" 
										className={`text-black placeholder:italic placeholder:text-lightGray block border-b border-lightGray py-2.5 pl-2 shadow-sm focus:outline-none focus:border-b border-lightGray sm:text-sm bg-transparent`}
										style={{minHeight: "38px"}}
										placeholder={"disk"}
										value={state.Disk}
										onChange={e => setState(prevState => ({ ...prevState, Disk: onlyNumeric(e.target.value) }))}
									/>
									{state.hasError && state.Disk > 100 ?
										<span className='ml-2 mt-2 text-danger self-center'>Disk should be between 0 and 100</span>
									: null}
								</div>
							</div>
						</div>

					{state.listPolicies && state.listPolicies.policy_id ?
						<div className="flex mt-3">
							<div className={`form-check form-check-inline self-center mr-2 ${state.retain_configuration ? "" : "checkboxGrayColor"}`}>
								<input
									className='form-check-input m-0'
									type='checkbox'
									checked={state.disabled_policy}
									onChange={e => {
										let disabled_policy = !state.disabled_policy
										setState(prevState => ({ ...prevState, disabled_policy }))
										callDisableFuction(disabled_policy)
									}}	
									// title={state.disabled_policy ? "not enable" : "enable"}								
								/>
								{!state.disabled_policy ?
									<span className="checkmark" onClick={e => {
										let disabled_policy = !state.disabled_policy
										setState(prevState => ({ ...prevState, disabled_policy }))
										callDisableFuction(disabled_policy)
									}}></span>
								: null}
							</div>
							<span className="mt-2p text-lg text-black">Disable policy</span>
						</div>
					: null}
					
					<div className="flex justify-end self-center mt-2">
						{state.actionsAllowed && state.actionsAllowed.includes('delete') && state.listPolicies && Object.entries(state.listPolicies).length ?
							<button className="border border-darkRed bg-darkRed rounded-md px-2 py-1 text-white mr-3" 
							onClick={() => {
								if(!state.submitLoading && !state.deleteLoading) {
									setState(prevState => ({ ...prevState, deleteLoading: true }))
									deletePolicy()
								}
							}}>
								{state.deleteLoading ? 
									<span>
										<svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
											<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
											<path d="M4 12a8 8 0 0112-6.9" />
										</svg>
										Deleting 
									</span>
								: 
									'Delete'
								}
							</button>
						: null}
						{state.actionsAllowed && state.actionsAllowed.includes('update') ?
							<button className="border border-HalloweenOrange bg-HalloweenOrange rounded-md px-2 py-1 text-white" 
								onClick={() => {
									if(!state.submitLoading && !state.deleteLoading) {
										setState(prevState => ({ ...prevState, submitLoading: true }))
										createUpdatePolicy()
									}
								}} 
							>
								{state.submitLoading ? 
									<span className='flex'>
										<svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
											<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
											<path d="M4 12a8 8 0 0112-6.9" />
										</svg> 
										Submitting 
									</span>
								: 
									'Submit'
								}
							</button>
						: null}
					</div>
				</div>
			}
		</div>
	)
}

export default Lifecycle