import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import ImageSection from './ImageSection';
import BuildSection from './BuildSection';
import ImagebuilderFilterSection from './ImagebuilderFilterSection';
import PlanSection from './PlanSection';
import ScheduleSection from './ScheduleSection';
import ScanSection from './ScanSection';
import { getSubPath } from '../../../utils/utility';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region', 'type']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath.replace(getSubPath, '');
                    if(row.submenulist.filter(e => e.link === trimmedPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter && filter.selectedProvider,
            selectedAccount: filter && filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter && filter.selectedRegion ? filter.selectedRegion : [],
            selectedImageType: filter && filter.selectedImageType ? filter.selectedImageType : '',
            selectedImage: filter && filter.selectedImage ? filter.selectedImage : '',
            callSearch: true            
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            let prevValue = state.selectedTab ? state.selectedTab : 'Image'
            setState(prevState => ({ ...prevState, callSearch: false, selectedTab: "" }))
            setTimeout(() => {
                setState(prevState => ({ ...prevState, selectedTab: prevValue })) // Set the same value
            }, 1000); // Change to the desired delay (in milliseconds)
        }
    }, [state.callSearch, state.selectedTab])

    const handleClickOutside = (event) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
    };

    return (
        <div onClick={(event) => handleClickOutside(event, 'parent')}>
            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Image Builder</p>
                        <p className="mx-4 text-black">Consolidated view of image builder</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <ImagebuilderFilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex grow'>
                <div className='w-full p-2'>
                    <div className='bg-white rounded-md'>
                        <div className={`px-2`}>
                            <ul className="flex flex-wrap text-sm font-bold text-center">
                                <li className="me-2">
                                    <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Image' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Image' }))}>Image</span>
                                </li>
                                {state.actionsAllowed && state.actionsAllowed.includes('builds') ?
                                    <li className="me-2">
                                        <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Builds' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Builds' }))}>Builds</span>
                                    </li>
                                : null}
                                
                                {state.actionsAllowed && state.actionsAllowed.includes('scans') ?
                                    <li className="me-2">
                                        <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Scans' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Scans' }))}>Scans</span>
                                    </li>
                                : null}
                                {state.actionsAllowed && state.actionsAllowed.includes('plans') ?
                                    <li className="me-2">
                                        <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Plans' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Plans' }))}>Plans</span>
                                    </li>
                                : null}
                                <li className="me-2">
                                    <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Schedule' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Schedule' }))}>Schedule</span>
                                </li>
                            </ul>
                        </div>
                        {state.selectedTab === 'Image' && state.selectedProvider ?
                            <ImageSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                            />
                        : state.selectedTab === 'Builds' && state.selectedProvider ?
                            <BuildSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                            />
                        : state.selectedTab === 'Scans' && state.selectedProvider ?
                            <ScanSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                            />
                        : state.selectedTab === 'Plans' && state.selectedProvider ?
                            <PlanSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                            />
                        : state.selectedTab === 'Schedule' && state.selectedProvider ?
                            <ScheduleSection
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedImageType={state.selectedImageType}
                                selectedImage={state.selectedImage}
                            />
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage