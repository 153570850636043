/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsDashboard.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import MultiSelectSection from '../../common/MultiSelectSection';
import { CSVLink } from "react-csv";

import { listAlerts } from '../../../actions/Collider/ObservabilityAction'

import { getAllTagsKeys, getAllTagsValues } from '../../../actions/commonActionNew'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchWithHiddenInput'
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import AlertDetails from './AlertDetails';
import {Icon} from "@iconify/react";

const AlertsDashboard = (props) => {
    const [state, setState] = useState({

        showLoading: true,
        
        jumpDate: '',
        alertDetails: {},
        showAnnotation: true,
    
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        pageType: "Alerts",
        showRightSide: true,
    
        tagsArray: [0],
    })
    const dispatch = useDispatch(); // Create a dispatch function
    // const tagKeys = useSelector(state => state?.cost?.costTagKeys || false);
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);
    const hpcAlertPageFilters = useSelector(state => state?.hpc?.hpcPropsDetails?.hpcAlertPageFilters || false);

	useEffect(() => {
        if(hpcAlertPageFilters && Object.entries(hpcAlertPageFilters).length && !_.isEqual(hpcAlertPageFilters, state.hpcAlertPageFilters)) {
            if(hpcAlertPageFilters.start_time && hpcAlertPageFilters.end_time && hpcAlertPageFilters.selectedProvider) {
                setState(prevState => ({
                    ...prevState, 
                    hpcAlertPageFilters,
                    emptySearchText: true,
                    // start_time: hpcAlertPageFilters.start_time ? hpcAlertPageFilters.start_time : '',
                    // end_time: hpcAlertPageFilters.end_time ? hpcAlertPageFilters.end_time : '',
                    // selectedProvider: hpcAlertPageFilters.selectedProvider ? hpcAlertPageFilters.selectedProvider : '',
                    // selectedAccount: hpcAlertPageFilters.selectedAccount ? hpcAlertPageFilters.selectedAccount : '',
                    // selectedRegion: hpcAlertPageFilters.selectedRegion ? hpcAlertPageFilters.selectedRegion : '',
                    showRightSide: false,
                    alertListLoader: true,
                    callAlerts: true
                }))
            }
        }
    }, [hpcAlertPageFilters, state.hpcAlertPageFilters])

    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    // const gotoPage = (pageNumber) => {
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callAlerts) {
            setState(prevState => ({ ...prevState, callAlerts: false }))
            let params = {}
            params.provider = hpcAlertPageFilters.selectedProvider.toLowerCase();

            if(hpcAlertPageFilters.selectedProjectTags && hpcAlertPageFilters.selectedProjectTags.length) {
                params.project_tags = hpcAlertPageFilters.selectedProjectTags
            }
            
            if(hpcAlertPageFilters.selectedAccount && hpcAlertPageFilters.selectedAccount.length) {
                params.account_id = hpcAlertPageFilters.selectedAccount
            }
            if(hpcAlertPageFilters.selectedRegion && hpcAlertPageFilters.selectedRegion.length) {
                params.region = hpcAlertPageFilters.selectedRegion
            }
            params.start_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.start_time, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.end_time, 'YYYY-MM-DD HH:mm:ss')
            params.aggregate_by = state.groupedBy
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }
            params.include_name = true
    
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false, structureAlerts: true }))
                        // this.props.setAiopsPropsDetails('tabOptions', {})
                    } else {
                        setState(prevState => ({ ...prevState, alertsList: [], alertListLoader: false }))
                    }
                })
        }
    }, [state.callAlerts, dispatch, state.alertType, state.groupedBy, hpcAlertPageFilters])

    useEffect(() => {
        setState(prevState => ({ ...prevState, structureAlerts: false }))
        let listAllAlerts = []
        
        let totalCount = 0
        let alertsCategory = []
        if(state.alertResponse && state.alertResponse.length) {
            totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), getCategoryAlerts: true}))
    }, [state.structureAlerts, state.alertResponse])
    
    const getAlertDetails = useCallback((alertItemDetails) => {
        alertItemDetails.group_id = alertItemDetails.event_id ? alertItemDetails.event_id : ''
        alertItemDetails.type = 'alert'
        setState(prevState => ({ ...prevState, selectedAlertId: alertItemDetails.event_id }))

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = alertItemDetails
        obj.merticsAssetNameInvolved = alertItemDetails.asset_id ? [alertItemDetails.asset_id] : []
        obj.datePickerStartDate = hpcAlertPageFilters.start_time 
        obj.datePickerEndDate = hpcAlertPageFilters.end_time 
        obj.pageType = "Alerts"
        dispatch(setHpcPropsDetails('alertDetails', obj))
    }, [hpcAlertPageFilters.end_time , hpcAlertPageFilters.start_time , dispatch])

    useEffect(() => {
        setState(prevState => ({ ...prevState, getCategoryAlerts: false }))
        let filterdCategoryAlerts = state.listAllAlerts
        if(state.selectedCategory !== 'Important / All') {
            filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
            if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > state.perPage) {
			totalPages = Math.ceil(orderedArray.length / state.perPage)
		}

        setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlertId: '', totalPages, startRecord: 0, currentPage: 1, callFormHeaders: true }))
        if(orderedArray && orderedArray.length) {
            getAlertDetails(orderedArray[0])
        }
    }, [state.getCategoryAlerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory, getAlertDetails])    
    
    const handleChange = (event) => {
        let groupedBy = state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        setState(prevState => ({ ...prevState, alertType: event.target.value, groupedBy, selectedAlertId: '', alertListLoader: true, callAlerts: true }))
    }

    // handleClickOutside(event) {
    //     if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
    //         this.setState({ showPageTypeOptions: false })
    //     } else {
    //         this.setState({ showPageTypeOptions: true })
    //     }
    // }

    const getAllTagsKeysFunction = () => {
        if(!tagKeys || !tagKeys.length) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }

    useEffect(() => {
		if(state.tag_key && state.tag_key.length) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [state.tag_key, dispatch])

    const addNewTag = (index) => {
		let selectedTags = state.selected_tags ? state.selected_tags : []

		if(state.tag_key && state.tag_key !== '' && state.tag_value && state.tag_value.length) {
			state.tag_value.forEach(tag => {
				if(!selectedTags.filter(e => e.key === state.tag_key && e.value === tag).length) {
					let dataRow = {}
					dataRow.key = state.tag_key
                    dataRow.value = tag
					selectedTags.push(dataRow)
				}
			})
		}

        setState(prevState => ({ ...prevState, selectedTags, tag_key: '', tag_value: [], tagValues: []}))
    }

	const removeTag = (tag) => {
		let filteredResult = state.selectedTags && state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}

    const resetFilterSelection = () => {
        state.tagsArray.forEach(item => {
            setState(prevState => ({ ...prevState, tag_key: "", tag_value: [], selectedTags: [] }))
        })

        setState(prevState => ({ ...prevState, tagsArray: [0], selectedService: [], assetCategory: [] }))
    }

    // const filteredResults = () => {
    //     let results = state.totalFilteredArray ? state.totalFilteredArray : []
        
    //     if(results && results.length && state.tagsArray && state.tagsArray.length) {
    //         let tagMatchedData = []
    //         let recordExist = false
    //         state.tagsArray.forEach(item => {
    //             if(state["tag_key_"+item] && state["tag_key_"+item] !== "" && state["tag_value_"+item] && state["tag_value_"+item] !== "") {
    //                 recordExist = true
    //                 if(tagMatchedData && tagMatchedData.length) {
    //                     let newTagsMatched = []
    //                     tagMatchedData.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             newTagsMatched.push(itm)
    //                         }
    //                     })
    //                     tagMatchedData = newTagsMatched
    //                 } else {
    //                     results.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             tagMatchedData.push(itm)
    //                         }
    //                     })                        
    //                 }
    //             }
    //         })
    //         if(recordExist) {
    //             results = tagMatchedData
    //         }
    //     }

    //     if(state.searchText && state.searchText !== '') {
	// 		results =
	// 			results &&
	// 			results.filter(item => {
	// 				let isPresent = []
	// 				isPresent = recursiveSearch(item, state.searchText)
	// 					.flat()
	// 					.filter(bool => bool === true)
	// 				if (isPresent[0]) {
	// 					return true
	// 				} else {
	// 					return false
	// 				}
	// 			})
	// 	}

    //     let totalPages = 1
    //     if(results && results.length > state.perPage) {
    //         totalPages = Math.ceil(results.length / state.perPage)
    //     }
    //     let showLoadMore = false
    //     if(results.length > state.endRecord) {
    //         showLoadMore = true
    //     } 

    //     setState(prevState => ({ ...prevState, filteredArray: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore }))
    //     // formHeaders()    
    // }

	useEffect(() => {
        if(state.callFormHeaders) {
            setState(prevState => ({ ...prevState, callFormHeaders: false }))
            let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
            if(dataResult.length) {
                let headers = []
                Object.entries(dataResult[0]).forEach(([key, value]) => {
                    if(typeof value === "string" && key !== "event_data") {
                        let headerRow = {}
                        headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                        headerRow.key = key
                        headers.push(headerRow)
                    }
                })
                setState(prevState => ({ ...prevState, headers }))
            }
        }
	}, [state.callFormHeaders, state.filteredArray])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`flex mt-2 ml-1 ${state.alertListLoader ? 'disabled' : ''}`}>
                <p className="m-0 self-end small mr-2">Show </p>
                <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit px-2 pb-1 focus-visible:border-b mr-2" onChange={handleChange} value={state.alertType}>
                    <option className="text-black" key="All" value="All">ALL</option>
                    <option className="text-black" key="Application" value="Application">Application</option>
                    <option className="text-black" key="Infra" value="Infra">Infra</option>
                </select>
                <p className="m-0 self-end small mr-2"> alerts groupd by</p>
                <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit px-2 pb-1 focus-visible:border-b mr-2" onChange={(e) =>
                    setState(prevState => ({ ...prevState, groupedBy: e.target.value, selectedAlertId: '', selectedCategory: "Important / All", alertListLoader: true, callAlerts: true }))
                } value={state.groupedBy}>
                    <option  className="text-black" value='severity'>Severity</option>
                    <option  className="text-black" value='event_name'>Name</option>
                    {state.alertType !== "Application" ?
                        <React.Fragment>
                        <option  className="text-black" value='asset_name'>Assets</option>
                        <option  className="text-black" value='resource_type'>Service</option>
                        <option  className="text-black" value='category'>Category</option>
                        </React.Fragment>
                    : null}
                </select>
                <Icon icon="mdi:filter" className='ml-3 text-lightGray cursor-pointer self-center' width={16} height={16} 
                    onClick={() => {
                        getAllTagsKeysFunction()
                        setState(prevState => ({ ...prevState, showFilterSection: !state.showFilterSection }))
                    }} 
                />
            </div>
            {state.showFilterSection ?
                <div className="border border-lightGray mt-2 absolute w-1/2">
                    <div className="p-3 bg-white">
                        <p className="mb-1">Tags</p>
                        <div className={`flex flex-wrap`}>
                            <div className='flex flex-wrap lg:w-5/6'>
                                <div className='lg:w-1/2 w-full' onClick={(event) => {
                                    if(!state.show_tag_key) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'show_tag_key')
                                    }
                                }}>
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between cursor-pointer ${state.tag_key && state.tag_key.length ? 'text-black' : 'text-lightGray'}`}>
                                        {state.tag_key ? state.tag_key : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.tag_key ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.show_tag_key ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["region", "name"]}
                                                // className={'w-70'}
                                                removeTop={true}
                                                options={tagKeys}
                                                selectedValues={state.tag_key ? state.tag_key : []}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, tag_key: value, tagValues: [] }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                                {state.tag_key && !state.tagValues.length ?
                                    <div className='ml-3'>
                                        <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                    </div>
                                :
                                    <div className='lg:w-1/2 w-full lg:pl-3 pl-0 lg:mt-0 mt-2' onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', 'show_tag_value')
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between cursor-pointer ${state.tag_value && state.tag_value.length ? 'text-black' : 'text-lightGray'}`} >
                                            {state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.tag_value ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.show_tag_value ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    // fields={["region", "name"]}
                                                    // className={'position-unset'}
                                                    removeTop={true}
                                                    options={state.tagValues}
                                                    selectedValues={state.tag_value ? state.tag_value : []}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                }
                            </div>
                            <Icon icon="iconoir:plus" width="25" height="25"  className='text-black pl-3 self-center lg:w-1/6 cursor-pointer' onClick={() => addNewTag()}/>
                        </div>
                        {state.selectedTags && state.selectedTags.length ? 
                            <div className={`flex flex-wrap`}>
                                {state.selectedTags.map((tag, tIndex) => {
                                    return(
                                        <span key={'tag_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'tag_'+tIndex}>
                                            <div className='truncate'  style={{maxWidth: '250px'}}>
                                                {tag.key +' : '+tag.value}
                                            </div>
                                            <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTag(tag)}/>
                                        </span>
                                    )
                                })}
                            </div>
                        : null}
                        <div className="flex justify-end mt-3">
                            <span className={`text-info mr-2 self-center cursor-pointer`} onClick={() => resetFilterSelection()}>Clear</span>
                            <button className={`btn bg-HalloweenOrange px-2 py-1 rounded-md text-black cursor-pointer mr-2`} onClick={() => setState(prevState => ({ ...prevState, showFilterSection: false }))}>Filter</button>
                            <button className={`btn bg-lightGray px-2 py-1 rounded-md text-black cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, showFilterSection: false }))}>Close</button>
                        </div>
                    </div>
                </div>
            : null}

            <div className="flex mt-2 flex-wrap">
                <div className={`xl:4/6 lg:w-3/5 md:w-3/5 w-full pr-2 overflow-auto h-full`}>
                    <div className="rounded-md bg-white p-3">
                        <div className="flex mb-2 justify-between">
                            <div className="flex flex-wrap mr-2 lg:w-2/5 md:2/5 w-full">
                                <p className="m-0 self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length} alert(s)</p>
                                <p className="m-0 self-center pt-1 text-info ml-1">{hpcAlertPageFilters.start_time  ? momentDateGivenFormat(hpcAlertPageFilters.start_time , 'DD MMM YYYY') +' - '+ momentDateGivenFormat(hpcAlertPageFilters.end_time , 'DD MMM YYYY') : ''}</p>
                            </div>
                            <div className="flex justify-end lg:w-3/5 md:3/5 w-full self-center">
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <CSVLink 
                                        data={state.filteredArray} 
                                        headers={state.headers ? state.headers : []}
                                        filename={"observability-alerts.csv"}
                                        className={"self-center mr-2"}
                                        target="_blank"
                                    >
                                        <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                    </CSVLink>
                                : null}
                                {state.totalFilteredArray && state.totalFilteredArray.length ?
                                    <Search
                                        data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                        searchClassName={'px-2'}
                                        searchIconColor={'text-black'}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent text-black px-2 py-1'}
                                        widthHeight={25}
                                        resetSearchBox={state.emptySearchText}
                                        filteredData={(filteredArray) => {
                                            setState(prevState => ({ ...prevState, filteredArray, alertDetails: {}, merticsAssetNameInvolved: [], emptySearchText: false }))
                                            if(filteredArray && filteredArray.length) {
                                                getAlertDetails(filteredArray[0])
                                            }
                                        }}
                                    />
                                : null}
                                {state.filteredArray && state.filteredArray.length > state.perPage ?
                                    <div className='flex justify-end text-black w-full'>
                                        <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                        <div className="pagination text-sm">
                                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                            {/* <span>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                        gotoPage(page)
                                                    }}
                                                    className="ml-1 inputClass"
                                                />
                                            </span> */}
                                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>

                        <div className="flex flex-wrap">
                            {state.alertsCategory ? 
                                <div className="xl:w-1/5 lg:w-2/6 w-full pr-2">
                                    <div className="bg-GhostWhite px-2 pt-2">
                                        {state.groupedBy === "severity" ?
                                            state.alertsCategory.map(item => {
                                                return(
                                                    item.category === "Important / All" ?
                                                        <div className={`flex p-2 cursor-pointer ${state.selectedCategory === item.category ? 'text-info pointer-events-none' : ''}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                            }
                                                        }}>
                                                            <span className="m-0 text-md">{item.category}</span>
                                                            <span className="m-0 self-end ml-2 text-md">{item.count !== '' ? item.count : ''}</span>
                                                        </div>
                                                    :
                                                        <div className={`flex justify-end mb-2 ${!item.count ? 'pointer-events-none opacity50' : 'cursor-pointer'}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                            }
                                                        }}>
                                                            <div className="flex">
                                                                {item.totalBars.map(bar => {
                                                                    return(
                                                                    <div className={`w-1 h-8 mr-1 bg-${item.category.toLowerCase()}`}></div>
                                                                    )
                                                                })}
                                                                <div className={`text-semibold text-center border border-lightGray rounded-md p-2`}>
                                                                    <p className="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        :
                                            state.alertsCategory.map((item, index) => {
                                                return(
                                                    item.category === "Important / All" ?
                                                        <div className={`flex cursor-pointer mb-3 ${state.selectedCategory === item.category ? 'text-info pointer-events-none' : ''}`} onClick={() => {
                                                            if(state.selectedCategory !== item.category) {
                                                                setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                            }
                                                        }}>
                                                            <span className="m-0 text-md">{item.category}</span>
                                                            <span className="m-0 self-end ml-2 text-md">{item.count !== '' ? item.count : ''}</span>
                                                        </div>
                                                    :
                                                    <div className={`flex cursor-pointer mb-2 ${state.selectedCategory === item.category ? 'text-info pointer-events-none' : (!item.count ? 'text-gray4 pointer-events-none' : 'text-black')}`} onClick={() => {
                                                        if(state.selectedCategory !== item.category) {
                                                            setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))
                                                        }
                                                    }}>
                                                        <div className="flex justify-between mb-2" style={{maxWidth: "160px"}}>
                                                            <p className="mb-0 self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                            {/* <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip> */}
                                                            <input className="form-control form-control-sm w-25 border-0 bg-grey text-black readOnly  disabled rounded-md form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            : null}
                            <div className={`${state.alertsCategory ? 'xl:w-4/5 lg:w-4/6' : ''} w-full`}>
                                <div className={`bg-GhostWhite rounded-md py-3 pl-2 min-h-screen`}>
                                    {state.alertListLoader ? 
                                        <div className='flex justify-center m-4'>
                                            <svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        </div>
                                    : state.filteredArray && state.filteredArray.length ?
                                        state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`flex mr-2 px-2 mb-2 pb-1 ${state.startRecord + state.perPage !== state.startRecord + i ? 'border-bottom-black5' : ''} ${state.selectedAlertId !== item.group_id ? 'cursor-pointer' : 'bg-BlockWhite text-black pt-1'}`} 
                                                onClick={() => getAlertDetails(item)}
                                                >  
                                                    <div className="flex">
                                                        <span className={`tex-xs mr-2 self-start mt-1 bg-${item.severity ? item.severity.toLowerCase() : 'critical'} rounded-full px-2 text-white`}>
                                                            {item.severity ?    
                                                                (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 text-semisolid text-lightGray">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                            <span className={`mb-0 mr-2 text-purple`}>
                                                                {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                {item.event_name ? ' : ' + item.event_name : ''}
                                                                {item.event_type ? ' : '+ item.event_type : ''}</span>
                                                            {item.asset_name ?
                                                                <span className="mb-0 mr-2 text-black text-semisolid">{item.asset_name}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-lightGray">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 text-lightGray text-semisolid">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 text-lightGray text-semisolid">{' : '+item.region}</span>
                                                            : null}
                                                            {item.resource_type ?
                                                                <span className="mb-0 text-lightGray text-semisolid">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                            : null}
                                                            {item.service_name ?
                                                                <span className="mb-0 text-lightGray text-semisolid mr-2">{' : '+item.service_name}</span>
                                                            : null}
                                                            {item.description ?
                                                                <span className="mb-0 ml-2 text-black text-semisolid">{item.description}</span>
                                                            : null}
                                                        </p>
                                                    </div>
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='flex justify-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!state.alertListLoader ?
                    <div className={`xl:2/6 lg:w-2/5 md:w-2/5 w-full overflow-auto h-full`}>
                        <AlertDetails />
                    </div>
                : null}
            </div>
        </div>
    )
}

export default AlertsDashboard