/*************************************************
 * Tvastar
 * @exports
 * @file AssocationDetails.js
 * @author Prakash // on 06/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import { useLocation } from 'react-router-dom';
import { qosListAccounts } from '../../../actions/Collider/AccountAction';
import {Icon} from "@iconify/react";

const AssocationDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		callUserList: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)
	const usersList = useSelector(state => state?.filters?.listUsers || false);

	const location = useLocation();
	const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedUsers: receivedData.selectedData && receivedData.selectedData.user_name ? receivedData.selectedData.user_name : []
			}))

			dispatch(setHpcPropsDetails('qosCreateEditInputs', receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [receivedData, dispatch])

	useEffect(() => {
        if (state.callUserList) {
			let missingInput = false
			let params = {}
			params.provider = qosCreateEditInputs.provider ? qosCreateEditInputs.provider : ''
			params.account_id = qosCreateEditInputs.account_id ? qosCreateEditInputs.account_id : ''
			if(qosCreateEditInputs.region) {
				params.region = qosCreateEditInputs.region ? qosCreateEditInputs.region : ''
			}
			if(qosCreateEditInputs.region) {
				params.cluster_name = qosCreateEditInputs.cluster_name ? qosCreateEditInputs.cluster_name : ''
			}
			if(!qosCreateEditInputs.account_id || !qosCreateEditInputs.account_id || !qosCreateEditInputs.region) {
				missingInput = true
			}
			if(!missingInput) {
				dispatch(qosListAccounts(params))
					.then((response) => {
						if(response) {
							let results = response.results ? response.results : []
							setState(prevState => ({ ...prevState, accountsList: results, selectedGroup: '' }));
						}
					})
			}
        }
    }, [dispatch, state.callUserList, usersList, qosCreateEditInputs.provider, qosCreateEditInputs.account_id, qosCreateEditInputs.region, qosCreateEditInputs.cluster_name])

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
		handleInputChange('requested_user_name', state[field].filter(e => e !== value))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		props.onChildPageClick(event)

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => props.onChildPageClick(event)}>
			<div className='flex justify-between'>
				<p className='text-info text-lg'>Associations</p>
			</div>
			<div className={`pb-3 mb-2`} onClick={(event) => handleChildClick(event, 'parent')}>
				<div className='flex mt-2 pt-2'>
					<div className='py-1 lg:w-1/4 md:w-1/2 w-full'>
						<div className='flex justify-between'>
							<p className="b-block mb-0">Group</p>
							{state.inputValidationError && !state.selectedGroup ?
								<span className='mb-0 text-red ml-2'>required</span>
							: null}
						</div>
						<div onClick={(event) => {
							if(!state.showAccount) {
								event.preventDefault();
								handleChildClick(event, 'child', 'singleDropDown', "showAccount")
							}
						}}>
							<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-2 truncate ${state.selectedGroup ? 'text-black' : 'text-lightGray'}`}>
								{state.selectedGroup ? state.selectedGroup : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedGroup ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
							</p>
							{state.showAccount ?
								<MultiSelectSection
									fields={["account", "account"]}
									options={state.accountsList}
									selectedValues={state.selectedGroup ? state.selectedGroup : ''}
									callbackMultiSelect={(value) => {
										if(!value || typeof(value) === 'string') {
											setState(prevState => ({ ...prevState, 
												selectedGroup: value,
												groupUsers: state.accountsList.filter(e => e.account === value).length && state.accountsList.filter(e => e.account === value)[0].user_name ? state.accountsList.filter(e => e.account === value)[0].user_name : []
											}))
											handleInputChange('account', value)
										} else {
											value.preventDefault();
											handleChildClick(value, 'child', 'search', "")
										}
									}}
									singleSelection={true}
									hideSearch={false}
									topClass={'auto'}
									widthClass={'minWidth220'}
									removeTopOptions={true}
								/>
							: null}
						</div>
					</div>
					<div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
						<div className='flex justify-between'>
							<p className="b-block mb-0">User</p>
							{state.inputValidationError && !state.selectedUsers ?
								<span className='mb-0 text-red ml-2'>required</span>
							: null}
						</div>
						<div onClick={(event) => {
							event.preventDefault();
							handleChildClick(event, 'child', 'muliselectDropDown', "showUser")
						}}>
							<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-2 truncate ${state.selectedGroup && state.selectedUsers && state.selectedUsers.length ? 'text-black' : 'text-lightGray'}`}>
								{/* {state.selectedGroup && state.selectedUsers && state.selectedUsers.length ? state.selectedUsers.length+' Selected' : 'Select'} */}
								Select
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedUsers && state.selectedUsers.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
							</p>
							{state.showUser ?
								<MultiSelectSection
									// fields={["user", "label"]}
									options={state.groupUsers}
									selectedValues={state.selectedUsers ? state.selectedUsers : ''}
									callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, selectedUsers: value }))
										handleInputChange('requested_user_name', value)
									}}
									singleSelection={false}
									hideSearch={false}
									topClass={'auto'}
									widthClass={'minWidth220'}
									removeTopOptions={true}
								/>
							: null}
						</div>
					</div>
				</div>
				{state.selectedUsers && state.selectedUsers.length ? 
					<div className='flex flex-wrap'>
						{state.selectedUsers.map(row => {
							return(
								<span key={row} className="flex border border-info rounded-full px-2 py-1 mr-2 mt-2 self-center text-sm text-black">{row}
									<Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center' onClick={ () => removeItem('selectedUsers', row) } />
								</span>
							)
						})}
					</div>
				: null}
			</div>
		</div>
	)
}

export default AssocationDetails
