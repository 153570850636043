/*************************************************
 * Kiosk
 * @exports
 * @file JobDetails.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getJobTotalCost } from '../../../actions/Collider/SpendAction'
import { momentConvertionLocalToUtcTime } from '../../../utils/utility';
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart';
import {Icon} from "@iconify/react";

const JobDetails = (props) => {
    const rightRadialStackedBarWidthRef = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callGetJobTotalCostFunction: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.callGetJobTotalCostFunction) {
            setState(prevState => ({ ...prevState, callGetJobTotalCostFunction: false }));
            let params = {
                // 'duration': '30d',
                'start_time': momentConvertionLocalToUtcTime(props.startDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(props.endDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by':'job_name', 
                'radial':true
            }
            if(props.selectedDetails && props.selectedDetails.provider) {
                params.provider = props.selectedDetails.provider
            }
            
            if(props.selectedDetails && props.selectedDetails.account_id) {
                params.account_id = props.selectedDetails.account_id
            }

            if(props.selectedDetails && props.selectedDetails.region) {
                params.region = props.selectedDetails.region
            }

            if(props.selectedDetails && props.selectedDetails.cluster_name) {
                params.cluster_name = props.selectedDetails.cluster_name
            }
            
            if(props.selectedDetails && props.selectedDetails.queue_name) {
                params.queue_name = props.selectedDetails.queue_name
            }

            dispatch(getJobTotalCost(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length && response.results[0].data ? response.results[0].data : {}
                        let totalPages = 1                
                        if(Object.entries(results).length > state.perPage) {
                            totalPages = Math.ceil(Object.entries(results).length / state.perPage)
                        }
                        
                        setState(prevState => ({
                            ...prevState,
                            radialTrendResponse: results,
                            filterData: results,
                            totalPages,
                            showLoading: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobTotalCostFunction, state.perPage, props])

    const count = useCallback((array, key) => {
		let data = array.reduce(function(r, a) {
			return r + a[key]
		}, 0)

		return data
	}, [])

    useEffect(() => {
        if(state.radialTrendResponse && Object.entries(state.radialTrendResponse).length) {
            let graphData = state.radialTrendResponse //radialBarData //radialTrendResponse
            let rightSideGraphColors = ['#8e3a80', '#a22ee6', '#bc47ff', '#c35cff', '#cb70ff', '#d285ff', '#da99ff', '#e15ad2', '#fb74eb', '#fb83ed', '#fc93f0', '#fca2f2', '#fdb2f4', '#fdc1f6']
            let keys = ['Cost']

            let rightSideArray = []
            let rightSideLowest = 0
            let rightSideHighest = 0
            let rightSideTotal = 0
            let rightSideTotalTableData = {}
            graphData && Object.entries(graphData).forEach(([key, value], index) => {
                let State = 'files_' + index
                let dataRow = {}
                let count = value
                dataRow.Cost = value

                dataRow.State = State
                dataRow.page = 'MultipleKeys'
                dataRow.name = key
                dataRow.total = count
                rightSideTotal = graphData.length
                if (rightSideHighest < count) {
                    rightSideHighest = count
                }
                if(count < rightSideLowest) {
                    rightSideLowest = count
                }
                if (count > 0) {
                    rightSideArray.push(dataRow)
                }

                rightSideTotalTableData[State] = dataRow
            })

            let rightSideTotalData = {}
            let data = {}
            data.name = 'Jobs'
            keys.forEach((key, j) => {
                data[key] = count(rightSideArray, key)
            })
            rightSideTotalData.data = data

            if (rightSideArray.length < 25) {
                let length = rightSideArray.length
                for (let i = length; i <= 25; i++) {
                    let dataRow = {}
                    dataRow.State = 'files_' + i
                    dataRow.name = 'empty_data'
                    let count = rightSideLowest / 5
                    dataRow.Cost = count
                    rightSideArray.push(dataRow)
                }
            }
            
            // rightSideArray = _.orderBy(rightSideArray, ['fail_count'], ['desc'])

            setState(prevState => ({ 
                ...prevState, 
                rightSideGraphColors,
                rightSideArray,
                rightSideKeys: keys,
                rightSideHighest,
                rightSideTotalData,
                rightSideTotal,
                rightSideGraphId: 'totalThreats',
                rightSideSvgGraphId: 'svgTotalThreats',
                showRightSideRadialChart: true,
                hideDistributionSection: true,
                showRadialLoading: false,
                rightSideTotalTableData,
            }))
        }
    }, [state.radialTrendResponse, count])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const handleClickOutside = (event) => {};

    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <svg className="animate-spin h-5 w-5 mr-2 text-balck" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        : state.filterData && Object.entries(state.filterData).length ?
            <div className='flex flex-wrap overflow-auto' onClick={handleClickOutside}>
                <div className='lg:w-5/12 w-full p-2'>
                    <div className='bg-GhostWhite rounded-md p-3'>
                        <div className='flex justify-between mt-2 w-100'>
                            <div className='self-center'>
                                <p className='text-black mb-0 text-lg'>Jobs</p>
                                <small className='mt-1 mb-0 small text-black'>View jobs</small>
                            </div>
                        </div>
                        <div className='flex flex-wrap justify-center'>
                            <div className='w-3/4' ref={rightRadialStackedBarWidthRef}>
                                {state.showRightSideRadialChart ?
                                    <StackedCircleBarChart
                                        ID={state.rightSideGraphId}
                                        svgID={state.rightSideSvgGraphId}
                                        width={
                                            rightRadialStackedBarWidthRef.current &&
                                            rightRadialStackedBarWidthRef.current.offsetWidth
                                        }
                                        data={state.rightSideArray}
                                        keys={state.rightSideKeys}
                                        userHighestValue={true}
                                        highestValue={state.rightSideHighest}
                                        hoverData={state.rightSideTotalData}
                                        totalValue={state.rightSideTotal}
                                        selectedState={
                                            state.selectedChartControl ? state.selectedChartControl : ''
                                        }
                                        tableData={(data, State) => {
                                            if (State && State !== state.selectedChartControl) {
                                                setState(prevState => ({ 
                                                    ...prevState,
                                                    selectedChartControl: State, 
                                                    // showRightSideRadialChart: false,
                                                    refreshStackedCircleBar: true
                                                }))
                                            }
                                        }}
                                        legendText={'Jobs'}
                                        colors={state.rightSideGraphColors}
                                    />
                                : null }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:w-7/12 w-full p-2'>
                    <div className='bg-backGroundGray p-3 rounded-md'>
                        <div className='flex justify-between mt-2'>
                            <p className='mb-1 text-black'>Showing job cost details</p>                            
                            <div className='flex w-3/5 justify-end'>
                                {state.radialTrendResponse && Object.entries(state.radialTrendResponse).length > state.perPage ?
                                    <div className='flex justify-end text-black w-full'>
                                        <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                        <div className="pagination text-sm">
                                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="relative overflow-x-auto mt-2">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-md text-black bg-lightGray">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-black">Job Name</th>
                                        <th scope="col" className="px-6 py-3 text-black">Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='text-black bg-white'>
                                    {state.filterData && Object.entries(state.filterData).slice(state.startRecord, state.startRecord + state.perPage).map(([key, value], index) => {
                                        return(
                                            <tr key={'tr_'+index} className={`bg-black ${!index ? '' : 'border-t border-lightGray'}`}>
                                                <td className={`px-6 py-3 text-black`}>{key}</td>
                                                <td className={`px-6 py-3 text-black`}>{value}</td>
                                            </tr>
                                        )

                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='flex justify-center m-4 text-black'>
                There are no job data on this {props.selectedType}.
            </div>
    );
};

export default JobDetails