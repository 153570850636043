/*************************************************
 * Collider
 * @exports
 * @file CatalogAction.js
 * @author Barani // on 07/10/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../../components/ApiCalls'

import {
	LIST_CATALOG_FILTERS,
} from '../../config'
import { refreshPage } from '../../utils/utility';


/**
 * Action to list-queue-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listCatalogFilters = (params) => {
	return async () => {
		try {
			const url = LIST_CATALOG_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				// //localStorage.setItem('ACCESS_TOKEN', '');
				// //window.location.href = '/signin';
			}
			return error
		}
	};
}