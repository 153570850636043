/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file UserDetails.js
 * @author Prakash // on 27/11/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listUserDetails } from '../../../actions/commonActionNew'
import { getUserCostBreakup } from '../../../actions/cost/CostAction'

import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import ApexBarChart from '../../common/charts/ApexBarChart';
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { capitalizeTheFirstLetterOfEachWord, currentUTCtime, momentDateGivenFormat, removeUnderScore, thousandSeparator } from '../../../utils/utility';
import StickyDarkThemeTable from '../../designComponents/Table/StickyDarkThemeTable';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";

const UserDetails = (props) => {
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 5,

        userDetails: [],
        selectedUserDetails: {},
        filteredUserDetails: [],
        callSearch: true,
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"}
		],
        graphOrder: ['cpus', 'cpu_hours', 'nodes', 'node_hours', 'jobs'],
        graphOrderColors: [['#854097'],['#693EBC'],['#775BA2'],['#9A77D1'],['#039BE5', '#43B02A', '#F75E3F']]
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }
            
            if(props.selectedUser && props.selectedUser.length) {
                params.user_name = props.selectedUser
            }
            if(props.currencyUnit) {
                params.currency_conversion = props.currencyUnit
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }

            params.size = state.perPage

            dispatch(listUserDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.userDetails &&  state.userDetails.length ? state.userDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }

                        let totalPages = 1
                        if(totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        
                        let responseTypes = response && response.results && response.results.length && response.results[0].data && Object.keys(response.results[0].data).length ? Object.keys(response.results[0].data) : [] 
                        let topStatusType = ['Budget/Spend']
                        topStatusType = [...topStatusType, ...responseTypes]
                        
                        setState(prevState => ({ ...prevState, userDetails: totalResults, filteredUserDetails: totalResults, topStatusType, detailsFormat: response && response.user_details ? response.user_details : [], showLoading: false, callBudgetSpendTrendSection: true, callSpendTrendSection: true, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));

                        props.callBack()
                    }
                })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.userDetails, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.callBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callBudgetSpendTrendSection: false }))
            state.filteredUserDetails.forEach((item, index) => {
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip
                    
                    // setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData }))
                
                    
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData, ["budgetSpendBarLineGraphData_"+index]: graphData }))
                }
            })
        }
    }, [state.callBudgetSpendTrendSection, state.filteredUserDetails])    

    useEffect(() => {
        if(state.callSpendTrendSection) {
            setState(prevState => ({ ...prevState, callSpendTrendSection: false }))

            state.filteredUserDetails.forEach((item, index) => {
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })

                    
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                    // colors={['#854097', '#693EBC', '#9A77D1', '#B05B6B']}
                    let trendBreakDownColor = ['purple3', 'purple', 'dark-theme-purple', 'high']
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+index]: spendDonutData, ['spendStackedTrendData_'+index]: graphData, ["trendBreakDownTotal_"+index]: spendDonutResponse, trendBreakDownColor }))
                }
            })
        }
    }, [state.callSpendTrendSection, state.filteredUserDetails])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {

        setState(prevState => ({ ...prevState, 
            ["budgetSpendData_"+selectedPeriodIndex]: {}, 
            ["budgetSpendBarLineGraphData_"+selectedPeriodIndex]: {}, 
            ["spendDonutData_"+selectedPeriodIndex]: {}, 
            ['spendStackedTrendData_'+selectedPeriodIndex]: {}, 
            ["trendBreakDownTotal_"+selectedPeriodIndex]: [],
        }))

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        params.spendboard = true
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(props.selectedClusters && props.selectedClusters.length) {
            params.cluster_name = props.selectedClusters
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.userId) {
            params.requested_user_id = item.userId
        }

        dispatch(listUserDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedClusterDetails: results,
                        selectedFilteredClusterDetails: results,
                        callSelectedBudgetSpendTrendSection: true,
                        selectedPeriodIndex
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callSelectedBudgetSpendTrendSection: false }))
            state.selectedFilteredClusterDetails.forEach(item => {
                
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip
                    
                    // setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData }))
                
                    
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+state.selectedPeriodIndex]: horiBarData, ["budgetSpendBarLineGraphData_"+state.selectedPeriodIndex]: graphData }))
                }
                
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+state.selectedPeriodIndex]: spendDonutData, ['spendStackedTrendData_'+state.selectedPeriodIndex]: graphData, ["trendBreakDownTotal_"+state.selectedPeriodIndex]: spendDonutResponse }))
                }
            })
        }

    }, [state.callSelectedBudgetSpendTrendSection, state.selectedFilteredClusterDetails, state.selectedPeriodIndex])

    const getExpandedItemDetails = (period, item, index) => {
        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        // params.spendboard = true
        params.duration_aggregate_by = period
        if(item.account_id && item.account_id.length) {
            params.account_id = item.account_id
        }
        if(item.region && item.region.length) {
            params.region = item.region
        }
        if(item.cluster_name && item.cluster_name.length) {
            params.cluster_name = item.cluster_name
        }
        if(item.userId) {
            params.requested_user_id = item.userId
        }
        if(props.currencyUnit) {
            params.currency_conversion = props.currencyUnit
        }

        dispatch(getUserCostBreakup(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        ['selectedItemDetails_'+index]: results,
                        selectedItemIndex: index,                        
                        callSelectedBreakDownDetail: true
                    }));
                }
        })

    }

    useEffect(() => {
        if(state.callSelectedBreakDownDetail) {
            setState(prevState => ({ ...prevState, callSelectedBreakDownDetail: false, ['showLoading_'+state.selectedItemIndex]: false })) 
            if(state['selectedItemDetails_'+state.selectedItemIndex] && state['selectedItemDetails_'+state.selectedItemIndex].length) {
                var trendTable = []
                state['selectedItemDetails_'+state.selectedItemIndex].forEach((item, index) => {

                    let budgetSpendTrendData = item.data ? item.data : []
                    let jobsData = {}
                    jobsData.labels = item.dates ? item.dates : []
                    jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                    jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                    jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                    
                    let nodesData = {}
                    nodesData.labels = item.dates ? item.dates : []
                    nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                    let vcpusData = {}
                    vcpusData.labels = item.dates ? item.dates : []
                    vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                    let cpuHours = {}
                    cpuHours.labels = item.dates ? item.dates : []
                    cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                    let nodeHours = {}
                    nodeHours.labels = item.dates ? item.dates : []
                    nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                    setState(prevState => ({ 
                        ...prevState, 
                        ["nodes_"+state.selectedItemIndex]: nodesData,
                        ["cpus_"+state.selectedItemIndex]: vcpusData,
                        ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                        ["node_hours_"+state.selectedItemIndex]: nodeHours,
                        ["jobs_"+state.selectedItemIndex]: jobsData,                        
                    }))

                    let labels = item.dates ? item.dates : []
                    if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                        Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                            var result = {
                                "Title": key,
                                'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                            }
                            
                            value.forEach((vItem, vIndex) => {
                                result[labels[vIndex]] = vItem
                            })
                            trendTable.push(result);
                        })
                    } else if(item.data && Array.isArray(item.data)) {
        
                        let data = item.data
                        // let totalCount = item.data.reduce(function(a, b){
                        // 	return a + b;
                        // }, 0);
                        var result = {
                            "Title": "Cost",
                            'total': item.total
                        }
                        
                        data.forEach((item,index) => {
                            if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                result[labels[index]] = item
                            }
                        })
                        
                        if(item.forecast && item.forecast.dates) {
                            item.forecast && item.forecast.dates.forEach((lab, index) => {					
                                result[lab] = item.forecast.data[index]
                            })
                        }
        
                        trendTable.push(result);
                    }
                })
                
                if(trendTable.length) {
                    let userHeaders = ['Title', 'total']
                    const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                        let sticky = false
                        let header = ''
                        let width = '100%'
                        let textColor = ''
                        if(tKey === 'Title') {
                            sticky = true
                            header = tKey
                            width = 300
                        } else if(tKey === 'total') {
                            sticky = true
                            header = 'Total'
                            width = 75
                        } else {
                            if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                textColor = "text-info"
                            }
                            // if(state.granualrity === 'monthly') {
                            //     header = moment(tKey).format('MMM YY')
                            //     width = 75
                            // } else  if(state.granualrity === 'daily') {
                            //     header = moment(tKey).format('MMM DD')
                            //     width = 75
                            // } else  {
                            header = moment(tKey).format('MMM DD')
                            width = 75
                            userHeaders.push(tKey)
                            // }
                        }
                        return {
                            Header: header,
                            accessor: tKey,
                            width: width,
                            sticky: sticky ? "left" : "",
                            textColor: textColor,
                            className: 'text-truncate text-right'
                        }
                    })
                    
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns, userHeaders }))
                } else {
                    const columns = []	
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns }))
                }
            }
        }
    }, [state.callSelectedBreakDownDetail, state])

    useEffect(() => {
        if(state.refreshClusters) {
            setState(prevState => ({ ...prevState, refreshClusters: false }))
        }
    }, [state.refreshClusters])

    const handleClickOutside = (event) => {}

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    return (
        <div className='bg-transparent' onClick={handleClickOutside}>
            <div className={`${(state.showLoading || state.downloading) ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            <div className='overflow-auto'>                
                <div className='flex justify-between'>
                    <p className='mb-0 self-center'>
                        <small>Showing {state.filteredUserDetails && state.filteredUserDetails.length} out of total {state.userDetails && state.userDetails.length} user(s)</small>
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                            <div className="pagination text-sm">
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'pointer-events-none text-lightGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                            </div>
                        </div>
                    : null}
                </div>
                {state.filteredUserDetails && state.filteredUserDetails.length ?
                    state.filteredUserDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                        return(
                            <div className={`bg-GhostWhite px-3 rounded mb-2 pb-2`} key={"row_"+(state.startRecord + index)}>
                                <div className='flex flex-wrap'>
                                    <div className="lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
                                        <div className='py-2'>
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-lightGray">Name</p>
                                                    <p className="mb-0 text-black">
                                                        { item.userName ? item.userName : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex pt-2">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-lightGray">Id</p>
                                                    <p className="mb-0 text-black">{item.userId ? item.userId : ''}</p>
                                                </div>
                                            </div>
                                            <div className="flex pt-2">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0 text-lightGray">Organization</p>
                                                    <p className="mb-0 text-black">{item.organization ? item.organization : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:w-1/6 md:w-1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                        <div className='flex'>
                                            {state["budgetSpendData_"+(state.startRecord + index)] && Object.entries(state["budgetSpendData_"+(state.startRecord + index)]).length ?
                                                <div className='-mt-2 -mb-2 mr-4 self-center'>
                                                    <ApexBarChart
                                                        graphData={state["budgetSpendData_"+(state.startRecord + index)]}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={false}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={false}
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={200}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        columnWidth={'25%'}
                                                        gradient={false}
                                                        gradientColor={['#009fdf', '#CD5A9F']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels= {true}
                                                        dataLabelsTextAnchor = {'end'}
                                                        dataLabelPosition={'top'}
                                                        colors={['#43b02a', '#009FDF']}
                                                        dataLabelsOffsetX={0}
                                                        chartDistributed={true}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="lg:w-1/6 md:w-1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                        <div className='flex'>
                                            {state['budgetSpendBarLineGraphData_'+(state.startRecord + index)] && Object.entries(state['budgetSpendBarLineGraphData_'+(state.startRecord + index)]).length ?
                                                <div className='mb-n3'>
                                                    <ApexMixedBarLineChart 
                                                        graphData={state['budgetSpendBarLineGraphData_'+(state.startRecord + index)]}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'datetime'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={-25}
                                                        legend={true}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={200}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'25%'}
                                                        gradient={true}
                                                        gradientColor={['#039BE5', '#5F5BA2']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        colors={['#43b02a', '#009FDF']}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="lg:w-5/12 sm:w-3/4 w-full pr-0 cursor-pointer">
                                        <div className='flex'>
                                            <div className='self-center mr-4 mt-3'>
                                                {state['spendDonutData_'+(state.startRecord + index)] && Object.entries(state['spendDonutData_'+(state.startRecord + index)]).length ? 
                                                    <ApexDonutChart
                                                        labelColor={"#999999"}
                                                        valueColor={"#999999"}
                                                        // labels={['Jobs', 'Workstation', 'Disk', 'Storage']}
                                                        graphData={state['spendDonutData_'+(state.startRecord + index)]}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        showGraphHeading={false}
                                                        height={120}
                                                        width={120}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        gradientColor={['#A88CCC', '#D88ACF']}
                                                        // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                        // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                        colors={['#d13737', '#1261AB', '#6500B7', '#27B224', '#037a2b', '#ba0000', '#d13737', '#ffb534' ]}
                                                        className={'transparentTooltip'}
                                                    />
                                                : null}
                                            </div>                                        
                                            {state['spendStackedTrendData_'+(state.startRecord + index)] && Object.entries(state['spendStackedTrendData_'+(state.startRecord + index)]).length ?
                                                <React.Fragment>
                                                <div className='-mt-3 -mb-3 mr-2 w-3/4'>
                                                    <ApexStackedBarChart 
                                                        graphData={state['spendStackedTrendData_'+(state.startRecord + index)]}
                                                        sparkline={false}
                                                        yaxis={false}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'datetime'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={-25}
                                                        legend={true}
                                                        stacked={true}
                                                        height={210}
                                                        horizontal={false}
                                                        // barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'25%'}
                                                        gradient={true}
                                                        stroke={'hide'}
                                                        // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                        // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                    />
                                                </div>
                                                <ul className="w-1/4 self-center mb-0 mt-3 text-black">
                                                    {state["trendBreakDownTotal_" + (state.startRecord + index)] && Object.values(state["trendBreakDownTotal_" + (state.startRecord + index)]).map((value, tIndex) => {
                                                        return(
                                                            <li key={'trend_' + tIndex} className='flex mb-2'><span className={`legend-circle bg-${state.trendBreakDownColor[tIndex]}`}></span> <span className="pt-1 ml-2 text-gray-400 d-inline-block"> &nbsp;{thousandSeparator(value)}</span></li>
                                                        )
                                                    })}
                                                </ul>
                                                </React.Fragment>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="lg:w-1/12 sm:w-1/4 w-full pr-0 cursor-pointer self-center">
                                        <div className="border-b border-lightGray mx-2 w-full mb-4 flex">
                                            {/* <small className=''>Show </small> */}
                                            <select className="form-control w-full bg-transparent text-black truncate"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                        ['showLoading_'+(state.startRecord + index)]: state['showExpandedSectoin_'+(state.startRecord + index)]
                                                    }));
                                                    onChangePeriod(selectedValue, (state.startRecord + index), item);
                                                    if(state['showExpandedSectoin_'+(state.startRecord + index)]) {
                                                        getExpandedItemDetails(selectedValue, item, (state.startRecord + index));
                                                    }

                                                    // Use the selectedValue directly in the callback to avoid potential issues
                                                }}
                                                value={state['selected_Period_'+(state.startRecord + index)]}
                                            >
                                                {state.periodOptions && state.periodOptions.map(item => {
                                                    return(
                                                        <option key={'opt_'+item.value} className='text-black' value={item.value}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <p className='mb-0 flex justify-center text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'User', showSidePanel: true }))}>Details</p>
                                        <span className='flex justify-center'>
                                            {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                                <Icon icon="basil:expand-outline" width="20" height="20" className={`text-info mt-3 cursor-pointer`}
                                                    onClick={() => {
                                                        setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: true, ['showLoading_'+(state.startRecord + index)]: true }))
                                                        getExpandedItemDetails(state['selected_Period_' + (state.startRecord + index)], item, (state.startRecord + index))
                                                    }} 
                                                />
                                            :
                                                <Icon icon="la:compress" width="20" height="20" className={`text-info mt-3 cursor-pointer`}
                                                    onClick={() => {
                                                        setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))
                                                    }}
                                                />
                                            }
                                        </span>
                                    </div>
                                </div>
                                {state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                    state['showLoading_'+(state.startRecord + index)] ?
                                        <div className="w-full lg:w-3/4 flex justify-center mt-4">
                                            <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        </div>
                                    :
                                        <div className='bg-GhostWhite px-2 py-3 mx-3 mt-2'>
                                            {state['selectedItemDetails_'+(state.startRecord + index)] && state['selectedItemDetails_'+(state.startRecord + index)].length && state['selectedItemDetails_'+(state.startRecord + index)][0].data && Object.entries(state['selectedItemDetails_'+(state.startRecord + index)][0].data).length ?
                                                <div className="w-full pr-0">
                                                    <div className='flex flex-wrap justify-start'>
                                                        {state.graphOrder && state.graphOrder.map((order, oIndex) => {
                                                            return(
                                                                <div key={'order_'+oIndex} className="w-full pr-2 mb-2">
                                                                    {state[order+'_'+(state.startRecord + index)] && Object.entries(state[order+'_'+(state.startRecord + index)]).length ?
                                                                        <React.Fragment>
                                                                        <p className='mb-2 text-black'>{capitalizeTheFirstLetterOfEachWord (removeUnderScore(order))}</p>
                                                                        <div className='mb-1'>
                                                                            <ApexLineChartSpendBoard
                                                                                graphData={state[order+'_'+(state.startRecord + index)]}
                                                                                sparkline={false}
                                                                                yaxis={true}
                                                                                yaxisLabel={true}
                                                                                yaxisTitle={false}
                                                                                // yaxisTitleLabel={state['selectedChartType_'+index]}
                                                                                yAxisTickAmount={2}
                                                                                xaxis={true}
                                                                                xaxisFormat={'datetime'}
                                                                                xaxisLabel={true}
                                                                                axisLabelColor={'#666666'}
                                                                                paddingLeft={20}
                                                                                legend={false}
                                                                                legendPostion={'bottom'}
                                                                                legendHorizontalAlign={'center'}
                                                                                legendUseSeriesColor={true}
                                                                                stacked={order==='jobs' ? true : false}
                                                                                height={120}
                                                                                horizontal={false}
                                                                                barHeight={'40%'}
                                                                                barEndShape={'flat'}
                                                                                columnWidth={'25%'}
                                                                                gradient={true}
                                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                                hideTooltipValue={true}
                                                                                backgroundBarShape={'flat'}
                                                                                backgroundBarColors={['#333947']}
                                                                                showBackgroundBarColors={false}
                                                                                className={'transparentTooltip'}
                                                                                colors={state.graphOrderColors[oIndex]}
                                                                                stroke={2}
                                                                            />
                                                                        </div>
                                                                        </React.Fragment>
                                                                    : null}
                                                                </div>
                                                            )                                                                
                                                        })}
                                                    </div>
                                                </div>
                                            : null}
                                            {state['trendTable_'+(state.startRecord + index)] ?
                                                <div className="w-full mt-3">
                                                    <div className="flex justify-between pt-2">
                                                        <p className="mb-0 self-center">Showing {state['filteredArray_'+(state.startRecord + index)] && state['filteredArray_'+(state.startRecord + index)].length} of total {state['trendTable_'+(state.startRecord + index)] && state['trendTable_'+(state.startRecord + index)].length} Cost and usage breakdown</p>
                                                        {state['trendTable_'+(state.startRecord + index)] && state['trendTable_'+(state.startRecord + index)].length ?
                                                            <div className="flex">
                                                                <CSVLink
                                                                    data={state['filteredArray_'+(state.startRecord + index)] ? state['filteredArray_'+(state.startRecord + index)] : []} 
                                                                    headers={state.userHeaders ? state.userHeaders : []}
                                                                    filename={'spenboard-users-'+new Date().getTime()+'.csv'}
                                                                    className={"mt-2"}
                                                                    target="_blank"
                                                                >
                                                                    <Icon icon="material-symbols:download" className='text-lightGray self-center' width="22" height="22"/>
                                                                </CSVLink>
                                                            </div>
                                                        : null}
                                                    </div>
                                                    {state['columns_'+(state.startRecord + index)] && state['columns_'+(state.startRecord + index)].length ? 
                                                        <div className="overflow-x-scroll">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-50">
                                                                    <tr className='border-b border-extraLightGray'>
                                                                        {state['columns_'+(state.startRecord + index)].map((col, colIndex) => {
                                                                            return (
                                                                                <th scope="col" key={'col_'+colIndex} className={`${col.sticky ? 'sticky-col' : 'bg-extraLightGray text-black'} ${colIndex === 1 ? 'sticky-col-1' : ''} px-6 py-1 text-left text-sm font-medium uppercase tracking-wider`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white divide-y divide-gray-200">
                                                                    {state['filteredArray_'+(state.startRecord + index)] && state['filteredArray_'+(state.startRecord + index)].length ?
                                                                        state['filteredArray_'+(state.startRecord + index)].map((col, colIndex) => {
                                                                            return(
                                                                                <tr className='border-b border-extraLightGray' key={'colval_'+colIndex}>
                                                                                    {state['columns_'+(state.startRecord + index)].map((obj, objIndex) => {
                                                                                        return(
                                                                                            <td key={objIndex+'_'+colIndex} className={`${objIndex <= 1 ? 'sticky-col' : 'bg-GhostWhite text-black'} ${objIndex === 1 ? 'sticky-col-1' : ''} px-6 py-1 whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                                        )
                                                                                    })}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    : null}
                                                    <div className='stickyTable spendBoardBudgetTable hidden' id={'table_'+(state.startRecord + index)}>
                                                        <StickyDarkThemeTable
                                                            id={'table_'+(state.startRecord + index)}
                                                            data={state['filteredArray_'+(state.startRecord + index)].length ? state['filteredArray_'+(state.startRecord + index)] : state['filteredArray_'+(state.startRecord + index)]}
                                                            columns = {state['columns_'+(state.startRecord + index)]}
                                                            onClickRow={tableRow => {}}
                                                            dashboard = {state['filteredArray_'+(state.startRecord + index)].length ? true : false}
                                                            //tooltipFor={[]}
                                                            marginRight={10}
                                                            tableHead={'bg-black text-black'}
                                                            tableBody={'bg-dark3 text-black'}
                                                            tableType={'table-dark-hover'}
                                                            perPageClass={`bg-transparent text-lightGray`}
                                                            textColor={'text-black'}
                                                            selectedColor={'bg-transparent'}
                                                            sortByColumn={"anomaly_time"}
                                                            riskTooltip={[]}
                                                        />
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center m-4 text-black'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='flex justify-end text-black'>
                        <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                        <div className="pagination text-sm">
                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'pointer-events-none text-lightGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default UserDetails