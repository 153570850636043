/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateQueuePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listCatalogFilters  } from '../../../actions/Collider/CatalogAction'
import { Spinner } from 'reactstrap';
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection';
 import { Store as CommonNotification } from 'react-notifications-component';
import { momentSlashConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, alphaNumeric, capitalizeAllLetter, getAccountNameFromId, getRegionName, onlyNumeric } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const CreateUpdateQueuePanel = ({ closeSidePanel, pageMode, selectedQueueDetails }) => {

    const clickOut = useRef();

    const [state, setState] = useState({
        selectedProvider: '',
        selectedAccount: '',
        selectedRegion: '',
        instanceTypes: []
    })

    // const allStates = useSelector(state => state)
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const tagKeys = useSelector(state => state.filters.tagKeys);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true,
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: true
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
            if(state.selectedProjectTags) {
                label = ''
                params.project_tags = state.selectedProjectTags
            }
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label !== '') {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }
            if(state.selectedProject) {
                label = '_'
                params.project_tags = state.selectedProjectTags
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            if(label !== '') {
                                let obj = propProjRegions ? propProjRegions : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjRegions', obj))
                            }
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();

            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    useEffect(() => {
        if(!tagKeys || !tagKeys.length) {
			let params = {}
			// params.account_id = '265469181489'
			// params.region = 'us-east-2'
			dispatch(getAllTagsKeys(params))
				.then((response) => {
                    if(response) {
					    // setState(prevState => ({ ...prevState, showTagKeyLoading: !state.showTagKeyLoading }))
                    }
				})
		}
    }, [dispatch, tagKeys])

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            // selectedCluster: "",
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        let params= {}
        dispatch(listCatalogFilters(params))
            .then((response) => {
                if(response) {
                    console.log("response", response);
                    setState(prevState => ({ 
                        ...prevState,
                        VPCIdOptions: response.vpc_ids ? response.vpc_ids : {},
                        subnetIdOptions: response.subnet_ids ? response.subnet_ids : [],
                        templateOptions: response.template ? response.template : [],
                        instanceTypes: response.instance_type ? response.instance_type : [],
                        keyPairs: response.key_pair ? response.key_pair : [],
                        operatingSystemOptions: response.os ? response.os : [],
                    }))
                }
            })
    }, [dispatch])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))
	}

	const removefromList = (value, listField) => {
        let filteredListItem = state[listField+''].filter(e => e !== value)

        let array = []
        filteredListItem.forEach(row => {
            let rowValue = {
                value: row,
                label: row
            }
            array.push(rowValue)
        })

        setState(prevState => ({ ...prevState, [listField+'']: filteredListItem }))
    }

    const addNewTagValue = () => {
        let selectedTags = state.selected_tags ? state.selected_tags : [];
    
        if (state.tag_key && state.tag_value && state.tag_value.length) {
          let dataRow = {};
          dataRow.key = state.tag_key;
          dataRow.value = state.tag_value;
          selectedTags.push(dataRow);
        }
    
        setState((prevState) => ({
          ...prevState,
          selected_tags: selectedTags,
          tag_key: "",
          tag_value: "",
        }));
    
        handleInputChange("tags", selectedTags);
      };
    
      const removeTagSection = (tag) => {
        let filteredResult =
          state.selected_tags && state.selected_tags.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, selected_tags: filteredResult }));
    
        handleInputChange("tags", filteredResult);
      };

    const addNewSoftwaresIntalled = () => {
        let softwaresInstalled = state.softwares_installed ? state.softwares_installed : [];

        if (state.softwares_installed_key && state.softwares_installed_value && state.softwares_installed_value.length) {
            let dataRow = {};
            dataRow.key = state.softwares_installed_key;
            dataRow.value = state.softwares_installed_value;
            softwaresInstalled.push(dataRow);
        }

        setState((prevState) => ({
            ...prevState,
            softwares_installed: softwaresInstalled,
            softwares_installed_key: "",
            softwares_installed_value: "",
        }));

        handleInputChange("softwares_installed", softwaresInstalled);
    };

    const removeSoftwaresIntalled = (tag) => {
        let filteredResult =
            state.softwares_installed && state.softwares_installed.filter((e) => e !== tag);
        setState((prevState) => ({ ...prevState, softwares_installed: filteredResult }));

        handleInputChange("softwares_installed", filteredResult);
    };
    
	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search h-full' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='w-3/4 bg-GhostWhite overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <p className='text-black text-xl'>{pageMode}Create Catalog</p>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded-md bg-white p-3'>
                        {state.createErrorMessage ?
                            <p className='text-red mb-2 text-center f18'>Error in saving data</p>
                        : null}
                        <div className='flex flex-wrap'>
                            <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                <div className='w-2/3'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Provider</p>
                                        {state.inputValidationError && !state.selectedProvider ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isProviderOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedProvider ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedProvider ? capitalizeAllLetter(state.selectedProvider) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isProviderOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["provider_name", "provider_name"]}
                                                    options={state.providers}
                                                    selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: "", selectedProject: "", selectedProjectTags: [], selectedRegion: "", selectedCluster: "" }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                <div className='w-2/3'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Account</p>
                                        {state.inputValidationError && !state.selectedAccount ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isAccountOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedAccount ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isAccountOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["account_id", "account_name"]}
                                                    options={state.accounts}
                                                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState,  selectedAccount: value, selectedCluster: "" }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/3 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <div className='w-2/3'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Region</p>
                                        {state.inputValidationError && !state.selectedRegion ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isRegionOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedRegion ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isRegionOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["region", "name"]}
                                                    options={state.regions}
                                                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, 
                                                                selectedRegion: value, selectedCluster: ""
                                                            }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='rounded-md bg-white p-3 my-2'>
                    <p className='b-block my-2 text-info f18'>Catalog configuration</p>
                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                <p className='b-block mb-0 flex'>
                                    Catalog Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                    {state.inputValidationError && !state['catalog_name'] ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {/* isDisabled={pageMode === 'Edit' ? true : false} */}
                                {pageMode === 'View' ?
                                    <p className='mb-0 text-black'>{state['catalog_name'] ? state['catalog_name'] : ''}</p>
                                : 
                                    <input
                                        type='text'
                                        placeholder='Enter catalog name'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        // maxlength='128'
                                        value={state['catalog_name'] ? state['catalog_name'] : ''}
                                        onChange={e => handleInputChange('catalog_name', e.target.value)}
                                    />
                                }
                            </div>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <p className='b-block mb-0 flex'>
                                    Description <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                    {state.inputValidationError && !state['description'] ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                <input
                                    type='text'
                                    placeholder='Enter description'
                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                    // maxlength='128'
                                    value={state['description'] ? state['description'] : ''}
                                    onChange={e => handleInputChange('description', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                <div onClick={(event) => {
                                    if(!state['showOperatingSystem']) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'showOperatingSystem')
                                    }
                                }}>
                                    <p className='b-block mb-0 flex truncate'>Operating System <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                        {state.inputValidationError && !state['os'] ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </p>
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['os'] ? 'text-black' : 'text-lightGray'}`}>
                                        {state['os'] ? state['os'] : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state['os'] ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state['showOperatingSystem'] ?
                                        <div className='relative'>
                                        <MultiSelectSection
                                            options={state.operatingSystemOptions ? state.operatingSystemOptions : []}
                                            selectedValues={state['os'] ? state['os'] : []}
                                            callbackMultiSelect={(value) => {
                                                if(!value || typeof(value) === 'string') {
                                                    setState(prevState => ({ ...prevState, ['os']: value }))
                                                } else {
                                                    value.preventDefault()
                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                }
                                            }}
                                            singleSelection={true}
                                            widthClass={'minWidth220'}
                                            removeTopOptions={true}
                                        />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <p className='b-block mb-0 flex'>
                                    Category <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                    {state.inputValidationError && !state['category'] ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {/* isDisabled={pageMode === 'Edit' ? true : false} */}
                                {pageMode === 'View' ?
                                    <p className='mb-0 text-black'>{state['category'] ? state['category'] : ''}</p>
                                : 
                                    <input
                                        type='text'
                                        placeholder='Enter category'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        // maxlength='128'
                                        value={state['category'] ? state['category'] : ''}
                                        onChange={e => handleInputChange('category', e.target.value)}
                                    />
                                }
                            </div>
                        </div>
                        
                        <div className='flex flex-wrap mt-2'>
                            <div className="'py-1 lg:w-5/12 md:w-1/2 w-full">
                                <div className="flex justify-content-between">
                                <label className="self-center m-0 text-black">Template</label>
                                {state.hasError && !state.template ? (
                                    <small className="text-issues">required</small>
                                ) : null}
                                </div>
                                <div className="text-black border-lightGray bg-transparent rounded-5"
                                    onClick={(event) => {
                                        if (!state.showTemplate) {
                                            event.preventDefault();
                                            handleChildClick(event,"child","singleDropDown","showTemplate");
                                        }
                                }}>
                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                    state.template ? "text-black" : "placeholder"
                                }`}>
                                    {state.template ? state.template : "Select"}
                                    {/* {props.pageMode === "Create" ? ( */}
                                    <Icon icon="icon-park-solid:down-one"  className={`${state.template ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                    {/* ) : null} */}
                                </p>
                                {state.showTemplate ? (
                                    <div className="relative">
                                        <MultiSelectSection
                                        options={ state.templateOptions ? state.templateOptions : []}
                                        selectedValues={state.template ? state.template : ""}
                                        callbackMultiSelect={(value) => {
                                            if (!value || typeof value === "string") {
                                                setState((prevState) => ({ ...prevState, template: value}));
                                            } else {
                                                value.preventDefault();
                                                handleChildClick(value, "search", "singleDropDown", "");
                                            }
                                        }}
                                        singleSelection={true}
                                        hideSearch={false}
                                        widthClass={"minWidth220"}
                                        removeTopOptions={true}
                                        />
                                    </div>
                                ) : null}
                                </div>
                            </div>
                            <div className="py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                            <div
                                onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, "child", "muliselectDropDown", "showInstanceTypes");
                                }}
                            >
                                <div className="flex flex-wrap">
                                    <p className="b-block mb-0">Allowed Instance Types</p>
                                </div>
                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.allowed_instance_types && state.allowed_instance_types.length ? 'text-black' : 'text-lightGray'}`}>
                                    {state.allowed_instance_types && state.allowed_instance_types.length ? state.allowed_instance_types.length+' Selected' : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${state.allowed_instance_types && state.allowed_instance_types.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.showInstanceTypes ? (
                                <div className="relative">
                                    <MultiSelectSection
                                    removeTopOptions={true}
                                    options={state.instanceTypes ? state.instanceTypes : []}
                                    selectedValues={state.allowed_instance_types ? state.allowed_instance_types : []}
                                    callbackMultiSelect={(value) => {
                                        setState((prevState) => ({ ...prevState, allowed_instance_types: value}));
                                    }}
                                    widthClass={"minWidth220"}
                                    />
                                </div>
                                ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                {state.allowed_instance_types && state.allowed_instance_types.length
                                ? state.allowed_instance_types.map((row) => {
                                    return (
                                        <span className="flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace">
                                        {row}
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'allowed_instance_types') }/>
                                        </span>
                                    );
                                    })
                                : null}
                            </div>
                            </div>
                        </div>

                        <div className='flex flex-wrap mt-2'>
                            <div className="'py-1 lg:w-5/12 md:w-1/2 w-full">
                                <div className="flex justify-content-between">
                                <label className="self-center m-0 text-black">VPC Id </label>
                                {state.hasError && !state.vpc_id ? (
                                    <small className="text-issues">required</small>
                                ) : null}
                                </div>
                                <div
                                className="text-black border-lightGray bg-transparent rounded-5"
                                onClick={(event) => {
                                    if (!state.showVpcId) {
                                    event.preventDefault();
                                    handleChildClick(event,"child","singleDropDown","showVpcId");
                                    }
                                }}
                                >
                                <p
                                    className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                    state.vpc_id ? "text-black" : "placeholder"
                                    }`}
                                >
                                    {state.vpc_id ? state.vpc_id : "Select"}
                                    {/* {props.pageMode === "Create" ? ( */}
                                    <Icon icon="icon-park-solid:down-one"  className={`${state.vpc_id ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                    {/* ) : null} */}
                                </p>
                                {state.showVpcId ? (
                                    <div className="relative">
                                        <MultiSelectSection
                                        options={ state.VPCIdOptions
                                            ? Object.keys(state.VPCIdOptions)
                                            : []}
                                        selectedValues={state.vpc_id ? state.vpc_id : ""}
                                        callbackMultiSelect={(value) => {
                                            if (!value || typeof value === "string") {
                                            setState((prevState) => ({ ...prevState, vpc_id: value}));
                                            } else {
                                            value.preventDefault();
                                            handleChildClick(value, "search", "singleDropDown", "");
                                            }
                                        }}
                                        singleSelection={true}
                                        hideSearch={false}
                                        widthClass={"minWidth220"}
                                        removeTopOptions={true}
                                        />
                                    </div>
                                ) : null}
                                </div>
                            </div>
                            <div className="py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                            <div
                                onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, "child", "muliselectDropDown", "showSubnetDropdown");
                                }}
                            >
                                <div className="flex flex-wrap">
                                    <p className="b-block mb-0">Subnet Ids</p>
                                </div>
                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.subnet_ids && state.subnet_ids.length ? 'text-black' : 'text-lightGray'}`}>
                                    {state.subnet_ids && state.subnet_ids.length ? state.subnet_ids.length+' Selected' : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${state.subnet_ids && state.subnet_ids.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.showSubnetDropdown ? (
                                <div className="relative">
                                    <MultiSelectSection
                                    removeTopOptions={true}
                                    options={state?.VPCIdOptions?.[state?.vpc_id] ? state?.VPCIdOptions?.[state?.vpc_id] : []}
                                    selectedValues={state.subnet_ids ? state.subnet_ids : []}
                                    callbackMultiSelect={(value) => {
                                        setState((prevState) => ({ ...prevState, subnet_ids: value}));
                                    }}
                                    widthClass={"minWidth220"}
                                    />
                                </div>
                                ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                {state.subnet_ids && state.subnet_ids.length
                                ? state.subnet_ids.map((row) => {
                                    return (
                                        <span className="flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace">
                                        {row}
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'subnet_ids') }/>
                                        </span>
                                    );
                                    })
                                : null}
                            </div>
                            </div>
                        </div>

                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <label className='self-center m-0 text-black'>Secret name</label>
                                    {state.hasError && !state.secret ?
                                        <small className='text-issues'>required</small>
                                    : null}
                                </div>
                                <div className='border-lightGray bg-transparent rounded-5'
                                    onClick={(event) => {
                                        if(!state.showSubnetId) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showSubnetId")
                                        }
                                    }}
                                >
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.secret && pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                                        {state.secret ? state.secret : 'Select'}
                                        {/* {pageMode === 'Create' ?  */}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.secret ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        {/* : null} */}
                                    </p>
                                    {state.showSubnetId && pageMode === 'Create' ?
                                        <MultiSelectSection
                                            options={state && state.secrets ? state.secrets : []}
                                            selectedValues={state.secret ? state.secret : ''}
                                            callbackMultiSelect={(value) => {
                                                if(!value || typeof(value) === 'string') {
                                                    setState(prevState => ({ ...prevState, secret: value }))
                                                    handleInputChange('secret', value)
                                                } else {
                                                    value.preventDefault()
                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                }
                                            }}
                                            singleSelection={true}
                                            removeTopOptions={true}
                                            hideSearch={false}
                                            topClass={'auto'}
                                            widthClass={'minWidth220'}
                                        />
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 lg:w-5/12 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <div className='flex justify-between'>
                                    <label className='self-center m-0 text-black'>Key pair name</label>
                                    {state.hasError && !state.key_pair_name ?
                                        <small className='text-issues'>required</small>
                                    : null}
                                </div>
                                <div className='border-lightGray bg-transparent rounded-5'
                                    onClick={(event) => {
                                        if(!state.showSubnetId) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showSubnetId")
                                        }
                                    }}
                                >
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.key_pair_name && pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                                        {state.key_pair_name ? state.key_pair_name : 'Select'}
                                        {/* {pageMode === 'Create' ?  */}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.key_pair_name ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        {/* : null} */}
                                    </p>
                                    {state.showSubnetId && pageMode === 'Create' ?
                                        <MultiSelectSection
                                            options={state && state.key_pair_name ? state.key_pair_name : []}
                                            selectedValues={state.key_pair_name ? state.key_pair_name : ''}
                                            callbackMultiSelect={(value) => {
                                                if(!value || typeof(value) === 'string') {
                                                    setState(prevState => ({ ...prevState, key_pair_name: value }))
                                                    handleInputChange('key_pair_name', value)
                                                } else {
                                                    value.preventDefault()
                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                }
                                            }}
                                            singleSelection={true}
                                            removeTopOptions={true}
                                            hideSearch={false}
                                            topClass={'auto'}
                                            widthClass={'minWidth220'}
                                        />
                                    : null}
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-5/12 md:w-1/2'>
                                <p className='b-block mb-0 flex'>
                                    Domain name
                                    {state.inputValidationError && !state['domain_name'] ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {/* isDisabled={pageMode === 'Edit' ? true : false} */}
                                {pageMode === 'View' ?
                                    <p className='mb-0 text-black'>{state['domain_name'] ? state['domain_name'] : ''}</p>
                                : 
                                    <input
                                        type='text'
                                        placeholder='Enter domain name'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        // maxlength='128'
                                        value={state['domain_name'] ? state['domain_name'] : ''}
                                        onChange={e => handleInputChange('domain_name', e.target.value)}
                                    />
                                }
                            </div>
                        </div>

                        {/* <div className='py-3 w-full'>
                            <p className='b-block mb-0'>Tags</p>
                            {pageMode !== 'View' ?
                                <div className='flex flex-wrap'>
                                    <div className='md:w-5/12 w-full'>
                                        <input
                                            type='text'
                                            placeholder='Enter Key'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full w-30`}
                                            // maxlength='128'
                                            value={state['tag_key'] ? state['tag_key'] : ''}
                                            onChange={e => handleInputChange('tag_key', e.target.value)}
                                        />
                                    </div>
                                    <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                        <input
                                            type='text'
                                            placeholder='Enter value'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['tag_value'] ? state['tag_value'] : ''}
                                            onChange={e => handleInputChange('tag_value', e.target.value)}
                                        />
                                    </div>
                                    <div className='py-1 md:w-1/12 w-full pl-3 self-center'>
                                        <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewTagValue()} />
                                    </div>
                                </div>
                            : null}
                            {state['selected_tags'] && state['selected_tags'].length ? 
                                <div className='flex flex-wrap'>
                                    {state['selected_tags'].filter(e => e.value !== 'All').map((tag, tIndex) => {
                                        return(
                                            <span key={'tag'+'_'+tIndex} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center f14 text-info px-2 breakWhiteSpace' id={'tag_'+tIndex}>
                                                <div className='truncate'  style={{maxWidth: '250px'}}>
                                                    {tag.key +' : '+tag.value}
                                                </div>
                                                {pageMode !== 'View' ?
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removeTagSection(tag, tIndex) } />
                                                : null}
                                            </span>
                                        )
                                    })}
                                </div>
                            : null}
                        </div> */}
                        <div className='py-3 w-full'>
                            <p className='b-block mb-0'>Tags</p>
                            <div className='flex flex-wrap mb-1'>
                                <div className='md:w-5/12 w-full'>
                                    <input
                                        type='text'
                                        placeholder='Enter key'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                        // maxlength='128'
                                        value={state.tag_key ? state.tag_key : ''}
                                        onChange={e => 
                                            setState(prevState => ({ ...prevState, tag_key: e.target.value 
                                        }))}
                                    />
                                </div>
                                <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                    <input
                                        type='text'
                                        placeholder='Enter value'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                        // maxlength='128'
                                        value={state.tag_value ? state.tag_value : ''}
                                        onChange={e => 
                                            setState(prevState => ({ ...prevState, tag_value: e.target.value 
                                        }))}
                                    />
                                </div>
                                <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 mt-0 cursor-pointer' onClick={() => addNewTagValue()}/>
                            </div>
                            {state.selected_tags && state.selected_tags.length ? 
                                <div className='flex flex-wrap'>
                                    {state.selected_tags.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                        return(
                                            <span key={'tag_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'tag_'+tIndex}>
                                                <div className='truncate'  style={{maxWidth: '250px'}}>
                                                    {tag.key +' : '+tag.value}
                                                </div>
                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                            </span>
                                        )
                                    })}
                                </div>
                            : null}
                        </div>

                        <div className='py-3 w-full'>
                            <p className='b-block mb-0'>Softwares Installed</p>
                            <div className='flex flex-wrap mb-1'>
                                <div className='md:w-5/12 w-full'>
                                    <input
                                        type='text'
                                        placeholder='Enter key'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                        // maxlength='128'
                                        value={state.softwares_installed_key ? state.softwares_installed_key : ''}
                                        onChange={e => 
                                            setState(prevState => ({ ...prevState, softwares_installed_key: e.target.value 
                                        }))}
                                    />
                                </div>
                                <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                    <input
                                        type='text'
                                        placeholder='Enter value'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black lg:w-full md:w-1/2`}
                                        // maxlength='128'
                                        value={state.softwares_installed_value ? state.softwares_installed_value : ''}
                                        onChange={e => 
                                            setState(prevState => ({ ...prevState, softwares_installed_value: e.target.value 
                                        }))}
                                    />
                                </div>
                                <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 mt-0 cursor-pointer' onClick={() => addNewSoftwaresIntalled()}/>
                            </div>
                            {state.softwares_installed && state.softwares_installed.length ? 
                                <div className='flex flex-wrap'>
                                    {state.softwares_installed.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                        return(
                                            <span key={'softwares_installed_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'softwares_installed_'+tIndex}>
                                                <div className='truncate'  style={{maxWidth: '250px'}}>
                                                    {tag.key +' : '+tag.value}
                                                </div>
                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={() => removeSoftwaresIntalled(tag)}/>
                                            </span>
                                        )
                                    })}
                                </div>
                            : null}
                        </div>

                        {/* <div className='py-3 w-full'>
                            <p className='b-block mb-0'>Softwares Installed</p>
                            {pageMode !== 'View' ?
                                <div className='flex flex-wrap'>
                                    <div className='md:w-5/12 w-full'>
                                        <input
                                            type='text'
                                            placeholder='Enter Key'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full w-30`}
                                            // maxlength='128'
                                            value={state['software_installed_key'] ? state['software_installed_key'] : ''}
                                            onChange={e => handleInputChange('software_installed_key', e.target.value)}
                                        />
                                    </div>
                                    <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                        <input
                                            type='text'
                                            placeholder='Enter value'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                            // maxlength='128'
                                            value={state['software_installed_value'] ? state['software_installed_value'] : ''}
                                            onChange={e => handleInputChange('software_installed_value', e.target.value)}
                                        />
                                    </div>
                                    <div className='py-1 md:w-1/12 w-full pl-3 self-center'>
                                        <Icon icon="iconoir:plus" width="25" height="25"  className='text-black ml-3 cursor-pointer' onClick={() => addNewTagValue()} />
                                    </div>
                                </div>
                            : null}
                            {state['selected_tags'] && state['selected_tags'].length ? 
                                <div className='flex flex-wrap'>
                                    {state['selected_tags'].filter(e => e.value !== 'All').map((tag, tIndex) => {
                                        return(
                                            <span key={'tag'+'_'+tIndex} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center f14 text-info px-2 breakWhiteSpace' id={'tag_'+tIndex}>
                                                <div className='truncate'  style={{maxWidth: '250px'}}>
                                                    {tag.key +' : '+tag.value}
                                                </div>
                                                {pageMode !== 'View' ?
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-black ml-2 self-center cursor-pointer' onClick={ () => removeTagSection(tag, tIndex) } />
                                                : null}
                                            </span>
                                        )
                                    })}
                                </div>
                            : null}
                        </div> */}
                    </div>

                    <div className={`flex flex-wrap mb-2 mt-4 justify-end`}>
                        <div className='py-1 self-center mr-3'>
                            <label className="mb-0">
                                <input type="checkbox" 
                                    className="mt-1" 
                                    checked={state.force_update ? true : false}
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, force_update: !state.force_update }))
                                    }}
                                />
                                <span className="slider round small pl-1"></span>
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2">Force update: Edit the cluster while the compute fleet is still running</span>
                        </div>
                        {pageMode !== 'View' ?
                            state.saveLoading ?
                                <button className='btn bg-HalloweenOrange px-2 py-1 self-center rounded-md'>
                                    {pageMode === 'Edit' ? 'Updating' : 'Saving'}<Spinner size='sm' color='light' className='ml-2' />
                                </button>
                            : 
                                <button className={`flex justify-end cursor-pointer btn bg-HalloweenOrange text-white px-2 py-1 rounded-md`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>{pageMode === 'Edit' ? 'Update' : 'Save'}</button>
                        : null}
                        <button className={`ml-2 flex justify-end cursor-pointer btn bg-lightGray text-white px-2 py-1 rounded-md`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateQueuePanel