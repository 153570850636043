/*************************************************
 * Collider
 * @exports
 * @file urlPath.js
 * @author Rajasekar // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/

const apiUrl = process.env;
let subPath = ''
if(apiUrl && apiUrl.REACT_APP_UI_SUBPATH) {
	subPath = apiUrl.REACT_APP_UI_SUBPATH
}

export const URL_PATH = {	
	
	ERROR_PAGE: subPath+'/error',
	LOADING_PAGE: subPath+'/authorize',
	PERMISSION_DENINED: subPath+'/permission/denied',
	LIFECYCLE_NOTIFICATIONS: subPath+'/lifecycle-notification',
	
	JOB_STATUS: subPath+'/hpc/jobstatus',
	FILES: subPath+'/hpc/files',
	HPC_DASHBOARD: subPath+'/hpc/dashboard',
	HPC_SPENDBOARD: subPath+'/hpc/spendboard',
	HPC_STATUS: subPath+'/hpc/status',
	HPC_LICENSES: subPath+'/hpc/licenses',
	CATALOG_LANDINGPAGE: subPath+'/hpc/workstation',
	WORKSTATION_REQUEST_LANDINGPAGE: subPath+'/hpc/request',
	CLUSTER_DETAILS: subPath+'/hpc/cluster',
	CREATE_CLUSTER: subPath+'/hpc/cluster/create',	
	QUEUE_DETAILS: subPath+'/hpc/queue',
	OBSERVABILITY: subPath+'/hpc/observability',
	MANAGE_QOS: subPath+'/hpc/qos',
	CREATE_QOS: subPath+'/hpc/qos/create',
	CREATE_GROUP: subPath+'/hpc/group/create',
	EDIT_QOS: subPath+'/hpc/qos/edit',
	HPC_ALERTS: subPath+'/hpc/alerts',
	HPC_REPORTS: subPath+'/hpc/reports',
	HPC_BUDGET: subPath+'/hpc/budget',
	HPC_CREATE_BUDGET: subPath+'/hpc/budget/create',
	
	ADMIN_USERS: subPath+'/admin/users',
	ADMIN_ACCOUNTS: subPath+'/admin/accounts',
	ADMIN_DEPARTMENT_MAPPING: subPath+'/admin/departments',
	ADMIN_DEPARTMENT_MAPPING_CREATE: subPath+'/admin/departments/create',
	ADMIN_SETTINGS: 'admin/settings',

	GOVERNANCE_DASHBOARD: subPath+'/governance/dashboard',
	GOVERNANCE_MANAGE_RULES: subPath+'/governance/rules',
	GOVERNANCE_CREATE_NEW_RULE: subPath+'/governance/rule/create',
	GOVERNANCE_EDIT_NEW_RULE: subPath+'/governance/rule/edit',

	RESOURCES_INVENTORY: subPath+'/resources/inventory',
	RESOURCES_LIFECYCLE: subPath+'/resources/lifecycle',
	RESOURCES_QUERIES: subPath+'/resources/queries',
	RESOURCES_COMPARISON: subPath+'/resources/compare/assets',
	RESOURCES_SEARCH: subPath+'/resources/search',

	COST_BUDGET_DASHBOARD: subPath+'/cost/budget/dashboard',
	COST_BUDGET_LIST: subPath+'/cost/budget',
	COST_BUDGET_CREATE: subPath+'/cost/budget/create',
	COST_EXPLORER: subPath+'/cost/explorer',
	COST_QUERIES: subPath+'/cost/queries',

	// COST_RECOMMENDATION: subPath+'/cost/recommendations',
	// COST_POWER_TUNING: subPath+'/cost/powertuning',
	// COST_ANOMALIES: subPath+'/cost/anomalies',
	// COST_COMPARISON: subPath+'/cost/comparison',


	IMAGE_BUILDER_DASHBOARD: subPath+'/imagebuilder/image',
	IMAGE_BUILDER_AUDIT: subPath+'/imagebuilder/audit'
}
