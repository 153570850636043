import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadUserFiles
} from "../../../../actions/files/FileAction";

const FileUploadModal = ({ isOpen, toggle, setModalIsOpen, handleFileBrowserRefresh, state, getLastObjectFromBreadcrumb }) => {

  const fileInputRef = useRef()
  const dispatch = useDispatch();
  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // const closeBtn = (
  //   <button className="close" onClick={toggle} type="button">
  //     <i className="fa fa-times" aria-hidden="true"></i>
  //   </button>
  // );

  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();

    if (selectedFiles.length > 0) {
      setUploadProgress(true)
      let currentPath = currentLocation.path ? currentLocation.path : ''
      // if(currentLocation.owner) {
      //   params.owner = currentLocation.owner
      // }
      formData.append('destination_path', currentPath);
      formData.append('provider', state.selectedProvider ? state.selectedProvider.toLowerCase() : '');
      formData.append('account_id', state.selectedAccount);
      formData.append('cluster_name', state.selectedClusters);
      formData.append('region', state.selectedRegion);

      selectedFiles.forEach((file) => {
        formData.append('files[]', file);
      });

      dispatch(uploadUserFiles(formData))
      .then((response) => {
        if(response.status) {
          setSelectedFiles([])
          setModalIsOpen(!isOpen)
          handleFileBrowserRefresh()
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        } else {
          setSelectedFiles([])
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          setErrorMessage(response.message)
        }
        setUploadProgress(false)
      })
    }
  };

  return (
    <div id="popup-modal" tabIndex="-1" className={`flex ${isOpen ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
      <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => {
                setErrorMessage(false)
                setSelectedFiles([])
                setUploadProgress(false)
                setModalIsOpen(!isOpen)
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">File Upload</h3>

                    {errorMessage ?
                      <React.Fragment>
                        <p className='text-lg text-danger'>Error</p>
                        <p className='mb-2 text-black'>{errorMessage}</p>
                      </React.Fragment>
                    : null}
                    <React.Fragment>
                    <input type="file" onChange={handleFileChange} multiple={true} ref={fileInputRef} />
                    {selectedFiles.length > 0 && (
                      <div className="mt-3">
                        <p>Selected Files:</p>
                        <ul>
                          {selectedFiles.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    </React.Fragment>

                    <div className='flex justify-center mt-4'>
                      <button data-modal-hide="popup-modal" type="button" className="py-0.5 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                        onClick={handleFileUpload}
                      >
                        {uploadProgress ?
                          <div className='flex'>
                            <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                            Uploading
                          </div>
                        :
                          'Upload File'
                        }
                      </button>
                      <button data-modal-hide="popup-modal" type="button" className="py-0.5 px-2 rounded-md bg-lightGray text-white" 
                        onClick={() => {
                          setErrorMessage(false)
                          setSelectedFiles([])
                          setUploadProgress(false)
                          setModalIsOpen(!isOpen)
                          if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                          }
                        }}
                      >
                          Cancel
                      </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default FileUploadModal;