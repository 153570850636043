/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useLocation, useNavigate } from 'react-router-dom'
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { URL_PATH } from '../../../../config/urlPath';

const CreateNewBudget = () => {

    const [state, setState] = useState({
    })
    
    const location = useLocation();
    const receivedData = location.state;
    
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const onClickSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickSave || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return() => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setHpcPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    useEffect(() => {
        if(receivedData && Object.entries(receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedData: receivedData.selectedData ? receivedData.selectedData : '',
                pageType: receivedData.pageType ? receivedData.pageType : '',
            }))
        }
    }, [receivedData])

    const navigateTo = () => {
        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
    }

    return (
        <div className='flex flex-wrap'>
            <div className={`Gray-right lg:w-1/2 md:full w-full`}>
                <LeftSection 
                    receivedData={receivedData}
                />
            </div>
            <div className={`lg:w-1/2 md:full w-full lg:pl-3 pl-0`}>
                <RightSection 
                    receivedData={receivedData}
                />
            </div>
            <div className='w-full flex justify-end bg-transparent mb-2 pr-3'>
            {/* <div className='fixed bottom-4 right-4 justify-end bg-transparent'> */}
                {state.onSaveFunction ?
                    <button className={`flex bg-HalloweenOrange px-2 py-1 rounded-md mr-2 texw-thie`}>
                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                        {state.pageType !== "edit" ?  "Saving"  : "Updating"}
                    </button>
                :
                    <button className={`bg-HalloweenOrange px-2 py-1 rounded-md mr-2 text-white`} onClick={() => {
                        setState(prevState => ({ ...prevState, onSaveFunction: true }))
                        dispatch(setHpcPropsDetails('onClickSave', 'save'))
                    }}>
                        {state.pageType !== "edit" ?  "Save"  : "Update"}
                    </button>
                
                }
                <button className={`bg-lightGray px-2 py-1 rounded-md text-white ${state.onSaveFunction ? "disbaled" : ""}`} onClick={() => navigateTo()}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateNewBudget