import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { Store as CommonNotification } from 'react-5notifications-component';
import { capitalizeFirstLetter, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, subDays, subHours } from '../../../utils/utility';
import { getWorkStationMetrics, listStackEvents } from '../../../actions/CatalogAction';
import {Icon} from "@iconify/react";
import LogsTab from './LogsTab';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import ReportsTab from './ReportsTab';
import NotificationsTab from './NotificationsTab';

const DetailsSidePanel = ({ detailsFormat, selectedDetails, closeSidePanel, fromPage }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        selectedTab: "Details",
        logsList: [],
        filteredLogsList: [],

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: "2h"
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])

    useEffect(() => {
        setState(prevState => ({ ...prevState, instance_type : selectedDetails.instance_type }))
        if(selectedDetails && selectedDetails.volume_details && Array.isArray(selectedDetails.volume_details)) {
            selectedDetails.volume_details.forEach(row => {
                setState(prevState => ({ ...prevState, ["volume_size_"+row.volume_id] : row.volume_size }))
            })
        }
    }, [selectedDetails])

    useEffect(() => {
        if (state.selectedTab === "Monitoring") {
            let duration = state.selectedGraphDuration

            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            let startTime = '';
            if (period === 'd') {
                startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            params.duration = duration
            params.asset_id = selectedDetails && selectedDetails.asset_id

            // Fetch job metrics data here using params and update the state
            if(params.asset_id) {
                dispatch(getWorkStationMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                startTime,
                                endTime
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    metricDetails: [],
                    showMetricLoading: false,
                    startTime,
                    endTime
                }))
            }
        } else if (state.selectedTab === "Events") {
            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            // params.duration = "2h"
            params.workstation_id = selectedDetails && selectedDetails.workstation_id
            // Fetch job metrics data here using params and update the state
            if(params.workstation_id) {
                dispatch(listStackEvents(params))
                    .then((response) => {
                        if(response) {
                            let results = response.results ? response.results : []
                            setState(prevState => ({
                                ...prevState,
                                listEvents: results,
                                filteredListEvents: results,
                                showEventsLoading: false,
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    listEvents: [],
                    filteredListEvents: [],
                    showEventsLoading: false,
                }))
            }
        }
    }, [state.selectedTab, dispatch, selectedDetails, state.selectedGraphDuration])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }
    
    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-4/5 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='w-full'>
                        <div className='flex flex-wrap'>
                            <div className='text-black lg:w-1/4 w-full self-center'>
                                <span className='font-bold text-xl text-black'>{capitalizeFirstLetter(fromPage)} Details</span>
                            </div>
                            <div className='w-full lg:w-3/4 text-md'>
                                <div className='flex flex-wrap'>
                                    {selectedDetails && selectedDetails.image_name ?
                                        <div className="w-full">
                                            <p className='mb-1 text-lightGray min-w-[300px]'>Image&nbsp;name<span className='text-info ml-2'>{selectedDetails.image_name}</span></p>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className="flex flex-wrap text-sm font-bold text-center">
                        <li className="me-2">
                            <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                        </li>
                        {fromPage !== 'Plan' ?
                            <React.Fragment>
                            <li className="me-2">
                                <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Reports' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Reports' }))}>Reports</span>
                            </li>
                            <li className="me-2">
                                <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Logs' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Logs' }))}>Logs</span>
                            </li>
                            </React.Fragment>
                        : null}
                        {fromPage === 'Image' ?
                            <li className="me-2">
                                <span className={`inline-block py-2 px-4 text-black rounded-t-lg ${state.selectedTab === 'Notifications' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Notifications' }))}>Notifications</span>
                            </li>
                        : null}
                    </ul>
                </div>
                {state.selectedTab === "Details" ?
                    <div className='m-2 overflow-auto'>
                        <div className="rounded bg-white p-3">
                            <React.Fragment>
                            {detailsFormat && detailsFormat.length ? 
                                detailsFormat.map((row, index) => {
                                    return(
                                        <div className={`${!index ? '' : 'mt-2'}`} key={'key_'+index}>
                                            <p className={`mb-1 text-info text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                            <small className='text-lightGray'>{row.description}</small>
                                            {row.fields && row.fields.map(fld => {
                                                return(
                                                    <div className="flex mt-3" key={fld}>
                                                        <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(fld)}</span>
                                                        {selectedDetails && selectedDetails[fld] ?
                                                            <React.Fragment>
                                                            {typeof selectedDetails[fld] === 'boolean' ?
                                                                <span className={`text-black`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedDetails[fld]) ?
                                                                <span className="text-black">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`self-center border border-${selectedDetails[fld] ? ((selectedDetails[fld].toLowerCase() === 'successful' || selectedDetails[fld].toLowerCase() === 'available') ? 'green' : selectedDetails[fld].toLowerCase() === 'in progress' ? 'warning' : 'danger') : 'ligthGray'} rounded-full text-${selectedDetails[fld] ? ((selectedDetails[fld].toLowerCase() === 'successful' || selectedDetails[fld].toLowerCase() === 'available') ? 'green' : selectedDetails[fld].toLowerCase() === 'in progress' ? 'warning' : 'danger'): 'white'} px-2 py-1`}>{selectedDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div className="flex flex-wrap gap-1">
                                                                    {selectedDetails[fld].map((item, itIndex) => {
                                                                        return(
                                                                            <span key={'item_tag_'+itIndex} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : Array.isArray(selectedDetails[fld]) ?
                                                                <div className='w-full mr-2'>
                                                                    <div className="flex flex-wrap">
                                                                        {selectedDetails[fld].map((item, itIndex) => {
                                                                            return(
                                                                                <React.Fragment key={"tak_key"+item.key}>
                                                                                {typeof item === 'string' ?
                                                                                    <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                : typeof item === 'object' ?
                                                                                    <div className='p-3 bg-GhostWhite rounded-md w-full mr-2 mt-2'>
                                                                                        {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                            return(
                                                                                                <React.Fragment key={itIndex+'_key_'+iiIndex}>
                                                                                                {typeof iValue === 'boolean' ?
                                                                                                    <p  className='mr-2 mb-1 text-sm mt-3'>{iKey} <span className='text-black break-all ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                                :
                                                                                                    <p className='mr-2 mb-1 text-sm'>{iKey}: <span className='text-black break-all ml-3'>{iValue}</span></p>
                                                                                                }
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : null}
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            :
                                                                <span className="text-black">{fld === 'provider' ? selectedDetails[fld].toUpperCase() : selectedDetails[fld]}</span>
                                                                // <span className="text-black">{selectedDetails[fld]}</span>
                                                            }
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })
                            : null}
                            </React.Fragment>
                        </div>
                    </div>
                : state.selectedTab === "Logs" ?
                    <LogsTab
                        selectedDetails={selectedDetails}
                    />
                : state.selectedTab === "Reports" ?
                    <ReportsTab
                        selectedDetails={selectedDetails}
                        fromPage={fromPage}
                    />
                : state.selectedTab === "Notifications" ?
                    <NotificationsTab
                        selectedDetails={selectedDetails}
                    />
                : null}
            </div>
        </div>
    );
};

export default DetailsSidePanel;