/*************************************************
 * Collider
 * @exports
 * @file ProjectFieldDetails.js
 * @author Prakash // on 23/03/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';

export const ProjectFieldDetails = ({ detailsFormat, selectedDetails, selectedType }) => {
    return (
        <div className='m-2 flex-grow-1 overflow-auto'>
            <div className="rounded-md bg-white p-3">
                {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                    return(
                        <div className={`${!index ? '' : 'mt-2'}`}>
                            <p className={`mb-1 text-inf text-lg pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                            <small className='text-lightGray'>{row.description}</small>
                            {row.fields && row.fields.map(fld => {
                                return(
                                    <React.Fragment>
                                        {fld === "queue_configuration" ?
                                            selectedDetails[fld] && selectedDetails[fld].map((item, iIndex) => {
                                                return(
                                                <div className={`rounded-md bg-GhostWhite p-2 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Subnet IDs</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.subnet_ids ?
                                                                item.subnet_ids.map(ids => {
                                                                    return(
                                                                        <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Security Group IDs</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.security_group_ids ?
                                                                item.security_group_ids.map(ids => {
                                                                    return(
                                                                        <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Policies</span>
                                                        <div className="flex flex-wrap gap-2">
                                                            {item && item.policies ?
                                                                item.policies.map(pol => {
                                                                    return(
                                                                        <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{pol}</span>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Script Location</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.script_location ? item.script_location : ""}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Volume Details</p>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Type</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Size</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_size && item.volume_size}</span>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Encryption</span>
                                                        <span className={`self-center text-black`}>{item && item.volume_encryption ? "True" : "False"}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>S3 Access</p>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Bucket</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.bucket_name ? item.bucket_name : ""}</span>
                                                    </div>
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray mr-3 self-center">Write Access</span>
                                                        <span className={`self-center text-black break-all`}>{item && item.enable_s3_write_access ? "True" : "False"}</span>
                                                    </div>

                                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-b border-lightGray`}>Compute Resources</p>
                                                    {item && item.compute_resources ?
                                                        item.compute_resources.map(comp => {
                                                            return (
                                                                <div className='bg-dark p-2 rounded-md mb-2'>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Name</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Instance Type</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Min Instance Count</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Max Instance Count</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Efa Enabled</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Placement Group Enabled</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                </div>
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">Disable Simultaneous Multithreading</span>
                                                                    <span className={`self-center text-black break-all`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                </div>
                                                                </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                                )
                                            })
                                        : fld === "storage_configuration" ?
                                            selectedDetails[fld] && selectedDetails[fld].map(storage => {
                                                return (
                                                    Object.entries(storage).length ?
                                                        Object.entries(storage).map(([key, value]) => {
                                                            return(
                                                                <div className="flex mt-3">
                                                                    <span className="minWidth220 text-lightGray mr-3 self-center">{removeUnderScore(key)}</span>
                                                                    {typeof value === 'boolean' ?
                                                                        <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                    : isValidDateTime(value) ?
                                                                        <span className="text-black break-all">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                    : convertToLowerCase(key) === "status" ?
                                                                        <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                    : convertToLowerCase(key) === "tags" ?
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {value.map(item => {
                                                                                return(
                                                                                    <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    : Array.isArray(value) ?
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {value.map(item => {
                                                                                return(
                                                                                    <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    : 
                                                                        <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    : null
                                                )
                                            })
                                        : fld === "head_node" || fld === "cluster_configuration" || fld === "database_configuration" ?
                                            selectedDetails[fld] && Object.entries(selectedDetails[fld]).length ?
                                                Object.entries(selectedDetails[fld]).map(([key, value]) => {
                                                    return(
                                                        <div className="flex mt-3">
                                                            <span className="minWidth220 text-lightGray mr-3 self-center">{removeUnderScore(key)}</span>
                                                            {typeof value === 'boolean' ?
                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                            : isValidDateTime(value) ?
                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(key) === "status" ?
                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                            : convertToLowerCase(key) === "tags" ?
                                                                <div className="flex flex-wrap gap-2">
                                                                    {value.map(item => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : Array.isArray(value) ?
                                                                <div className='w-full mr-2'>
                                                                    <div className="flex flex-wrap">
                                                                        {value.map((item, itIndex) => {
                                                                            return(
                                                                                typeof item === 'string' ?
                                                                                    <span key={"tak_key"+item.key} className='bg-info rounded-full px-2 py-1 text-black newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                                : typeof item === 'object' ?
                                                                                    <div className='p-3 bg-dark rounded-md w-full mr-2 mt-2'>
                                                                                        {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                            return(
                                                                                                typeof iValue === 'boolean'?
                                                                                                    <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 text-sm mt-3'>{iKey} <span className='text-black ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                                :
                                                                                                    <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 text-sm'>{iKey}: <span className='text-black ml-3'>{iValue}</span></p>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : null
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            : Array.isArray(value) ?
                                                                <div className="flex flex-wrap gap-2">
                                                                    {value.map(item => {
                                                                        return(
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-black break-all">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            : null
                                        : selectedDetails && selectedDetails[fld] && fld !== 'head_node' && fld !== "cluster_configuration" && fld !== "database_configuration" && fld !== "queue_configuration" && fld !== "storage_configuration" ?
                                            <div className="flex mt-3">
                                                <span className="minWidth220 text-lightGray mr-3 self-center">{removeUnderScore(fld)}</span>
                                                {typeof selectedDetails[fld] === 'boolean' ?
                                                    <span className={`text-black`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                : isValidDateTime(selectedDetails[fld]) ?
                                                    <span className="text-black">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                : convertToLowerCase(fld) === "status" ?
                                                    <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{selectedDetails[fld]}</span>
                                                : convertToLowerCase(fld) === "tags" ?
                                                    <div className="flex flex-wrap gap-2">
                                                        {selectedDetails[fld].map(item => {
                                                            return(
                                                                <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                            )
                                                        })}
                                                    </div>
                                                : 
                                                    <span className="text-black break-all">{fld === 'provider' ? selectedDetails[fld].toUpperCase() : selectedDetails[fld]}</span>
                                                }
                                            </div>
                                        : null}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}