import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import ExistingDatabaseSection from "./ExistingDatabaseSection";
import NewDatabaseSection from "./NewDatabaseSection";

const DatabaseSection = (props) => {
  const [state, setState] = useState({});
  const createUpdateCluster = useSelector((state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false);

  const handleDatabaseOptionChange = (e) => {
    if (window.confirm(
          "If you change the database option, the values already entered in the database fields will be lost. Are you sure you want to proceed ?"
        )
      ) {
        setState((prevState) => ({ ...prevState, databaseOption: e.target.value }));
        let obj = createUpdateCluster ? createUpdateCluster : {};
        obj["database_configuration"] = {};
      }
  };

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};
    if (!state.databaseOption) {
      setState((prevState) => ({ ...prevState, databaseOption: Object.keys(databaseConfiguration).includes("vpc_id") ? "new" : "existing" }));
    }
  }, [createUpdateCluster, state.databaseOption]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };


    return (
        <div className='rounded bg-GhostWhite p-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className="p-3 mb-3">
              <p className="text-black text-base mt-3">Database Options</p>
              <div className="flex justify-start">
                <div className="py-1 px-3 w-40 self-center">
                  <div className="flex mr-2">
                    <label className="mb-0 ml-4 flex items-center cursor-pointer">
                      <input
                        className="form-check-input mt-1"
                        type="radio"
                        name="databaseOption"
                        id="databaseOption"
                        value="existing"
                        checked={state.databaseOption === "existing"}
                        onChange={handleDatabaseOptionChange}
                      />
                      <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                        Use Existing
                      </span>
                    </label>
                  </div>
                </div>
                <div className="py-1 px-3 w-40 self-center">
                  <div className="flex mr-2">
                    <label className="mb-0 ml-4 flex items-center cursor-pointer">
                      <input
                        className="form-check-input mt-1"
                        type="radio"
                        name="databaseOption"
                        id="databaseOption"
                        value="new"
                        checked={state.databaseOption === "new"}
                        onChange={handleDatabaseOptionChange}
                      />
                      <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                        Create New
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {state.databaseOption === "existing" ?
                <ExistingDatabaseSection pageMode={props.pageMode} />
              :
                <NewDatabaseSection pageMode={props.pageMode} />
              }
            </div>
        </div>
    )
}


export default DatabaseSection