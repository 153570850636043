import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImageFilters, createSchedule, listImages, getTriggerDates } from '../../../actions/ImagebuilderAction'
import { listUsers } from '../../../actions/commonActionNew'
 import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import MultiSelectSection from '../../common/MultiSelectSection';
import { COST_DURATION_OPTIONS } from '../../../utils/constants';
import { validateEmail } from '../../../utils/utility';

const ScheduleReport = ({ selectedDetails, closeSidePanel, selectedProvider, pageMode }) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        callListPackages: true,
        existingRecord: false,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
        durations: ['Monthly', 'Yearly'],
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			// {label: "Quarterly", value: "quarterly"},
			// {label: "Half Yearly", value: "half-yearly"}
		],
        selectedTab: 'Create',
        filteredArray: [
            {durtaion: 'Monthly', emails: ['text@user.com', 'rest@user.com'], frequency: 'Weekly', cron: '01**'},
            {durtaion: 'Monthly', emails: ['text@user.com'], frequency: 'Weekly', cron: '02**'}
        ]
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const usersList = useSelector(state => state?.filters?.usersList)
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000); 
        }
    }, [state.showLoading])

    useEffect(() => {
        let params = {};
        if(!usersList || !usersList.length) {
            dispatch(listUsers(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, usersList: response, userLoader: false }));
                    }
                })
        } else {
            setState(prevState => ({ ...prevState, usersList: usersList }));
        }
    }, [dispatch])

    useEffect(() => {
        if(selectedDetails && Object.entries(selectedDetails).length) {
            if(selectedDetails.schedule_expression) {
                let split = selectedDetails.schedule_expression.split(' ')
                setState(prevState => ({ ...prevState, 
                    minutes: split[0],
                    hours: split[1],
                    day_of_month: split[2],
                    month: split[3],
                    day_of_week: split[4],
                    // year: split[5]
                }))
            }
            setState(prevState => ({ ...prevState, selectedProvider: selectedDetails.provider, selectedAccount: selectedDetails.account_id, selectedRegion: selectedDetails.region, selectedOs: selectedDetails.os, schedule_expression: selectedDetails.schedule_expression, active: selectedDetails.active ? false : true, existingRecord: true }))
        }
    }, [selectedDetails])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            let aggregate_by = []
            if(state.selectedProvider) {
                params.provider = state.selectedProvider
    
                aggregate_by = ['image_name']
                if(state.selectedProvider !== 'docker') {
                    aggregate_by = ['account_id', 'region', 'os']
                }
            }
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = aggregate_by
            dispatch(getImageFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, filterDetails: response, imageLoader: false }))
                    }
                })
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, dispatch]);

    useEffect(() => {
        let params = {};
        params.aggregate_by = ['provider']
        dispatch(getImageFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, providerFilterDetails: response, imageLoader: false }))
                }
            })
    }, [state.selectedProvider, dispatch]);

    useEffect(() => {
        if(state.selectedProvider && (!selectedDetails || (selectedDetails && !Object.entries(selectedDetails).length))) {
            let params = {}
            if(state.selectedProvider) {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedOs) {
                params[state.selectedProvider === 'docker' ? 'image_name' : 'os'] = state.selectedOs
            }
            params.size = state.perPage
    
            dispatch(listImages(params))
                .then((response) => {
                    if(response) {
                        let totalResults = response && response.results ? response.results : []
    
                        setState(prevState => ({ ...prevState, imagesList: totalResults, filterListImages: totalResults, totalRecords: response.total ? response.total : 0, showLoading: false }));
                    }
                })
        }
    }, [selectedDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedImage, state.selectedOs, state.perPage, dispatch]);
	
	// const handleInputChange = (label, value) => {
    //     if(label === 'packages') {
    //         let selectedPackages = state.selectedPackages ? state.selectedPackages : []

    //         // if(selectedPackages.filter(e => e === value).length) {
    //         //     selectedPackages = selectedPackages.filter(e => e !== value)
    //         // } else {
    //         //     selectedPackages.push(value)
    //         // }

    //         // label = 'selectedPackages'
    //         // value = selectedPackages

    //         selectedPackages.forEach(pac => {
    //             setState(prevState => ({ ...prevState, [pac]: true }))
    //         })
    //     }
    //     setState(prevState => ({ ...prevState, [label]: value }))
	// }

    const handleSchedule = (value) => {
        // Remove non-digit characters
        const cleaned = value.replace(/\D/g, '');

        // Format to DD/MM/YYYY
        const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

        if (match) {
        const formattedDate = [match[1], match[2], match[3]]
            .filter(Boolean) // Remove empty strings
            .join(':'); // Join parts with "/"
        return formattedDate;
        }

        return value;
    };

    const handleChange = (e, label) => {
      const inputValue = e.target.value;
      const formattedValue = handleSchedule(inputValue);
      setState(prevState => ({ ...prevState, [label]: formattedValue }))
    };

    useEffect(() => {
        if(state.minutes && state.hours && state.day_of_month && state.month && state.day_of_week) {
            let params = {
                schedule_expression: state.minutes +' '+ state.hours +' '+ state.day_of_month +' '+ state.month +' '+ state.day_of_week
            }

            dispatch(getTriggerDates(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, nextSchedules: response.results ? response.results : [] }))
                })
        }
    }, [dispatch, state.minutes, state.hours, state.day_of_month, state.month, state.day_of_week])

    useEffect(() => {
        if(state.saveFunction) {
            setState(prevState => ({ ...prevState, saveFunction: false }))
            let hasError = false
            let params = {}
            if(selectedDetails && Object.entries(selectedDetails).length) {
                params = selectedDetails
            } else {
                params.provider = state.selectedProvider
                if(state.selectedAccount) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion) {
                    params.region = state.selectedRegion
                }
                if(state.selectedOs) {
                    params[state.selectedProvider === 'docker' ? 'image_name' : 'os'] = state.selectedOs
                }
                params.image_id = state.selectedImage

                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedImage || !state.selectedImage.length) {
                    hasError = true
                }
            }

            let schedule_expression = ''
            if(!state.minutes || !state.hours || !state.day_of_month || !state.month || !state.day_of_week) {
                hasError = true
            } else {
                schedule_expression = state.minutes +' '+ state.hours +' '+ state.day_of_month +' '+ state.month +' '+ state.day_of_week
            }

            params.schedule_expression = schedule_expression
    
            setState(prevState => ({ ...prevState, schedule_expression, hasError, saveLoading: hasError ? false : true }))

            
            params.active = state.active ? false : true
            if(pageMode === 'Edit') {
                params.action = 'update'
            }

            if(!hasError) {
                dispatch(createSchedule(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in scheduling image'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Image scheduling successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel("refresh"), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false }))
                        }
                    })
    
            }
        }
    },  [state.saveFunction, closeSidePanel, dispatch, selectedDetails, pageMode, state])

	const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const addEmails = () => {
        setState((prevState) => ({ ...prevState, emailValidationFaild: false}))

        if(validateEmail(state.emails)) {
            let userEmails = state.userEmails ? state.userEmails : [];
            userEmails.push(state.emails);
    
            setState((prevState) => ({ ...prevState, userEmails, emails: "" }));
        } else {
            setState((prevState) => ({ ...prevState, emailValidationFaild: true}))
        }
    }
    
    const removeEmails = (value) => {
        let policies = state.userEmails.filter((e) => e !== value);
    
        setState((prevState) => ({ ...prevState, userEmails: policies }));
      };

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            {/* <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div> */}
            <div className='bg-GhostWhite w-4/5 overflow-auto' ref={clickOut}>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black'>
                        <p className='text-xl text-black'>Schedule Report</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className="flex flex-wrap text-sm font-bold text-center">
                        <li className="me-2">
                            <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Create' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Create', showcreateSchedukeLoading: true }))}>Create</span>
                        </li>
                        <li className="me-2">
                            <span className={`inline-block mb-0 text-xs px-4 text-SlateBlue rounded-t-lg ${state.selectedTab === 'Schedules' ? ' active border-b-2 border-SlateBlue' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Schedules' }))}>Schedules</span>
                        </li>
                    </ul>
                </div>
                {state.selectedTab === 'Create' ?
                    <div className='m-2'>
                        <div className='rounded bg-white p-3'>
                            <div className='flex flex-wrap'>
                                <div className='md:w-1/3 w-full mt-2 pr-2'>
                                    <div className='flex'>
                                        <label className='self-center m-0 text-black'>Duration</label>
                                        {state.hasError && !state.selectedDuration ?
                                            <span className='text-danger self-center'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'showDuration')
                                    }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate w-2/3 pl-3 ${state.selectedDuration ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedDuration ? state.selectedDuration : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedDuration  ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.showDuration ?
                                            <MultiSelectSection
                                                options={state.durations}
                                                selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedDuration: value, selectedRegion: '', selectedAccount: '', selectedOs: '', selectedImage: [] }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}  
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                                makeLabelUpperCase={'capitalizeAllLetter'}
                                            />
                                        : null}
                                    </div>
                                </div>
                                {/* <div className='w-full'>
                                    <div className='py-1 lg:w-1/2 md:w-1 w-full'>
                                        <div className='flex justify-between'>
                                            <label className='self-center m-0 text-black'>User Email</label>
                                            {state.hasError && (!state.userEmails || !state.userEmails.length) ? (
                                                <small className='text-issues'>required</small>
                                            ) : null}

                                            {state.emailValidationFaild ? (
                                                <small className='text-issues'>invalid email</small>
                                            ) : null}
                                        </div>
                                        <div className='flex'>
                                            <input
                                                type='text'
                                                placeholder='jhondoe@email.com'
                                                className='border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full'
                                                // maxlength='128'
                                                value={state.emails ? state.emails : ''}
                                                onChange={e => {
                                                    setState(prevState => ({ ...prevState, emails: e.target.value}))                                    
                                                }}
                                            />
                                            <Icon icon="iconoir:plus" width="25" height="25"  className='text-black cursor-pointer ml-3 lg:mt-0 md:mt-0 mt-2' onClick={() => addEmails()}/>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {state.userEmails && Array.isArray(state.userEmails) && state.userEmails.length ? 
                                            state.userEmails.map(row => {
                                                return(
                                                    <span className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace'>{row}
                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeEmails(row) }/>
                                                    </span>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div> */}
                                <div className='w-full'>
                                <div className='py-1 lg:w-1/4 md:w-1/2 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">User</p>
                                        {/* {state.inputValidationError && !state.selectedUsers ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null} */}
                                    </div>
                                    <div className='border-lightGray bg-transparent rounded-5' onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', "showUser")
                                    }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-2 truncate ${state.selectedUsers && state.selectedUsers.length ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedUsers && state.selectedUsers.length ? state.selectedUsers.length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedUsers && state.selectedUsers.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.showUser ?
                                            <MultiSelectSection
                                                // fields={["user", "label"]}
                                                options={state.usersList}
                                                selectedValues={state.selectedUsers ? state.selectedUsers : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedUsers: value }))
                                                    // handleInputChange('requested_user_name', value)
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                                topClass={'auto'}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                </div>
                                <div className='flex flex-wrap'>
                                    {state.selectedUsers && Array.isArray(state.selectedUsers) && state.selectedUsers.length ? 
                                        state.selectedUsers.map(row => {
                                            return(
                                                <span className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace'>{row}
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-black ml-2 self-center' onClick={ () => removeEmails(row) }/>
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                                </div>
                                <div className='md:w-1/3 w-full mt-2 pr-2'>
                                    <div className='flex'>
                                        <label className='self-center m-0 text-black'>Frequency</label>
                                        {state.hasError && !state.selectedFrequency ?
                                            <span className='text-danger self-center'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'showFrequency')
                                    }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate w-2/3 pl-3 ${state.selectedFrequency ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedFrequency ? state.selectedFrequency : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedFrequency  ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.showFrequency ?
                                            <MultiSelectSection
                                                fields={['value', 'label']}
                                                options={state.periodOptions}
                                                selectedValues={state.selectedFrequency ? state.selectedFrequency : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedFrequency: value, selectedRegion: '', selectedAccount: '', selectedOs: '', selectedImage: [] }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}  
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                                makeLabelUpperCase={'capitalizeAllLetter'}
                                            />
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-wrap mt-5'>
                                <p className='self-start mb-0 text-black pr-2 font-bold'>Cron (</p>
                                {state.hasError && !state.schedule_expression ?
                                    <span className='text-danger self-center'>required all cron inputs</span>
                                : null}
                                <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='0-59'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.minutes ? state.minutes : ''}
                                        onChange={(e) => {
                                            // handleChange(e, "minutes") // Use onChange instead of onKeyUp
                                            setState(prevState => ({ ...prevState, minutes: e.target.value, nextSchedules: [] }))
                                        }}
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">Minutes</p>
                                    </div>
                                </div>
                                <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='0-23'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.hours ? state.hours : ''}
                                        onChange={(e) => {
                                            // handleChange(e, "hours") // Use onChange instead of onKeyUp
                                            setState(prevState => ({ ...prevState, hours: e.target.value, nextSchedules: [] }))
                                        }}
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">hours</p>
                                    </div>
                                </div>
                                <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='1-31'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.day_of_month ? state.day_of_month : ''}
                                        onChange={(e) => {
                                            // handleChange(e, "day_of_month") // Use onChange instead of onKeyUp
                                            setState(prevState => ({ ...prevState, day_of_month: e.target.value, nextSchedules: [] }))
                                        }}
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">Day of month</p>
                                    </div>
                                </div>
                                <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='1-12(or JAN to DEC)'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.month ? state.month : ''}
                                        onChange={(e) => {
                                            handleChange(e, "month") // Use onChange instead of onKeyUp
                                            setState(prevState => ({ ...prevState, month: e.target.value, nextSchedules: [] }))
                                        }}
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">Month</p>
                                    </div>
                                </div>
                                <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='0-6 (or SUN to SAT;or 7 for sunday)'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.day_of_week ? state.day_of_week : ''}
                                        onChange={(e) => {
                                            handleChange(e, "day_of_week") // Use onChange instead of onKeyUp
                                            setState(prevState => ({ ...prevState, day_of_week: e.target.value, nextSchedules: [] }))
                                        }}
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">Day of week</p>
                                    </div>
                                </div>
                                {/* <div className='md:w-1/12 w-full mr-2'>
                                    <input
                                        type='text'
                                        placeholder='0'
                                        maxLength={5}
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
                                        value={state.year ? state.year : ''}
                                        onChange={(e) => handleChange(e, "year")} // Use onChange instead of onKeyUp
                                    />
                                    <div className='flex'>
                                        <p className="b-block mb-0 text-lightGray mr-4">Year</p>
                                    </div>
                                </div> */}
                                <p className='self-start mb-0 text-black font-bold'>)</p>
                                <span className='text-lightGray ml-2'>(UTC time)</span>
                            </div>

                            <div className='self-center mt-5 md:w-1/2 w-full'>
                                {state.nextSchedules && state.nextSchedules.length ?
                                    <React.Fragment>
                                    <p className='text-black mb-5'>Next 10 trigger date(s) in UTC time</p>
                                    {state.nextSchedules.map(rec => {
                                        return(
                                            <p className='w-full'>{rec}</p>
                                        )
                                    })}
                                    </React.Fragment>
                                : null}
                            </div>

                            <div className='my-3 flex justify-end mx-2'>
                                {state.saveLoading ?
                                    <button type="button" className="bg-info text-white ml-2 px-2 py-1 rounded-md inline-flex items-center self-center">
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                        Saving
                                    </button>
                                :
                                    <div className='flex'>
                                        <button type="button" className="bg-info text-white ml-2 px-2 py-1 rounded-md inline-flex items-center self-center" 
                                        // onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, saveFunction: true }))}
                                        >Save</button>
                                        <button type="button" className="bg-lightGray text-white ml-2 px-2 py-1 rounded-md inline-flex items-center self-center" onClick={ () => closeSidePanel()}>Cancel</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                : 
                    <div className="overflow-x-scroll m-2">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr className='border-b border-extraLightGray'>
                                    <th scope="col" className={`bg-extraLightGray text-black px-6 py-1 text-left text-sm font-medium tracking-wider`}>Duration</th>
                                    <th scope="col" className={`bg-extraLightGray text-black px-6 py-1 text-left text-sm font-medium tracking-wider`}>Emails</th>
                                    <th scope="col" className={`bg-extraLightGray text-black px-6 py-1 text-left text-sm font-medium tracking-wider`}>Frequency</th>
                                    <th scope="col" className={`bg-extraLightGray text-black px-6 py-1 text-left text-sm font-medium tracking-wider`}>Cron Details</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {state.filteredArray && state.filteredArray.length ?
                                    state.filteredArray.map((col, colIndex) => {
                                        return(
                                            <tr className='border-b border-extraLightGray' key={'colval_'+colIndex}>
                                                {Object.entries(col).map(([key, value], objIndex) => {
                                                    return(
                                                        <td key={objIndex+'_'+colIndex} className={`bg-GhostWhite text-black px-6 py-1 whitespace-nowrap`}>{
                                                            Array.isArray(value) ? value.join(', ') : value
                                                        }</td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })
                                : null}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    );
};

export default ScheduleReport;