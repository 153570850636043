/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ClusterDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { deleteCluster, listClusterDetails } from '../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, noSpace, getSubPath } from '../../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';

import { CSVLink } from 'react-csv';
import ClusterDetailsSidePanel from './ClusterDetailsSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import FilterSection from '../../common/FiltersSection';
import { URL_PATH } from '../../../config/urlPath';
import {Icon} from "@iconify/react";

import 'xterm/css/xterm.css';
import ClusterTerminal from './ClusterTerminal';

const ClusterDetails = () => {        
    const clickOutside = useRef()
    const [state, setState] = useState({
        showLoading: true,

        //filter section start

        selectedProvider: '',
        selectedAccount: [],
        isAccountOpen: false,

        selectedRegion: [],
        isRegionOpen: false,
        
        //filter section end

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 5,

        clusterDetails: [],
        selectedClusterDetails: {},
        filteredClusterDetails: [],
        clusterheaders: [],
        
        topSectionFilters: ['provider', 'project', 'account', 'region']
    })
    
    
    const dispatch = useDispatch(); // Create a dispatch function
    const navigate = useNavigate();
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath.replace(getSubPath, '');
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
                //     if(row.action) {
                //         actionsAllowed = row.action
                //     }
                // }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            nextToken: [],
            clusterDetails: [],
            startRecord: 0,
            currentPage: 1,
            callSearch: true,
            showLoading: true,
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))  
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            params.size = state.perPage
            dispatch(listClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []

                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.clusterDetails &&  state.clusterDetails.length ? state.clusterDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }

                        let totalPages = 1
                        if(totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        
                        setState(prevState => ({ ...prevState, clusterDetails: totalResults, filteredClusterDetails: totalResults, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedProjectTags, state.clusterDetails, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.clusterDetails && state.clusterDetails.length) {
            let dataResult = state.clusterDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, clusterheaders: headers }))
        }

        //status graph of how many running days 
        state.clusterDetails && state.clusterDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name:'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label':'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ["statusDonutData_"+row.cluster_name]: statusDonutData }))
        })
    }, [state.clusterDetails])

    useEffect(() => {
        if(state.selectedClusterDetails && Object.entries(state.selectedClusterDetails).length)
        setState(prevState => ({ ...prevState, showClusterDetails: true }))
    }, [state.selectedClusterDetails])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const navigateTo = () => {
        navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Create' } });
    }
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const deleteFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        params.provider = item.provider
        params.account_id = item.account_id
        params.region = item.region
        params.cluster_name = item.cluster_name
        
        dispatch(deleteCluster(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, nextToken: [], callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    useEffect(() => {
        if(state.editCluster && state.editClusterDetails) {
            setState(prevState => ({ ...prevState, editCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Edit', selectedData: state.editClusterDetails } });
        }
    }, [state.editCluster, state.editClusterDetails, navigate, dispatch])

    useEffect(() => {
        if(state.fixCluster && state.fixClusterDetails) {
            setState(prevState => ({ ...prevState, fixCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Fix', selectedData: state.fixClusterDetails } })
        }
    }, [state.fixCluster, state.fixClusterDetails, navigate, dispatch])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            {state.showClusterDetails ? 
                <ClusterDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedClusterDetails: {} }))}
                    selectedClusterDetails={state.selectedClusterDetails}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            {state.showTerminal ?
                <ClusterTerminal
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showTerminal: false, selectedClusterDetails: {} }))}
                    selectedClusterDetails={state.selectedClusterItem}
                />
            : null}
            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Cluster Details</p>
                        <p className="mx-4 text-black">Consolidated view of cluster details</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='p-2 min-h-screen overflow-auto'>
                <div className='flex flex-wrap justify-between mb-2'>
                    <div className='lg:w-1/2 w-full self-center'>
                        <p className='mb-0 self-center text-black text-lg'>Cluster List</p>
                        <p className='mb-0 self-center text-lightGray'>Showing {state.filteredClusterDetails && state.filteredClusterDetails.length} of total {state.clusterDetails && state.clusterDetails.length} cluster(s)</p>
                    </div>
                    <div className='self-center lg:w-1/2 w-full'>
                        <div className='flex justify-end'>
                            {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                                <CSVLink 
                                    data={state.filteredClusterDetails ? state.filteredClusterDetails : []} 
                                    headers={state.clusterheaders ? state.clusterheaders : []}
                                    filename={'cluster-lists.csv'}
                                    className={'mr-2 self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            {state.clusterDetails && state.clusterDetails.length ?
                                <Search
                                    data={state.clusterDetails ? state.clusterDetails : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={25}
                                    filteredData={(filteredClusterDetails) => {
                                        let totalPages = 1
                                        if(filteredClusterDetails.length > state.perPage) {
                                            totalPages = Math.ceil(filteredClusterDetails.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filteredClusterDetails, totalPages, startRecord: 0, currentPage: 1 })
                                    )}}
                                />
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <button className={`btn bg-HalloweenOrange rounded-md px-2 py-1 text-white ml-2`} onClick={() => navigateTo()}>Create&nbsp;Cluster</button>
                            : null}
                        </div>

                        {state.totalRecords > state.perPage ?
                            <div className='flex justify-end text-black w-full mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                <div className="pagination text-sm">
                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                    {/* <span>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ""
                                                gotoPage(page)
                                            }}
                                            className="ml-1 inputClass"
                                        />
                                    </span> */}
                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                </div>
                            </div>
                        : null}
                    </div>
                </div>

                {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                    state.filteredClusterDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                        return(
                            <div className='flex flex-wrap bg-white rounded-md p-3 mb-2' key={"row_"+(state.startRecord + index)}> 
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                    <div className='py-2'>
                                        <div className="flex">
                                            <div className="py-1 w-100">
                                                <p className="b-block mb-0">Cluster</p>
                                                <p className="mb-0 text-black">
                                                    { item.cluster_name ? item.cluster_name : ''}
                                                    {item.cluster_version ?
                                                        <span className={`mb-0 mr-2 f18 text-black`}>
                                                            (v {item.cluster_version})
                                                        </span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex pt-2">
                                            <div className="py-1 w-100">
                                                <p className="b-block mb-0">Asset</p>
                                                <p className="mb-0 text-black">{item.asset_name ? item.asset_name : ''}</p>
                                            </div>
                                        </div>
                                        <div className="flex pt-2">
                                            <div className="py-1 w-100">
                                                <p className="b-block mb-0">Resources</p>
                                                <p className="mb-0 text-black">
                                                    {item.account_id ?
                                                        <span>{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span>{' '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span>{' '+item.resource_type}</span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                    <div className='py-2'>
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'tag_'+tIndex} className={`flex ${!tIndex ? "" : "pt-2"}`}>
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                    <div className='py-2'>
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'ptag_'+tIndex} className={`flex ${!tIndex ? "" : "pt-2"}`}>
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0 cursor-pointer">
                                    <div className='py-2 flex'>
                                        <div className='p-2 rounded-md border border-lightGray mr-2 w-4/5'>
                                            <div className="flex">
                                                <div className="py-1 w-100">
                                                    <p className="b-block mb-1">Status</p>
                                                    <p className="mb-0 text-white">
                                                        { item.status && item.status === "CREATE_INITIATED" ?
                                                            // <span className={`self-center border-${item.status === "RUNNING" ? "success" : item.status === "STOPPED" ? 'danger' : item.status === "completed" ? 'success' : 'mulbery'} px-2 rounded-md text-white ${item.status === "RUNNING" ? 'bg-success' : item.status !== "completed" ? "bg-danger" : item.status !== "STOPPED" ? 'bg-success' : 'bg-mulbery'}`}>{item.status}</span>
                                                            <span className='border border-badgeBorder bg-submitted px-2 rounded-full text-white text-xs'>{item.status} </span>
                                                        : item.status && item.status === "CREATE_FAILED" ?
                                                            <span className='border border-badgeBorder bg-failed px-2 rounded-full text-white text-xs'>{item.status} </span>
                                                        : item.status && item.status === "STOPPED" ?
                                                            <span className='border border-badgeBorder bg-stopped px-2 rounded-full text-white text-xs'>{item.status} </span>
                                                        : item.status && item.status === "UNKNOWN" ?
                                                            <span className='border border-badgeBorder bg-suspended px-2 rounded-full text-white text-xs'>{item.status} </span>
                                                        : item.status && item.status === "RUNNING" ?
                                                            <span className='border border-badgeBorder bg-running px-2 rounded-full text-white text-xs'>{item.status} </span>
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-100">
                                                    <p className="b-block mb-0">Days Running</p>
                                                    <p className="mb-0 text-black">
                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Created at</p>
                                                    <p className="mb-0 text-black">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Modified at</p>
                                                    <p className="mb-0 text-black">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-0 self-center ml-3'>
                                            <p className='text-info w-full mb-2' onClick={() => setState(prevState => ({ ...prevState, selectedClusterDetails: item }))}>Details</p>
                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ? (
                                                item.status === "CREATE_FAILED" ? 
                                                    <p className='text-info w-full mb-2' onClick={() => setState(prevState => ({ ...prevState, fixClusterDetails: item, fixCluster: true }))}>Fix</p>
                                                :
                                                    <p className='text-info w-full mb-2' onClick={() => setState(prevState => ({ ...prevState, editClusterDetails: item, editCluster: true }))}>Edit</p>
                                            ) : null}
                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ? (
                                                <p className='text-danger w-full mb-0' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, deleteModal: true }))}>Delete</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex justify-start w-1/2 mt-2">
                                        <p className='bg-info rounded-md px-2 py-1 text-white cursor-pointer' onClick={() => { setState(prevState => ({ ...prevState, connectLoginModal: true })) }}>Connect to Login Node</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center text-black m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
            </div>
            <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the cluster "{state.selectedDeleteItem && state.selectedDeleteItem.cluster_name ? state.selectedDeleteItem.cluster_name : ''}"?</h3>

                            <p>Please enter the cluster name to delete</p>
                            <input
                                type='text'
                                placeholder={'Enter cluster name'}
                                className={`bg-transparent rounded-sm px-1 border border-mediumDarkGray text-black`}
                                // maxlength='128'
                                value={state.name ? state.name : ''}
                                onChange={e => handleInputChange('name', noSpace(e.target.value))}
                            />
                            <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="popup-modal" tabIndex="-1" className={`flex ${state.connectLoginModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, connectLoginModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-center text-gray-500 dark:text-gray-400">Steps to connect Login Node</h3>

                            <p className='text-base mb-2'>Step-1 : Open an SSH client</p>
                            <p className='text-base mb-2'>Step-2 : Connect to the Login Node using the following SSH command</p>
                            <p className='text-base mb-2 border border-box px-1'>ssh john@112.83.72.1</p>
                            <p className='text-base mb-2 text-lightGray'>Note: Use your AD username and password</p>

                            <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, connectLoginModal: !state.connectLoginModal }))
                                        // if(!state.showDeleteLoading) {
                                        //     deleteFunction()
                                        // }
                                    }}
                                >
                                    Okay
                                </button>
                                {/* <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, connectLoginModal: !state.connectLoginModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClusterDetails