import React, { useCallback, useEffect, useState } from 'react';
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import MultiSelectSection from '../../../common/MultiSelectSection';
import { onlyNumeric } from '../../../../utils/utility';
import {Icon} from "@iconify/react";

const LoginNodeSection = (props) => {
  const [state, setState] = useState({});

  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const handleLoginNodeInput = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let loginNodeConfiguration = obj.login_node ? obj.login_node : [{arrayIndex : 0}];

    if (label !== "enable_login_node"){
        loginNodeConfiguration[0][label] = type === "integer" ? parseInt(value) : value;
    }

    if (label === "enable_login_node" && value === false) {
        loginNodeConfiguration =  [{arrayIndex : 0}];
        setState((prevState) => ({ ...prevState, instance_type: [], instance_count: ''}));
    } else {
        setState((prevState) => ({ ...prevState, [label]: value }));
    }

    obj["login_node"] = loginNodeConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

  };
  const callPageVist = useCallback(() => {
    let loginNodeConfiguration = createUpdateCluster && createUpdateCluster.login_node ? createUpdateCluster.login_node : [{}];

    Object.entries(loginNodeConfiguration[0]).forEach(([key, value]) => {
        if (key === "instance_type" || key === "instance_count"){
        setState((prevState) => ({ ...prevState, [key]: value, enable_login_node: true}));
        }
    });

  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);
  
  const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };
  
    return (
        <div className='rounded bg-white p-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex mt-3'>
                <div className='py-1 w-full self-center'>
                    <div className='flex'>
                        <label className="mb-0">
                            <input type="checkbox" 
                                className="mt-1" 
                                checked={state.enable_login_node ? true : false}
                                onChange={() => {
                                    setState(prevState => ({ ...prevState, enable_login_node: !state.enable_login_node}))
                                }}
                            />
                        </label>
                        <span className="mb-0 text-black f16 ml-2">Enable login node</span>
                    </div>
                </div>
            </div>
                {state.enable_login_node ? 
                <React.Fragment>
                    <div className='py-1 lg:w-1/5 md:1/3 w-full'>
                        <div className='flex justify-between'>
                            <label className='self-center m-0 text-black'>Instance Type</label>
                            {state.hasError && state.instance_type ? 
                                <small className='text-issues'>required</small>
                            : null}
                        </div>
                        <div className='border-lightGray bg-transparent rounded-5'
                            onClick={(event) => {
                                if(!state.showInstanceType) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showInstanceType")
                                }
                            }}
                        >
                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${state.instance_type && props.pageMode === 'Create' ? 'text-black' : 'text-lightGray'}`}>
                                {state.instance_type ? state.instance_type : 'Select'}
                                {props.pageMode === 'Create' ? 
                                    <Icon icon="icon-park-solid:down-one" className={`${state.instance_type ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                : null}
                            </p>
                            {state.showInstanceType && props.pageMode === 'Create' ?
                                <MultiSelectSection
                                    fields={["instance_type", "instance_type"]}
                                    removeTop={true}
                                    options={propsClusterFilters && propsClusterFilters.instance_type ? propsClusterFilters.instance_type : []}
                                    selectedValues={state.instance_type ? state.instance_type : ''}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            setState(prevState => ({ ...prevState,  instance_type: value }))
                                            handleLoginNodeInput('instance_type', value)
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    singleSelection={true}
                                    removeTopOptions={true}
                                    hideSearch={false}
                                    topClass={'auto'}
                                    widthClass={'minWidth220'}
                                />
                            : null}
                        </div>
                    </div>
                    <div className={'flex mt-3'}>
                        <div className='py-1 w-full'>
                            <div className='py-1 lg:w-1/5 md:1/3 w-full'>
                                <p className='b-block mb-0'>Instance count</p>
                                <input
                                    type='number'
                                    placeholder='Enter instance count'
                                    className={`border border-lightGray focus-visible:border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full ${props.pageMode !== 'Create' ? 'disabled text-lightGray' : 'text-black'}`}
                                    // maxlength='128'
                                    value={state.instance_count ? state.instance_count : ''}
                                    onChange={e => {
                                        if(props.pageMode === 'Create') {
                                            handleLoginNodeInput('instance_count', onlyNumeric(e.target.value), 'integer')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                : null}
        </div>
    )
}


export default LoginNodeSection