/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file DetailsTab.js
 * @author Prakash // on 06/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import { momentConvertionUtcToLocalTime, removeUnderScore, convertToLowerCase, capitalizeTheFirstLetterOfEachWord, isValidDateTime } from '../../../utils/utility';

const DetailsTab = (props) => {
    // const [showClusterDetails, setShowClusterDetails] = useState(true);
    // const [showSelectedItemDetails, setShowSelectedItemDetails] = useState(false);

    return (
        <React.Fragment>
            <div className="flex mb-2 justify-between">
                <p className="f12 m-0 self-center pt-1">Showing {props.selectedLeftOption} details</p>
            </div>
            <div className="overflow-y-auto mb-3">
                {props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length?
                    <div className="overflow-y-auto">
                        {props.detailsFormat && props.detailsFormat.length && props.detailsFormat.map((row, index) => {
                            return(
                                <div key={"main_"+index} className={`${!index ? '' : 'mt-2'}`}>
                                    <p className={`mb-1 text-info text-lg pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                    <small className='text-lightGray'>{row.description}</small>
                                    {row.fields && Array.isArray(row.fields) && row.fields.map(fld => {
                                        return(
                                            <div key={'field_'+fld}>
                                                {fld === "queue_configuration" ?
                                                    props.selectedClusterDetails[fld] && props.selectedClusterDetails[fld].map((item, iIndex) => {
                                                        return(
                                                        <div key={'item_'+iIndex} className={`rounded-md bg-BlockWhite p-2 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Subnet IDs</span>
                                                                <div className="flex flex-wrap gap-2">
                                                                    {item && item.subnet_ids ?
                                                                        item.subnet_ids.map(ids => {
                                                                            return(
                                                                                <span key={'ids'+ids} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Security Group IDs</span>
                                                                <div className="flex flex-wrap gap-2">
                                                                    {item && item.security_group_ids ?
                                                                        item.security_group_ids.map(ids => {
                                                                            return(
                                                                                <span key={'ids_'+ids} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{ids}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Policies</span>
                                                                <div className="flex flex-wrap gap-2">
                                                                    {item && item.policies ?
                                                                        item.policies.map(pol => {
                                                                            return(
                                                                                <span key={'pol'+pol} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{pol}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Script Location</span>
                                                                <span className={`self-center text-black break-all`}>{item && item.script_location ? item.script_location : ""}</span>
                                                            </div>

                                                            <p className={`mb-1 mt-2 text-purple text-lg font-semibold pb-1 border-b border-lightGray`}>Volume Details</p>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Type</span>
                                                                <span className={`self-center text-black`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Size</span>
                                                                <span className={`self-center text-black`}>{item && item.volume_size && item.volume_size}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Encryption</span>
                                                                <span className={`self-center text-black`}>{item && item.volume_encryption ? "True" : "False"}</span>
                                                            </div>

                                                            {/* <p className={`mb-1 mt-2 text-purple text-lg font-semibold pb-1 border-b border-lightGray`}>S3 Access</p>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                                <span className={`self-center text-black`}>{item && item.bucket_name ? item.bucket_name : ""}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                                <span className={`self-center text-black`}>{item && item.enable_s3_write_access ? "True" : "False"}</span>
                                                            </div> */}

                                                            <p className={`mb-1 mt-2 text-purple text-lg font-semibold pb-1 border-b border-lightGray`}>Compute Resources</p>
                                                            {item && item.compute_resources ?
                                                                item.compute_resources.map((comp, coIndex) => {
                                                                    return(
                                                                        <div key={'comp_'+coIndex} className='bg-transparent p-2 rounded'>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Name</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Instance Type</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Min Instance Count</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Max Instance Count</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Efa Enabled</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Placement Group Enabled</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                        </div>
                                                                        <div className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">Disable Simultaneous Multithreading</span>
                                                                            <span className={`self-center text-black`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                        </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                        )
                                                    })
                                                : fld === "storage_configuration" ?
                                                    props.selectedClusterDetails[fld] && props.selectedClusterDetails[fld].map((storage, strIndex) => {
                                                        return(
                                                            <div key={'item_'+strIndex}>
                                                            {Object.entries(storage).length ?
                                                                Object.entries(storage).map(([key, value], storIndex) => {
                                                                    return(
                                                                        <div key={"storIndex_"+storIndex} className="flex mt-3">
                                                                            <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={"tagKey_"+item.Key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span key={"item__"+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                            </div>
                                                        )
                                                    })
                                                : fld === "head_node" || fld === "cluster_configuration" || fld === "database_configuration" ?
                                                    props.selectedClusterDetails[fld] && Object.entries(props.selectedClusterDetails[fld]).length ?
                                                        Object.entries(props.selectedClusterDetails[fld]).map(([key, value]) => {
                                                            return(
                                                                <div key={'cls_'+key} className="flex mt-3">
                                                                    <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(key)}</span>
                                                                    {typeof value === 'boolean' ?
                                                                        <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                    : isValidDateTime(value) ?
                                                                        <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                    : convertToLowerCase(key) === "status" ?
                                                                        <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                    : convertToLowerCase(key) === "tags" ?
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {value.map(item => {
                                                                                return(
                                                                                    <span key={"tak_key"+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    : Array.isArray(value) ?
                                                                        <div className='w-100 mr-2'>
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {value.map((item, itIndex) => {
                                                                                    return(
                                                                                        typeof item === 'string' ?
                                                                                            <span key={"tak_key"+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                        : typeof item === 'object' ?
                                                                                            <div className='p-3 bg-BlockWhite rounded-md w-100 mr-2 mt-2'>
                                                                                                {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                                    return(
                                                                                                        typeof iValue === 'boolean'?
                                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 f13 mt-3'>{iKey} <span className='text-black ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                                        :
                                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 f13'>{iKey}: <span className='text-black ml-3'>{iValue}</span></p>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        : null
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    : Array.isArray(value) ?
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {value.map(item => {
                                                                                return(
                                                                                    <span key={item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    : 
                                                                        <span className="text-black break-all">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    : null
                                                : props.selectedClusterDetails && props.selectedClusterDetails[fld] && fld !== 'head_node' && fld !== "cluster_configuration" && fld !== "database_configuration" && fld !== "queue_configuration" && fld !== "storage_configuration" ?
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(fld)}</span>
                                                        {typeof props.selectedClusterDetails[fld] === 'boolean' ?
                                                            <span className={`text-black`}>{props.selectedClusterDetails[fld] ? 'True' : 'False'}</span>
                                                        : isValidDateTime(props.selectedClusterDetails[fld]) ?
                                                            <span className="text-black">{momentConvertionUtcToLocalTime(props.selectedClusterDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                        : convertToLowerCase(fld) === "status" ?
                                                            <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{props.selectedClusterDetails[fld]}</span>
                                                        : convertToLowerCase(fld) === "tags" ?
                                                            <div className="flex flex-wrap gap-2">
                                                                {props.selectedClusterDetails[fld].map(item => {
                                                                    return(
                                                                        <span key={'__'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        : 
                                                            <span className="text-black">{fld === 'provider' ? props.selectedClusterDetails[fld].toUpperCase() : props.selectedClusterDetails[fld]}</span>
                                                        }
                                                    </div>
                                                : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                : props.selectedLeftOption === "Disk1" ?
                    <div className="bg-white">
                        <div className="flex flex-wrap mt-2">
                            <div className="py-1 lg:w-1/2 w-full">
                                <p className="b-block mb-0 text-lightGray">File System Name</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.file_system_name}</p>
                            </div>
                            <div className="py-1 lg:w-1/2 w-full lg:pl-3">
                                <p className="b-block mb-0 text-lightGray">Category</p>
                                <p className="mb-0 text-black">
                                    {props.selectedData && props.selectedData.category && props.selectedData.category.map(row => {
                                        return(
                                            <span key={'cls_'+row} className={`mr-2 font-weight-bold f12 cursor-pointer self-center badge badge-outline-info text-black mb-1`}>{row}</span>
                                        )
                                    })}
                                </p>
                            </div>
                            {/* <div className="py-1 lg:w-1/2 w-full lg:pl-3">
                                <p className="b-block mb-0 text-lightGray">Queue Version</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.queue_version}</p>
                            </div> */}
                        </div>
                        <div className="flex pt-2 mt-2 border-t border-lightGray">
                            <div className="py-1 lg:w-1/2 w-full">
                                <p className="b-block mb-0 text-lightGray">Created At</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.created_at ? momentConvertionUtcToLocalTime(props.selectedData.created_at, "DD MMM YYY HH:mm") : ""}</p>
                            </div>
                            <div className="py-1 lg:w-1/2 w-full lg:pl-3">
                                <p className="b-block mb-0 text-lightGray">Asset Action</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.asset_action}</p>
                            </div>
                        </div>
                        <div className="flex pt-2 mt-2 border-t border-lightGray">
                            <div className="py-1 lg:w-1/2 w-full">
                                <p className="b-block mb-0 text-lightGray">Asset Name</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.asset_name}</p>
                            </div>
                            <div className="py-1 lg:w-1/2 w-full lg:pl-3">
                                <p className="b-block mb-0 text-lightGray">Asset Id</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ''}</p>
                            </div>
                        </div>                   
                        <div className="flex pt-2 mt-2 border-t border-lightGray">
                            <div className="py-1 w-100">
                                <p className="b-block mb-0 text-lightGray">Asset Arn</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.asset_arn}</p>
                            </div>
                        </div>
                        <div className="flex pt-2 mt-2 border-t border-lightGray">
                            <div className="py-1 lg:w-1/2 w-full">
                                <p className="b-block mb-0 text-lightGray">Provider/Account/Region</p>
                                <p className="mb-0 text-black">
                                    {props.selectedData && props.selectedData.provider ? props.selectedData.provider.toUpperCase() : ""}
                                    {props.selectedData && props.selectedData.account_id ? (" : ")+props.selectedData.account_id : ""}
                                    {props.selectedData && props.selectedData.region ? (" : ")+props.selectedData.region : ""}
                                </p>
                            </div>
                            <div className="py-1 lg:w-1/2 w-full lg:pl-3">
                                <p className="b-block mb-0 text-lightGray">Service</p>
                                <p className="mb-0 text-black">{props.selectedData && props.selectedData.resource_type}</p>
                            </div>
                        </div>
                        <div className="flex pt-2 mt-2 border-t border-lightGray">
                            <div className="py-1 w-full">
                                <p className="b-block mb-0 text-lightGray">Tags</p>
                                <p className="mb-0 text-black">
                                    {props.selectedData && props.selectedData.tags && props.selectedData.tags.map((tag, tIndex) => {
                                        return(
                                            <span key={'tag_'+tIndex} className={`border border-info rounded-full px-2 bg-info text-black mr-2 py-1 break-all`}>{tag.key+": "+tag.value}</span>
                                        )
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                :  
                    <div className="overflowYAuto">
                        {props.detailsFormat && props.detailsFormat.length && props.detailsFormat.map((row, index) => {
                            return(
                                <div key={'det_'+index} className={`${!index ? '' : 'mt-2'}`}>
                                    <p className={`mb-1 text-info text-lg pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                    <small className='text-lightGray'>{row.description}</small>
                                    {row.fields && row.fields.map(fld => {
                                        return(
                                            <div key={'fld_'+fld}>
                                            {fld === "volume_details" ?
                                                props.selectedData[fld].map(volume => {
                                                    return(
                                                        <div key={'volume_'+volume}>
                                                        {Object.entries(volume).length ?
                                                            Object.entries(volume).map(([key, value]) => {
                                                                return(
                                                                    <div key={'-'+key} className="flex mt-3">
                                                                        <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(key)}</span>
                                                                        {typeof value === 'boolean' ?
                                                                            <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                        : isValidDateTime(value) ?
                                                                            <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                        : convertToLowerCase(key) === "status" ?
                                                                            <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                        : convertToLowerCase(key) === "tags" ?
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {value.map(item => {
                                                                                    return(
                                                                                        <span key={'tag-'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : Array.isArray(value) ?
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {value.map(item => {
                                                                                    return(
                                                                                        <span key={'arr-'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : 
                                                                            <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        : null}
                                                        </div>
                                                    )
                                                })
                                            : props.selectedData && props.selectedData[fld] ?
                                                fld === "ontapConfiguration" && typeof props.selectedData[fld] === 'object' ?
                                                    typeof props.selectedData[fld] === 'object' ?
                                                        Object.entries(props.selectedData[fld]).length ?
                                                            Object.entries(props.selectedData[fld]).map(([key, value]) => {
                                                                return(
                                                                    <div key={'selFld_'+key} className="flex mt-3">
                                                                        <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(key)}</span>
                                                                        {typeof value === 'object' ?
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {Object.entries(value).map(([oKey, oValue], oIndex) => {
                                                                                    return(
                                                                                        <span key={'okey_'+oIndex} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{oKey+" : "+oValue}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : typeof value === 'boolean' ?
                                                                            <span className={`text-black`}>{value ? 'True' : 'False'}</span>
                                                                        : isValidDateTime(value) ?
                                                                            <span className="text-black">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                        : convertToLowerCase(key) === "status" ?
                                                                            <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{value}</span>
                                                                        : convertToLowerCase(key) === "tags" ?
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {value.map(item => {
                                                                                    return(
                                                                                        <span key={'kkk_'+item.key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : Array.isArray(value) ?
                                                                            <div className="flex flex-wrap gap-2">
                                                                                {value.map(item => {
                                                                                    return(
                                                                                        <span key={'kk_'+item} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : 
                                                                            <span className="text-black">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                    : null
                                                :
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 mr-3 self-center text-lightGray">{removeUnderScore(fld)}</span>
                                                        {typeof props.selectedData[fld] === 'boolean' ?
                                                            <span className={`text-black`}>{props.selectedData[fld] ? 'True' : 'False'}</span>
                                                        : Array.isArray(props.selectedData[fld]) ?
                                                            <div className="flex flex-wrap self-center gap-2">
                                                                {props.selectedData[fld].map((item, sdIndex) => {
                                                                    return(
                                                                        <React.Fragment key={'ll_'+sdIndex}>
                                                                        {typeof item === "string" ?
                                                                            <span className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item}</span>
                                                                        : 
                                                                            Object.entries(item).map(([key, value]) => {
                                                                                return(
                                                                                    <span key={'item_'+key} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{key +' : '+value}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        : isValidDateTime(props.selectedData[fld]) ?
                                                            <span className="text-black">{momentConvertionUtcToLocalTime(props.selectedData[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                        : convertToLowerCase(fld) === "status" ?
                                                            <span className={`border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all`}>{props.selectedData[fld]}</span>
                                                        : convertToLowerCase(fld) === "tags" ?
                                                            <div className="flex flex-wrap gap-2">
                                                                {props.selectedData[fld].map((item, tIndex) => {
                                                                    return(
                                                                        <span key={'tak_'+tIndex} className='border border-badgeBorder bg-badgeBg px-2 rounded-full text-black text-xs break-all'>{item.key +' : '+item.value}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        :
                                                            <span className="text-black">{fld === 'provider' ? props.selectedData[fld].toUpperCase() : props.selectedData[fld]}</span>
                                                        }
                                                    </div>
                                            : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default DetailsTab