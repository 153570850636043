import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Store as CommonNotification } from 'react-notifications-component';

import { useDispatch } from 'react-redux';
import { saveFileContent } from '../../../../actions/files/FileAction';
import {Icon} from "@iconify/react";

const EditFile = ({state, newState, closeSidePanel}) => {
    const clickOut = useRef()
    const [editState, setEditState] = useState({
        showLoading: true,
        callHpcReports: true,
        editData: newState.fileContent && newState.fileContent.data ? newState.fileContent.data :''
    })

    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel()
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    const saveFunction = () => {
        if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
          let data = state.statsDetail.data
          let params = {}
          params.provider = state.selectedProvider.toLowerCase()
          params.account_id = state.selectedAccount ? state.selectedAccount : []
          params.region = state.selectedRegion ? state.selectedRegion : []
          params.cluster_name = state.selectedClusters
          params.path = data.path
          if(data.owner) {
            params.owner = data.owner
          }
          params.data = editState.editData
          if(params.data) {
              dispatch(saveFileContent(params)).then((response) => {
                  let messageType = 'success'
                  let message = response.data && response.data.message ? response.data.message : 'Error in updaing file'
                  if(!response.status) {   
                      messageType = 'danger'
                      setEditState(prevEditState => ({ ...prevEditState, onSaveFunction: false }))
                  } else {
                      message = response.data && response.data.message ? response.data.message : 'Updated Successfully'
                      setEditState(prevEditState => ({ ...prevEditState, deleteModal: false, onSaveFunction: false }))
                  }
    
                  CommonNotification.addNotification({
                      //title: 'Wonderful!',
                      message: message,
                      type: messageType,
                      insert: 'top',
                      container: 'top-center',
                      // animationIn: ['animate__animated', 'animate__fadeIn'],
                      // animationOut: ['animate__animated', 'animate__fadeOut'],
                      dismiss: {
                          duration: 5000,
                          onScreen: false,
                          pauseOnHover: true,
                          showIcon: true,
                      }
                  });
              })   
          }
        }
      }

	const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setEditState(prevEditState => ({ ...prevEditState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setEditState(prevEditState => ({ ...prevEditState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
          } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setEditState(prevEditState => ({ ...prevEditState, [row]: false }))
                })
            }
            
            setEditState(prevEditState => ({ ...prevEditState, clickedChild }))
          }
	}

    const handleEditData = e => {		
        const { name, value } = e.target;
		setEditState(prevEditState => ({ ...prevEditState, [name]: value }))
	}

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='search-content bg-GhostWhite w-4/5'>
                <div className='header-search bg-GhostWhite flex justify-between p-2'>
                    <div className='text-black'>
                        <h5>File Details</h5>
                    </div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>                
                {!editState.showEditOption ?
                    <p className='my-1 text-info r-2 cursor-pointer' onClick={() => setEditState(prevEditState => ({ ...prevEditState, showEditOption: true}))}><Icon icon="octicon:pencil-24" className="text-black f12 mr-2" /> Edit File</p>
                : null}
                <div className='flex mx-2 border-t border-darkGray'>
                    <textarea id="editData" name="editData" 
                        rows="4" 
                        className={`w-full p-2 mb-4 bg-transparent ${editState.showEditOption ? 'border border-lightGray' : '0'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 text-black`}
                        onChange={handleEditData}
                        value={editState.editData}
                        placeholder='Enter script'
                    />
                </div>
                {editState.showEditOption ?
                    <div className={`self-center flex justify-end`}>
                        {editState.onSaveFunction ?
                            <button type="button" className="bg-HalloweenOrange text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center"><Icon icon="mingcute:down-small-line mt-1 mr-2"></Icon>  Updating</button>
                        :
                            <div className='flex'>
                                {state.pageType !== "View" ?
                                    <button type="button" className="bg-HalloweenOrange text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center"
                                    onClick={ () => {
                                        if(!editState.onSaveFunction) {
                                            setEditState(prevEditState => ({ ...prevEditState, onSaveFunction: true }))
                                            saveFunction()
                                        }
                                    }}>
                                        Update
                                    </button>
                                : null}
                                <button type="button" className="bg-lightGray text-black ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center" onClick={ () => closeSidePanel()}>Cancel</button>
                            </div>
                        } 
                    </div>
                : null}
            </div>
        </div>
    
    )
}
export default EditFile;
    