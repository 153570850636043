import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import { Icon } from "@iconify/react";

const NewDatabaseSection = (props) => {
  const [state, setState] = useState({});
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};

        if (databaseConfiguration) {
          Object.entries(databaseConfiguration).forEach(([key, value]) => {
              setState((prevState) => ({ ...prevState, [key]: value }));
          });
  
    // if (databaseConfiguration && databaseConfiguration.length) {
    //   Object.keys(databaseConfiguration).map(([key, value]) => {
    //     setState((prevState) => ({ ...prevState, [key]: value }));
    //   });
    }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleDatabaseInput = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let databaseConfiguration = obj.database_configuration
      ? obj.database_configuration
      : {};
    databaseConfiguration[label] = value;

    databaseConfiguration[label] = type === "integer" ? parseInt(value) : value;
    obj["database_configuration"] = databaseConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const removefromList = (value, listField) => {
    let filteredListItem = state[listField].filter((e) => e !== value);

    let array = [];
    filteredListItem.forEach((row) => {
      let rowValue = {
        value: row,
        label: row,
      };
      array.push(rowValue);
    });

    setState((prevState) => ({ ...prevState, [listField]: filteredListItem }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return  (
    <div
      className="rounded bg-GhostWhite p-3"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      <div className={"flex"}>
        <div className="py-1 w-full">
          <div className="lg:w-1/3 md:w-1/2">
            <p className="b-block mb-0">Database name</p>
            <input
              type="text"
              placeholder="Enter database name (Eg. database-1)"
              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
              // maxlength='128'
              value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : "" }
              onChange={(e) => handleDatabaseInput("database", e.target.value)}
            />
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.database_name ? (
            <p className="mt-1 mb-0 text-info">
              { propsClusterFilters.description.database_configuration.database_name }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"flex mt-3"}>
        <div className="py-1 w-full">
          <div className="lg:w-1/3 md:w-1/2">
            <p className="b-block mb-0">Username</p>
            <input
              type="text"
              placeholder="Enter username (Eg. admin)"
              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
              // maxlength='128'
              value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : ""}
              onChange={(e) => handleDatabaseInput("user_name", e.target.value)}
            />
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.user_name ? (
            <p className="mt-1 mb-0 text-info">
              {propsClusterFilters.description.database_configuration.user_name}
            </p>
          ) : null}
        </div>
      </div>
      <div className={`flex mt-3`}>
        <div className="py-1 w-full">
          <div className="lg:w-1/2 md:w-1/2">
            <div className="flex justify-content-between">
              <label className="self-center m-0 text-black">VPC Id </label>
              {state.hasError && !state.vpc_id ? (
                <small className="text-issues">required</small>
              ) : null}
            </div>
            <div
              className="text-black border-lightGray bg-transparent rounded-5"
              onClick={(event) => {
                if (!state.showVpcId) {
                  event.preventDefault();
                  handleChildClick(event,"child","singleDropDown","showVpcId");
                }
              }}
            >
              <p
                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                  state.vpc_id && props.pageMode === "Create" ? "text-black" : "placeholder"
                }`}
              >
                {state.vpc_id ? state.vpc_id : "Select"}
                {props.pageMode === "Create" ? (
                  <Icon icon="icon-park-solid:down-one"  className={`${state.vpc_id && state.vpc_id.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                ) : null}
              </p>
              {state.showVpcId && props.pageMode === "Create" ? (
                <MultiSelectSection
                  options={
                    propsClusterFilters && propsClusterFilters.vpc_ids
                      ? Object.keys(propsClusterFilters.vpc_ids)
                      : []
                  }
                  selectedValues={state.vpc_id ? state.vpc_id : ""}
                  callbackMultiSelect={(value) => {
                    if (!value || typeof value === "string") {
                      setState((prevState) => ({ ...prevState, vpc_id: value}));
                      handleDatabaseInput("vpc_id", value);
                    } else {
                      value.preventDefault();
                      handleChildClick(value, "search", "singleDropDown", "");
                    }
                  }}
                  singleSelection={true}
                  hideSearch={false}
                  widthClass={"minWidth220"}
                  removeTopOptions={true}
                />
              ) : null}
            </div>
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.vpc_id ? (
            <p className="mt-1 mb-0 text-info">
              {propsClusterFilters.description.database_configuration.vpc_id}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex mt-3">
        <div className="py-1 w-full">
          <div
            className="lg:w-1/2 md:w-1/2"
            onClick={(event) => {
              event.preventDefault();
              handleChildClick(event, "child", "muliselectDropDown", "showSubnetDropdown");
            }}
          >
            <div className="flex flex-wrap">
              <p className="b-block mb-0">Subnet Ids</p>
              {state.subnet_ids && state.subnet_ids.length === 1 ? (
                <p className="mb-0 text-danger ml-2">
                  Select atleast 2 subnet ids
                </p>
              ) : null}
            </div>
            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.subnet_ids && state.subnet_ids.length ? 'text-black' : 'text-lightGray'}`}>
                {state.subnet_ids && state.subnet_ids.length ? state.subnet_ids.length+' Selected' : 'Select'}
                <Icon icon="icon-park-solid:down-one" className={`${state.subnet_ids && state.subnet_ids.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
            </p>
            {state.showSubnetDropdown ? (
              <div className="position-relative">
                <MultiSelectSection
                  // fields={["account_id", "account_name"]}
                  // className={}
                  removeTopOptions={true}
                  options={propsClusterFilters && propsClusterFilters?.vpc_ids?.[state?.vpc_id] ? propsClusterFilters?.vpc_ids?.[state?.vpc_id] : []}
                  selectedValues={state.subnet_ids ? state.subnet_ids : []}
                  callbackMultiSelect={(value) => {
                    handleDatabaseInput("subnet_ids", value);
                    setState((prevState) => ({ ...prevState, subnet_ids: value}));
                  }}
                  widthClass={"minWidth220"}
                />
              </div>
            ) : null}
            </div>
            <div className="flex flex-wrap">
            {state.subnet_ids && state.subnet_ids.length
              ? state.subnet_ids.map((row) => {
                  return (
                    <span className="flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace">
                      {row}
                      <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='cursor-pointer text-black ml-2 self-center' onClick={() => removefromList(row, 'subnet_ids') }/>
                    </span>
                  );
                })
              : null}
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration &&
            propsClusterFilters.description.database_configuration.subnet_id ? (
            <p className="mt-1 mb-0 text-info">
              {propsClusterFilters.description.database_configuration.subnet_id}
            </p>
          ) : null}
        </div>
      </div>
      <div className={"flex mt-3"}>
        <div className="py-1 w-full">
          <div className="lg:w-1/3 md:w-1/2">
            <p className="b-block mb-0">Minimum Capacity</p>
            <input
              type="text"
              placeholder="Enter minimum capacity units"
              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
              // maxlength='128'
              // value={state.min_capacity ? state.min_capacity : ''}
              value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.min_capacity ? createUpdateCluster.database_configuration.min_capacity : "" }
              onChange={(e) =>
                handleDatabaseInput("min_capacity", e.target.value, "integer")
              }
            />
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.min_capacity ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.database_configuration.min_capacity
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"flex mt-3"}>
        <div className="py-1 w-full">
          <div className="lg:w-1/3 md:w-1/2">
            <p className="b-block mb-0">Maximum Capacity</p>
            <input
              type="text"
              placeholder="Enter maximum capacity units"
              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-black w-full`}
              // maxlength='128'
              // value={state.max_capacity ? state.max_capacity : ''}
              value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.max_capacity ? createUpdateCluster.database_configuration.max_capacity : ""}
              onChange={(e) =>
                handleDatabaseInput("max_capacity", e.target.value, "integer")
              }
            />
          </div>
          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.max_capacity ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.database_configuration.max_capacity
              }
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewDatabaseSection;
