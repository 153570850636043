/*************************************************
 * Kiosk
 * @exports
 * @file Api.js
 * @author Prakash // on 19/12/2019
 * @copyright © 2019 Kiosk. All rights reserved.
 *************************************************/
import axios from 'axios';
import { msalConfig, tokenRequest } from '../authConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { KIOSK_END_POJNT } from '../config'
import { momentDateFormat, subMinutes } from '../utils/utility';
// import { momentDateFormat } from '../utils/utility';
// import { apiConfigScopes } from '../apiConfig';

const msalInstance = new PublicClientApplication(msalConfig);

let tokenAcquired = false;

// Define an asynchronous function to initialize MSAL and acquire the token
const initializeMsal = async () => {
    try {
        await msalInstance.initialize();
        const account = msalInstance.getAllAccounts()[0];
        
        if(account.idTokenClaims) {
            if(account.idTokenClaims.exp) {
                let date = new Date(account.idTokenClaims.exp * 1000); // Convert seconds to milliseconds

                // const timestamp = 1728538837 * 1000; // Convert seconds to milliseconds
                // const dateNew = new Date(timestamp);

                // // Extract year, month, day, hours, and minutes
                // const year = String(dateNew.getFullYear()).slice(2); // Get last two digits of the year
                // const month = String(dateNew.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                // const day = String(dateNew.getDate()).padStart(2, '0');
                // const hours = String(dateNew.getHours()).padStart(2, '0');
                // const minutes = String(dateNew.getMinutes()).padStart(2, '0');

                // // Format as 'yy-mm-dd hh:mm'
                // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

                // console.log("formattedDate", formattedDate);

                if(momentDateFormat(date,'YYYY-MM-DD HH:mm:ss') < momentDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')) {
                    tokenAcquired = false 
                } else if(localStorage.getItem('ACCESS_TOKEN')){
                    tokenAcquired = true
                }
            } else {
                tokenAcquired = false
            }
        } else {
            tokenAcquired = false
        }

        // console.log("account", account)
        // console.log("tokenAcquired", tokenAcquired)

        if (!tokenAcquired && account) {
            let accessTokenRequest = tokenRequest
            accessTokenRequest.account = msalInstance.getAllAccounts()[0];
            const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            // console.log("accessTokenResponse", accessTokenResponse)
            const accessToken = accessTokenResponse?.idToken;
            const expiresOn = accessTokenResponse?.expiresOn;
    
            localStorage.setItem('ACCESS_TOKEN', accessToken ?? '');
            localStorage.setItem('ACCESS_TOKEN_EXPIRES_ON', expiresOn ?? '');
            tokenAcquired = true;

            return accessToken

        } else if(tokenAcquired && account) {
            return localStorage.getItem('ACCESS_TOKEN')
        } else if(!tokenAcquired || !account) {
            const logoutRequest = {
                postLogoutRedirectUri: msalConfig.auth.redirectUri,
            };
            msalInstance.logoutRedirect(logoutRequest)
        }
    } catch (error) {
        console.error('MSAL initialization error:', error);
    }
}

const ApiCalls = axios.create({ 
    baseURL: KIOSK_END_POJNT 
}) // Replace with your API's base URL });

ApiCalls.interceptors.request.use(async (config) => {
    if (typeof(config.formContentType) == 'undefined')
        config.headers['Content-Type'] = 'application/json'
    let accessToken = await initializeMsal()
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config
})

export default ApiCalls;