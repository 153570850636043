import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import MultiSelectSection from '../../common/MultiSelectSection';
import { subscribeCatalog } from '../../../actions/CatalogAction'
import { Store as CommonNotification } from 'react-notifications-component';
import { onlyNumeric, removeUnderScore } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const RequestWorkstationSidePanel = ({ selectedCatalog, catalogTypes, catalogType,closeSidePanel }) => {
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false
    })
    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.onRequest) {
            setState(prevState => ({ ...prevState, onRequest: false}))
            let hasError = false
            // if(!state.workstation_name || !state.selectedInstances || !Object.entries(state.selectedInstances).length) {
            //     hasError = true
            // }

            if(catalogTypes && catalogTypes[catalogType]) {
                catalogTypes[catalogType].forEach(int => {
                    if(!state[int.output_field]) {
                        hasError = true
                    }
                })
            }


            setState(prevState => ({ ...prevState, hasError, saveLoading: !hasError ? true : false }))

            if(!hasError) {
                let params = selectedCatalog
                params.workstation_name = state.workstation_name
                if(state.additional_storage_size) {
                    params.additional_storage_size = state.additional_storage_size                    
                }
                params = {...params, ...state.selectedInstances }
                if(!params.description) {
                    delete params.description
                }

                // params.subscribed = false
                dispatch(subscribeCatalog(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, saveLoading: false }))
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in submitting request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust submitted successfully'
                        }

                        closeSidePanel()

                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            }
        }

    }, [dispatch, state.onRequest, selectedCatalog, state.selectedInstances, state.workstation_name, closeSidePanel, state.additional_storage_size, catalogTypes, catalogType, state])

    const handleInputChange = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: value }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-2/5 bg-GhostWhite min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search flex justify-between p-2'>
                    <div className='text-black lg:w-1/4 w-full self-center'>
                        <span className='font-bold text-xl text-black text-nowrap'>Workstation Request</span>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2 overflow-auto'>
                    <div className="rounded-md bg-white p-3">
                        {catalogTypes && catalogTypes[catalogType] ?
                            catalogTypes[catalogType].map((int, index) => {
                                return(
                                    <React.Fragment key={'init_'+index}>
                                    {int.type === 'text' ?
                                        <div className='flex py-2'>
                                            <div className='py-1 w-80'>
                                                <p className='b-block mb-0 flex text-lightGray'>
                                                {int.label} <span className="fa fa-star text-red ml-1 text-xs self-center"></span>
                                                    {state.hasError && !state[int.output_field] ?
                                                        <span className='mb-0 text-red pl-3'>required</span>
                                                    : null}
                                                </p>
                                                <div className='flex mt-2'>
                                                    <input
                                                        type='text'
                                                        placeholder={removeUnderScore(int.output_field)}
                                                        className="placeholder:italic text-black placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent" 
                                                        value={state[int.output_field] ? state[int.output_field] : ''}
                                                        onChange={e => handleInputChange(int.output_field, e.target.value)}
                                                        //setState(prevState => ({ ...prevState, [int.output_field]: e.target.value
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : int.type === 'dropdown' ?
                                        <div className='flex flex-wrap py-2'>
                                            <div className='py-1 w-full'>
                                                <div className='flex'>
                                                    <p className="b-block mb-0 text-lightGray">{int.label}</p>
                                                    <span className="fa fa-star text-red ml-1 text-xs self-center"></span>
                                                    {state.hasError && !state[int.output_field] ?
                                                        <p className='pl-3 text-danger self-center mb-0'>required</p>
                                                    : null}
                                                </div>
                                                <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                    if(!state.showRole) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', "showRole")
                                                    }
                                                }}>
                                                    <p className={`p-2 bg-transparent border border-lightGray mb-0 rounded-md flex justify-between ${state.instance_type ? 'text-black' : 'text-lightGray'}`}>
                                                        {state.instance_type ? state.instance_type : 'Select'}
                                                        <Icon icon="icon-park-solid:down-one" className={`${state.instance_type ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                                    </p>
                                                    {state.showRole ?
                                                        <div className='position-relative'>
                                                            <MultiSelectSection 
                                                                fields={["instance_type", "instance_type"]}
                                                                removeTop={true}
                                                                removeTopOptions={true}
                                                                options={selectedCatalog.allowed_instance_types ? selectedCatalog.allowed_instance_types : []}
                                                                selectedValues={state.instance_type ? state.instance_type : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    if(!value || typeof(value) === 'string') {
                                                                        setState(prevState => ({ ...prevState, instance_type: value, selectedInstances: selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value).length ? selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value)[0] : {} }))
                                                                        if(value) {
                                                                            handleInputChange(int.output_field, value)
                                                                        }
                                                                    } else {
                                                                        value.preventDefault()
                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                    }
                                                                    
                                                                }}
                                                                singleSelection={true}
                                                                hideSearch={true}
                                                                // makeLabelUpperCase={'capitalizeAllLetter'}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            <div className='py-1 w-full hidden'>
                                                <div className='flex'>
                                                    <p className="b-block text-lightGray mb-0">Additional Storage Size</p>
                                                </div>
                                                <div className='flex'>
                                                    <input
                                                        type='number'
                                                        placeholder='size in GB'
                                                        maxLength={5}
                                                        className="placeholder:italic text-black placeholder:text-lightGray block w-full border border-lightGray rounded-md py-2.5 pl-2 shadow-sm focus:outline-none focus:border-lightGray sm:text-sm bg-transparent"
                                                        value={state.additional_storage_size ? state.additional_storage_size : ''}
                                                        onChange={e => {
                                                            if (e.target.value.length <= 5) {
                                                                setState(prevState => ({ ...prevState, additional_storage_size: onlyNumeric(e.target.value)}))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    </React.Fragment>
                                )
                            })
                        : null}
                        {state.selectedInstances && state.selectedInstances.vcpus ?
                            <div className='flex py-2'>
                                <p className='b-block mb-0 flex w-32'>vCPUs</p>
                                <p className='pl-3 b-block mb-0 text-black'>{state.selectedInstances.vcpus}</p>
                            </div>
                        : null}
                        {state.selectedInstances && state.selectedInstances.memory ?
                            <div className='flex py-2'>
                                <p className='b-block mb-0 flex w-32'>Memory</p>
                                <p className='pl-3 b-block mb-0 text-black'>
                                    {state.selectedInstances.memory}
                                    {state.selectedInstances && state.selectedInstances.memory_unit ? 
                                        <span className='ml-1'>{state.selectedInstances.memory_unit}</span>
                                    : null}
                                </p>
                            </div>
                        : null}
                        {state.selectedInstances && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_type ?
                            <div className='flex py-2'>
                                <p className='b-block mb-0 flex w-32 text-nowrap'>Storage Type</p>
                                <p className='pl-3 b-block mb-0 text-black'>
                                    {selectedCatalog.storage_details.storage_type}
                                </p>
                            </div>
                        : null}
                        {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_size ?
                            <div className='flex py-2'>
                                <p className='b-block mb-0 flex w-32 text-nowrap'>Storage Size</p>
                                <p className='pl-3 b-block mb-0 text-black'>
                                    {selectedCatalog.storage_details.storage_size}
                                    {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_unit ? 
                                        <span className='ml-1'>{selectedCatalog.storage_details.storage_unit}</span>
                                    : null}
                                </p>
                            </div>
                        : null}
                        {selectedCatalog && selectedCatalog.price_per_month ?
                            <div className='flex py-2'>
                                <p className='b-block mb-0 flex w-32'>Cost per Month</p>
                                <p className='pl-3 b-block mb-0 text-black'>
                                    {state.selectedInstances.price_per_month}
                                    {state.selectedInstances && state.selectedInstances.price_unit ?
                                        <span className='ml-2  text-lightGray'>({state.selectedInstances.price_unit})</span>
                                    : null}
                                </p>
                                {/* <div className='flex'>
                                    <Input
                                        type='text'
                                        placeholder='storage size'
                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                        value={state.size ? state.size : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, size: e.target.value }))}
                                    />
                                </div> */}
                            </div>
                        : null}
                    </div>

                    <div className={`flex mb-2 mt-4 justify-end`}>
                        {state.saveLoading ?
                            <button className='flex border border-lightGray px-2 py-1 bg-HalloweenOrange text-white rounded-md'>
                                Requesting 
                                <svg className="animate-spin h-5 w-5 ml-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                            </button>
                        : 
                            <button className={`flex justify-end cursor-pointer border border-lightGray px-2 py-1 bg-HalloweenOrange rounded-md text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, onRequest: true }))}>Request</button>
                        }
                        <button className={`ml-2 flex justify-end cursor-pointer border border-lightGray bg-lightGray text-white rounded-md px-2 py-1`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestWorkstationSidePanel;