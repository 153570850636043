import React, { useState, useEffect, useRef } from 'react';
import { capitalizeAllLetter, capitalizeFirstLetter } from '../../utils/utility';
import {Icon} from "@iconify/react";
import Search from './SearchWithHiddenInput';

const MultiSelectSectionNew = ({ label, fields, options, selectedValues, callbackMultiSelect, className, removeTop, topClass, closeDiv, borderClass, singleSelection, hideSearch, makeLabelUpperCase, dropdownWidth, widthClass, addAllField, selectedLabel=false }) => {
    const dropdownRef = useRef()

    const [state, setState] = useState({
        options,
        filteredOptions: options ? options : [],
        selectedValues,
    })

    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, filteredOptions: options, selectedValues }))
    // }, [options, selectedValues])

    useEffect(() => {
        // if(addAllField) {
        //     let filteredOptions = options
        //     // if(fields && fields.length) {
        //     //     if(!filteredOptions.filter(e => e[fields[0]] === 'All').length) {
        //     //         let dataRow = {}
        //     //         dataRow[fields[0]] = 'All'
        //     //         dataRow[fields[1]] = 'All'
        //     //         filteredOptions.unshift(dataRow)
        //     //     }
        //     // } else {
        //     //     if(!filteredOptions.filter(e => e === 'All').length) {
        //     //         filteredOptions.unshift('All')
        //     //     }
        //     // }
        //     setState(prevState => ({ ...prevState, filteredOptions, addAllField: false }))
        // }
    }, [addAllField, options, fields, selectedValues])

    const toggleDropdown = () => {
        setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
    };

    const handleSelectAll = (e) => {
        let opt = []
        options.forEach(row => {
            opt.push(fields && fields.length ? row[fields[0]] : row)
        })
        let selectedValues = []
        if (e.target.checked) {
            selectedValues = opt
        }
        setState(prevState => ({ ...prevState, selectedValues: opt }));

        callbackMultiSelect(selectedValues)
    };

    const handleOptionChange = (option) => {

        // event.stopPropagation();
        if(singleSelection) {
            setState(prevState => ({ ...prevState, selectedValues: option }))
            // callbackMultiSelect(selectedValues)
        } else {
            if (selectedValues && selectedValues.includes(option)) {
                selectedValues = selectedValues.filter((item) => item !== option);
            } else {
                selectedValues.push(option);
            }

            setState(prevState => ({ ...prevState, selectedValues })); 
            // if(addAllField) {
            //     callbackMultiSelect(selectedValues.filter(e => e !== 'All'))
            // } else {
            //     callbackMultiSelect(selectedValues)
            // }

            callbackMultiSelect(selectedValues)
        }

        // alert(1)
        // let selectedValues = state.selectedValues
        // if(!singleSelection) {
        //     if (selectedValues && selectedValues.includes(option)) {
        //         selectedValues = selectedValues.filter((item) => item !== option);
        //     } else {
        //         selectedValues.push(option);
        //     }
        // } else {
        //     selectedValues = option
        // }
        // setState(prevState => ({ ...prevState, selectedValues, dropdownOpen: singleSelection ? false : true }))

        // if(Array.isArray(option)) {
        //     setState(prevState => ({ ...prevState, dropdownOpen: !state.dropdownOpen }))
        // }
        
        callbackMultiSelect(selectedValues)
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, dropdownOpen: false }))
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative inline-block w-full h-fit self-end pr-2 flex flex-col justify-between rounded-md cursor-pointer min-w-[${dropdownWidth ? dropdownWidth : '120px'}]`} ref={dropdownRef}>
            <label className='text-black text-xs pb-1'>{label}</label>
            <div>
                <button className="w-full bg-transparent rounded shadow-sm text-left cursor-default focus:outline-none sm:text-sm"
                    onClick={toggleDropdown}
                >
                    <p className={`flex justify-between border border-lightGray rounded-md px-2 py-1 text-sm`}>
                        <span className={`truncate text-sm ${((Array.isArray(state.selectedValues) && state.selectedValues.length) || (singleSelection && state.selectedValues)) ? "text-black" : "text-lightGray"}`}>
                            {selectedValues && Array.isArray(selectedValues) ?
                                selectedValues.length > 0 ? selectedValues.length + ' Selected' : 'Select'
                            : selectedValues ?
                                label === 'Provider' ?
                                    state.selectedValues ? state.selectedValues.toUpperCase() : ''
                                : fields && fields.length && fields[0] !== fields[1] && selectedLabel ?
                                    options.filter(e => e[fields[0]] === state.selectedValues).length ? options.filter(e => e[fields[0]] === state.selectedValues)[0][fields[1]] : 'Select'
                                :
                                    state.selectedValues
                            : 'Select'}
                        </span>
                        <Icon icon="icon-park-solid:down-one" className={`${((Array.isArray(state.selectedValues) && state.selectedValues.length) || (singleSelection && state.selectedValues)) ? "text-lightGray" : "text-lightGray"} self-center`} width="16" height="16" />                        
                    </p>
                </button>
            </div>
            {state.dropdownOpen ?
                <div className={`relative`}>
                    <div className={`absolute mt-1 w-full bg-white border border-lightGray rounded-md shadow-lg z-10 ${widthClass ? widthClass : 'minWidth220'} ${className ? className : ''}`}>
                        <div className="max-h-60 overflow-auto">
                            <div className="p-2 w-full">
                                <Search
                                    data={state.options ? state.options : []}
                                    filteredData={(filteredArray, searchText) => {
                                        {console.log("state.options", state.options)}
                                        setState(prevState => ({ ...prevState, filteredOptions: searchText !== "" ? filteredArray : state.options }))
                                    }}
                                    topClassName={'text-fontColor bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-fontColor'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-fontColor px-2 py-1'}
                                    widthHeight={25}
                                />
                            </div>
                            {!singleSelection && state.options && state.options.length > 1 ?
                                <div className="px-4 py-1 bg-gray-50 border-b border-gray-200 hover:bg-info-old hover:text-white cursor-pointer">
                                    <label className="inline-flex items-center text-fontColor">
                                        <input
                                            type="checkbox"
                                            checked={state.selectedValues && state.selectedValues.length === options.length}
                                            onChange={handleSelectAll}
                                            className="form-checkbox h-4 w-4 text-blue-600"
                                        />
                                        <span className="ml-2 text-gray-700">Select All</span>
                                    </label>
                                </div>
                            : null}
                            <ul className="">
                                {state.filteredOptions && state.filteredOptions.length ?
                                    state.filteredOptions.map((row, index) => {
                                        return(
                                            <div key={'drop_'+index}>
                                                {!singleSelection ?
                                                   <li key={index} className={`px-4 py-1 ${state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? 'text-white bg-info-old' : 'text-fontColor'} hover:bg-info-old hover:text-white`} onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}> 
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={state.selectedValues && state.selectedValues.includes(fields && fields.length ? row[fields[0]] : row) ? true : false}
                                                                onChange={() => {}}
                                                                className="form-checkbox cursor-pointer h-4 w-4 text-blue-600 option-checkbox"
                                                            />
                                                            <span className="ml-2" onClick={() => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                                {makeLabelUpperCase === 'capitalizeFirstLetter'
                                                                    ? capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : makeLabelUpperCase === 'capitalizeAllLetter'
                                                                    ? capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                    : fields && fields.length ? row[fields[1]] : row}
                                                            </span>
                                                        </label>
                                                    </li>
                                                :   
                                                    <li key={index} className={`px-4 py-1 ${state.selectedValues === (fields && fields.length ? row[fields[0]] : row) ? 'text-white bg-info-old' : 'text-fontColor'} hover:bg-info-old hover:text-white`} onClick={(event) => handleOptionChange(fields && fields.length ? row[fields[0]] : row)}>
                                                        <label className="inline-flex items-center">
                                                            <span className="ml-2">
                                                                {makeLabelUpperCase && makeLabelUpperCase === 'capitalizeFirstLetter' ?
                                                                    capitalizeFirstLetter(fields && fields.length ? row[fields[1]] : row)
                                                                : makeLabelUpperCase && makeLabelUpperCase === 'capitalizeAllLetter' ?
                                                                    capitalizeAllLetter(fields && fields.length ? row[fields[1]] : row)
                                                                :  
                                                                    fields && fields.length ? row[fields[1]] : row
                                                                }
                                                            </span>
                                                        </label>
                                                    </li>
                                                }
                                            </div>
                                        )
                                    })
                                : 
                                    <span className="ml-2 text-gray-700">No Options</span>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default MultiSelectSectionNew