import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstatioRequestHistory } from '../../../../actions/CatalogAction'
import WorkstationSidePanel from '../WorkstationSidePanel';
import Search from '../../../common/SearchWithHiddenInput'
import { CSVLink } from 'react-csv';
import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable';
import { capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../../utils/utility';
import {Icon} from "@iconify/react";

const RequestHistory = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion
    })

    const dispatch = useDispatch(); // Create a dispatch function
    // const history = useHistory();
    // const navigate = useNavigate();

    useEffect(() => {
        setState(prevState => ({ ...prevState, callListReqeustHistory: true }))
    }, [])

    useEffect(() => {
        if(state.callListReqeustHistory) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextHistoryToken) {
                params.next_token = state.nextHistoryToken
            }
    
            dispatch(listWorkstatioRequestHistory(params))
                .then((response) => {
                    if(response) {
                        let totalResults = [] 
                        if(state.nextHistoryToken) {
                            totalResults = state.listRequestHistory &&  state.listRequestHistory.length ? state.listRequestHistory : []
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }
                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                        
                        setState(prevState => ({ ...prevState, listRequestHistory: totalResults, filterListRequests: totalResults, detailsFormat, selectedFilters: params, nextHistoryToken: response.next_token, showLoading: false, callListReqeustHistory: false }));
                    }
                })
        }
    }, [state.callListReqeustHistory, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listRequestHistory, state.nextHistoryToken])

    

	useEffect(() => {
        let dataResult = state.filterListRequests && state.filterListRequests.length ? state.filterListRequests : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListRequests])

    return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                    showOnlyDeatils={true}
                />
            : null}
            <div className='p-2 min-h-screen overflow-y-auto'>
                <div className='flex justify-between mb-2'>
                    <p className='mb-0 self-center'>
                        <small>Showing {state.filterListRequests && state.filterListRequests.length ? state.filterListRequests.length : 0 } out of total {state.listRequestHistory && state.listRequestHistory.length ? state.listRequestHistory.length : 0} request history</small>
                        {state.selectedProvider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedAccount && state.selectedAccount.length ?
                            <React.Fragment>
                            <small className='ml-1'>with the account</small>
                            <small className='border border-info rounded-full px-2 mx-1'>{state.selectedAccount[0]}</small>
                            {state.selectedAccount.length > 1 ?
                                <small className='ml-1 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedRegion && state.selectedRegion.length ?
                            <React.Fragment>
                            <small className='ml-1'>in the</small>
                            <small className='border border-info rounded-full px-2 mx-1'>{state.selectedRegion[0]}</small>
                            {state.selectedRegion.length > 1 ?
                                <small className='ml-1 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='lg:w-1/2'>
                        <div className='flex justify-end mr-2'>
                            {state.filterListRequests && state.filterListRequests.length ?
                                <CSVLink 
                                    data={state.filterListRequests ? state.filterListRequests : []} 
                                    headers={state.headers ? state.headers : []}
                                    filename={'workstation_history_'+new Date().getTime()+'.csv'}
                                    className={'self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            {state.listRequestHistory && state.listRequestHistory.length ?
                                <Search
                                    data={state.listRequestHistory ? state.listRequestHistory : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'text-black bg-transparent border border-lightGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={25}
                                    filteredData={(filterListRequests) => setState(prevState => ({ ...prevState, filterListRequests }))}
                                />
                            : null}
                        </div>                                    
                    </div>
                </div>

                <div className='flex flex-wrap mx-0'>
                    <div className='w-full px-3 py-1'>
                        {state.filterListRequests && state.filterListRequests.length ?
                            <ResizeableDarkThemeTable
                                columns={[
                                    {
                                        Header: 'Name',
                                        accessor: 'workstation_name',
                                        Cell: cellInfo => (
                                            <div className="flex justify-between cursor-pointer self-center">
                                                <div className='text-info mr-2' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))}>{cellInfo.row.original.workstation_name}</div>
                                            </div>
                                        ),
                                        width: 200
                                    },
                                    {
                                        Header: 'Status',
                                        accessor: 'Status',
                                        Cell: cellInfo => (
                                            cellInfo.row.original.status ?
                                                <span className={`badge badge-outline-${cellInfo.row.original.status === 'rejected' ? 'danger' : cellInfo.row.original.status === 'approved' ? 'success' : 'info'}`}>{cellInfo.row.original.status}</span>
                                            : 
                                                <span className={`badge badge-outline-secondary`}>No Status</span>
                                        ),
                                    },
                                    {
                                        Header: 'Date',
                                        accessor: 'created_at',
                                        Cell: cellInfo => (<span>{cellInfo.row.original.created_at ?  momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : ''}</span>),
                                        width: 200
                                    },
                                    {
                                        Header: 'Requested By',
                                        accessor: 'user_name',
                                        width: 200
                                    },
                                    {
                                        Header: 'Category',
                                        accessor: d => d.category ? capitalizeFirstLetter(d.category) : '',
                                        width: 150,
                                    },
                                    {
                                        Header: 'Resources',
                                        accessor: d => d.provider + ' : ' + d.account_id + ' : ' + d.region,
                                        Cell: cellInfo => (
                                            <React.Fragment>
                                                <span className=''>
                                                    {(cellInfo.row.original.asset_name
                                                        ? cellInfo.row.original.asset_name + ' : '
                                                        : '') +
                                                        cellInfo.row.original.provider +
                                                        ' : ' +
                                                        cellInfo.row.original.account_id +
                                                        ' : ' +
                                                        (cellInfo.row.original.region === 'NA'
                                                            ? 'Global'
                                                            : cellInfo.row.original.region)}
                                                </span>
                                            </React.Fragment>
                                        ),
                                        width: 300
                                    },
                                    {
                                        Header: 'Instance Type',
                                        // accessor: 'instance_type',
                                        accessor: d => d.instance_type + ' : ' + d.vcpus + ' : ' + d.memory,
                                        Cell: cellInfo => (
                                            <React.Fragment>
                                                <span className=''>
                                                    {(cellInfo.row.original.instance_type ? cellInfo.row.original.instance_type : '') + '  ('+
                                                    cellInfo.row.original.vcpus + ' vCPUs'+
                                                    '     memory' +
                                                    cellInfo.row.original.memory +
                                                    '  '+
                                                    (cellInfo.row.original.memory_unit ? cellInfo.row.original.memory_unit : '')
                                                    +')'}
                                                </span>
                                            </React.Fragment>
                                        ),
                                        width: 250,
                                    },
                                    {
                                        Header: 'Description',
                                        accessor: 'description',
                                        width: 350,
                                    },
                                    // {
                                    //     Header: ' ',
                                    //     Cell: cellInfo => (
                                    //         <div className="flex justify-between cursor-pointer self-center">
                                    //             <div className='text-info mr-2'>Details</div>
                                    //         </div>
                                    //     ),
                                    //     width: full
                                    // }
                                ]}
                                data={state.filterListRequests}
                                perPage={10}
                                tableHead={'bg-lightGray text-black'}
                                // tableBody={'bg-mediumDarkGray'}
                                tableBodyColor={'bg-white text-black'}
                                tableType={'table-light-hover'}
                                perPageClass={`bg-lightGray text-white rounded-md ml-2`}
                                textColor={'text-black'}
                                selectedColor={'bg-transparent'}
                                paginationColor={'text-black'}

                                dashboard={state.filterListRequests && state.filterListRequests.length ? true : false}
                                sortByColumn={''}
                                riskTooltip={[0]}
                                onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistRequestHistory: tableRow }))}
                                // tableSize={'table-sm'}
                                paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                checkForNextSetoffRecords={state.nextHistoryToken ? true : false}
                                callToFetchNewRecord={(paginationIndex) => {
                                    if(state.nextHistoryToken) {
                                        setState(prevState => ({ ...prevState, callListReqeustHistory: true, paginationIndex }))
                                    }
                                }}
                                showPaginationTop={false}
                                paginationIconClass={'text-lightGray'}
                                paginationIconWidth={18}
                            />
                        : 
                            <div className='w-full flex justify-center m-4 text-white'>
                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </React.Fragment>
    );
};

export default RequestHistory