    /*************************************************
 * Tvastar
 * @exports
 * @file PeakDipMetrics.js
 * @author Prakash // on 07/08/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash'
import Chart from 'react-apexcharts'

import { momentDateGivenFormat, momentConvertionUtcToLocalTime, subDays, addMinutes, capitalizeFirstLetter, currentLocaltime } from '../../../utils/utility'
import { getPeakDipDetectionResults } from '../../../actions/MetricsActionNew'
import ApexPositiveNegativeBar from '../../common/charts/ApexPositiveNegativeBar'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import {Icon} from "@iconify/react";

const PeakDipMetrics = (props) => {
    const [state, setState] = useState({
        peakDipLoader: true,
        expandPeakAndDip: true,
        selectedDuration: "+7d",
        dataFrequency: ["Hourly", "Daily"],
        selectedFrequency: "Hourly",
        metricsGroup: props.selectedDetails.peak_dip_metric_names ? props.selectedDetails.peak_dip_metric_names : [],
        selectedMetric: props.selectedDetails.peak_dip_metric_names && props.selectedDetails.peak_dip_metric_names.length ? props.selectedDetails.peak_dip_metric_names[0] : ""
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.selectedMetric) {
            setState(prevState => ({ ...prevState, callGetPeakDipDetectionResults: true }))
        }
    }, [state.selectedMetric])

    useEffect(() => {
        if(state.callGetPeakDipDetectionResults) {
            setState(prevState => ({ ...prevState, callGetPeakDipDetectionResults: false }))
            // let startTime = momentDateGivenFormat(subDays(currentUTCtime(), state.selectedDuration), 'YYYY-MM-DD HH:mm:ss')
            // let endTime = momentDateGivenFormat(currentUTCtime(), 'YYYY-MM-DD HH:mm:ss')
            // let params = {"duration": "+1w", "asset_id": "d4075848948e82925275d96c5db0f47b", "future_metrics": true, "past_metrics": true,
            // "extrema_freq": "Daily", "top": 3}
            let params = {}
            // params.start_time = startTime
            // params.end_time = endTime
            params.duration = state.selectedDuration
            params.asset_id = props.selectedDetails && props.selectedDetails.asset_id ? props.selectedDetails.asset_id : ""
            // params.future_metrics = false
            params.past_metrics = true
            params.extrema_freq = state.selectedFrequency
            params.top = 3
            if(state.selectedMetric) {
                params.metric_name = state.selectedMetric
            }
            
            if(params.asset_id) {
                dispatch(getPeakDipDetectionResults(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results ? response.results : []
                            if(results) {
                                setState(prevState => ({ ...prevState, peakDipData: results, filteredPeakDipData: results, peakDipLoader: false, callonChangeinput: true }))
                            }

                        } else {
                            setState(prevState => ({ ...prevState, peakDipData: [], peakDipLoader: false }))
                        }

                    })
            }
        }
    }, [state.callGetPeakDipDetectionResults, dispatch, props.selectedDetails, state.selectedDuration, state.selectedFrequency, state.selectedMetric])

    useEffect(() => {
        if(state.callonChangeinput && state.peakDipData && state.peakDipData.length) {
            let hourlyData = state.peakDipData ? state.peakDipData : []
            let peakData = hourlyData.filter(e => e.extrema_type === "peak")
            let dipData = hourlyData.filter(e => e.extrema_type === "dip")
            // let futureData = hourlyData.filter(e => e.future_metrics)
            let pastData = hourlyData.filter(e => e.past_metrics)
            setState(prevState => ({ ...prevState, callonChangeinput: false, peakData, dipData, pastData, filteredPastData: pastData, formPeakData: state.selectedFrequency === "Hourly" ? true : false, createHeatMap: state.selectedFrequency !== "Hourly" ? true : false }))
        }
    }, [state.callonChangeinput, state.peakDipData, state.selectedFrequency, state.selectedMetric])

    useEffect(() => {
        if(state.formPeakData) {
            let peakData = state.peakData ? state.peakData : []
            let dates = []
            let data = []
            peakData.forEach(row => {
                if(state.selectedFrequency === "Daily") {
                    // let date = row.date
                    // dates.push(date)
                    // data.push(row.extrema_value)
                } else {

                    for(let i=0; i<=row.time_range_in_mins; i++) {
                        let date = momentDateGivenFormat(addMinutes(row.min_time, i), "YYYY-MM-DD HH:mm:ss")
                        dates.push(date)
                        data.push(row.extrema_score)
                    }
                }
            })
    
            let dipData = state.dipData ? state.dipData : []
            dipData.forEach(row => {
                if(state.selectedFrequency === "Daily") {
                    // let date = row.date
                    // dates.push(date)
                    // data.push(row.extrema_value)
                } else {
                    for(let i=0; i<=row.time_range_in_mins; i++) {
                        let date = momentDateGivenFormat(addMinutes(row.min_time, i), "YYYY-MM-DD HH:mm:ss")
                        dates.push(date)
                        data.push(-row.extrema_score)
                    }
                }
            })
    
            let peakGraphData = {}
            peakGraphData.name = "Peak"
            peakGraphData.data = data
            peakGraphData.labels = dates
            
            setState(prevState => ({ ...prevState, peakGraphData, formPeakData: false }))
        }
    }, [state.formPeakData, state.dipData, state.peakData, state.selectedFrequency])

    useEffect(() => {
        if(state.createHeatMap) {
            let peakDipData = state.peakDipData ? state.peakDipData : []
            let seriesPast = []
            let seriesFuture = []
            
            let pastData = []
            // let futureData = []
            let orderPeakDipData = _.orderBy(peakDipData, ['date'], ['asc'])
            let index = 0

            let peakData = orderPeakDipData.filter(e => e.extrema_type === "peak")
            let dipData = orderPeakDipData.filter(e => e.extrema_type === "dip")

            const currentDate = currentLocaltime();
            const selectedDays = [];
            if(state.selectedDuration === "+7d") {
                // Loop through the last 7 days and push each date to the array
                for (let i = 6; i >= 0; i--) {
                    const date = subDays(currentDate, i)
                    selectedDays.push(momentDateGivenFormat(date, 'YYYY-MM-DD')); // Format the date as needed
                }
            } else if(state.selectedDuration === "+30d") {
                // Loop through the last 7 days and push each date to the array
                for (let i = 34; i >= -1; i--) {
                    const date = subDays(currentDate, i)
                    selectedDays.push(momentDateGivenFormat(date, 'YYYY-MM-DD')); // Format the date as needed
                }
            }
            if(state.selectedDuration === "+7d") {
                let pastDipData = []
                selectedDays.forEach(day => {
                    let peakRow = peakData.filter(e => e.date === day) && peakData.filter(e => e.date === day).length ? peakData.filter(e => e.date === day)[0] : {}
                    let dipRow = dipData.filter(e => e.date === day) && dipData.filter(e => e.date === day).length ? dipData.filter(e => e.date === day)[0] : {}
                    let dataRow = {
                        w: momentDateGivenFormat(day, 'dddd'),
                        x: momentDateGivenFormat(day, 'ddd'),
                        z: day,
                    }
                    if(Object.entries(peakRow).length && Object.entries(dipRow).length) {
                        if(peakRow.extrema_strength > dipRow.extrema_strength) {
                            dataRow.y = peakRow.extrema_strength
                        } else {
                            dataRow.y = -dipRow.extrema_strength
                        }
                    } else if(Object.entries(peakRow).length) {
                        dataRow.y = peakRow.extrema_strength
                    } else if(Object.entries(dipRow).length) {
                        dataRow.y = -dipRow.extrema_strength
                    } else {
                        dataRow.y = 0
                    }
                    pastDipData.push(dataRow)
                })

                let seriesPastRow = {}
                seriesPastRow.name = ""
                seriesPastRow.data = pastDipData
                seriesPast.push(seriesPastRow)
            } else if(state.selectedDuration === "+30d") {
                selectedDays.forEach(day => {
                    index++
                    if(index > 7) {
                        let seriesPastRow = {}
                        seriesPastRow.name = ""
                        seriesPastRow.data = pastData
                        seriesPast.push(seriesPastRow)
                        
                        index = 1
                        pastData = []
                    }
                    let peakRow = peakData.filter(e => e.date === day) && peakData.filter(e => e.date === day).length ? peakData.filter(e => e.date === day)[0] : {}
                    let dipRow = dipData.filter(e => e.date === day) && dipData.filter(e => e.date === day).length ? dipData.filter(e => e.date === day)[0] : {}
                    let dataRow = {
                        w: momentDateGivenFormat(day, 'dddd'),
                        x: momentDateGivenFormat(day, 'ddd'),
                        z: day,
                    }
                    if(Object.entries(peakRow).length && Object.entries(dipRow).length) {
                        if(peakRow.extrema_strength > dipRow.extrema_strength) {
                            dataRow.y = peakRow.extrema_strength
                        } else {
                            dataRow.y = -dipRow.extrema_strength
                        }
                    } else if(Object.entries(peakRow).length) {
                        dataRow.y = peakRow.extrema_strength
                    } else if(Object.entries(dipRow).length) {
                        dataRow.y = -dipRow.extrema_strength
                    } else {
                        dataRow.y = 0
                    }
                    pastData.push(dataRow)
                })
            }            
            
            let heatOptions = {
                chart: {
                    height: 240,
                    type: "heatmap",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false
                    },
                    animations: {
                        enabled: false
                    },
                },
                tooltip: {
                    enabled: true,
                    style: {
                        fontSize: '9px',
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let retrunData = ''
                        // if(w.globals.initialSeries && w.globals.initialSeries.length) {
                        //     w.globals.initialSeries[seriesIndex].forEach(row => {
                                retrunData = '<div class="arrow_box"> <span>' +  w.globals.initialSeries[seriesIndex].data[dataPointIndex].z + ' '+  w.globals.initialSeries[seriesIndex].data[dataPointIndex].w+'</span> '
                                retrunData += '<span style="color:#039BE5"> ' + w.globals.initialSeries[seriesIndex].data[dataPointIndex].y + '</span>'                    
                                retrunData += '</div>'
                                return retrunData
                        //     })
                        // }
                        // return retrunData
                        // let val  = w.globals.initialSeries[0].data[dataPointIndex].y
                        // // let labelName = ""
                        
                        // if(val < 0) {
                        //     // labelName = "Dip"
                        //     val = -(val)
                        // } else if(val > 0) {
                        //     // labelName = "Peak"
                        // } else {
                        //     val = ""
                        // }
    
                        // let retrunData = '<div class="arrow_box"> <span>' +  w.globals.initialSeries[0].data[dataPointIndex].z + ' '+  w.globals.initialSeries[0].data[dataPointIndex].w+'</span> '
                        // retrunData += '<span style="color:#039BE5"> ' + val + '</span>'                    
                        // retrunData += '</div>'
    
                        // return retrunData
                    }
                },
                plotOptions: {
                    heatmap: {
                        shadeIntensity: 0.5,
                        radius: 0,
                        useFillColorAsStroke: false,
                        colorScale: {
                            ranges: [
                                {
                                    from: 1,
                                    to: 49,
                                    name: "low2",
                                    color: "#CD5A9F" //f48071 //f8aaa0
                                },
                                {
                                    from: 50,
                                    to: 79,
                                    name: "medium1",
                                    color: "#c6007e" //ff7171 //f15b46
                                },
                                {
                                    from: 80,
                                    to: 100,
                                    name: "extreme",
                                    color: "#8e3a80"
                                },
                                {
                                    from: -100,
                                    to: -51,
                                    name: "high",
                                    color: "#0057b8"
                                },
                                {
                                    from: -50,
                                    to: -34,
                                    name: "medium",
                                    color: "#485cc7"
                                },
                                {
                                    from: -33,
                                    to: -1,
                                    name: "low",
                                    color: "#05c3de"
                                },
                                {
                                    from: 0,
                                    to: 0,
                                    name: "",
                                    color: "#999999"
                                },
                            ]
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1
                },
                
                xaxis: {
                    show: true,
                    tooltip: {
                        enabled: false
                    },
                    type: 'string',
                    axisBorder: {
                        show: false,
                        color:'#434B5E'
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        datetimeUTC: false,
                        show: true,
                        style: {
                            colors: '#B8BBBE',
                            fontSize: '13px',
                            fontWeight: 'bolder',
                        },
                    },
                },
                yaxis: {
                    show: false,
                    // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                    axisBorder: {
                        show: false,
                        color: '#969AA7',
                    },
                    // tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                    labels: {
                        show: false,
                    }
                },
                legend: {
                    show: false,
                },
            }
            
            setState(prevState => ({ ...prevState, heatPastSeries: seriesPast, heatOptions, heatFutureSeries: seriesFuture }))
        }
    }, [state.createHeatMap, state.peakDipData, state.selectedDuration])

    const filterTableResults = (field, value, key) => {
        if(state.selectedFrequency === "Hourly") {
            let data = state.peakDipData
            if(key === "type" && value && value !== "") {
                data = data.filter(e => e.extrema_type === value)
            }
            
            if(key === "metrics" && value && value !== "") {
                if(value === "past") {
                    data = data.filter(e => e.past_metrics)
                } else {
                    data = data.filter(e => e.future_metrics)
                }
            }

            setState(prevState => ({ ...prevState, filteredPeakDipData: data, [field]: value }))
        } else {
            // let filteredFutureData = state.futureData
            let filteredPastData = state.pastData

            if(key === "type" && value && value !== "") {
                // filteredFutureData = filteredFutureData.filter(e => e.extrema_type === value)
                filteredPastData = filteredPastData.filter(e => e.extrema_type === value)
            }

            setState(prevState => ({ ...prevState, filteredPastData, [field]: value })) //filteredFutureData
        }
    }

    const filterZoomData = (zoomStartTime, zoomEndTIme) => {
        let data = state.filteredPeakDipData

        data = data.filter(e => momentConvertionUtcToLocalTime(e.min_time, "YYYY-MM-DD HH:mm:ss") >= zoomStartTime && momentConvertionUtcToLocalTime(e.max_time, "YYYY-MM-DD HH:mm:ss") <= zoomEndTIme)

        setState(prevState => ({ ...prevState, filteredPeakDipData: data }))
    }

    const resetGraph = (type) => {
        let graphData = state[type]
        setState(prevState => ({ ...prevState, [type]: "" }))
        setTimeout(() => {
            setState(prevState => ({ ...prevState, [type]: graphData, filteredPeakDipData: state.peakDipData })) // Set the same value
        }, 1000); // Change to the desired delay (in milliseconds)
    }
	
    return (
        <React.Fragment>
            <div className={`flex justify-end ${state.peakDipLoader ? "disabled" : ""}`}>
                <span className={`mr-2 text-sm self-center text-lightGray`}>Last</span>
                <span className={`mr-2 font-weight-bold text-sm cursor-pointer self-center ${state.selectedDuration === '+30d' ? 'border border-info bg-info mb-1 px-2 rounded-full text-white' : 'text-info'} `} onClick={() => setState(prevState => ({ ...prevState, peakGraphData: {}, peakDipLoader: true, selectedDuration: '+30d', callGetPeakDipDetectionResults: true }))}>1 Month</span>
                <span className={`mr-2 font-weight-bold text-sm cursor-pointer self-center ${state.selectedDuration === '+7d' ? 'border border-info bg-info mb-1 px-2 rounded-full text-white' : 'text-info'} `} onClick={() => setState(prevState => ({ ...prevState, peakGraphData: {}, peakDipLoader: true, selectedDuration: '+7d', callGetPeakDipDetectionResults: true }))}>1 Week</span>
                <span className={`mr-2 font-weight-bold text-sm cursor-pointer self-center ${state.selectedDuration === '+1d' ? 'border border-info bg-info mb-1 px-2 rounded-full text-white' : 'text-info'} `} onClick={() => setState(prevState => ({ ...prevState, peakGraphData: {}, peakDipLoader: true, selectedDuration: '+1d', callGetPeakDipDetectionResults: true }))}>24 Hours</span>
            </div>
            {state.peakDipLoader ?
                <div className="w-full flex justify-center mt-4">
                    <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                        <path d="M4 12a8 8 0 0112-6.9" />
                    </svg>
                </div>
            :
                <div className={``}>
                    <div className="rounded-md bg-GhostWhite px-3 pt-2 pb-3">
                        <div className="flex flex-wrap justify-between mb-2">
                            <div className="flex lg:w-3/4 w-full">
                                <p className="small mb-0 mr-2 self-end text-nowrap">Showing peak and dip </p>
                                <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-32 p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => setState(prevState => ({ ...prevState, heatPastSeries: [], peakDipData: [], peakGraphData: {}, selectedFrequency:  e.target.value, callGetPeakDipDetectionResults: true }))} value={state.selectedFrequency}>
                                    {state.dataFrequency && state.dataFrequency.map((cat) => {
                                        return(
                                            <option className='text-black' key={cat} value={cat}>{cat}</option>
                                        )
                                    })}
                                </select>
                                <p className="small mb-0 ml-1 self-end text-nowrap mx-2">data for metric.</p>
                                <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => setState(prevState => ({ ...prevState, selectedMetric: e.target.value }))} value={state.selectedFrequency}>
                                    {state.metricsGroup && state.metricsGroup.map((cat) => {
                                        return(
                                            <option className='text-black' key={cat} value={cat}>{cat}</option>
                                        )
                                    })}
                                </select>                             
                            </div>
                            {state.selectedFrequency === "Hourly" ?
                                <span className=' lg:w-3/4 w-full' onClick={() =>resetGraph("peakGraphData")}>
                                    <Icon icon="material-symbols-light:refresh" className="text-info" width="16" height="16" />
                                </span>
                            : null}
                        </div>
                        {state.selectedFrequency === "Hourly" ?
                            state.peakGraphData && Object.entries(state.peakGraphData).length ?
                                <ApexPositiveNegativeBar
                                    graphData={state.peakGraphData}
                                    sparkline={false}
                                    yaxis={false}
                                    yaxisLabel={false}
                                    xaxis={true}
                                    xaxisFormat={state.selectedDuration !== "+1d" && state.selectedDuration !== "+30d" ? 'dateWithtime' : 'dateWithtime'}
                                    xaxisLabel={true}
                                    axisLabelColor={'#B8BBBE'}
                                    paddingLeft={20}
                                    paddingTop={-50}
                                    legend={true}
                                    stacked={true}
                                    stackType={true}
                                    height={240}
                                    // barHeight={'100%'}
                                    barEndShape={'flat'}
                                    // columnWidth={state.selectedDuration !== "+1d" && state.selectedDuration !== "+30d" ? '40%' : '40%'}
                                    backgroundBarShape={'flat'}
                                    // backgroundBarColors={['#333947']}
                                    // showBackgroundBarColors={false}
                                    className={"transparentTooltip"}
                                    zoomEnabled={true}
                                    getZoomPeriod={true}
                                    zoomPeriod={(zoomStartTime, zoomEndTIme) => filterZoomData(zoomStartTime, zoomEndTIme)}
                                />
                            : null
                        : 
                            <div className="flex flex-wrap justify-between transparentTooltip">
                                <div className="flex justify-center lg:w-1/2 w-full lg:border-r border-info">
                                    <div className="w-60 pr-2">
                                        <React.Fragment>
                                        <span className="flex justify-center mt-3 text-black">Past</span>
                                        <div className="mt-n3">
                                        {state.heatOptions && Object.entries(state.heatOptions).length && state.heatPastSeries && state.heatPastSeries.length ?
                                            <Chart options={state.heatOptions} series={state.heatPastSeries} type="heatmap" height={180} />
                                        : null}
                                        <ul className="stock-legend flex justify-center" style={{ marginTop: state.heatPastSeries && state.heatPastSeries.length ? "-1rem" : "100px" }}>
                                            <li><span className="legend-circle" style={{backgroundColor: "#05c3de"}}></span> <span className="text-lightGray d-inline-block ml-1">Peak</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#8e3a80"}}></span> <span className="text-lightGray d-inline-block ml-1"> Dip</span></li>
                                        </ul>
                                        </div>
                                        </React.Fragment>
                                    </div>
                                </div>
                                <div className="lg:w-1/2 w-full lg:pl-3">
                                    <div className={`flex justify-between mb-2`}>
                                        <div className={`flex`}>
                                            <p className="m-0 self-end small mr-2">Show </p>
                                            <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs w-fit p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => filterTableResults('showPeakDipType', e.target.value, 'type')} value={state.showPeakDipType}>
                                                <option className='text-black' key={'All'} value={''}>{'All'}</option>
                                                <option className='text-black' key={'peak'} value={'peak'}>{'Peak'}</option>
                                                <option className='text-black' key={'dip'} value={'dip'}>{'Dip'}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: "Date",
                                                accessor: "date",
                                                Cell: cellInfo => (<span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, 'DD MMM YYYY')  : ''}</span>),
                                                width: 150
                                            },
                                            {
                                                Header: "Past",
                                                accessor: d => d.day +" : "+ d.extrema_type, //+" : "+ d.extrema_freq +" data"
                                                width: 250
                                            },
                                            {
                                                Header: "Strength",
                                                accessor: 'extrema_strength'
                                            },
                                        ]}
                                        data={state.filteredPastData ? state.filteredPastData : []}
                                        perPage={10}
                                        tableHead={'bg-lightGray text-black'}
                                        tableBody={'bg-white text-black'}
                                        tableType={'table-light-hover'}
                                        perPageClass={`bg-transparent text-lightGray`}
                                        textColor={'text-black'}
                                        selectedColor={'bg-extraLightGray'}
                                        dashboard={true}
                                        sortByColumn={"date"}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        emptyMessage={"There is no data on this criteria"}
                                    />
                                </div>
                            </div>
                        }
                    </div> 
                   {state.selectedFrequency === "Hourly" ?
                        <div className="rounded-md bg-GhostWhite p-3 mt-2">
                            {/* <p className="small m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et, sed do eiusmod tempor incididunt ut labore et.</p> */}
                            <div className="mt-2">
                                <div className={`flex justify-between mb-2`}>
                                    <div className={`flex`}>
                                        <p className="m-0 self-end small mr-2">Show </p>
                                        <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs lg:w-1/6 w-2/5 p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => filterTableResults('showPeakDipType', e.target.value, 'type')} value={state.showPeakDipType}>
                                            <option className='text-black' key={'All'} value={''}>{'All'}</option>
                                            <option className='text-black' key={'peak'} value={'peak'}>{'Peak'}</option>
                                            <option className='text-black' key={'dip'} value={'dip'}>{'Dip'}</option>
                                        </select>
                                        {state.selectedFrequency === "Hourly" ?
                                            <React.Fragment>
                                            <p className="m-0 self-end small mx-2"> data metrics type</p>
                                            <select className="self-center bg-transparent border-b border-mediumDarkGray text-black text-xs lg:w-1/6 w-2/5 p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={(e) => filterTableResults('showFuturePastData', e.target.value, 'type')} value={state.showFuturePastData}>
                                                <option className='text-black' key={'All'} value={''}>{'All'}</option>
                                                <option className='text-black' key={'future'} value={'future'}>Future</option>
                                                <option className='text-black' key={'past'} value={'past'}>Past</option>
                                            </select>
                                            </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: "Date",
                                            accessor: "extrema_time",
                                            Cell: cellInfo => (<span>{cellInfo.row.original.extrema_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.extrema_time, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                            width: 200
                                        },
                                        {
                                            Header: "Day",
                                            accessor: "day"
                                        },
                                        {
                                            Header: "Type",
                                            accessor: "extrema_type",
                                            Cell: cellInfo => (<span>{cellInfo.row.original.extrema_type ? capitalizeFirstLetter(cellInfo.row.original.extrema_type)  : ''}</span>),
                                        },
                                        {
                                            Header: "Frequency",
                                            accessor: "extrema_freq"
                                        },
                                        {
                                            Header: "Metrics Type",
                                            Cell: cellInfo => (<span>{cellInfo.row.original.past_metrics ? "Past" : cellInfo.row.original.past_metrics ? "Future" : ''}</span>)
                                        },
                                        {
                                            Header: "Strength",
                                            accessor: 'extrema_strength'
                                        }
                                    ]}
                                    data={state.filteredPeakDipData ? state.filteredPeakDipData : []}
                                    perPage={10}
                                    tableHead={'bg-lightGray text-black'}
                                    tableBody={'bg-white text-black'}
                                    tableType={'table-light-hover'}
                                    perPageClass={`bg-transparent text-lightGray`}
                                    textColor={'text-black'}
                                    selectedColor={'bg-extraLightGray'}
                                    dashboard={true}
                                    sortByColumn={"date"}
                                    riskTooltip={[]}
                                    onClickRow={tableRow => {}}
                                />
                            </div>
                        </div>
                    : null}
                </div>
            }
        </React.Fragment>
    )
}

export default PeakDipMetrics